import moment from "moment";
import * as types from "../actions/actionTypes";
import initialState from "./initialState";
import { dateWithFullTimeFormat } from "../constants/miscellaneous";

export default function sidebarErrorLogReducer(state = initialState.sidebarErrorLog, action) {
  switch (action.type) {
    case types.OPEN_ERROR_LOG_SLIDER:
      return Object.assign({}, state, { sliderVisible: true });
    case types.CLOSE_ERROR_LOG_SLIDER:
      return Object.assign({}, state, { sliderVisible: false });
    case types.PUSH_ERROR_LOG_TO_SLIDER: {
      const newErr = action.error;
      let newError = {};
      if (state.errors.length === 0) {
        newError = {
          id: 0,
          detail: newErr.detail,
          orchestrationId: newErr.orchestrationId,
          errorDate: moment().format(dateWithFullTimeFormat)
        };
      } else {
        const id = state.errors
          .map((e) => e.id)
          .sort()
          .reverse()[0];
        newError = {
          id: id + 1,
          detail: newErr.detail,
          orchestrationId: newErr.orchestrationId,
          errorDate: moment().format(dateWithFullTimeFormat)
        };
      }

      return Object.assign({}, state, { errors: [...state.errors, newError] });
    }
    case types.DISMISS_ITEM_ERROR_LOG_TO_SLIDER: {
      const newErrors1 = state.errors.filter((e) => e !== action.error);
      return Object.assign({}, state, { errors: newErrors1 });
    }
    case types.DISMISS_ALL_ERROR_LOG_TO_SLIDER:
      return Object.assign({}, state, { errors: [] });
    default:
      return state;
  }
}
