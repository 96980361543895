import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Label, Segment, Radio } from "semantic-ui-react";
import { Container, Row, Col, Button, Icon } from "../../../../ui";
import * as patientActions from "../../../../actions/patientActions";

export class AdvancedDirectives extends Component {
  state = {
    mode: "view",
    saveButtonPressed: false,
    originalModel: { hasAdvanceDirectives: "", advanceDirectivesProvided: "" },
    model: { hasAdvanceDirectives: "", advanceDirectivesProvided: "" }
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    const {
      patientId,
      actions: { loadAdvanceDirectives }
    } = this.props;
    loadAdvanceDirectives(patientId).then(() => {
      if (this._isMounted)
        this.setState({
          originalModel: { ...this.props.advanceDirectives },
          model: { ...this.props.advanceDirectives }
        });
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleEdit = () => {
    if (this._isMounted) this.setState({ mode: "edit", saveButtonPressed: false });
  };

  handleSave = () => {
    const { model } = this.state;
    const {
      patientId,
      actions: { loadAdvanceDirectives, saveAdvanceDirectives }
    } = this.props;
    if (this._isMounted) this.setState({ saveButtonPressed: true });
    saveAdvanceDirectives(patientId, model)
      .then(() => {
        loadAdvanceDirectives(patientId).then(() => {
          if (this._isMounted) this.setState({ model: { ...this.props.advanceDirectives }, mode: "view" });
          if (this.props.afterSave) this.props.afterSave();
        });
      })
      // .catch(() => {})
      .finally(() => {
        if (this._isMounted) this.setState({ saveButtonPressed: false });
      });
  };

  handleCancel = () => {
    if (this._isMounted) this.setState({ model: { ...this.state.originalModel } });
    if (this._isMounted) this.setState({ mode: "view", saveButtonPressed: false });
  };

  handleFormFieldChange = (e, data) => {
    const { model } = this.state;
    model[data.name] = data.value;
    if (this._isMounted) this.setState({ model });
  };

  renderView = () => {
    const {
      model: { hasAdvanceDirectives, advanceDirectivesProvided }
    } = this.state;
    return (
      <Container className="no-margin">
        <Row>
          <Col className="" style={{ paddingBottom: "1rem" }}>
            <Button variant="outline-primary" size="sm" onClick={this.handleEdit} title="Edit" className="float-end">
              <Icon name="pencil-square" />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col width="7">
            <Label>Does Patient have Advance Directives?</Label>
            <Segment basic padded className="no-v-padding">
              {hasAdvanceDirectives}
            </Segment>
          </Col>
          <Col width="9">
            <Label>Was information about Advance Directives provided to Patient?</Label>
            <Segment basic padded className="no-v-padding">
              {advanceDirectivesProvided}
            </Segment>
          </Col>
        </Row>
      </Container>
    );
  };

  renderEdit = () => {
    const { saveButtonText, saveButtonIcon, processing } = this.props;
    const {
      saveButtonPressed,
      model: { hasAdvanceDirectives, advanceDirectivesProvided }
    } = this.state;

    const hasAdvanceDirectivesOptions = [
      { value: "Yes", label: "Yes" },
      { value: "No", label: "No" },
      { value: "Declined/Refused", label: "Declined/Refused" }
    ];
    const advanceDirectivesProvidedOptions = [
      { value: "Yes", label: "Yes, information was provide" },
      { value: "No", label: "No, Patient declined information." }
    ];

    const disableSaveButton = saveButtonPressed || hasAdvanceDirectives === null || advanceDirectivesProvided === null;

    return (
      <Container className="no-margin">
        <Row>
          <Col width="7">
            <Label>Does Patient have Advance Directives?</Label>
            <Segment basic padded className="no-v-padding">
              {hasAdvanceDirectivesOptions.map((option) => (
                <React.Fragment key={option.value}>
                  <Radio
                    name="hasAdvanceDirectives"
                    value={option.value}
                    checked={hasAdvanceDirectives === option.value}
                    onChange={this.handleFormFieldChange}
                    label={option.label}
                    disabled={saveButtonPressed}
                  />
                  <br />
                </React.Fragment>
              ))}
            </Segment>
          </Col>
          <Col width="9">
            <Label>Was information about Advance Directives provided to Patient?</Label>
            <Segment basic padded className="no-v-padding">
              {advanceDirectivesProvidedOptions.map((option) => (
                <Radio
                  key={option.value}
                  name="advanceDirectivesProvided"
                  value={option.value}
                  checked={advanceDirectivesProvided === option.value}
                  onChange={this.handleFormFieldChange}
                  label={option.label}
                  disabled={saveButtonPressed}
                />
              ))}
            </Segment>
          </Col>
        </Row>
        <Row style={{ borderTop: "1px solid rgba(34,36,38,.15)" }}>
          <Col width="16" className="half-v-padding">
            <Button variant="danger" onClick={this.handleCancel} className="float-start">
              Cancel
            </Button>

            <Button
              variant="primary"
              onClick={this.handleSave}
              disabled={processing || disableSaveButton}
              loading={processing}
              className="float-end"
              style={{ minWidth: "100px" }}
            >
              {saveButtonText || `Save`}
              {saveButtonIcon && <i className={saveButtonIcon} />}
            </Button>
          </Col>
        </Row>
      </Container>
    );
  };

  render() {
    const { mode } = this.state;
    const { hideContinueButton } = this.props;
    // if (!advanceDirectives) return null;

    return (
      <Container className="no-margin">
        <Row>
          <Col>{mode === "view" ? this.renderView() : this.renderEdit()}</Col>
        </Row>
        {mode === "view" && !hideContinueButton && (
          <Row className="p-2" style={{ borderTop: "1px solid rgba(34,36,38,.15)" }}>
            <Col className="no-padding text-end">
              <Button
                variant="primary"
                onClick={() => {
                  this.props.afterSave();
                }}
                style={{ minWidth: "100px" }}
              >
                {`Continue`}
                <Icon name="chevron-right" />
              </Button>
            </Col>
          </Row>
        )}
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    advanceDirectives: state.patient.advanceDirectives
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...patientActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdvancedDirectives);
