import React, { Component, Suspense } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { isEmpty } from "lodash";
import "react-toastify/dist/ReactToastify.css";
import * as modalActions from "../actions/modalActions";
import * as lookupActions from "../actions/lookupActions";
import * as visitActions from "../actions/visitActions";
import * as userPreferenceActions from "../actions/userPreferenceActions";
import SideNav from "./navigation/SideNav";
import Toolbar from "./common/toolbar";
import NewVersionAlert from "./NewVersionAlert";
import ConnectionLostAlert from "./ConnectionLostAlert";
import TopNav from "./navigation/TopNav";
import SearchNav from "./navigation/SearchNav";
import SidebarErrorLog from "./sidebarErrorLog/SidebarErrorLog";
import ModalRoot from "./reusable/ModalRoot";
import { subscribe, unsubscribe, checkRoles } from "../helpers";
import { roleGroups } from "../constants/securityRoles";
import withRouter from "../hoc/withRouter";
import LoadComponent from "./reusable/LoadComponent";
import { ConfirmationModal } from "../ui";

export class DefaultLayout extends Component {
  state = {
    isMobile: false,
    isOpen: false
  };

  componentDidMount() {
    const {
      centerContext: centerId,
      currentUserId,
      actions: { loadTheme, loadVisitTypes, loadVisitStatuses, loadCentersLookup, loadCenterContext, loadCurrentUserId }
    } = this.props;

    loadTheme();
    loadCentersLookup();
    loadCenterContext();
    loadVisitTypes();
    loadVisitStatuses();
    loadCurrentUserId();
    this.loadUserStatusWatch();

    if (!isEmpty(centerId)) subscribe(`/visits/center/${centerId}`);
    if (!isEmpty(currentUserId)) {
      subscribe(`/visits/provider/${currentUserId}`);
      subscribe(`/appointments/provider/${currentUserId}`);
    }

    this.updateWindowWidth();
    window.addEventListener("resize", this.updateWindowWidth);
    window.addEventListener("scroll", () => {
      const element = document.getElementById("btn-back-to-top");
      if (element && window.scrollY > 300) {
        element.classList.add("show");
      } else if (element) {
        element.classList.remove("show");
      }
    });
  }

  loadUserStatusWatch() {
    this.props.actions.loadStatusWatchMyPatient();
    this.props.actions.loadStatusWatchVisiting();
  }

  componentDidUpdate(prevProps) {
    const { centerContext: centerId, currentUserId } = this.props;
    if (centerId !== prevProps.centerContext) {
      if (!isEmpty(prevProps.centerContext)) unsubscribe(`/visits/center/${prevProps.centerContext}`);
      if (!isEmpty(centerId)) subscribe(`/visits/center/${centerId}`);
    }
    if (currentUserId !== prevProps.currentUserId) {
      if (!isEmpty(prevProps.currentUserId)) {
        unsubscribe(`/visits/provider/${prevProps.currentUserId}`);
        unsubscribe(`/appointments/provider/${prevProps.currentUserId}`);
      }
      if (!isEmpty(currentUserId)) {
        subscribe(`/visits/provider/${currentUserId}`);
        subscribe(`/appointments/provider/${currentUserId}`);
      }
    }
  }

  componentWillUnmount() {
    const { centerContext: centerId, currentUserId } = this.props;
    unsubscribe(`/visits/center/${centerId}`);
    if (!isEmpty(currentUserId)) {
      unsubscribe(`/visits/provider/${currentUserId}`);
      unsubscribe(`/appointments/provider/${currentUserId}`);
    }
  }

  updateWindowWidth = () => {
    if (window.innerWidth < 767) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  handleNavToggle = () => {
    const { isOpen } = this.state;
    if (isOpen) this.setState({ isOpen: false });
    else this.setState({ isOpen: true });
  };

  handleReportProblem = () => {
    this.props.actions.showModal({
      type: "EXPORT_CLIENT_EVENTS_MODAL",
      props: { open: true, isNew: false }
    });
  };

  render() {
    const {
      theme,
      isProvider,
      showSearchBar,
      confirmationModal,
      router: {
        location: { pathname }
      }
    } = this.props;
    const { isMobile, isOpen } = this.state;
    return (
      <div>
        <button
          id="btn-back-to-top"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
        >
          <i className="bi bi-chevron-up" />
        </button>
        <ConnectionLostAlert />
        <NewVersionAlert />
        <div style={{ backgroundColor: "white", borderBottom: "1px solid rgba(34, 36, 38, 0.15)" }}>
          <TopNav location={this.props.location} isMobile={isMobile} handleNavToggle={this.handleNavToggle} />
          {pathname !== "/search" && showSearchBar !== false ? <SearchNav /> : null}
        </div>
        <SideNav
          authRoles={this.props.authRoles}
          isMobile={isMobile}
          isOpen={isOpen}
          handleNavToggle={this.handleNavToggle}
          isProvider={isProvider}
        />
        {isOpen && (
          <div
            role="button"
            tabIndex={0}
            className="side-nav-overlay"
            onClick={this.handleNavToggle}
            onKeyDown={() => {}}
          />
        )}
        <div className="page-wrapper">
          <Suspense fallback={<LoadComponent />}>
            <Outlet />
          </Suspense>
        </div>
        <SidebarErrorLog />
        <ModalRoot />
        {confirmationModal.show && <ConfirmationModal {...confirmationModal.props} />}
        <ToastContainer />
        {false && <Toolbar placement="right" autohide={false} />}
        {false && (
          <div id="div-float-log-error">
            <a href="#" className="hbutton" onClick={this.handleReportProblem}>
              <span className="hicon">
                <i aria-hidden="true" className="telegram plane icon" />
              </span>
              <span className="htext">Report a problem</span>
            </a>
          </div>
        )}
        <link rel="stylesheet" type="text/css" href={theme} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { auth: { user: { profile: { roles = [] } = {} } = {} } = {} } = state;
  const isProvider = checkRoles(roleGroups.medicalProvider, roles);
  return {
    isProvider,
    authRoles: roles,
    centerContext: state.userPreference.centerContext,
    currentUserId: state.userPreference.currentUserId,
    confirmationModal: state.confirmationModal,
    theme: state.userPreference.theme
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...modalActions, ...lookupActions, ...visitActions, ...userPreferenceActions },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DefaultLayout));
