import center from "./center";
import patientDemographics from "./patientDemographics";
import provider from "./provider";
import patient from "./patient";
import pharmacy from "./pharmacy";
import prescription from "./prescription";
import screening from "./screening";
import report from "./report";
import user from "./user";
import notification from "./notification";
import templates from "./templates";

export default {
  ...center,
  ...patientDemographics,
  ...provider,
  ...patient,
  ...pharmacy,
  ...prescription,
  ...screening,
  ...report,
  ...user,
  ...notification,
  ...templates
};
