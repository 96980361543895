import apiSlice from "../../api/apiSlice";
import { surveySaved, clearCachedSurvey, clearCachedSurveys } from "../../reducers/surveysSlice";

const store = window.cleanSlate.store;

const handleSurveyResultAdded = (message) => {
  /* {"Type":"@Notification.Survey.SurveyResultAdded","Title":"Survey has been updated!","Content":{"patientId":"4925133c-c222-4312-9384-76b22e82138b","visitId":"f4ee8521-e5df-44c4-a699-7d4bce0f2557","orchestrationId":"98de7ec6-2b37-4a25-95d6-d1d4a2de6bdb","platformEventId":"f6970c6f-ab33-44d4-b956-c9dc740e6cb4"},"Options":null,"Time":"2023-08-29T14:56:41.8242799+03:00"} */
  const { patientId, visitId, templateId, surveyId } = message.Content;
  store.dispatch(surveySaved({ patientId, templateId, surveyId }));
  store.dispatch(clearCachedSurvey({ patientId, templateId, surveyId }));
  store.dispatch(
    apiSlice.util.invalidateTags([
      `Template-${patientId}-${visitId}`,
      `Template-${patientId}-${templateId}`,
      `Template-${patientId}-${templateId}-${visitId}`
    ])
  );
};

const handleSurveyResultsCleared = (message) => {
  /* {"Type":"@Notification.Survey.SurveyResultsCleared","Title":"Survey has been cleared!","Content":{"patientId":"4925133c-c222-4312-9384-76b22e82138b","visitId":"f4ee8521-e5df-44c4-a699-7d4bce0f2557","orchestrationId":"f3582ee4-6abd-4c9a-afa4-065f12e0f623","platformEventId":"ec3c2baf-8fa5-4036-80f8-6b54575c74b7"},"Options":null,"Time":"2023-08-29T17:47:21.1648434+03:00"} */
  const { patientId, visitId } = message.Content;
  if (store.getState().surveys.context.patientId === patientId) {
    store.dispatch(clearCachedSurveys());
  }
  store.dispatch(apiSlice.util.invalidateTags([`Template-${patientId}-${visitId}`]));
};

export default {
  handleSurveyResultAdded,
  handleSurveyResultsCleared
};
