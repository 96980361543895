import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import * as types from "./actionTypes";
import { pollApiEvents, processApiResponse, processApiError, processThenThrowApiError } from "../helpers";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { adalApiFetch } from "../adalConfig";
import { API_URL } from "../constants/environment";
import { fetchUsersByRoles } from "../api/user";
import { updateUserPreference } from "./userPreferenceActions";
import api from "../api";
import { toastErrorOptions } from "../constants/toastconfig";

function loadMainPrescribersSuccess(mainPrescribers) {
  return {
    type: types.LOAD_MAIN_PRESCRIBERS_SUCCESS,
    mainPrescribers
  };
}

function loadAllUsersSuccess(list) {
  return {
    type: types.LOAD_ALL_USERS_SUCCESS,
    list
  };
}

function loadDrugFavoritesDone(drugFavorites) {
  return {
    type: types.LOAD_DRUG_FAVORITES_DONE,
    drugFavorites
  };
}

export function loadMainPrescribers(roles) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadMainPrescribers"));
    return fetchUsersByRoles(roles)
      .then((mainPrescribers) => dispatch(loadMainPrescribersSuccess(mainPrescribers)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadUsersWithRoles(roles, activeOnly = true) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadAllUsersSuccess"));
    return fetchUsersByRoles(roles, activeOnly)
      .then(({ users }) => dispatch(loadAllUsersSuccess(users)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function filterUsers(isActive) {
  return (dispatch) => {
    dispatch({ type: types.LOAD_USERS_BY_FILTER_SUCCESS, isActive });
  };
}

export function login() {
  return adalApiFetch(
    `${API_URL}/Users/Login`,
    {
      method: "post"
    },
    true,
    true /* logout user if he is forbidden from accessing login api */
  )
    .then((response) => processApiResponse(response.orchestrationId))
    .then((orchestrationId) => pollApiEvents(orchestrationId, ["IUserLoggedinSuccessfully"]))
    .then((response) => {
      if (window.cleanSlate.store)
        window.cleanSlate.store.dispatch(updateUserPreference(response.UserPreferenceSetting));
      return response;
    })
    .catch((_error) => {
      processApiError(_error);
    });
}

export function toggleUserStatus(userId, activate) {
  return (dispatch) => {
    dispatch(beginAjaxCall("toggleUserStatus"));
    return api
      .toggleUserStatus(userId, activate)
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) =>
        pollApiEvents(orchestrationId, ["IProviderActivatedEvent", "IProviderInactivatedEvent"])
      )
      .then(() => dispatch({ type: types.TOGGLE_USER_STATUS_SUCCESS, userId, isActive: activate }))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function sortUserByColumn(sortColumn, columnToSort) {
  return {
    type: types.SORT_USER_BY_COLUMN,
    sortColumn,
    columnToSort
  };
}

export function loadDrugFavorites() {
  return (dispatch, getState) => {
    const userId = getState().userPreference.currentUserId;
    if (isEmpty(userId)) throw Error("Cannot fetch drug favorites for undefined user");
    dispatch(beginAjaxCall("loadDrugFavorites"));
    return adalApiFetch(`${API_URL}/Users/${userId}/DrugFavorites`)
      .then((drugFavorites) => {
        if (
          drugFavorites.filter((i) => isNaN(i.dosage) || i.favoriteDrugId === `00000000-0000-0000-0000-000000000000`)
            .length > 0
        ) {
          toast.error("Corrupted favorite prescriptions data", toastErrorOptions);
        }
        return dispatch(
          loadDrugFavoritesDone(
            drugFavorites.filter((i) => !isNaN(i.dosage) && i.favoriteDrugId !== `00000000-0000-0000-0000-000000000000`)
          )
        );
      })
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function addDrugFavorites(prescription) {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall("addDrugFavorites"));
    const userId = getState().userPreference.currentUserId;
    return adalApiFetch(`${API_URL}/Users/${userId}/DrugFavorites`, {
      method: "POST",
      body: JSON.stringify(prescription)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IFavoriteDrugAddedToUserEvent"))
      .then(() => {
        toast.success("Favorite prescription updated successfully");
      })
      .then(() => dispatch(loadDrugFavorites()))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function editDrugFavorites(favoriteDrugId, prescription) {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall("editDrugFavorites"));
    const userId = getState().userPreference.currentUserId;
    return adalApiFetch(`${API_URL}/Users/${userId}/DrugFavorites/${favoriteDrugId}`, {
      method: "PUT",
      body: JSON.stringify(prescription)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IUserFavoriteDrugUpdatedEvent"))
      .then(() => {
        toast.success("Favorite prescription added successfully");
      })
      .then(() => dispatch(loadDrugFavorites()))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function deleteDrugFavorites(favoriteDrugId) {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall("deleteDrugFavorites"));
    const userId = getState().userPreference.currentUserId;
    return adalApiFetch(`${API_URL}/Users/${userId}/DrugFavorites/${favoriteDrugId}`, {
      method: "DELETE"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IUserFavoriteDrugDeletedEvent"))
      .then(() => dispatch(loadDrugFavorites()))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function registerUserCenterByGateway(
  providerId,
  body = { allCenters: true /* , specificCenterIds: ["string"] */, gateway: "surescripts" }
) {
  return (dispatch) => {
    dispatch(beginAjaxCall("registerUserCenterByGateway"));
    return adalApiFetch(`${API_URL}/Users/${providerId}/RegisterUserCenterByGateway`, {
      method: "PUT",
      body: JSON.stringify(body)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) =>
        pollApiEvents(orchestrationId, [
          "IPrescriberCentersAlreadyRegisteredEvent",
          "IPrescriberCentersRegistrationsRetrievedEvent"
        ])
      )
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function resetRxPin(providerId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("resetRxPin"));
    return adalApiFetch(`${API_URL}/Users/${providerId}/rxpin/reset`, {
      method: "POST"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IRxPinResetEvent"))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}
