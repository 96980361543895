import React, { Component } from "react";
import shortid from "shortid";
import { Modal, Button, Icon } from ".";
import { hideConfirmationModal } from "../helpers";

export class ConfirmationModal extends Component {
  state = { confirmButtonPressed: false };
  handleConfirm = async () => {
    this.setState({ confirmButtonPressed: true });
    await this.props.onConfirm();
    this.hideModal();
  };

  handleCancel = async () => {
    if (Object.prototype.hasOwnProperty.call(this.props, "onCancel")) {
      await this.props.onCancel();
    }

    this.hideModal();
  };

  hideModal = () => hideConfirmationModal();

  render() {
    const { confirmButtonPressed } = this.state;
    const {
      icon,
      iconColor = "secondary",
      title,
      description,
      confirmButtonMessage = "Confirm",
      cancelButtonMessage = "Cancel",
      confirmButtonVariant = "primary",
      cancelButtonVariant = "danger",
      size = "md",
      centered = false
    } = this.props;

    return (
      <Modal
        size={size}
        backdrop="static"
        keyboard={false}
        onHide={this.hideModal}
        centered={centered}
        className="confirmation-modal"
        show
      >
        {title && (
          <Modal.Header id="confirmation-modal" className="py-2" closeButton>
            {icon && <Icon name={icon} className={`me-2 fs-4 text-${iconColor}`} />}
            <strong>{title}</strong>
          </Modal.Header>
        )}
        <Modal.Body id="lbl-modalDesc" style={{ textAlign: "justify" }}>
          {typeof description === "function"
            ? description()
            : description.split("\n").map((item) => (
                <span key={`rand-${shortid.generate()}`}>
                  {item}
                  <br />
                </span>
              ))}
        </Modal.Body>
        <Modal.Footer>
          {cancelButtonMessage && (
            <Button variant={cancelButtonVariant} onClick={this.handleCancel} style={{ marginRight: "auto" }}>
              {cancelButtonMessage}
            </Button>
          )}
          {confirmButtonMessage && (
            <Button
              variant={confirmButtonVariant}
              onClick={this.handleConfirm}
              loading={confirmButtonPressed}
              disabled={confirmButtonPressed}
            >
              {confirmButtonMessage}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ConfirmationModal;
