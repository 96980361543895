// ajaxStatusActions
export const BEGIN_AJAX_CALL = "BEGIN_AJAX_CALL";
export const AJAX_CALL_DONE = "AJAX_CALL_DONE";
export const AJAX_CALL_ERROR = "AJAX_CALL_ERROR";

// context
export const SET_CONTEXT = "SET_CONTEXT";

// badgeActions
export const UPDATE_BADGE_AMOUNT = "UPDATE_BADGE_AMOUNT";
export const LOAD_CHART_BADGE_SUCCESS = "LOAD_CHART_BADGE";
export const LOAD_PRESCRIPTIONS_TO_SIGN_BADGE_SUCCESS = "LOAD_PRESCRIPTIONS_TO_SIGN_BADGE";
export const SET_VISITING_STATUS_BADGE = "LOAD_VISITING_STATUS_BADGE";
export const SET_MY_PATIENT_STATUS_BADGE = "LOAD_MY_PATIENT_STATUS_BADGE";
export const SET_LAB_SUBMISSION_QUEUE_COUNT = "SET_LAB_SUBMISSION_QUEUE_COUNT";

export const LOAD_TASKS_BADGE_SUCCESS = "LOAD_TASKS_BADGE";

export const LOAD_COSIGN_BADGE_SUCCESS = "LOAD_COSIGN_BADGE";
export const LOAD_VISITS_GROUP_BADGE_SUCCESS = "LOAD_VISITS_GROUP_BADGE";
export const LOAD_NOTIFICATIONS_BADGE_SUCCESS = "LOAD_NOTIFICATIONS_BADGE_SUCCESS";
export const RESET_NOTICATIONS_BADGE = "RESET_NOTICATIONS_BADGE";

// authentication
export const STORE_TOKEN = "STORE_TOKEN";
export const STORE_USER = "STORE_USER";

// documents
export const SET_DOCUMENTS_FORCE_RELOAD = "SET_DOCUMENTS_FORCE_RELOAD";
export const GET_DOCUMENT_SUCCESS = "GET_DOCUMENT_SUCCESS";
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";
export const LOAD_AVAILABLE_DOCS_SUCCESS = "LOAD_AVAILABLE_DOCS_SUCCESS";
export const EDIT_DOCUMENT_SUCCESS = "EDIT_DOCUMENT_SUCCESS";
export const DOWNLOAD_DOCS_SUCCESS = "DOWNLOAD_DOCS_SUCCESS";

// centerActions
export const LOAD_CENTERS_SUCCESS = "LOAD_CENTERS_SUCCESS";
export const CREATE_CENTER_SUCCESS = "CREATE_CENTER_SUCCESS";
export const LOAD_CENTER_DETAILS_SUCCESS = "LOAD_CENTER_DETAILS_SUCCESS";
export const UPDATE_CENTER_DETAILS_SUCCESS = "UPDATE_CENTER_DETAILS_SUCCESS";
export const UPDATE_CENTER_SORT = "UPDATE_CENTER_SORT";
export const CLEAR_CENTER_ADDRESS_HISTORY = "CLEAR_CENTER_ADDRESS_HISTORY";
export const FETCH_CENTER_ADDRESS_HISTORY_DONE = "FETCH_CENTER_ADDRESS_HISTORY_DONE";
export const CLEAR_LOCATION_AND_DIRECTOR_HISTORY = "CLEAR_LOCATION_AND_DIRECTOR_HISTORY";
export const FETCH_LOCATION_AND_DIRECTOR_HISTORY_DONE = "FETCH_LOCATION_AND_DIRECTOR_HISTORY_DONE";
export const SET_FORCE_UPDATE_CENTER_DETAILS = "SET_FORCE_UPDATE_CENTER_DETAILS";

// patientActions
export const LOAD_PATIENT_SUMMARY_SUCCESS = "LOAD_PATIENT_SUMMARY_SUCCESS";
export const CREATE_PATIENT_SUCCESS = "CREATE_PATIENT_SUCCESS";
export const LOAD_ADVANCE_DIRECTIVES_DONE = "LOAD_ADVANCE_DIRECTIVES_DONE";
export const RETRIEVE_PATIENT_ID_SUCCESS = "RETRIEVE_PATIENT_ID_SUCCESS";
export const CREATE_NEW_PATIENT_SUCCESS = "CREATE_NEW_PATIENT_SUCCESS";
export const LOAD_DISCHARGE_REASONS_SUCCESS = "LOAD_DISCHARGE_REASONS_SUCCESS";
export const UPDATE_CURRENT_PATIENT = "UPDATE_CURRENT_PATIENT";
export const SET_PATIENT_LEVEL = "SET_PATIENT_LEVEL";
export const UPDATE_CURRENT_PATIENT_ADDRESS = "UPDATE_CURRENT_PATIENT_ADDRESS";
export const UPDATE_PATIENT_LEVEL = "UPDATE_PATIENT_LEVEL";
export const UPDATE_CURRENT_PATIENT_INSURANCE = "UPDATE_CURRENT_PATIENT_INSURANCE";
export const UPDATE_CURRENT_PATIENT_TREATMENT_PLAN_STATUS = "UPDATE_CURRENT_PATIENT_TREATMENT_PLAN_STATUS";
export const CLEAR_PATIENT = "CLEAR_PATIENT";
export const LOAD_PATIENT_EXTERNAL_REFS_SUCCESS = "LOAD_PATIENT_EXTERNAL_REFS_SUCCESS";
export const GET_PATIENT_NOTE_SLASH_TASK_COUNT_DONE = "GET_PATIENT_NOTE_SLASH_TASK_COUNT_DONE";
export const FETCH_CUSTOM_FIELDS_DONE = "FETCH_CUSTOM_FIELDS_DONE";

// patient export actions
export const FETCH_PATIENT_EXPORT_DATA_DONE = "FETCH_PATIENT_EXPORT_DATA_DONE";

// demographicActions
export const SET_SSN = "SET_SSN";
export const SET_UNMASKED_SSN = "SET_UNMASKED_SSN";
export const CLEAR_PATIENT_DEMOGRAPHICS = "CLEAR_PATIENT_DEMOGRAPHICS";
export const LOAD_PATIENT_DEMOGRAPHICS_DONE = "LOAD_PATIENT_DEMOGRAPHICS_DONE";
export const LOAD_COMM_PREF_SUCCESS = "LOAD_COMM_PREF_SUCCESS";
export const LOAD_RELATIONSHIPS_SUCCESS = "LOAD_RELATIONSHIPS_SUCCESS";
export const LOAD_ETHNICITIES_SUCCESS = "LOAD_ETHNICITIES_SUCCESS";
export const LOAD_MARITAL_STATUSES_SUCCESS = "LOAD_MARITAL_STATUSES_SUCCESS";
export const LOAD_RACES_SUCCESS = "LOAD_RACES_SUCCESS";
export const LOAD_TREATMENTS_SUCCESS = "LOAD_TREATMENTS_SUCCESS";
export const LOAD_APPOINTMENT_CANCEL_REASON_SUCCESS = "LOAD_APPOINTMENT_CANCEL_REASON_SUCCESS";
export const LOAD_LANGUAGES_SUCCESS = "LOAD_LANGUAGES_SUCCESS";
export const LOAD_PERSON_ADDRESS_SUCCESS = "LOAD_PERSON_ADDRESS_SUCCESS";
export const UPDATE_CORE_DEMO_SUCCESS = "UPDATE_CORE_DEMO_SUCCESS";
export const DELETE_BILLING_FLAG = "DELETE_BILLING_FLAG";
export const CREATE_ADDRESS_SUCCESS = "CREATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const DELETE_ADDRESS_SUCCESS = "DELETE_ADDRESS_SUCCESS";
export const CREATE_PHONE_SUCCESS = "CREATE_PHONE_SUCCESS";
export const UPDATE_PHONE_SUCCESS = "UPDATE_PHONE_SUCCESS";
export const DELETE_PHONE_SUCCESS = "DELETE_PHONE_SUCCESS";
export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS";
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS";
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS";
export const CREATE_EMAIL_SUCCESS = "CREATE_EMAIL_SUCCESS";
export const UPDATE_EMAIL_SUCCESS = "UPDATE_EMAIL_SUCCESS";
export const DELETE_EMAIL_SUCCESS = "DELETE_EMAIL_SUCCESS";
export const CREATE_PHOTO_SUCCESS = "CREATE_PHOTO_SUCCESS";
export const SET_DEFAULT_PRESCRIBING_PROVIDER_DONE = "SET_DEFAULT_PRESCRIBING_PROVIDER_DONE";
export const LOAD_MAIN_PRESCRIBER_SUCCESS = "LOAD_MAIN_PRESCRIBER_SUCCESS";
export const LOAD_DEF_PRESC_PROV_SUCCESS = "LOAD_DEF_PRESC_PROV_SUCCESS";
export const DISCHARGE_PATIENT_SUCCESS = "DISCHARGE_PATIENT_SUCCESS";
export const REVERSE_DISCHARGE_PATIENT_SUCCESS = "REVERSE_DISCHARGE_PATIENT_SUCCESS";
export const DELETE_PRESC_PROVIDER = "DELETE_PRESC_PROVIDER";
export const DELETE_PRIMARY_FLAG = "DELETE_PRIMARY_FLAG";
export const CREATE_PCP_SUCCESS = "CREATE_PCP_SUCCESS";
export const LOAD_GENDERPRONOUNS_SUCCESS = "LOAD_GENDERPRONOUNS_SUCCESS";
export const LOAD_GENDER_IDENTITIES_SUCCESS = "LOAD_GENDER_IDENTITIES_SUCCESS";
export const LOAD_SEXASSIGNEDATBIRTHS_SUCCESS = "LOAD_SEXASSIGNEDATBIRTHS_SUCCESS";
export const LOAD_SEXUALORIENTATIONS_SUCCESS = "LOAD_SEXUALORIENTATIONS_SUCCESS";
export const CREATE_OUTSIDE_COUNSELOR_SUCCESS = "CREATE_OUTSIDE_COUNSELOR_SUCCESS";
export const UPDATE_PSYCHIATRIST_SUCCESS = "UPDATE_PSYCHIATRIST_SUCCESS";
export const UPDATE_OUTSIDE_COUNSELOR_SUCCESS = "UPDATE_OUTSIDE_COUNSELOR_SUCCESS";
export const CREATE_OB_PROVIDER_SUCCESS = "CREATE_OB_PROVIDER_SUCCESS";
export const UPDATE_OB_PROVIDER_SUCCESS = "UPDATE_OB_PROVIDER_SUCCESS";
export const UPDATE_OTHER_PROVIDERS_SUCCESS = "UPDATE_OTHER_PROVIDERS_SUCCESS";
export const LOAD_PATIENT_HOMECENTER_SUCCESS = "LOAD_PATIENT_HOMECENTER_SUCCESS";
export const UPDATE_DISCHARGE_SUCCESS = "UPDATE_DISCHARGE_SUCCESS";
export const LOAD_PATIENT_DISCHARGE_REASON_SUCCESS = "LOAD_PATIENT_DISCHARGE_REASON_SUCCESS";
export const LOAD_PATIENT_DISCHARGE_SUMMARY_SUCCESS = "LOAD_PATIENT_DISCHARGE_SUMMARY_SUCCESS";
export const LOAD_AVAILBLE_GRAPH_LABS_SUCCESS_SUCCESS = "LOAD_AVAILBLE_GRAPH_LABS_SUCCESS_SUCCESS";
export const LOAD_LABS_ORDES_AND_RESULTS_PER_VISIT_SUCCESS = "LOAD_LABS_ORDES_AND_RESULTS_PER_VISIT_SUCCESS";

// patientHistoryActions
export const LOAD_HISTORICAL_ADDRESS_SUCCESS = "LOAD_HISTORICAL_ADDRESS_SUCCESS";
export const LOAD_MEDICATION_SUCCESS = "LOAD_MEDICATION_SUCCESS";
export const LOAD_SOCIAL_HIST_SUCCESS = "LOAD_SOCIAL_HIST_SUCCESS";
export const LOAD_ALLERGY_HIST_SUCCESS = "LOAD_ALLERGY_HIST_SUCCESS";
export const LOAD_FAMILY_HIST_SUCCESS = "LOAD_FAMILY_HIST_SUCCESS";
export const LOAD_PATIENT_HIST_SUCCESS = "LOAD_PATIENT_HIST_SUCCESS";
export const CLEAR_PATIENT_HISTORY = "CLEAR_PATIENT_HISTORY";
export const LOAD_MEDICAL_HIST_SUCCESS = "LOAD_MEDICAL_HIST_SUCCESS";
export const LOAD_INTERVENTION_HISTORY_SUCCESS = "LOAD_INTERVENTION_HISTORY_SUCCESS";
export const LOAD_OB_PROVIDER_SUCCESS = "LOAD_OB_PROVIDER_SUCCESS";
export const LOAD_OUTSIDE_COUNSELOR_SUCCESS = "LOAD_OUTSIDE_COUNSELOR_SUCCESS";
export const CREATE_MEDICATION_SUCCESS = "CREATE_MEDICATION_SUCCESS";
export const UPDATE_MEDICATION_SUCCESS = "UPDATE_MEDICATION_SUCCESS";
export const DELETE_MEDICATION_SUCCESS = "DELETE_MEDICATION_SUCCESS";
export const CREATE_SOCIAL_HIST_SUCCESS = "CREATE_SOCIAL_HIST_SUCCESS";
export const CREATE_FAMILY_HIST_SUCCESS = "CREATE_FAMILY_HIST_SUCCESS";
export const UPDATE_FAMILY_HIST_SUCCESS = "UPDATE_FAMILY_HIST_SUCCESS";
export const DELETE_FAMILY_HIST_SUCCESS = "DELETE_FAMILY_HIST_SUCCESS";
export const CREATE_ALLERGY_HIST_SUCCESS = "CREATE_ALLERGY_HIST_SUCCESS";
export const UPDATE_ALLERGY_HIST_SUCCESS = "UPDATE_ALLERGY_HIST_SUCCESS";
export const DELETE_ALLERGY_HIST_SUCCESS = "DELETE_ALLERGY_HIST_SUCCESS";
export const CREATE_MEDICAL_HIST_SUCCESS = "CREATE_MEDICAL_HIST_SUCCESS";
export const UPDATE_MEDICAL_HIST_SUCCESS = "UPDATE_MEDICAL_HIST_SUCCESS";
export const DELETE_MEDICAL_HIST_SUCCESS = "DELETE_MEDICAL_HIST_SUCCESS";
export const CREATE_INTERVENTION_HISTORY_SUCCESS = "CREATE_INTERVENTION_HISTORY_SUCCESS";
export const UPDATE_INTERVENTION_HISTORY_SUCCESS = "UPDATE_INTERVENTION_HISTORY_SUCCESS";
export const DELETE_INTERVENTION_HISTORY_SUCCESS = "DELETE_INTERVENTION_HISTORY_SUCCESS";
export const CREATE_PATIENT_HISTORY_ITEM_BY_VISIT_SUCCESS = "CREATE_PATIENT_HISTORY_ITEM_BY_VISIT_SUCCESS";

// noteActions
export const CREATE_NOTE_SUCCESS = "CREATE_NOTE_SUCCESS";
export const LOAD_NOTES_SUCCESS = "LOAD_NOTES_SUCCESS";

// prescriptionActions
export const LOAD_PHARMACIES_SUCCESS = "LOAD_PHARMACIES_SUCCESS";
export const LOAD_PATIENT_FAVORITE_PHARMACIES_SUCCESS = "LOAD_PATIENT_FAVORITE_PHARMACIES_SUCCESS";
export const CLEAR_PATIENT_FAVORITE_PHARMACIES = "CLEAR_PATIENT_FAVORITE_PHARMACIES";
export const LOAD_PRESCRIPTIONS_SUCCESS = "LOAD_PRESCRIPTIONS_SUCCESS";
export const LOAD_RECURRING_PRESCRIPTION_SUCCESS = "LOAD_RECURRING_PRESCRIPTION_SUCCESS";
export const CREATE_PRESCRIPTION_SUCCESS = "CREATE_PRESCRIPTION_SUCCESS";
export const CREATE_RECURRING_PRESCRIPTION_SUCCESS = "CREATE_RECURRING_PRESCRIPTION_SUCCESS";
export const UPDATE_PRESCRIPTION_SUCCESS = "UPDATE_PRESCRIPTION_SUCCESS";
export const UPDATE_PRESCRIPTION_PRESCRIBER_SUCCESS = "UPDATE_PRESCRIPTION_PRESCRIBER_SUCCESS";
export const DELETE_PRESCRIPTION_SUCCESS = "DELETE_PRESCRIPTION_SUCCESS";
export const LOAD_DRUG_FORMULARY_SUCCESS = "LOAD_DRUG_FORMULARY_SUCCESS";
export const SET_SELECTED_PRESCRIPTION = "SET_SELECTED_PRESCRIPTION";
export const SET_PRESCRIPTION_DRUG_CHANGE = "SET_PRESCRIPTION_DRUG_CHANGE";
export const ADD_RECURRING_TO_CURRENT_SUCCESS = "ADD_RECURRING_TO_CURRENT_SUCCESS";
export const UPDATE_RECURRING_PRESCRIPTION_SUCCESS = "UPDATE_RECURRING_PRESCRIPTION_SUCCESS";
export const DELETE_RECURRING_PRESCRIPTION_SUCCESS = "DELETE_RECURRING_PRESCRIPTION_SUCCESS";
export const SEND_PRESCRIPTION_SUCCESS = "SEND_PRESCRIPTION_SUCCESS";
export const SEND_PRESCRIPTION_FROM_REPORT_SUCCESS = "SEND_PRESCRIPTION_FROM_REPORT_SUCCESS";
export const LOAD_PRESCRIPTIONS_SENT_SUCCESS = "LOAD_PRESCRIPTIONS_SENT_SUCCESS";
export const LOAD_PRESCRIPTIONS_TO_SIGN_SUCCESS = "LOAD_PRESCRIPTIONS_TO_SIGN_SUCCESS";
export const SET_FORCE_RELOAD_PRESCRIPTIONS_TO_SIGN = "SET_FORCE_RELOAD_PRESCRIPTIONS_TO_SIGN";
export const CLEAR_PRESCRIPTIONS_TO_SIGN = "CLEAR_PRESCRIPTIONS_TO_SIGN";
export const CLEAR_PRESCRIPTION_TO_SIGN = "CLEAR_PRESCRIPTION_TO_SIGN";
export const LOAD_PRESCRIPTION_TO_SIGN_SUCCESS = "LOAD_PRESCRIPTION_TO_SIGN_SUCCESS";
export const SAVE_ATTESTATION_SUCCESS = "SAVE_ATTESTATION_SUCCESS";
export const DELETE_PRESCRIPTION_TO_SIGN_SUCCESS = "DELETE_PRESCRIPTION_TO_SIGN_SUCCESS";
export const LOAD_PRESCRIPTIONS_SENT_BY_VISIT_SUCCESS = "LOAD_PRESCRIPTIONS_SENT_BY_VISIT_SUCCESS";
export const PRINT_PRESCRIPTION_SUCCESS = "PRINT_PRESCRIPTION_SUCCESS";
export const SIGN_PRESCRIPTION_SUCCESS = "SIGN_PRESCRIPTION_SUCCESS";
export const SET_RX_ITEM_FORM = "SET_RX_ITEM_FORM";
export const APPLY_FAVORITE_PRESCRIPTION = "APPLY_FAVORITE_PRESCRIPTION";
export const FETCH_RX_PIN_INFO_DONE = "FETCH_RX_PIN_INFO_DONE";

// modalActions
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const SHOW_CONFIRMATION_MODAL = "SHOW_CONFIRMATION_MODAL";
export const HIDE_CONFIRMATION_MODAL = "HIDE_CONFIRMATION_MODAL";
export const SHOW_MULTIPLE_MODALS = "SHOW_MULTIPLE_MODALS";
export const HIDE_MULTIPLE_MODALS = "HIDE_MULTIPLE_MODALS";

// lookupActions
export const LOAD_REACTIONS_SUCCESS = "LOAD_REACTIONS_SUCCESS";
export const LOAD_STATES_SUCCESS = "LOAD_STATES_SUCCESS";
export const LOAD_INTEGRATION_KEYS_SUCCESS = "LOAD_INTEGRATION_KEYS_SUCCESS";
export const LOAD_SUP_PROVIDERS_SUCCESS = "LOAD_SUP_PROVIDERS_SUCCESS";
export const LOAD_MAIN_PRESCRIBERS_SUCCESS = "LOAD_MAIN_PRESCRIBERS_SUCCESS";
export const LOAD_LIVING_SITUATIONS_SUCCESS = "LOAD_LIVING_SITUATION_SUCCESS";
export const LOAD_BILLING_CODES_SUCCESS = "LOAD_BILLING_CODES_SUCCESS";
export const LOAD_VISIT_TYPES_SUCCESS = "LOAD_VISIT_TYPES_SUCCESS";
export const LOAD_VISIT_STATUSES_SUCCESS = "LOAD_VISIT_STATUSES_SUCCESS";
export const LOAD_ASSIGNED_TO_SUCCESS = "LOAD_ASSIGNED_TO_SUCCESS";
export const FETCH_TASK_ROLES_DONE = "FETCH_TASK_ROLES_DONE";
export const FETCH_ROLE_GROUPS_DONE = "FETCH_ROLE_GROUPS_DONE";
export const LOAD_LEVELS_SUCCESS = "LOAD_LEVELS_SUCCESS";
export const LOAD_CENTERS_LOOKUP_SUCCESS = "LOAD_CENTERS_LOOKUP_SUCCESS";
export const LOAD_MSP_OPTIONS_SUCCESS = "LOAD_MSP_OPTIONS_SUCCESS";
export const LOAD_PCP_OPTIONS_SUCCESS = "LOAD_PCP_OPTIONS_SUCCESS";
export const LOAD_INSURANCE_PROV_SUCCESS = "LOAD_INSURANCE_PROV_SUCCESS";
export const LOAD_INS_RELATIONSHIPS_SUCCESS = "LOAD_INS_RELATIONSHIPS_SUCCESS";
export const LOAD_AUX_FLAGS_SUCCESS = "LOAD_AUX_FLAGS_SUCCESS";
export const LOAD_GROUP_TYPES_SUCCESS = "LOAD_GROUP_TYPES_SUCCESS";
export const LOAD_VISIT_TYPE_GROUPS_SUCCESS = "LOAD_VISIT_TYPE_GROUPS_SUCCESS";
export const LOAD_DRUG_FORMS_SUCCESS = "LOAD_DRUG_FORMS_SUCCESS";
export const LOAD_DRUG_ROUTES_SUCCESS = "LOAD_DRUG_ROUTES_SUCCESS";
export const LOAD_DRUG_STRENGTHS_SUCCESS = "LOAD_DRUG_STRENGTHS_SUCCESS";
export const LOAD_NUMBER_PER_DOSE_OPTIONS_SUCCESS = "LOAD_NUMBER_PER_DOSE_OPTIONS_SUCCESS";
export const LOAD_REFILL_OPTIONS_SUCCESS = "LOAD_REFILL_OPTIONS_SUCCESS";
export const LOAD_PRESCRIPTION_FREQUENCY_OPTIONS_SUCCESS = "LOAD_PRESCRIPTION_FREQUENCY_OPTIONS_SUCCESS";
export const CLEAR_DRUG_ROUTES_SUCCESS = "CLEAR_DRUG_ROUTES_SUCCESS";
export const CLEAR_DRUG_STRENGTHS_SUCCESS = "CLEAR_DRUG_STRENGTHS_SUCCESS";
export const LOAD_DOCUMENT_TAGS_SUCCESS = "LOAD_DOCUMENT_TAGS_SUCCESS";
export const LOAD_PRESCRIBER_SLOTS_SUCCESS = "LOAD_PRESCRIBER_SLOTS_SUCCESS";
export const CLEAR_PRESCRIBER_SLOTS = "CLEAR_PRESCRIBER_SLOTS";
export const LOAD_RX_AUTHENTICATION_TYPES_SUCCESS = "LOAD_RX_AUTHENTICATION_TYPES_SUCCESS";
export const LOAD_ALL_USERS_SUCCESS = "LOAD_ALL_USERS_SUCCESS";
export const LOAD_DRUG_FAVORITES_DONE = "LOAD_DRUG_FAVORITES_DONE";
export const LOAD_ATTESTATION_OPTIONS_SUCCESS = "LOAD_ATTESTATION_OPTIONS_SUCCESS";
export const LOAD_ALL_CONDITION_TO_SUCCESS = "LOAD_ALL_CONDITION_TO_SUCCESS";
export const LOAD_INTERVENTION_CONDITIONS_SUCCESS = "LOAD_INTERVENTION_CONDITIONS_SUCCESS";
export const LOAD_ALL_DRUG_TO_SUCCESS = "LOAD_ALL_DRUG_TO_SUCCESS";
export const LOAD_ALL_ALLERGY_TO_SUCCESS = "LOAD_ALL_ALLERGY_TO_SUCCESS";
export const LOAD_USER_CREDENTIAL_SUCCESS = "LOAD_USER_CREDENTIAL_SUCCESS";
export const LOAD_ALL_FAMILY_MEDICAL_CONDITIONS_SUCCESS = "LOAD_ALL_FAMILY_MEDICAL_CONDITIONS_SUCCESS";
export const LOAD_USERS_BY_FILTER_SUCCESS = "LOAD_USERS_BY_FILTER_SUCCESS";
export const LOAD_LABS_UNIQUE_SUCCESS = "LOAD_LABS_UNIQUE_SUCCESS";
export const LOAD_LAB_SPECIMEN_TYPES_SUCCESS = "LOAD_LAB_SPECIMEN_TYPES_SUCCESS";
export const LOAD_LAB_TEST_TYPES_SUCCESS = "LOAD_LAB_TEST_TYPES_SUCCESS";
export const LOAD_LAB_TEST_STATUSES_SUCCESS = "LOAD_LAB_TEST_STATUSES_SUCCESS";
export const LOAD_LAB_PROVIDERS_SUCCESS = "LOAD_LAB_PROVIDERS_SUCCESS";
export const LOAD_LAB_ORDER_STATUSES_SUCCESS = "LOAD_LAB_ORDER_STATUSES_SUCCESS";
export const LOAD_EXOSTAR_ACTION_TYPES_SUCCESS = "LOAD_EXOSTAR_ACTION_TYPES_SUCCESS";
export const LOAD_TASK_TEMPLATES_SUCCESS = "LOAD_TASK_TEMPLATES_SUCCESS";
export const LOAD_NOTE_TEMPLATES_SUCCESS = "LOAD_NOTE_TEMPLATES_SUCCESS";
export const LOAD_PATIENT_CUSTOM_FIELDS_SUCCESS = "LOAD_PATIENT_CUSTOM_FIELDS_SUCCESS";
export const ADD_TO_CACHED = "ADD_TO_CACHED";
export const CLEAR_LOOKUP_CACHE = "CLEAR_LOOKUP_CACHE";

// searchActions
export const SEARCH_PATIENT_SUCCESS = "SEARCH_PATIENT_SUCCESS";
export const SEARCH_MEDS_SUCCESS = "SEARCH_MEDS_SUCCESS";
export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const SEARCH_PHYSICIAN_SUCCESS = "SEARCH_PHYSICIAN_SUCCESS";
export const SEARCH_ALLERGY_SUCCESS = "SEARCH_ALLERGY_SUCCESS";
export const SEARCH_CONDITION_SUCCESS = "SEARCH_CONDITION_SUCCESS";

// providerActions
export const LOAD_PROVIDERS_SUCCESS = "LOAD_PROVIDERS_SUCCESS";
export const UPDATE_PROVIDER_SUCCESS = "UPDATE_PROVIDER_SUCCESS";
export const LOAD_PROVIDER_DETAILS_SUCCESS = "LOAD_PROVIDER_DETAILS_SUCCESS";
export const LOAD_PRESCRIBER_HISTORY_SUCCESS = "LOAD_PRESCRIBER_HISTORY_SUCCESS";
export const LOAD_PROVIDER_CENTERS_SUCCESS = "LOAD_PROVIDER_CENTERS_SUCCESS";
export const UPDATE_PROVIDER_CENTERS_SUCCESS = "UPDATE_PROVIDER_CENTERS_SUCCESS";
export const SET_SELECTED_PROVIDER_INTEGRATION_KEYS = "SET_SELECTED_PROVIDER_INTEGRATION_KEYS";
export const SET_SELECTED_PROVIDER_GATEWAYS = "SET_SELECTED_PROVIDER_GATEWAYS";
export const ADD_SELECTED_PROVIDER_INTEGRATION_KEY_VALUE = "ADD_SELECTED_PROVIDER_INTEGRATION_KEY_VALUE";
export const DELETE_SELECTED_PROVIDER_INTEGRATION_KEY_VALUE = "DELETE_SELECTED_PROVIDER_INTEGRATION_KEY_VALUE";
export const SET_IDENTITY_PROOFING_STATUS = "SET_IDENTITY_PROOFING_STATUS";
export const SET_LAUNCH_SELF_ADMIN_SESSION = "SET_LAUNCH_SELF_ADMIN_SESSION";
export const SET_IDENTITY_PROOFING_FLAGS = "SET_IDENTITY_PROOFING_FLAGS";

// visitActions
export const LOAD_PATIENT_ACTIVE_VISITS_SUCCESS = "LOAD_PATIENT_ACTIVE_VISITS_SUCCESS";
export const CLEAR_PATIENT_RELATED_VISITS_DATA = "CLEAR_PATIENT_RELATED_VISITS_DATA";
export const SET_CONTEXT_VISIT = "SET_CONTEXT_VISIT";
export const CLEAR_CONTEXT_VISIT = "CLEAR_CONTEXT_VISIT";
export const LOAD_PATIENT_VISITS_SUCCESS = "LOAD_PATIENT_VISITS_SUCCESS";
export const UPDATE_VISIT_PATIENT_LEVEL = "UPDATE_VISIT_PATIENT_LEVEL";
export const SET_PATIENT_VISITS_LOADING = "SET_PATIENT_VISITS_LOADING";
export const CREATE_VISIT_SUCCESS = "CREATE_VISIT_SUCCESS";
export const UPDATE_VISIT_STATUS_SUCCESS = "UPDATE_VISIT_STATUS_SUCCESS";
export const UPDATE_VISIT_TYPE_SUCCESS = "UPDATE_VISIT_TYPE_SUCCESS";
export const UPDATE_PATIENT_VISIT_STATUS_STARTED = "UPDATE_PATIENT_VISIT_STATUS_STARTED";
export const UPDATE_PATIENT_VISIT_STATUS_SUCCESS = "UPDATE_PATIENT_VISIT_STATUS_SUCCESS";
export const UPDATE_PATIENT_VISIT_STATUS_FAILED = "UPDATE_PATIENT_VISIT_STATUS_FAILED";
export const LOAD_VISIT_SUMMARY_HEADER_SUCCESS = "LOAD_VISIT_SUMMARY_HEADER_SUCCESS";
export const SET_MY_CALENDAR = "SET_MY_CALENDAR";
export const SET_PROVIDER_VISITS_MESSAGE = "SET_PROVIDER_VISITS_MESSAGE";
export const SET_PROVIDER_APPOINTMENTS_MESSAGE = "SET_PROVIDER_APPOINTMENTS_MESSAGE";
export const LOAD_STATUS_WATCH_VISITING_SUCCESS = "LOAD_STATUS_WATCH_VISITING_SUCCESS";
export const SET_ALL_CENTER_VISITS = "SET_ALL_CENTER_VISITS";
export const UPDATE_VISITING_STATUS_SELECT_SUCCESS = "UPDATE_VISITING_STATUS_SELECT_SUCCESS";
export const UPDATE_STATUS_WATCH_VISITING_SUCCESS = "UPDATE_STATUS_WATCH_VISITING_SUCCESS";
export const CREATE_VISIT_AMENDMENT_SUCCESS = "CREATE_VISIT_AMENDMENT_SUCCESS";
export const SUBMIT_ATHENA_CLAIM_SUCCESS = "SUBMIT_ATHENA_CLAIM_SUCCESS";
export const LOAD_AMENDMENT_BY_VISIT_ID_SUCCESS = "LOAD_AMENDMENT_BY_VISIT_ID_SUCCESS";
export const LOAD_STATUS_WATCH_MY_PATIENT_SUCCESS = "LOAD_STATUS_WATCH_MY_PATIENT_SUCCESS";
export const UPDATE_MY_PATIENT_STATUS_SELECT_SUCCESS = "UPDATE_MY_PATIENT_STATUS_SELECT_SUCCESS";
export const UPDATE_STATUS_WATCH_MY_PATIENT_SUCCESS = "UPDATE_STATUS_WATCH_MY_PATIENT_SUCCESS";
export const LOAD_PATIENT_TIMELINE_SUCCESS = "LOAD_PATIENT_TIMELINE_SUCCESS";
export const FETCH_TIMELINE_ITEM_DETAILS_DONE = "FETCH_TIMELINE_ITEM_DETAILS_DONE";
export const ADD_COUNSELING_VISIT_SIGNATURE_SUCCESS = "ADD_COUNSELING_VISIT_SIGNATURE_SUCCESS";
export const ADD_VISIT_CANCELED_SUCCESS = "ADD_VISIT_CANCELED_SUCCESS";
export const LOAD_VISIT_MEDICAL_RECENT_SUCCESS = "LOAD_VISIT_MEDICAL_RECENT_SUCCESS";
export const LOAD_VISIT_PATIENT_HIST_SUCCESS = "LOAD_VISIT_PATIENT_HIST_SUCCESS";
export const LOAD_VISIT_ALLERGY_HIST_SUCCESS = "LOAD_VISIT_ALLERGY_HIST_SUCCESS";
export const LOAD_VISIT_FAMILY_HIST_SUCCESS = "LOAD_VISIT_FAMILY_HIST_SUCCESS";
export const LOAD_VISIT_MEDICATION_SUCCESS = "LOAD_VISIT_MEDICATION_SUCCESS";
export const LOAD_VISIT_MEDICAL_HIST_SUCCESS = "LOAD_VISIT_MEDICAL_HIST_SUCCESS";
export const LOAD_VISIT_INTERVENTION_HISTORY_SUCCESS = "LOAD_VISIT_INTERVENTION_HISTORY_SUCCESS";
export const LOAD_VISIT_SIGN_PERMISSION_DETAILS_SUCCESS = "LOAD_VISIT_SIGN_PERMISSION_DETAILS_SUCCESS";
export const UPDATE_LIST_VISIT_SUMMARY_TO_PRINT = "UPDATE_LIST_VISIT_SUMMARY_TO_PRINT";
export const LOAD_VISIT_SIGN_HISTORY_SUCCESS = "LOAD_VISIT_SIGN_HISTORY_SUCCESS";
export const GET_VISIT_LOCATIONS_DONE = "GET_VISIT_LOCATIONS_DONE";
export const CLEAR_VISIT_LOCATIONS = "CLEAR_VISIT_LOCATIONS";
export const SET_FORCE_RELOAD_PATIENT_VISITS = "SET_FORCE_RELOAD_PATIENT_VISITS";
export const SET_CENTER_VISITS_MESSAGE = "SET_CENTER_VISITS_MESSAGE";
export const NAVIGATE_TO_NEW_VISIT_WHEN_IT_COMES = "NAVIGATE_TO_NEW_VISIT_WHEN_IT_COMES";

// noteSlashTasksActions
export const LOAD_NOTE_SLASH_TASKS_DONE = "LOAD_NOTE_SLASH_TASKS_DONE";
export const SET_NOTE_SLASH_TASKS_FORCE_RELOAD = "SET_NOTE_SLASH_TASKS_FORCE_RELOAD";
export const FETCH_NOTE_SLASH_TASK_DETAILS_DONE = "FETCH_NOTE_SLASH_TASK_DETAILS_DONE";

// taskLists
export const TASK_LISTS_LOAD_ALL_SUCCESS = "TASK_LISTS_LOAD_ALL_SUCCESS";
export const TASK_LISTS_LOAD_BY_ROLE_SUCCESS = "TASK_LISTS_LOAD_BY_ROLE_SUCCESS";

// insurance
export const CREATE_INSURANCE_SUCCESS = "CREATE_INSURANCE_SUCCESS";
export const LOAD_PATIENT_INSURANCE_SUCCESS = "LOAD_PATIENT_INSURANCE_SUCCESS";
export const CLEAR_PATIENT_INSURANCE_SUCCESS = "CLEAR_PATIENT_INSURANCE_SUCCESS";
export const CREATE_REFERRAL_SUCCESS = "CREATE_REFERRAL_SUCCESS";
export const CREATE_UDS_PRIOR_AUTH_SUCCESS = "CREATE_UDS_PRIOR_AUTH_SUCCESS";
export const CREATE_MED_PRIOR_AUTH_SUCCESS = "CREATE_MED_PRIOR_AUTH_SUCCESS";
export const LOAD_INSURANCE_HISTORY_SUCCESS = "LOAD_INSURANCE_HISTORY_SUCCESS";
export const LOAD_BENEFIT_INFORMATION_SUCCESS = "LOAD_BENEFIT_INFORMATION_SUCCESS";
export const LOAD_DRUG_HISTORY_SUCCESS = "LOAD_DRUG_HISTORY_SUCCESS";

// treatment plan
export const LOAD_TREATMENT_PLAN_HISTORY_DONE = "LOAD_TREATMENT_PLAN_HISTORY_DONE";
export const SET_SELECTED_TREATMENT_PLAN = "SET_SELECTED_TREATMENT_PLAN";
export const CLEAR_SELECTED_TREATMENT_PLAN = "CLEAR_SELECTED_TREATMENT_PLAN";
export const LOAD_TREATMENT_PLAN_DONE = "LOAD_TREATMENT_PLAN_DONE";
export const LOAD_TREATMENT_PLAN_SUMMARY_DONE = "LOAD_TREATMENT_PLAN_SUMMARY_DONE";
export const CREATE_TREATMENT_PLAN_DONE = "CREATE_TREATMENT_PLAN_DONE";
export const HIDE_TREATMENT_PLAN_SURVEY = "HIDE_TREATMENT_PLAN_SURVEY";
export const CLEAR_HIDDEN_TREATMENT_PLAN_SURVEYS = "CLEAR_HIDDEN_TREATMENT_PLAN_SURVEYS";
export const CLEAR_HIDDEN_TREATMENT_PLAN_SURVEY = "CLEAR_HIDDEN_TREATMENT_PLAN_SURVEY";
export const CLEAR_TREATMENT_PLAN = "CLEAR_TREATMENT_PLAN";

// groups
export const LOAD_GROUPS_SUCCESS = "LOAD_GROUPS_SUCCESS";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";

// labs
export const CLEAR_PATIENT_LABS = "CLEAR_PATIENT_LABS";
export const LOAD_AVAILABLE_LABS_SUCCESS = "LOAD_AVAILABLE_LABS_SUCCESS";
export const LOAD_RECENT_ORDERS_SUCCESS = "LOAD_RECENT_ORDERS_SUCCESS";
export const LOAD_LAB_RESULT_GRAPHS_SUCCESS = "LOAD_LAB_RESULT_GRAPHS_SUCCESS";
export const SET_SELECTED_LAB_ORDER = "SET_SELECTED_LAB_ORDER";
export const LOAD_PROT_ORDER_SUCCESS = "LOAD_PROT_ORDER_SUCCESS";
export const LOAD_PEND_ORDERS_SUCCESS = "LOAD_PEND_ORDERS_SUCCESS";
export const LOAD_LAB_RESULT_HEADERS_SUCCESS = "LOAD_LAB_RESULT_HEADERS_SUCCESS";
export const LOAD_LAB_RESULTS_SUCCESS = "LOAD_LAB_RESULTS_SUCCESS";
export const SET_REQUESTED_LABS = "SET_REQUESTED_LABS";
export const SET_FETCHING_REQUESTED_LABS_FLAG = "SET_FETCHING_REQUESTED_LABS_FLAG";
export const SET_REQUESTED_LAB_SPECIMEN_COLLECTION_CENTER = "SET_REQUESTED_LAB_SPECIMEN_COLLECTION_CENTER";
export const SET_REQUESTED_LAB_SPECIMEN_COLLECTION_DATE = "SET_REQUESTED_LAB_SPECIMEN_COLLECTION_DATE";
export const CONFIRMING_SPECIMEN_DATE_AND_CENTER = "CONFIRMING_SPECIMEN_DATE_AND_CENTER";
export const CONFIRMING_SPECIMEN_DATE_AND_CENTER_DONE = "CONFIRMING_SPECIMEN_DATE_AND_CENTER_DONE";
export const SET_STANDING_LAB_ORDER_RENDERED = "SET_STANDING_LAB_ORDER_RENDERED";
export const SET_STANDING_LAB_ORDER_SAVING = "SET_STANDING_LAB_ORDER_SAVING";
export const SET_TEST_CANCEL_BUTTON_PRESSED = "SET_TEST_CANCEL_BUTTON_PRESSED";
export const CLEAR_LAB_SUBMISSION_QUEUE = "CLEAR_LAB_SUBMISSION_QUEUE";
export const SET_LAB_SUBMISSION_QUEUE_PATIENT_CONTEXT = "SET_LAB_SUBMISSION_QUEUE_PATIENT_CONTEXT";
export const CLEAR_RECENT_AND_SELECTED_LAB_ORDERS = "CLEAR_RECENT_AND_SELECTED_LAB_ORDERS";
export const TOGGLE_INCLUDE_ORDERED_TODAY = "TOGGLE_INCLUDE_ORDERED_TODAY";
export const SET_LABS_RAPID_TEST_REFERENCE = "SET_LABS_RAPID_TEST_REFERENCE";
export const INIT_LABS_RAPID_TEST_RESULTS = "INIT_LABS_RAPID_TEST_RESULTS";
export const PREFILL_LABS_RAPID_TEST_RESULTS = "PREFILL_LABS_RAPID_TEST_RESULTS";
export const UPDATE_LAB_RAPID_TEST_RESULTS = "UPDATE_LAB_RAPID_TEST_RESULTS";
export const ALREADY_ADDED_CONFIRMATORY_TESTS = "ALREADY_ADDED_CONFIRMATORY_TESTS";

// userPreferenceActions
export const UPDATE_USER_PREFERENCE = "UPDATE_USER_PREFERENCE";
export const UPDATE_CENTER_CONTEXT_SUCCESS = "UPDATE_CENTER_CONTEXT_SUCCESS";
export const LOAD_CENTER_CONTEXT_SUCCESS = "LOAD_CENTER_CONTEXT_SUCCESS";
export const LOAD_CURRENT_USER_ID_SUCCESS = "LOAD_CURRENT_USER_ID_SUCCESS";
export const SET_PROVIDER_DETAILS = "SET_PROVIDER_DETAILS";
export const SET_RELOAD_PAGE_IN = "SET_RELOAD_PAGE_IN";
export const SET_RETRY_CONNECTING_IN = "SET_RETRY_CONNECTING_IN";
export const SET_RETRY_REACHING_SERVER_IN = "SET_RETRY_REACHING_SERVER_IN";
export const TOGGLE_USER_STATUS_SUCCESS = "TOGGLE_USER_STATUS_SUCCESS";
export const SORT_USER_BY_COLUMN = "SORT_USER_BY_COLUMN";
export const REQUEST_EPCS_ADMIN_ACCESS_SUCCESS = "REQUEST_EPCS_ADMIN_ACCESS_SUCCESS";
export const SET_THEME = "SET_THEME";

// charts
export const LOAD_CHARTS_TO_SIGN_SUCCESS = "LOAD_CHARTS_TO_SIGN_SUCCESS";
export const SIGN_CHART_SUCCESS = "SIGN_CHART_SUCCESS";
export const CLEAR_CHART_DETAILS = "CLEAR_CHART_DETAILS";
export const CLEAR_COSIGNED_CHART_DETAIL = "CLEAR_COSIGNED_CHART_DETAIL";
export const LOAD_CHART_DETAILS_SUCCESS = "LOAD_CHART_DETAILS_SUCCESS";
export const LOAD_COSIGNED_CHART_DETAIL_SUCCESS = "LOAD_COSIGNED_CHART_DETAIL_SUCCESS";
export const COSIGN_VISIT_SUCCESS = "COSIGN_VISIT_SUCCESS";

// Error log
export const OPEN_ERROR_LOG_SLIDER = "OPEN_ERROR_LOG_SLIDER";
export const CLOSE_ERROR_LOG_SLIDER = "CLOSE_ERROR_LOG_SLIDER";
export const PUSH_ERROR_LOG_TO_SLIDER = "PUSH_ERROR_LOG_TO_SLIDER";
export const DISMISS_ITEM_ERROR_LOG_TO_SLIDER = "DISMISS_ITEM_ERROR_LOG_TO_SLIDER";
export const DISMISS_ALL_ERROR_LOG_TO_SLIDER = "DISMISS_ALL_ERROR_LOG_TO_SLIDER";
export const SET_RECOVERABLE_ERRORS = "SET_RECOVERABLE_ERRORS";
export const SET_NON_RECOVERABLE_ERRORS = "SET_NON_RECOVERABLE_ERRORS";
export const SET_SELECTED_ERROR_DETAILSS = "SET_SELECTED_ERROR_DETAILSS";
export const SET_SELECTED_CLAIM_ERROR_DETAILS_SUCCESS = "SET_SELECTED_CLAIM_ERROR_DETAILS_SUCCESS";
export const SET_CLAIM_ERRORS_SUCCESS = "SET_CLAIM_ERRORS_SUCCESS";
export const SET_ALL_CLAIM_ERRORS_SUCCESS = "SET_ALL_CLAIM_ERRORS_SUCCESS";
export const SET_ERROR_KEYWORDS = "SET_ERROR_KEYWORDS";

// Power BI Report
export const LOAD_REPORTS_SUCCESS = "LOAD_REPORTS_SUCCESS";
export const LOAD_REPORTTOKENS_SUCCESS = "LOAD_REPORTTOKENS_SUCCESS";
export const LOAD_REPORTROLES_SUCCESS = "LOAD_REPORTROLES_SUCCESS";
export const SET_CLAIM_RECONCILIATIONS = "SET_CLAIM_RECONCILIATIONS";
export const SET_ALL_CLAIM_RECONCILIATIONS = "SET_ALL_CLAIM_RECONCILIATIONS";
export const SET_REVIEW_OF_PATIENTS = "SET_REVIEW_OF_PATIENTS";
export const SET_ALL_REVIEW_OF_PATIENTS = "SET_ALL_REVIEW_OF_PATIENTS";
export const SET_REPORT_ALL_DATA = "SET_REPORT_ALL_DATA";
export const SET_REPORT_DATA = "SET_REPORT_DATA";

// admin
export const SET_SHADOW_QUEUE = "SET_SHADOW_QUEUE";
export const SET_PROXY_REQUESTS = "SET_PROXY_REQUESTS";
export const PUSH_ITEM_TO_BREADCRUMB = "PUSH_ITEM_TO_BREADCRUMB";

export const POP_ITEM_FROM_BREADCRUMB = "POP_ITEM_FROM_BREADCRUMB";

// notifications
export const GET_NOTIFICATIONS_DONE = "GET_NOTIFICATIONS_DONE";
export const DISMISS_ALL_NOTIFICATIONS_DONE = "DISMISS_ALL_NOTIFICATIONS_DONE";
export const DISMISS_NOTIFICATION_DONE = "DISMISS_NOTIFICATION_DONE";

// Streaming
export const SET_STREAM_ID = "SET_STREAM_ID";
export const ADD_ITEM_TO_SUBSCRIPTION_LIST = "ADD_ITEM_TO_SUBSCRIPTION_LIST";
export const REMOVE_ITEM_FROM_SUBSCRIPTION_LIST = "REMOVE_ITEM_FROM_SUBSCRIPTION_LIST";

// misc
export const SET_NARX_SCORES = "SET_NARX_SCORES";
export const CLEAR_NARX_SCORES = "CLEAR_NARX_SCORES";
