import React from "react";
import { Link } from "react-router-dom";
import { List } from "semantic-ui-react";

export default function NewPatientSearchResults(props) {
  const { patientId, headerTextLeft, headerTextRight, descripTextLeft, descripTextRight, noDescripText, separator } =
    props;
  let descripText = noDescripText;
  if (descripTextLeft && descripTextRight) {
    descripText = `
      ${descripTextLeft}
      ${separator}
      ${descripTextRight}
      `;
  }
  return (
    <List.Item key={patientId} patientId={patientId} onClick={props.onSearchResultClick}>
      <Link to={`/patient/${patientId}/demographics`} style={{ textDecoration: "none", color: "black" }}>
        <List.Content>
          <List.Header>
            {headerTextLeft} {headerTextRight}
          </List.Header>
          {descripText}
        </List.Content>
      </Link>
    </List.Item>
  );
}
