/* eslint-disable react/no-array-index-key */
import React, { Component, createRef } from "react";
import { toast } from "react-toastify";
import { Form, Menu, Tab, Label } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEqual, isEmpty, debounce, groupBy, cloneDeep } from "lodash";
import { Button, Col, Container, Icon, Message, Modal, Row } from "../../../ui";
import emrComponent from "../../common/emrComponent";
import * as templateActions from "../../../actions/templateActions";
import * as treatmentPlanActions from "../../../actions/treatmentPlanActions";
import * as patientActions from "../../../actions/patientActions";
import MultiToggle from "./MultiToggle";
import { toastErrorOptions } from "../../../constants/toastconfig";
import {
  parseLabel,
  isQuestionTriggered,
  getQuestionPaddingLevel,
  initSurvey,
  applyAction,
  findItem,
  getQuestionStringId,
  getQuestionId,
  refreshQuestionOptions,
  refreshRequiredState,
  validateSurveyAnswersDataIntegrity
} from "./surveyHelper";
import cssClasses from "./Surveys.module.css";
import { dateFormat, numbersToText } from "../../../constants/miscellaneous";
import AdvancedSearch from "./AdvancedSearch";
import { cacheSurvey, clearCachedSurvey, setActiveSurvey } from "../../../reducers/surveysSlice";
import { hideModal, showConfirmationModal } from "../../../helpers";

export class Survey extends Component {
  constructor(props) {
    super(props);
    const { id: surveyId, sections } = props.survey;
    this.state = {
      surveyId,
      saving: false,
      activeSectionKey: `${sections[0].key}-1`,
      surveyLayout: { sections: [] },
      surveyData: {},
      actions: this.props.survey.templateActions.templateActions.map((a) => ({
        ...a,
        targetKey: JSON.parse(a.targetKey)
      })),
      initialActiveSurveyQuestionKey: this.props.activeSurvey?.questionKey,
      activeQuestionRef: null
      // logicComponents: {}
    };
  }

  _closing = false;
  _isMounted = false;

  formContainerRef = createRef(null);

  componentDidMount() {
    this._isMounted = true;
    const {
      patientId,
      cachedSurveys,
      selectedTemplate: { templateId },
      survey: { id: surveyId },
      activeSurvey
    } = this.props;

    if (cachedSurveys[`${patientId}-${templateId}-${surveyId}`]) {
      this.preloadHiddenSurvey();
    } else {
      const [surveyLayout, surveyData] = initSurvey(
        this.props.survey,
        this.props.survey.templateActions.templateActions.map((a) => ({ ...a, targetKey: JSON.parse(a.targetKey) }))
      );
      this.setState({ surveyLayout, surveyData }); // , initSurveyData: cloneDeep(surveyData) });
    }

    if (
      activeSurvey &&
      activeSurvey.surveyId === surveyId &&
      activeSurvey.sectionKey &&
      activeSurvey.sectionSortOrder
    ) {
      this.setState({
        activeSectionKey: `${activeSurvey.sectionKey}-${activeSurvey.sectionSortOrder}`
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { surveyLayout, surveyData, initialActiveSurveyQuestionKey } = this.state;

    if (!isEqual(surveyData, prevState.surveyData)) {
      const _surveyLayout = refreshRequiredState(surveyLayout, surveyData);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ surveyLayout: _surveyLayout });
    }

    // if (!isEqual(surveyLayout, prevState.surveyLayout)) {
    //   const {
    //     survey: { cptRecommenders }
    //   } = this.props;
    //   if (!isEmpty(cptRecommenders)) {
    //     cptRecommenders.forEach((cptRecommender) => this.registerLogicComponent(cptRecommender));
    //   }
    // }

    if (this.props.surveysVisitId !== prevProps.surveysVisitId) {
      toast.error(
        <div>
          Error !
          <br />
          Context visit got changed in the middle of filling a survey, Please use the red `Report a problem` button at
          the bottom right corner immediately;
          <br />
          Share the result with support team
        </div>,
        toastErrorOptions
      );
    }

    if (initialActiveSurveyQuestionKey && prevState.activeQuestionRef === null && this.state.activeQuestionRef) {
      // this.state.activeQuestionRef.scrollIntoView();
      window.scrollTo({
        top: this.state.activeQuestionRef.getBoundingClientRect().top + window.pageYOffset - 50,
        behavior: "smooth"
      });
      this.setState({ activeQuestionRef: false });
    }

    if (this.props.savedSurveyTimestamp !== prevProps.savedSurveyTimestamp) {
      if (this.props.handleSave || this._isMounted) this.setState({ saving: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this._closing || !this.props.autoSave) return;
    this.handleHide();
  }

  preloadHiddenSurvey = () => {
    const {
      patientId,
      cachedSurveys,
      selectedTemplate: { templateId },
      survey: { id: surveyId }
    } = this.props;
    const cachedSurvey = cachedSurveys[`${patientId}-${templateId}-${surveyId}`];
    if (isEmpty(cachedSurvey)) return;
    this.setState({ surveyLayout: cachedSurvey.surveyLayout, surveyData: cachedSurvey.surveyData });
  };

  /*
  registerLogicComponent = (component) => {
    const { surveyData, logicComponents } = this.state;
    const keys = Object.keys(surveyData);
    if (isEmpty(component.triggeringQuestions)) {
      this.setState({ logicComponents: { ...this.state.logicComponents } });
    } else {
      // const components = component.triggeringQuestions.reduce((acc, curr) => {
      //   const qKeys = keys.filter((key) => key.includes(`-${curr}-`));
      //   qKeys.forEach((qKey) => {
      //     acc[qKey] = component;
      //   });
      //   return acc;
      // }, {});
      component.triggeringQuestions.forEach((questionKey) => {
        const existing = Object.keys(logicComponents).filter((key) => key.includes(`-${questionKey}-`));
        if (true) {
          // check also not existing .. duplicate question will add to layout
          console.log(keys, existing);
        }
      });
      this.setState({ logicComponents });
    }
  };
  */

  getElucidationValue = (_question, elucidationKey, triggerCheck) => {
    const elucidation = _question.elucidations.find((e) => e.key === elucidationKey);
    return triggerCheck && elucidation ? elucidation.value : "";
  };

  getQuestionValue = (type, question, option) => {
    const _question = this.state.surveyData[getQuestionStringId(question)];
    switch (type) {
      case "MultiSelect":
        return _question.answers.includes(option);
      case "Radio":
        return option ? _question.answers[0] === option : !!_question.answers.pop();
      case "Dropdown":
      case "Text":
        return _question.answers[0] || "";
      case "Keyword":
        return _question.keyword || "";
      default:
        return "";
    }
  };

  handleHide = () => {
    const {
      for: _for = "surveys",
      patientId,
      selectedTemplate: { templateId },
      survey: { id: surveyId },
      actions: { cacheSurvey: _cacheSurvey, hideTreatmentPlanSurvey },
      asModal
    } = this.props;
    const { surveyLayout, surveyData } = this.state;
    const funcs = { treatmentPlan: hideTreatmentPlanSurvey, surveys: _cacheSurvey };
    funcs[_for]({ templateId, surveyId, patientId, snapshot: { surveyLayout, surveyData } });
    if (asModal) hideModal();
  };

  handleCloseConfirmed = () => {
    const {
      for: _for = "surveys",
      patientId,
      selectedTemplate: { templateId },
      survey: { id: surveyId },
      actions: { clearCachedSurvey: _clearCachedSurvey, clearHiddenTreatmentPlanSurvey }
    } = this.props;
    const funcs = { treatmentPlan: clearHiddenTreatmentPlanSurvey, surveys: _clearCachedSurvey };
    funcs[_for]({ patientId, templateId, surveyId });
    this._closing = true;
    if (this.props.afterCancel) this.props.afterCancel();
    hideModal();
  };

  createAnswersObject() {
    const {
      selectedTemplate: { version: templateVersion }
    } = this.props;
    const { surveyLayout, surveyData, actions } = this.state;
    const flatLayout = [].concat(
      ...surveyLayout.sections.map((_s) => [].concat(..._s.groups.map((_g) => _g.questions)))
    );
    const answersToPush = flatLayout
      .filter((_q) => isQuestionTriggered(_q, flatLayout, surveyData))
      .map((question) => {
        const _question = surveyData[getQuestionStringId(question)];
        return {
          visitId: this.props.surveysVisitId,
          questionKey: question.key,
          questionCount: question.questionCount,
          sectionKey: question.sectionKey,
          sectionCount: question.sectionCount,
          groupKey: question.groupKey,
          groupCount: question.groupCount,
          questionVersion: question.version,
          answers: _question.answers,
          elucidations: _question.elucidations.filter(
            (e) => !isEmpty(e.value) && (isEmpty(e.key) || _question.answers.includes(e.key))
          )
        };
      });
    return { templateVersion, surveyVersion: surveyLayout.version, answers: answersToPush, templateActions: actions };
  }

  handleSave = async () => {
    const {
      tab,
      patientId,
      handleSave,
      manipulateAnswers,
      surveysVisitId,
      availableTemplates,
      selectedTemplate,
      selectedTemplate: { templateId },
      survey,
      survey: { id: surveyId },
      actions: { saveSurvey, loadPatientSummary }
    } = this.props;
    let surveyAnswers = this.createAnswersObject();
    this.setState({ saving: true });
    if (manipulateAnswers) {
      surveyAnswers = manipulateAnswers(surveyAnswers);
    }
    if (this.props.closeAfterSave) this._closing = true;
    if (handleSave) {
      handleSave(templateId, surveyId, surveyAnswers).then(() => {
        if (this.props.afterSave) this.props.afterSave();
      });
      // .finally(() => {
      //   this.setState({ saving: false }); // this has been moved to ws
      // });
    } else {
      try {
        validateSurveyAnswersDataIntegrity(
          tab === "discharge" ? null : surveysVisitId,
          availableTemplates,
          selectedTemplate,
          templateId,
          survey,
          surveyAnswers
        );
        await saveSurvey(surveysVisitId, templateId, surveyId, patientId, surveyAnswers).then(() => {
          if (this.props.afterSave) this.props.afterSave();
        });
        if (this.props.hideModal !== false) {
          loadPatientSummary(this.props.patientId);
          hideModal();
        }
      } catch (error) {
        toast.error(error.message, toastErrorOptions);
      }
      // finally {
      //   if (this._isMounted) this.setState({ saving: false });  // this has been moved to ws
      // }
    }
  };

  createComponent = (question, triggered, questionPaddingLevel, uiMetadata) => {
    switch (uiMetadata.component) {
      case "Dropdown":
        return this.createDropdown(question, triggered, questionPaddingLevel);
      case "RadioGroup":
        return this.createSingleSelect(question, triggered, questionPaddingLevel);
      case "DateInput":
        return this.createDateInput(question, triggered, questionPaddingLevel);
      default:
        return "";
    }
  };

  renderQuestion = (question) => {
    const { surveyLayout } = this.state;
    const flatLayout = [].concat(
      ...surveyLayout.sections.map((_s) => [].concat(..._s.groups.map((_g) => _g.questions)))
    );
    const triggered = isQuestionTriggered(question, flatLayout, this.state.surveyData);

    if (!triggered) return <Col key={question.key} className={cssClasses.hide} />;

    const questionPaddingLevel = getQuestionPaddingLevel(question, flatLayout);

    const uiMetadata =
      question.metadata &&
      typeof question.metadata === "object" &&
      question.metadata.ui &&
      typeof question.metadata.ui === "object"
        ? question.metadata.ui
        : {};
    if (Object.prototype.hasOwnProperty.call(uiMetadata, "component")) {
      return this.createComponent(question, triggered, questionPaddingLevel, uiMetadata);
    }

    switch (question.type) {
      case "MultiSelect":
        return this.createMultiSelect(question, triggered, questionPaddingLevel);
      case "SingleSelect":
        // if 7 or more options, create a dropdown instead
        if (question.options.length >= 7) return this.createDropdown(question, triggered, questionPaddingLevel);
        return this.createSingleSelect(question, triggered, questionPaddingLevel);
      case "Dropdown":
        return this.createDropdown(question, triggered, questionPaddingLevel);
      case "ShortText":
        return this.createShortText(question, triggered, questionPaddingLevel);
      case "LongText":
        return this.createLongText(question, triggered, questionPaddingLevel);
      case "MultiToggle":
        return this.createMultiToggle(question, triggered, questionPaddingLevel);
      case "AdvancedSearch":
        return this.createAdvancedSearch(question, triggered, questionPaddingLevel);
      default:
        return "";
    }
  };

  initiateMultiToggleAnswers = (selection, question) => {
    const stringId = getQuestionStringId(question);
    const questionOptions = question.options;
    const _question = { ...this.state.surveyData[stringId] };
    if (selection) {
      // add defaults
      const answersToPush = questionOptions.map((optionsString) => {
        const options = optionsString.split("|").flat();
        for (let i = 0; i < options.length; i++) {
          if (parseLabel(options[i], "style").includes("option-default")) return parseLabel(options[i], "label");
        }
        return null;
      });
      _question.answers = ["Yes", ...answersToPush];
    } else {
      // remove defaults
      _question.answers = ["No"];
    }
    this.setState({ surveyData: { ...this.state.surveyData, [stringId]: _question } });
  };

  restoreMultiToggleState = (question, answers) => {
    const stringId = getQuestionStringId(question);
    const _question = this.state.surveyData[stringId];
    _question.answers = answers.length > 0 ? ["Yes", ...answers] : ["No"];
    this.setState({ surveyData: { ...this.state.surveyData, [stringId]: _question } });
  };

  clearQuestionAnswer = (question) => {
    const { surveyLayout, surveyData } = this.state;
    const stringId = getQuestionStringId(question);
    const _surveyData = { ...surveyData, [stringId]: { ...surveyData[stringId], keyword: "", answers: [] } };
    this.setState({
      surveyLayout: !isEmpty(question.originalOptions)
        ? refreshQuestionOptions(surveyLayout, _surveyData)
        : surveyLayout,
      surveyData: _surveyData
    });
  };

  updateAnswerObjectAnswers = (question, answers) => {
    const stringId = getQuestionStringId(question);
    const _question = this.state.surveyData[stringId];
    this.setState({ surveyData: { ...this.state.surveyData, [stringId]: { ..._question, answers } } });
  };

  handleDuplicateSection = (section) => {
    const { surveyLayout, surveyData, actions } = this.state;
    const max = Math.max(...actions.map((action) => action.order), 0);
    const action = {
      type: "DUPLICATE",
      targetType: "SECTION",
      targetKey: { sectionKey: section.key },
      order: max + 1
    };
    const [_surveyLayout, _surveyData, _newSection] = applyAction(surveyLayout, surveyData, action);
    this.setState({
      surveyLayout: _surveyLayout,
      surveyData: _surveyData,
      actions: [...actions, action],
      activeSectionKey: `${_newSection.key}-${_newSection.sortOrder}`
    });
  };

  handleDuplicateGroup = (group) => {
    const { surveyLayout, surveyData, actions } = this.state;
    const max = Math.max(...actions.map((action) => action.order), 0);
    const action = {
      type: "DUPLICATE",
      targetType: "GROUP",
      targetKey: { sectionKey: group.sectionKey, sectionCount: group.sectionCount, groupKey: group.key },
      order: max + 1
    };
    const [_surveyLayout, _surveyData] = applyAction(surveyLayout, surveyData, action);
    this.setState({ surveyLayout: _surveyLayout, surveyData: _surveyData, actions: [...actions, action] });
  };

  handleDuplicateQuestion = (question) => {
    const { surveyLayout, surveyData, actions } = this.state;
    const max = Math.max(...actions.map((action) => action.order), 0);
    const action = {
      type: "DUPLICATE",
      targetType: "QUESTION",
      targetKey: {
        sectionKey: question.sectionKey,
        sectionCount: question.sectionCount,
        groupKey: question.groupKey,
        groupCount: question.groupCount,
        questionKey: question.key
      },
      order: max + 1
    };
    const [_surveyLayout, _surveyData] = applyAction(surveyLayout, surveyData, action);
    this.setState({ surveyLayout: _surveyLayout, surveyData: _surveyData, actions: [...actions, action] });
  };

  handleDeleteSection = (section) => {
    const { activeSectionKey, surveyLayout, surveyData, actions } = this.state;
    const max = Math.max(...actions.map((action) => action.order), 0);
    const action = {
      type: "DELETE",
      targetType: "SECTION",
      targetKey: {
        sectionKey: section.key,
        sectionCount: section.sectionCount
      },
      order: max + 1
    };
    const [_surveyLayout, _surveyData] = applyAction(surveyLayout, surveyData, action);

    const order = +activeSectionKey.split("-")[1];
    const _activeSectionKey = section.sortOrder <= order ? `${section.key}-${+order - 1}` : `${section.key}-${order}`;

    this.setState({
      surveyLayout: _surveyLayout,
      surveyData: _surveyData,
      actions: [...actions, action],
      activeSectionKey: _activeSectionKey
    });
  };

  handleDeleteGroup = (group) => {
    const { surveyLayout, surveyData, actions } = this.state;
    const max = Math.max(...actions.map((action) => action.order), 0);
    const action = {
      type: "DELETE",
      targetType: "GROUP",
      targetKey: {
        sectionKey: group.sectionKey,
        sectionCount: group.sectionCount,
        groupKey: group.key,
        groupCount: group.groupCount
      },
      order: max + 1
    };
    const [_surveyLayout, _surveyData] = applyAction(surveyLayout, surveyData, action);
    this.setState({ surveyLayout: _surveyLayout, surveyData: _surveyData, actions: [...actions, action] });
  };

  handleDeleteQuestion = (question) => {
    const { surveyLayout, surveyData, actions } = this.state;
    const max = Math.max(...actions.map((action) => action.order), 0);
    const action = {
      type: "DELETE",
      targetType: "QUESTION",
      targetKey: {
        sectionKey: question.sectionKey,
        sectionCount: question.sectionCount,
        groupKey: question.groupKey,
        groupCount: question.groupCount,
        questionKey: question.key,
        questionCount: question.questionCount
      },
      order: max + 1
    };
    const [_surveyLayout, _surveyData] = applyAction(surveyLayout, surveyData, action);
    this.setState({ surveyLayout: _surveyLayout, surveyData: _surveyData, actions: [...actions, action] });
  };

  questionWrapper = (question, component, triggered, questionPaddingLevel) => {
    const className = `${triggered ? cssClasses.show : cssClasses.hide} ${
      cssClasses[`${numbersToText[questionPaddingLevel]}-rem-padded-question`]
    } no-top-padding`;
    return (
      <Col key={question.key} className={className}>
        <div
          id={`qc-${question.key}-${question.questionCount}`}
          ref={(element) => {
            if (
              element !== null &&
              this.state.activeQuestionRef === null &&
              this.props.activeSurvey?.questionKey === question.key &&
              this.props.activeSurvey?.questionCount === question.questionCount
            ) {
              this.setState({ activeQuestionRef: element });
            }
          }}
          className={cssClasses["survey-question-container"]}
          key={question.key}
          role="button"
          tabIndex={0}
          onClick={() => {
            const section = this.state.surveyLayout.sections.find(
              (_section) => _section.key === question.sectionKey && _section.sectionCount === question.sectionCount
            );
            this.props.actions.setActiveSurvey({
              surveyId: this.props.survey.id,
              sectionKey: section.key,
              sectionSortOrder: section.sortOrder,
              questionKey: question.key,
              questionCount: question.questionCount
            });
          }}
        >
          {question.isDynamic && question.questionCount > 1 && (
            <Button
              size="sm"
              variant="danger"
              title="Delete group"
              onClick={() => this.handleDeleteQuestion(question)}
              style={{ float: "right" }}
            >
              <Icon name="x" />
            </Button>
          )}
          <label className={question.isRequired ? `mock-semantic-required` : ``}>{question.text}</label>
          <Button
            variant="danger"
            size="sm"
            disabled={!this.questionAnswered(this.state.surveyData[getQuestionStringId(question)])}
            onClick={() => this.clearQuestionAnswer(question)}
            style={{ float: "right" }}
          >
            Clear
          </Button>
          {question.isDynamic && question.isLastDynamicQuestion && (
            <Button
              variant="warning"
              size="sm"
              className="mt-2"
              onClick={() => this.handleDuplicateQuestion(question)}
              style={{ float: "right" }}
            >
              <Icon name="plus-lg" />
            </Button>
          )}
          {!isEmpty(question.suggestedAnswers) && (
            <div>
              <h5>Previous visit answer(s)</h5>
              <div style={{ paddingLeft: "1em" }}>
                {question.suggestedAnswers.map((answer) => (
                  <Label key={answer} style={{ marginBottom: ".5em" }}>
                    {answer}
                  </Label>
                ))}
              </div>
              <div style={{ paddingLeft: "1em", textAlign: "right", marginBottom: "1em" }}>
                <Button
                  variant="success"
                  size="sm"
                  onClick={() => {
                    this.updateAnswerObjectAnswers(question, question.suggestedAnswers);
                  }}
                >
                  Apply
                </Button>
              </div>
            </div>
          )}
          {component}
        </div>
      </Col>
    );
  };

  createMultiSelect = (question, triggered, questionPaddingLevel) =>
    this.questionWrapper(
      question,
      <React.Fragment>
        {question.options.map((option, index) => (
          <React.Fragment key={index}>
            <Form.Checkbox
              className={parseLabel(option, "style")
                .map((className) => cssClasses[className])
                .join(" ")}
              key={index}
              label={parseLabel(option, "label")}
              checked={this.getQuestionValue("MultiSelect", question, parseLabel(option, "label"))}
              onChange={this.handleMultiSelect}
              name={question.key}
              skey={question.sectionKey}
              scount={question.sectionCount}
              gkey={question.groupKey}
              gcount={question.groupCount}
              qcount={question.questionCount}
            />
            {this.createElucidation(question, triggered, option)}
          </React.Fragment>
        ))}
        {this.createElucidation(question, triggered, "")}
      </React.Fragment>,
      triggered,
      questionPaddingLevel
    );

  createDateInput = (question, triggered, questionPaddingLevel) =>
    this.questionWrapper(
      question,
      <React.Fragment>
        <DateInput
          name={question.key}
          skey={question.sectionKey}
          scount={question.sectionCount}
          gkey={question.groupKey}
          gcount={question.groupCount}
          qcount={question.questionCount}
          onChange={this.handleInputChange}
          value={this.getQuestionValue("Text", question)}
          placeholder="Select Date"
          dateFormat={dateFormat}
          iconPosition="right"
          hideMobileKeyboard
          closable
        />
        {this.createElucidation(question, triggered, "")}
      </React.Fragment>,
      triggered,
      questionPaddingLevel
    );

  createSingleSelect = (question, triggered, questionPaddingLevel) =>
    this.questionWrapper(
      question,
      <React.Fragment>
        {question.options.map((option, index) => (
          <React.Fragment key={index}>
            <Form.Field key={index}>
              <Form.Radio
                className={parseLabel(option, "style")
                  .map((className) => cssClasses[className])
                  .join(" ")}
                label={parseLabel(option, "label")}
                name={question.key}
                skey={question.sectionKey}
                scount={question.sectionCount}
                gkey={question.groupKey}
                gcount={question.groupCount}
                qcount={question.questionCount}
                value={option}
                checked={
                  triggered
                    ? this.getQuestionValue("Radio", question, parseLabel(option, "label"))
                    : this.getQuestionValue("Radio", question, "")
                }
                onChange={this.handleInputChange}
              />
            </Form.Field>
            {this.createElucidation(question, triggered, option)}
          </React.Fragment>
        ))}
        {this.createElucidation(question, triggered, "")}
      </React.Fragment>,
      triggered,
      questionPaddingLevel
    );

  createDropdown = (question, triggered, questionPaddingLevel) =>
    this.questionWrapper(
      question,
      <React.Fragment>
        <Form.Field>
          <Form.Dropdown
            fluid
            selection
            search
            options={question.options.map((option) => ({
              text: option,
              value: option
            }))}
            required={question.isRequired}
            name={question.key}
            skey={question.sectionKey}
            scount={question.sectionCount}
            gkey={question.groupKey}
            gcount={question.groupCount}
            qcount={question.questionCount}
            onChange={this.handleInputChange}
            placeholder="Select..."
            value={this.getQuestionValue("Dropdown", question)}
          />
        </Form.Field>
        {question.options.map((option, index) => (
          <React.Fragment key={index}>{this.createElucidation(question, triggered, option)}</React.Fragment>
        ))}
        {this.createElucidation(question, triggered, "")}
      </React.Fragment>,
      triggered,
      questionPaddingLevel
    );

  createShortText = (question, triggered, questionPaddingLevel) =>
    this.questionWrapper(
      question,
      <React.Fragment>
        <Form.Field>
          <Form.Input
            name={question.key}
            skey={question.sectionKey}
            scount={question.sectionCount}
            gkey={question.groupKey}
            gcount={question.groupCount}
            qcount={question.questionCount}
            onChange={this.handleInputChange}
            value={this.getQuestionValue("Text", question)}
          />
        </Form.Field>
        {this.createElucidation(question, triggered, "")}
      </React.Fragment>,
      triggered,
      questionPaddingLevel
    );

  createLongText = (question, triggered, questionPaddingLevel) =>
    this.questionWrapper(
      question,
      <React.Fragment>
        <Form.Field>
          <Form.TextArea
            name={question.key}
            skey={question.sectionKey}
            scount={question.sectionCount}
            gkey={question.groupKey}
            gcount={question.groupCount}
            qcount={question.questionCount}
            onChange={this.handleInputChange}
            value={this.getQuestionValue("Text", question)}
          />
        </Form.Field>
        {this.createElucidation(question, triggered, "")}
      </React.Fragment>,
      triggered,
      questionPaddingLevel
    );

  createMultiToggle = (question, triggered, questionPaddingLevel) => (
    <MultiToggle
      key={question.key}
      question={question}
      triggered={triggered}
      questionPaddingLevel={questionPaddingLevel}
      questionWrapper={this.questionWrapper}
      getQuestionValue={this.getQuestionValue}
      createElucidation={this.createElucidation}
      surveyData={this.state.surveyData}
      handleMultiToggle={this.handleMultiToggle}
      initiateMultiToggleAnswers={this.initiateMultiToggleAnswers}
      restoreMultiToggleState={this.restoreMultiToggleState}
      parseLabel={parseLabel}
    />
  );

  createAdvancedSearch = (question, triggered, questionPaddingLevel) => {
    const stringId = getQuestionStringId(question);
    const _question = this.state.surveyData[stringId];
    return (
      <AdvancedSearch
        key={question.key}
        question={question}
        triggered={triggered}
        questionPaddingLevel={questionPaddingLevel}
        answerObject={_question}
        questionWrapper={this.questionWrapper}
        handleSearchChange={this.handleSearchChange}
        handleResultSelect={this.handleResultSelect}
        updateAnswerObjectAnswers={this.updateAnswerObjectAnswers}
      />
    );
  };

  // Handles the creation of elucidations, can be at question level or option level
  createElucidation = (question, triggered, option) => {
    const _question = this.state.surveyData[getQuestionStringId(question)];
    const _elucidation = _question.elucidations.find((_e) => _e.key === option);
    if (_elucidation) {
      const hide = _elucidation.key ? !_question.answers.includes(_elucidation.key) : false;
      return (
        <Form.Field
          className={_elucidation.key ? cssClasses["elucidation-input"] : ""}
          style={hide ? { display: "none" } : {}}
        >
          <Form.Input
            onChange={this.handleElucidation}
            name={`${_question.questionKey}~${_elucidation.key}`}
            skey={_question.sectionKey}
            scount={_question.sectionCount}
            gkey={_question.groupKey}
            gcount={_question.groupCount}
            qcount={_question.questionCount}
            value={this.getElucidationValue(_question, _elucidation.key, triggered) || ""}
            label={_elucidation.caption}
            disabled={hide}
          />
        </Form.Field>
      );
    }
    return "";
  };

  autoSave = debounce(() => {
    if (this._closing || !this.props.autoSave) return;
    this.handleHide();
  }, 1500);

  handleMultiToggle = (index, value, question) => {
    const stringId = getQuestionStringId(question);
    const _question = cloneDeep(this.state.surveyData[stringId]);
    _question.answers[index + 1] = value;
    this.setState({ surveyData: { ...this.state.surveyData, [stringId]: _question } }, this.autoSave);
  };

  resetSearch = (id) => {
    const { surveyData } = this.state;
    const stringId = getQuestionStringId(id);
    const _question = this.state.surveyData[stringId];
    this.setState({
      surveyData: {
        ...surveyData,
        [stringId]: { ..._question, searchResult: [] }
      }
    });
  };

  debouncedSearch = debounce((id) => this.doSearch(id), 500);

  doSearch = (id) => {
    const { surveyLayout, surveyData } = this.state;
    const flatLayout = [].concat(
      ...surveyLayout.sections.map((_s) => [].concat(..._s.groups.map((_g) => _g.questions)))
    );
    const question = findItem(flatLayout, id);

    const stringId = getQuestionStringId(id);
    const _question = this.state.surveyData[stringId];

    const value = this.getQuestionValue("Keyword", _question);
    if (isEmpty(value.trim())) {
      this.resetSearch(id);
    } else {
      const config = question.config && typeof question.config === "object" ? question.config : {};

      if (isEmpty(config.searchUrl)) throw new Error("Search URL is missing");

      this.props.actions.search(config.searchUrl, value.trim()).then((result) => {
        this.setState({
          surveyData: {
            ...surveyData,
            [stringId]: { ..._question, searchResult: result.map((i) => ({ ...i, key: i.id, title: i.text })) }
          }
        });
      });
    }
  };

  handleSearchChange = (e, data) => {
    const {
      name: key,
      qcount: questionCount,
      skey: sectionKey,
      scount: sectionCount,
      gkey: groupKey,
      gcount: groupCount,
      value,
      mcs
    } = data;
    const id = getQuestionId({ sectionKey, sectionCount, groupKey, groupCount, key, questionCount });
    const stringId = getQuestionStringId({ sectionKey, sectionCount, groupKey, groupCount, key, questionCount });
    const _question = { ...this.state.surveyData[stringId] };
    _question.keyword = value;
    this.setState(
      {
        surveyData: { ...this.state.surveyData, [stringId]: _question }
      },
      () => {
        if (value.trim().length >= mcs) this.debouncedSearch(id);
        else this.resetSearch(id);
      }
    );
  };

  handleResultSelect = (e, data) => {
    const {
      name: key,
      qcount: questionCount,
      skey: sectionKey,
      scount: sectionCount,
      gkey: groupKey,
      gcount: groupCount,
      result
    } = data;
    const stringId = getQuestionStringId({ sectionKey, sectionCount, groupKey, groupCount, key, questionCount });
    const _question = { ...this.state.surveyData[stringId] };
    _question.keyword = "";
    _question.answers = [...new Set([..._question.answers, result.text])];
    this.setState(
      {
        surveyData: { ...this.state.surveyData, [stringId]: _question }
      },
      this.autoSave
    );
  };

  handleInputChange = (e, data) => {
    const {
      name: questionKey,
      qcount: questionCount,
      skey: sectionKey,
      scount: sectionCount,
      gkey: groupKey,
      gcount: groupCount,
      label,
      type,
      value
    } = data;
    const { surveyLayout, surveyData } = this.state;
    const stringId = getQuestionStringId({
      key: questionKey,
      questionCount,
      sectionKey,
      sectionCount,
      groupKey,
      groupCount
    });
    const _surveyData = {
      ...surveyData,
      [stringId]: { ...surveyData[stringId], answers: type === "radio" ? [label] : !isEmpty(value) ? [value] : [] }
    };
    this.setState(
      {
        surveyLayout: data.options ? refreshQuestionOptions(surveyLayout, _surveyData) : surveyLayout,
        surveyData: _surveyData
      },
      this.autoSave
    );
  };

  handleMultiSelect = (e, data) => {
    const {
      name: questionKey,
      qcount: questionCount,
      skey: sectionKey,
      scount: sectionCount,
      gkey: groupKey,
      gcount: groupCount,
      checked,
      label
    } = data;
    const stringId = getQuestionStringId({
      questionKey,
      questionCount,
      sectionKey,
      sectionCount,
      groupKey,
      groupCount
    });
    const { surveyLayout, surveyData } = this.state;
    const _question = cloneDeep(surveyData[stringId]);
    if (checked) _question.answers.push(label);
    else _question.answers.splice(_question.answers.indexOf(label), 1);
    const _surveyData = { ...surveyData, [stringId]: _question };
    this.setState(
      {
        surveyLayout: refreshQuestionOptions(surveyLayout, _surveyData),
        surveyData: _surveyData
      },
      this.autoSave
    );
  };

  handleElucidation = (e, data) => {
    const {
      name,
      qcount: questionCount,
      skey: sectionKey,
      scount: sectionCount,
      gkey: groupKey,
      gcount: groupCount,
      value
    } = data;
    const splitName = name.split("~");
    const questionKey = splitName[0];
    const elucidationKey = splitName[1];
    const stringId = getQuestionStringId({
      key: questionKey,
      questionCount,
      sectionKey,
      sectionCount,
      groupKey,
      groupCount
    });
    const _question = cloneDeep(this.state.surveyData[stringId]);
    const elucidations = [..._question.elucidations];
    const elucidation = elucidations.find((_elu) => _elu.key === elucidationKey);
    const elucidationIndex = elucidations.findIndex((_elu) => _elu.key === elucidationKey);
    elucidation.value = value;
    elucidations.splice(elucidationIndex, 1, elucidation);
    _question.elucidations = elucidations;
    this.setState({ surveyData: { ...this.state.surveyData, [stringId]: _question } }, this.autoSave);
  };

  questionAnswered = (question) => !isEmpty(question.answers);

  // Checks to make sure the required questions are filled out
  requiredQuestionsFilled = () => {
    const { surveyLayout, surveyData } = this.state;
    const flatLayout = [].concat(
      ...surveyLayout.sections.map((_s) => [].concat(..._s.groups.map((_g) => _g.questions)))
    );
    const requiredQuestions = flatLayout.filter(
      (_q) => _q.isRequired === true && isQuestionTriggered(_q, flatLayout, surveyData)
    );
    return requiredQuestions.every((_q) => this.questionAnswered(surveyData[getQuestionStringId(_q)]));
  };

  renderGroup = (group) => {
    const { surveyLayout, surveyData } = this.state;
    const flatLayout = [].concat(
      ...surveyLayout.sections.map((_s) => [].concat(..._s.groups.map((_g) => _g.questions)))
    );
    const show = surveyData ? group.questions.some((_q) => isQuestionTriggered(_q, flatLayout, surveyData)) : false;

    if (!show) return null;

    const handleRows = (rows) =>
      Object.keys(rows).map((key) => (
        <Row columns={rows[key].length} key={key} className={`p-0 ${cssClasses["survey-row"]}`}>
          {rows[key].map((question) => this.renderQuestion(question))}
        </Row>
      ));

    const plusButton = () => (
      <Button
        size="sm"
        variant="warning"
        className="mt-2 full-width"
        onClick={(e) => {
          e.stopPropagation();
          this.handleDuplicateGroup(group);
        }}
      >
        <Icon name="plus-lg" />
      </Button>
    );

    const deleteButton = () => (
      <Button
        size="sm"
        variant="danger"
        title="Delete group"
        onClick={(e) => {
          e.stopPropagation();
          this.handleDeleteGroup(group);
        }}
        style={{ float: "right" }}
      >
        <Icon name="x" />
      </Button>
    );

    const rows = groupBy(group.questions, "sortOrder");

    switch (group.style) {
      case "none":
        return (
          <React.Fragment key={`${group.key}-${group.sortOrder}`}>
            {group.isDynamic === true && group.groupCount > 1 && deleteButton()}
            {handleRows(rows)}
            {group.isDynamic === true && group.isLastDynamicGroup && plusButton()}
          </React.Fragment>
        );
      // case "solid-border": return null; // DIL Do It Later
      case "dashed-border":
        return (
          <Container
            key={`${group.key}-${group.sortOrder}`}
            className="no-padding"
            style={{ width: "100%", border: "dashed 1px grey", margin: "1em 0" }}
          >
            {group.isDynamic === true && group.groupCount > 1 && (
              <Row className="no-bottom-padding">
                <Col sm={12}>{deleteButton()}</Col>
              </Row>
            )}
            {handleRows(rows)}
            {group.isDynamic === true && group.isLastDynamicGroup && (
              <Row>
                <Col sm={12}>{plusButton()}</Col>
              </Row>
            )}
          </Container>
        );
      // empty string, null and undefined are treated the same as fieldset to maintain legacy data
      case "":
      case null:
      case undefined:
      case "fieldset":
      default:
        return (
          <fieldset
            key={`${group.key}-${group.sortOrder}`}
            // className={cssClasses["survey-question-group"]}
            style={{
              display: "block",
              width: "100%",
              marginTop: "2rem",
              padding: group && group.header ? `1em` : `0`,
              border: "1px solid #d0d0d0"
            }}
          >
            {group && group.header && (
              <legend style={{ float: "none", width: "auto", padding: "0 .5rem", fontSize: "18px", fontWeight: "500" }}>
                {group.header}
              </legend>
            )}
            {group.isDynamic === true && group.groupCount > 1 && deleteButton()}
            {handleRows(rows)}
            {group.isDynamic === true && group.isLastDynamicGroup && plusButton()}
          </fieldset>
        );
    }
  };

  renderSection = (section, showTitle = true) => {
    if (section.groups.length < 1) return null;

    const sectionTitle = section.header;
    const groups = cloneDeep(section.groups);

    return (
      <Container>
        {showTitle && sectionTitle && (
          <Row key={`${section.key}-${section.sortOrder}`} className="no-padding">
            <h2>{sectionTitle}</h2>
          </Row>
        )}
        {groups
          .sort((a, b) => (+a.sortOrder > +b.sortOrder ? 1 : +a.sortOrder < +b.sortOrder ? -1 : 0))
          .map((group) => this.renderGroup(group))}
      </Container>
    );
  };

  renderForm = () => {
    const {
      surveyLayout: { sections },
      activeSectionKey
    } = this.state;

    if (sections.length === 1 && !sections[0].isDynamic) {
      return <div className={cssClasses[`survey-form`]}>{this.renderSection(sections[0])}</div>;
    }

    const panes = [];

    [...sections]
      .sort((a, b) => (+a.sortOrder > +b.sortOrder ? 1 : +a.sortOrder < +b.sortOrder ? -1 : 0))
      .forEach((section) => {
        panes.push({
          menuItem: (
            <Menu.Item
              key={`${section.key}-${section.sortOrder}`}
              className={`no-padding ${cssClasses["menu-item"]} ${
                section.hasMissingRequiredFields ? cssClasses.danger : cssClasses.success
              } ${activeSectionKey === `${section.key}-${section.sortOrder}` ? cssClasses["active-menu-item"] : ""} ${
                section.isDynamic && section.isLastDynamicSection ? cssClasses["last-dynamic"] : ""
              }`}
            >
              <div className="full-padding">
                {section.isDynamic && section.sectionCount > 1 && (
                  <Button
                    size="sm"
                    title="Delete section"
                    className="no-padding transparent-button-icon delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.handleDeleteSection(section);
                    }}
                  >
                    <Icon name="x" />
                  </Button>
                )}
                {section.header + (section.hasRequiredFields ? " *" : "")}
                <br />
                {section.groups.map((group) =>
                  group.questions.map((question) =>
                    question.showAnswerInSectionHeader
                      ? (() => {
                          const answer = this.getQuestionValue("Text", question);
                          return !isEmpty(answer) ? (
                            <span className={cssClasses["section-extra"]} key={answer}>
                              {answer}
                              <br />
                            </span>
                          ) : (
                            ``
                          );
                        })()
                      : ``
                  )
                )}
              </div>
              {section.isDynamic && section.isLastDynamicSection && (
                <Button
                  size="sm"
                  variant="warning"
                  className="mt-2 full-width"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.handleDuplicateSection(section);
                  }}
                >
                  <Icon name="plus-lg" />
                </Button>
              )}
            </Menu.Item>
          ),
          render: () => (
            <Tab.Pane key={`${section.key}-${section.sortOrder}`}>{this.renderSection(section, false)}</Tab.Pane>
          )
        });
      });
    const twoTabs = sections.length > 14;
    return (
      <div className={cssClasses[`survey-form`]}>
        <Tab
          grid={{ paneWidth: twoTabs ? 8 : 12, tabWidth: twoTabs ? 8 : 4 }}
          menu={{
            fluid: true,
            pointing: true,
            vertical: true,
            tabular: true
            // className: twoTabs ? "columns_" : ""
          }}
          panes={panes}
          activeIndex={sections.findIndex((s) => `${s.key}-${s.sortOrder}` === activeSectionKey)}
          onTabChange={(_, data) => {
            this.props.actions.setActiveSurvey({
              surveyId: this.props.survey.id,
              sectionKey: sections[data.activeIndex].key,
              sectionSortOrder: sections[data.activeIndex].sortOrder,
              questionKey: sections[data.activeIndex].groups[0].questions[0].key,
              questionCount: sections[data.activeIndex].groups[0].questions[0].questionCount
            });
            this.setState({
              activeSectionKey: `${sections[data.activeIndex].key}-${sections[data.activeIndex].sortOrder}`
            });
            this.formContainerRef.current.scrollTop = 0;
          }}
        />
      </div>
    );
  };

  renderAsModal(requiredQuestionsFilled, someQuestionsFilled) {
    const { survey, disableSave } = this.props;
    const { saving } = this.state;
    return (
      <Modal className={cssClasses["survey-modal"]} size="xl" backdrop="static" keyboard={false} show={this.props.open}>
        <Modal.Header>
          <Modal.Title>{!isEmpty(survey.title) && <h3>{survey.title}</h3>}</Modal.Title>
        </Modal.Header>
        <Modal.Body ref={this.formContainerRef} style={{ maxHeight: "70vh", overflow: "auto" }}>
          <Form>{this.renderForm()}</Form>
        </Modal.Body>
        <Modal.Footer className="bottom-bar">
          <Container>
            {!requiredQuestionsFilled && (
              <Row>
                <Col sm={12}>
                  <Message variant="danger" className="half-padding">
                    Please fill in all required fields marked with a red asterisk (*)
                  </Message>
                </Col>
              </Row>
            )}
            <Row>
              <Col sm={12}>
                <Button
                  onClick={() => {
                    showConfirmationModal({
                      icon: "exclamation-triangle",
                      iconColor: "warning",
                      title: "Confirm closing survey",
                      confirmButtonMessage: "Yes",
                      confirmButtonVariant: "danger",
                      cancelButtonMessage: "No",
                      cancelButtonVariant: "primary",
                      description: "Are you sure you want to cancel editing survey?\nAny unsaved data will be lost",
                      onConfirm: () => {
                        this.handleCloseConfirmed();
                      }
                    });
                  }}
                  id="btn-cancel"
                  variant="secondary"
                  disabled={saving}
                >
                  Cancel
                </Button>
                <Button
                  onClick={this.handleSave}
                  variant="primary"
                  id="btn-save"
                  loading={saving}
                  disabled={saving || disableSave || !requiredQuestionsFilled}
                  className="ms-2"
                  style={{ float: "right" }}
                >
                  Save
                </Button>
                <Button
                  onClick={this.handleHide}
                  id="btn-hide"
                  variant="warning"
                  disabled={!someQuestionsFilled}
                  style={{ float: "right" }}
                >
                  Hide
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    );
  }

  renderAsSegment(requiredQuestionsFilled) {
    const { survey, disableSave } = this.props;
    const { saving } = this.state;
    return (
      <Container className={cssClasses["survey-container"]}>
        {!isEmpty(survey.title) && <h3>{survey.title}</h3>}
        <Row ref={this.formContainerRef}>
          <Col sm={12}>
            <Form>{this.renderForm()}</Form>
          </Col>
        </Row>
        {!requiredQuestionsFilled && (
          <Row className="mt-2">
            <Col sm={12}>
              <Message variant="danger" className="half-padding mb-0">
                Please fill in all required fields marked with a red asterisk (*)
              </Message>
            </Col>
          </Row>
        )}
        <Row
          className="mt-2 py-3"
          style={{ position: "sticky", bottom: 0, backgroundColor: "RGB(255, 230, 160, 0.8)" }}
        >
          <Col sm={12} style={{ textAlign: "right" }} className={cssClasses["bottom-bar"]}>
            <Button
              onClick={() => {
                showConfirmationModal({
                  icon: "exclamation-triangle",
                  iconColor: "warning",
                  title: "Confirm closing survey",
                  confirmButtonMessage: "Yes",
                  confirmButtonVariant: "danger",
                  cancelButtonMessage: "No",
                  cancelButtonVariant: "primary",
                  description: "Are you sure you want to cancel editing survey?\nAny unsaved data will be lost",
                  onConfirm: () => {
                    this.handleCloseConfirmed();
                  }
                });
              }}
              id="btn-cancel"
              variant="secondary"
              style={{ float: "left" }}
              disabled={saving}
            >
              Cancel
            </Button>
            <Button
              onClick={this.handleSave}
              variant="primary"
              id="btn-save"
              loading={saving}
              disabled={saving || disableSave || !requiredQuestionsFilled}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }

  render() {
    const { surveyData } = this.state;

    const { asModal } = this.props;

    const questions = Object.values(surveyData);
    const requiredQuestionsFilled = this.requiredQuestionsFilled();
    const someQuestionsFilled = questions.some((_q) => this.questionAnswered(_q));
    return (
      <React.Fragment>
        {asModal === false
          ? this.renderAsSegment(requiredQuestionsFilled)
          : this.renderAsModal(requiredQuestionsFilled, someQuestionsFilled)}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, props) {
  const subState = state[props.for];
  const patientId = state.patient.currentPatient.patientId;
  return {
    patientId,
    cachedSurveys: subState.cachedSurveys,
    activeSurvey: subState.activeSurvey,
    savedSurveyTimestamp:
      state.surveys.savedSurveys[`${patientId}-${props.selectedTemplate.templateId}-${props.survey.id}`]
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators({ ...templateActions, ...treatmentPlanActions, ...patientActions }, dispatch),
      cacheSurvey: (payload) => dispatch(cacheSurvey(payload)),
      clearCachedSurvey: (payload) => dispatch(clearCachedSurvey(payload)),
      setActiveSurvey: (payload) => dispatch(setActiveSurvey(payload))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(emrComponent(Survey));
