import React, { Component } from "react";
import shortid from "shortid";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Button, Icon } from "../../ui";
import * as modalActions from "../../actions/modalActions";

export class ConfirmationModal extends Component {
  state = { confirmButtonPressed: false };
  handleConfirm = async () => {
    this.setState({ confirmButtonPressed: true });
    await this.props.onConfirm();
    this.hideModal();
  };

  handleCancel = async () => {
    if (Object.prototype.hasOwnProperty.call(this.props, "onCancel")) {
      await this.props.onCancel();
    }

    this.hideModal();
  };

  hideModal = () => this.props.actions.hideModal();

  sizeMap = {
    mini: "xs",
    tiny: "sm",
    small: "sm",
    medium: "md",
    large: "md",
    big: "lg",
    huge: "xl",
    massive: "xxl",
    xs: "xs",
    sm: "sm",
    md: "md",
    lg: "lg",
    xl: "xl",
    xxl: "xxl"
  };

  colorToVariantMap = {
    red: "danger",
    orange: "warning",
    yellow: "warning",
    olive: "success",
    green: "success",
    teal: "info",
    blue: "primary",
    violet: "primary",
    purple: "primary",
    pink: "danger",
    brown: "secondary",
    grey: "secondary",
    black: "dark"
  };

  render() {
    const { confirmButtonPressed } = this.state;
    const {
      open,
      icon,
      iconColor = "secondary",
      title,
      description,
      cancelButtonMessage,
      buttonMessage,
      disableButton = false,
      buttonVariant,
      buttonColor,
      size
    } = this.props;

    const _size = this.sizeMap[size];
    const _buttonVariant = buttonVariant || this.colorToVariantMap[buttonColor];

    return (
      <Modal show={open} size={_size || "md"} backdrop="static" keyboard={false} onHide={this.hideModal}>
        <Modal.Header id="modal-confirmation" closeButton className="py-2">
          {icon && <Icon name={icon} className={`me-2 fs-4 text-${iconColor}`} />}
          <strong>{title}</strong>
        </Modal.Header>
        <Modal.Body id="lbl-modalDesc" style={{ textAlign: "justify" }}>
          {typeof description === "function"
            ? description()
            : description.split("\n").map((item) => (
                <span key={`rand-${shortid.generate()}`}>
                  {item}
                  <br />
                </span>
              ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.handleCancel} style={{ marginRight: "auto" }}>
            {cancelButtonMessage || "Cancel"}
          </Button>
          <Button
            variant={_buttonVariant}
            onClick={this.handleConfirm}
            loading={confirmButtonPressed}
            disabled={disableButton || confirmButtonPressed}
          >
            {buttonMessage || "Confirm"}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(modalActions, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(ConfirmationModal);
