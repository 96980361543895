import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import { logClientEvent } from "../helpers";
import { apiSlice } from "../api/apiSlice";
import rootReducer from "../reducers/rootReducer";

// eslint-disable-next-line consistent-return
const forceContextMiddleware = (store) => (next) => (action) => {
  if (action.context) {
    const ctx = store.getState().context[action.context];
    if (!Object.keys(ctx).every((key) => Object.prototype.hasOwnProperty.call(action, key))) {
      throw Error("Context action must have all relevant context's attribute in its payload");
    }
    if (Object.keys(ctx).every((key) => action[key] === ctx[key])) return next(action);
  } else {
    return next(action);
  }
};

const lastNActionsLog = () => (next) => (action) => {
  const ignoreList = ["SET_THEME", "BEGIN_AJAX_CALL", "AJAX_CALL_DONE"];
  if (!ignoreList.includes(action.type)) logClientEvent(action.type);
  return next(action);
};

const middlewares = [forceContextMiddleware, lastNActionsLog];
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: false /* { 
        warnAfter: 128,
        // Ignore these action types
        ignoredActions: ["SHOW_MODAL", "SHOW_MULTIPLE_MODALS", "AJAX_CALL_ERROR"],
        // Ignore these field paths in all actions
        ignoredActionPaths: ["meta.arg", "meta.baseQueryMeta"],
        // Ignore these paths in the state
        ignoredPaths: [
          "modal.props.description",
          /^.*\.preCancel$/,
          /^.*\.postCancel$/,
          /^.*\.onConfirm$/,
          /^.*\.afterSave$/,
          /^.*\.onCancel$/,
          /^.*\.onClose$/
        ]
      } */
    }).concat(middlewares, apiSlice.middleware)
});

export const persistor = persistStore(store);

export default store;
