import React, { Component } from "react";
import { Button, Header, Modal, Table } from "semantic-ui-react";
import { connect } from "react-redux";
import shortid from "shortid";
import moment from "moment";
import { bindActionCreators } from "redux";
import * as modalActions from "../../../actions/modalActions";
import * as providerActions from "../../../actions/providerActions";
import "./MainPrescriberHistoryModal.css";
import { dateFormat } from "../../../constants/miscellaneous";

export class MainPrescriberHistoryModal extends Component {
  componentDidMount() {
    this.props.actions.loadPrescriberHistory(this.props.patientId);
  }

  handleClose = () => this.props.actions.hideModal();

  render() {
    const { open, prescribers } = this.props;
    return (
      <Modal className="prescriber-history-modal" open={open} onClose={this.handleClose} closeIcon>
        <Header content="Main Provider History" />
        <Modal.Content>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Provider</Table.HeaderCell>
                <Table.HeaderCell>Date Added</Table.HeaderCell>
                <Table.HeaderCell>Date Removed</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {prescribers.length
                ? prescribers.map((prescriber) => (
                    <Table.Row key={`rand-${shortid.generate()}`}>
                      <Table.Cell>{`${prescriber.firstName} ${prescriber.lastName}`}</Table.Cell>
                      <Table.Cell>
                        {prescriber.dateAssigned ? `${moment(prescriber.dateAssigned).format(dateFormat)}` : null}
                      </Table.Cell>
                      <Table.Cell>
                        {prescriber.dateRemoved ? `${moment(prescriber.dateRemoved).format(dateFormat)}` : null}
                      </Table.Cell>
                    </Table.Row>
                  ))
                : null}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    prescribers: state.providers.prescriberHistory
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...providerActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPrescriberHistoryModal);
