import React, { Component } from "react";
import { Form, Grid } from "semantic-ui-react";
import shortid from "shortid";
import cssClasses from "./Surveys.module.css";

const setEvaluatedOnLoad = (props) => {
  const answers = Object.values(props.surveyData).find((answer) => answer.questionKey === props.question.key).answers;
  if (answers.length === 0) return false;
  if (answers[0] === "No") return false;
  return true;
};

export default class MultiToggle extends Component {
  state = {
    evaluated: setEvaluatedOnLoad(this.props)
  };

  componentDidUpdate(prevProps) {
    const { question, surveyData } = this.props;
    const answers = Object.values(surveyData).find((q) => q.questionKey === question.key).answers;
    const prevAnswers = Object.values(prevProps.surveyData).find((a) => a.questionKey === question.key).answers;
    if (answers.length !== prevAnswers.length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ evaluated: setEvaluatedOnLoad(this.props) });
    }
  }

  handleEvaluatedRadio = (e, data) => {
    const evaluated = data.label === "Yes";
    if (evaluated) {
      if (this.state.originalAnswers) {
        this.props.restoreMultiToggleState(this.props.question, this.state.originalAnswers);
      } else {
        this.props.initiateMultiToggleAnswers(evaluated, this.props.question);
      }
      this.setState({
        evaluated
      });
    } else {
      const originalAnswers = Object.values(this.props.surveyData).find((q) => q.questionKey === data.name).answers;
      this.props.initiateMultiToggleAnswers(evaluated, this.props.question);
      this.setState({
        evaluated,
        originalAnswers
      });
    }
  };

  handleInternalQuestionState = (e, data) => {
    const { name, value } = data;
    const { question, handleMultiToggle } = this.props;
    const index = question.options.findIndex((option) => option === name);
    handleMultiToggle(index, value, question);
  };

  render() {
    const {
      question,
      questionWrapper,
      triggered,
      questionPaddingLevel,
      getQuestionValue,
      createElucidation,
      parseLabel
    } = this.props;
    const { evaluated } = this.state;
    return questionWrapper(
      question,
      <div className={cssClasses.multitoggle}>
        {/* put a yes/no option here */}
        <Form.Field>
          <Form.Radio name={question.key} label="Yes" checked={evaluated} onChange={this.handleEvaluatedRadio} />
        </Form.Field>
        <Form.Field>
          <Form.Radio name={question.key} label="No" checked={!evaluated} onChange={this.handleEvaluatedRadio} />
        </Form.Field>
        {/* only show below questions if yes */}
        <Form.Group className={evaluated ? cssClasses["multitoggle-show"] : cssClasses["multitoggle-hide"]}>
          <Grid columns="2">
            {question.options.map((option) => {
              const splitOptions = option.split("|");
              return splitOptions.map((splitOption, i) => (
                <Grid.Column width={i % 2 === 0 ? "5" : "11"} key={`${splitOption}-rand-${shortid.generate()}`}>
                  <Form.Field>
                    <Form.Radio
                      className={parseLabel(splitOption, "style")
                        .map((className) => cssClasses[className])
                        .join(" ")}
                      label={parseLabel(splitOption, "label")}
                      name={option}
                      value={parseLabel(splitOption, "label")}
                      checked={
                        triggered
                          ? getQuestionValue("MultiSelect", question, parseLabel(splitOption, "label"))
                          : getQuestionValue("MultiSelect", question, "")
                      }
                      onChange={this.handleInternalQuestionState}
                    />
                  </Form.Field>
                  {createElucidation(question, triggered, option)}
                </Grid.Column>
              ));
            })}
          </Grid>
        </Form.Group>
        {createElucidation(question, triggered, "")}
      </div>,
      triggered,
      questionPaddingLevel
    );
  }
}
