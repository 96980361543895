import React, { Component } from "react";
import { Button, Header, Modal, Form, Message, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import { isEmpty } from "lodash";
import { DateInput } from "semantic-ui-calendar-react";
import {
  dateFormat,
  dateWithTimeFormat,
  isoFormat,
  VISIT_STATUS_VISIT_CANCELED
} from "../../../constants/miscellaneous";
import * as modalActions from "../../../actions/modalActions";
import * as visitActions from "../../../actions/visitActions";
import * as documentActions from "../../../actions/documentActions";
import * as lookupActions from "../../../actions/lookupActions";
import { dateRegEx } from "../../../constants/validation";
import { removeSpecialCharacters, updateErrors, requiredFieldsFilled, getLookupText } from "../../../helpers";
import "./AddDocumentModal.css";

const inputValidation = {
  required: ["displayName", "fileTags"],
  expirationDate: dateRegEx
};

const fileTypes = ["application/pdf", "image/jpeg", "image/png", "image/gif"];

export class AddDocumentModal extends Component {
  state = {
    uploadFile: this.props.uploadFile,
    errors: {},
    fileError: "",
    defaultFileName: this.props.uploadFile.originalFileName,
    requiredExpDate: false,
    maxExpirationDate: null,
    visitOptions: []
  };

  componentDidMount() {
    this.props.actions.loadLookup("DocumentTags");
    this.props.actions.loadVisitStatuses();
    this.props.actions
      .search({
        patientId: this.props.patientId,
        startDate: moment().subtract(14, "days").format(isoFormat),
        endDate: moment().format(isoFormat),
        sortBy: "visitTime",
        sortOrder: "desc",
        pageNumber: 1,
        itemsPerPage: 100 // just a large number .. to get all visits in the past 14 days
      })
      .then((visits) => {
        this.setState({ visitOptions: visits });
      });
    this.checkRequireTag(this.props.uploadFile.fileTags);
  }

  componentWillUnmount() {
    // Update list document
    const { patientId, pageSize, pageNumber, sortColumn, sortDirection, filterTagsSelected } = this.props;
    this.props.actions.loadDocuments(patientId, filterTagsSelected, pageSize, pageNumber, sortColumn, sortDirection);
  }

  handleClose = () => this.props.actions.hideModal();

  createEditObject = () => {
    const { uploadFile } = this.state;
    const editObject = {
      displayName: uploadFile.displayName,
      fileTags: Array.isArray(uploadFile.fileTags) ? uploadFile.fileTags : [uploadFile.fileTags],
      description: uploadFile.description,
      expirationDate: isEmpty(uploadFile.expirationDate)
        ? ``
        : moment(uploadFile.expirationDate).endOf("day").format(isoFormat),
      visitId: uploadFile.visitId
    };
    return editObject;
  };

  handleSave = () => {
    const { isEdit, patientId, documentId } = this.props;
    const formData = new FormData();
    for (const key in this.state.uploadFile) {
      if (key === "fileTags") {
        if (Array.isArray(this.state.uploadFile.fileTags))
          for (let i = 0; i < this.state.uploadFile.fileTags.length; i++) {
            formData.append("fileTags[]", this.state.uploadFile.fileTags[i]);
          }
        else formData.append("fileTags[]", this.state.uploadFile.fileTags);
      } else if (key === "displayName") {
        formData.append("fileName", this.state.uploadFile[key]);
      } else if (key === "expirationDate") {
        formData.append(
          "expirationDate",
          isEmpty(this.state.uploadFile[key]) ? `` : moment(this.state.uploadFile[key]).endOf("day").format(isoFormat)
        );
      } else {
        formData.append(key, this.state.uploadFile[key]);
      }
    }

    if (isEdit) {
      this.props.actions.editDocument(patientId, documentId, this.createEditObject()).then(this.handleClose);
    } else {
      this.props.actions.uploadDocument(formData, patientId).then(this.handleClose);
    }
  };

  validDocumentFile = (file) => {
    for (let i = 0; i < fileTypes.length; i++) {
      if (file.type === fileTypes[i]) {
        return true;
      }
    }
    return false;
  };

  updateDefaultName = () => {
    const input = document.getElementById("uploadFile");
    const currentFile = input.files[0];
    if (typeof currentFile !== "undefined") {
      const { name } = currentFile;
      const fileReader = new FileReader();
      if (fileReader && currentFile) {
        const fileSize = currentFile.size;
        if (!this.validDocumentFile(currentFile) || fileSize > 50000000 || name.length > 50) {
          const uploadFile = Object.assign({}, this.state.uploadFile, {
            defaultFileName: "",
            file: currentFile,
            originalFileName: name
          });
          this.setState({
            uploadFile,
            fileError:
              "Uploaded file must be smaller than 50MB and of type .jpg, .pdf, .gif, or .png and file name length must smaller than 100 characters."
          });
        } else {
          const uploadFile = Object.assign({}, this.state.uploadFile, {
            file: currentFile,
            originalFileName: name
          });
          document.querySelector("#input-tags input.search").focus();
          this.setState({ uploadFile, fileError: "", defaultFileName: name });
        }
      }
    }
  };

  fileUploadClick = () => {
    document.getElementById("uploadFile").click();
  };

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleInput = (e, data) => {
    const { name: field, value } = data;
    let { maxExpirationDate } = this.state;
    const uploadFile = { ...this.state.uploadFile, [field]: value };
    if (field === "fileTags") {
      uploadFile.displayName = value === "OT32" ? `` : this.props.documentTags.find((i) => i.value === value).text;
      const listTag = Array.isArray(value) ? value : [value];
      // as of #29952 we only allow one tag from now on
      const tag = this.props.documentTags.find((i) => i.value === listTag[0]);
      if (tag && tag.expireAfterDays) {
        uploadFile.expirationDate = moment().add(tag.expireAfterDays, "days");
        maxExpirationDate = moment().add(tag.expireAfterDays, "days");
      }
    } else if (field === "expirationDate") {
      uploadFile.expirationDate = moment(value, dateFormat);
    }
    this.setState({ uploadFile, maxExpirationDate }, () => {
      this.checkRequireTag(uploadFile.fileTags);
    });
  };

  checkRequireTag(fileTags) {
    const listTag = Array.isArray(fileTags) ? fileTags : [fileTags];
    // old code allowing multi tags
    const { documentTags } = this.props;
    const listTagReq = [];
    documentTags.forEach((item) => {
      if (item.isRequireExpirationDate) {
        listTagReq.push(item.value);
      }
    });
    let requiredExpDate = false;
    this.setState({ requiredExpDate });
    const index = inputValidation.required.indexOf("expirationDate");
    if (index !== -1) inputValidation.required.splice(index, 1);
    for (const key in listTag) {
      if (listTagReq.includes(listTag[key])) {
        requiredExpDate = true;
        inputValidation.required.push("expirationDate");
        this.setState({ requiredExpDate });
        break;
      }
    }
  }

  handleMaskedInput = (e) => {
    const {
      type,
      target: { name: field, required, value }
    } = e;
    let errors = Object.assign({}, this.state.errors);

    if (type !== "focus" && type !== "blur") {
      errors = updateErrors(
        field,
        required ? removeSpecialCharacters(value) : value,
        required,
        errors,
        inputValidation[field]
      );
    }
    const { uploadFile } = Object.assign({}, this.state);
    if (!Object.prototype.hasOwnProperty.call(errors, "expirationDate")) {
      uploadFile.expirationDate = value;
    }

    this.setState({ errors, uploadFile });
  };
  render() {
    const {
      uploadFile,
      uploadFile: {
        displayName,
        fileTags,
        description,
        expirationDate,
        visitId,
        isDeleted,
        deletedOn,
        deletedByUserFirstName,
        deletedByUserLastName,
        deletionReason
      },
      errors,
      defaultFileName,
      fileError,
      requiredExpDate,
      maxExpirationDate,
      visitOptions
    } = this.state;
    const { open, documentTags, isEdit, readOnly, saving, visitStatuses } = this.props;
    const requiredFieldsCheck = requiredFieldsFilled(inputValidation.required, uploadFile);
    const disableSave =
      requiredFieldsCheck ||
      !defaultFileName ||
      defaultFileName.length === 0 ||
      Object.keys(errors).length !== 0 ||
      saving;

    return (
      <Modal className="add-document-modal" open={open} onClose={this.handleClose} closeIcon size="small">
        <Header content={isEdit ? "Edit Document" : "Add a Document"} />
        <Modal.Content>
          <Form>
            {isDeleted && (
              <Message>
                {`Deleted by: ${deletedByUserFirstName} ${deletedByUserLastName}, On: ${moment(deletedOn).format(
                  dateFormat
                )}, Reason: ${deletionReason}`}
              </Message>
            )}
            <Form.Group style={{ marginBottom: ".2em" }}>
              <Form.Field width={7} className="input-no-label">
                <Form.Input
                  label="File"
                  name="defaultFileName"
                  id="input-defaultFileName"
                  value={defaultFileName}
                  disabled
                />
              </Form.Field>
              <Form.Field width={3}>
                <input
                  id="uploadFile"
                  onChange={this.updateDefaultName}
                  data-testid="uploadFile"
                  type="file"
                  accept=".pdf, .png, .jpg, .gif"
                  style={{ display: "none" }}
                  disabled={readOnly}
                />
                <Button
                  fluid
                  onClick={this.fileUploadClick}
                  id="btn-open-upload"
                  className="btn-upload-doc"
                  disabled={isEdit || readOnly}
                >
                  Browse
                </Button>
              </Form.Field>
              <Form.Field
                width={6}
                className={Object.prototype.hasOwnProperty.call(errors, "expirationDate") ? "error" : ""}
              >
                <label htmlFor="input-exp-date" className={requiredExpDate ? " mock-semantic-required" : ""}>
                  Expiration date
                </label>
                <DateInput
                  name="expirationDate"
                  placeholder="Expiration Date"
                  value={expirationDate ? moment(expirationDate).format(dateFormat) : ""}
                  dateFormat={dateFormat}
                  iconPosition="right"
                  onChange={this.handleInput}
                  minDate={isEdit ? null : moment()}
                  maxDate={maxExpirationDate}
                  disabled={isEmpty(fileTags) || readOnly}
                  hideMobileKeyboard
                  closable
                />
              </Form.Field>
            </Form.Group>
            {fileError ? (
              <Message color="red" style={{ margin: ".2em 0" }}>
                {fileError}
              </Message>
            ) : null}
            <Form.Group style={{ marginBottom: ".2em" }}>
              <Form.Field width={16}>
                <Form.Dropdown
                  label="Tag"
                  placeholder="Select..."
                  fluid
                  search
                  selection
                  required
                  name="fileTags"
                  value={Array.isArray(fileTags) ? fileTags[0] : fileTags}
                  options={documentTags
                    .filter((i) => i.active)
                    .map((i) => {
                      const u = {
                        ...i,
                        is_require_expiration_date: i.isRequireExpirationDate ? "true" : "false",
                        expire_after_days: i.expireAfterDays
                      };
                      delete u.isRequireExpirationDate;
                      delete u.expireAfterDays;
                      return u;
                    })}
                  id="input-tags"
                  onChange={this.handleInput}
                  disabled={readOnly}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group style={{ marginBottom: ".2em" }}>
              <Form.Field width={16}>
                <Form.Input
                  label="Document Name"
                  name="displayName"
                  maxLength={100}
                  required
                  id="input-displayName"
                  value={displayName}
                  onChange={this.handleInput}
                  disabled={readOnly}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group style={{ marginBottom: ".2em" }}>
              <Form.Field width={16}>
                <Form.Dropdown
                  label="Date of Service"
                  placeholder="Select..."
                  selection
                  name="visitId"
                  value={visitId}
                  options={visitOptions.map((visit) => ({
                    id: `visit-${visit.visitId}`,
                    key: visit.visitId,
                    value: visit.visitId,
                    text: (
                      <Grid className="no-margin" style={{ minWidth: "550px" }}>
                        <Grid.Row className="no-padding">
                          <Grid.Column width={12} className="no-padding">
                            {`${moment(visit.visitTime).format(dateWithTimeFormat)} (${visit.visitType})`}
                            &nbsp;<strong>{`${visit.providerFirstName} ${visit.providerLastName}`}</strong>
                          </Grid.Column>
                          <Grid.Column width={4} style={{ color: "red" }}>
                            {visit.visitStatus === VISIT_STATUS_VISIT_CANCELED
                              ? `(${getLookupText(visit.visitStatus, visitStatuses)})`
                              : ``}
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    ),
                    description:
                      moment.utc(visit.visitTime).startOf("day").diff(moment.utc().startOf("day"), "days") === 0
                        ? "Today"
                        : `${-moment
                            .utc(visit.visitTime)
                            .startOf("day")
                            .diff(moment.utc().startOf("day"), "days")} days`
                  }))}
                  id="input-tags"
                  onChange={this.handleInput}
                  disabled={readOnly}
                  clearable
                />
              </Form.Field>
            </Form.Group>

            <Form.TextArea
              label="Description"
              name="description"
              maxLength={255}
              value={description || ``}
              onChange={this.handleInput}
              id="input-description"
              disabled={readOnly}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
          <Button
            onClick={this.handleSave}
            color="blue"
            id="btn-save"
            disabled={disableSave || readOnly}
            loading={saving}
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let uploadFile = {
    file: "",
    displayName: "",
    fileTags: [],
    description: "",
    originalFileName: "",
    expirationDate: "",
    visitId: ""
  };

  if (ownProps.isEdit) {
    uploadFile = {
      ...uploadFile,
      ...state.documents.availableDocuments.find((document) => document.documentId === ownProps.documentId)
    };
  }
  return {
    patientId: state.patient.currentPatient.patientId,
    visitStatuses: state.lookups.visitStatuses,
    documentTags: state.lookups.documentTags,
    saving: state.ajaxCallsInProgress > 0,
    uploadFile
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...visitActions, ...documentActions, ...lookupActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDocumentModal);
