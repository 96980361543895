import { API_URL } from "../constants/environment";
import { adalApiFetch } from "../adalConfig";

export const fetchPatientFavoritePharmacies = (patientId) =>
  adalApiFetch(`${API_URL}/Patients/${patientId}/Pharmacy/Favorite`, {
    method: "GET"
  });

export const addPatientFavoritePharmacy = (patientId, pharmacy) =>
  adalApiFetch(`${API_URL}/Patients/${patientId}/Pharmacy/Favorite`, {
    method: "POST",
    body: JSON.stringify(pharmacy)
  });

export const updatePatientFavoritePharmacies = (patientId, pharmacy) =>
  adalApiFetch(`${API_URL}/Patients/${patientId}/Pharmacy/Favorite`, {
    method: "PUT",
    body: JSON.stringify(pharmacy)
  });

export const deletePatientFavoritePharmacies = (patientId, pharmacy) =>
  adalApiFetch(`${API_URL}/Patients/${patientId}/Pharmacy/Favorite`, {
    method: "DELETE",
    body: JSON.stringify(pharmacy)
  });

export default {
  fetchPatientFavoritePharmacies,
  addPatientFavoritePharmacy,
  updatePatientFavoritePharmacies,
  deletePatientFavoritePharmacies
};
