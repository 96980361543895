import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { get, isEmpty, isEqual, set } from "lodash";
import withRouter from "../../../../hoc/withRouter";
import { Container, Row, Col, Button } from "../../../../ui";
import * as lookupActions from "../../../../actions/lookupActions";
import * as patientActions from "../../../../actions/patientActions";
import * as demographicActions from "../../../../actions/demographicActions";
import MilitaryServiceForm from "./MilitaryServiceForm";
import {
  CUSTOM_FIELD_ID_ADDITIONAL_COMMENTS,
  CUSTOM_FIELD_ID_CURRENT_MILITARY_INVOLVEMENT_DESCRIPTION,
  CUSTOM_FIELD_ID_EVER_BEEN_IN_MILITARY_SERVICE,
  CUSTOM_FIELD_ID_INTERESTED_IN_REFER_MILITARY_INVOLVEMENT
} from "../../../../constants/miscellaneous";

export class MilitaryService extends Component {
  state = {
    model: {
      everBeenInMilitaryService: "",
      currentMilitaryInvolvementDescription: [],
      interestedInReferMilitaryInvolvement: "",
      additionalComments: ""
    },
    errors: {}
  };

  componentDidMount() {
    const {
      patientId,
      everBeenInMilitaryServiceField,
      currentMilitaryInvolvementDescriptionField,
      interestedInReferMilitaryInvolvementField,
      additionalCommentsField
    } = this.props;
    if (!patientId) return;
    this.props.actions.fetchCustomFields(patientId, [
      everBeenInMilitaryServiceField.customeFieldAthenaId,
      currentMilitaryInvolvementDescriptionField.customeFieldAthenaId,
      interestedInReferMilitaryInvolvementField.customeFieldAthenaId,
      additionalCommentsField.customeFieldAthenaId
    ]);
    if (!isEmpty(this.props.customFields)) {
      this.setState({
        model: {
          everBeenInMilitaryService: this.props.customFields[everBeenInMilitaryServiceField.id]
            ? this.props.customFields[everBeenInMilitaryServiceField.id].customFieldAnswer
            : "",
          currentMilitaryInvolvementDescription: this.props.customFields[currentMilitaryInvolvementDescriptionField.id]
            ? this.props.customFields[currentMilitaryInvolvementDescriptionField.id].customFieldAnswer.split(",")
            : [],
          interestedInReferMilitaryInvolvement: this.props.customFields[interestedInReferMilitaryInvolvementField.id]
            ? this.props.customFields[interestedInReferMilitaryInvolvementField.id].customFieldAnswer
            : "",
          additionalComments: this.props.customFields[additionalCommentsField.id]
            ? this.props.customFields[additionalCommentsField.id].customFieldAnswer
            : ""
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEmpty(this.props.customFields) && !isEqual(this.props.customFields, prevProps.customFields)) {
      const {
        everBeenInMilitaryServiceField,
        currentMilitaryInvolvementDescriptionField,
        interestedInReferMilitaryInvolvementField,
        additionalCommentsField
      } = this.props;
      this.setState({
        model: {
          everBeenInMilitaryService: this.props.customFields[everBeenInMilitaryServiceField.id]
            ? this.props.customFields[everBeenInMilitaryServiceField.id].customFieldAnswer
            : "",
          currentMilitaryInvolvementDescription: this.props.customFields[currentMilitaryInvolvementDescriptionField.id]
            ? this.props.customFields[currentMilitaryInvolvementDescriptionField.id].customFieldAnswer.split(",")
            : [],
          interestedInReferMilitaryInvolvement: this.props.customFields[interestedInReferMilitaryInvolvementField.id]
            ? this.props.customFields[interestedInReferMilitaryInvolvementField.id].customFieldAnswer
            : "",
          additionalComments: this.props.customFields[additionalCommentsField.id]
            ? this.props.customFields[additionalCommentsField.id].customFieldAnswer
            : ""
        }
      });
    }
  }

  handleInput = (e, data) => {
    const { name, value } = data;
    const updatedState = { ...this.state };
    const currentValue = get(updatedState, name);
    if (Array.isArray(currentValue)) {
      if (currentValue.includes(value))
        set(
          updatedState,
          name,
          currentValue.filter((v) => v !== value)
        );
      else set(updatedState, name, [...currentValue, value]);
    } else {
      set(updatedState, name, value);
    }
    this.setState(updatedState);
  };

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleSave = () => {
    const {
      patientId,
      everBeenInMilitaryServiceField,
      currentMilitaryInvolvementDescriptionField,
      interestedInReferMilitaryInvolvementField,
      additionalCommentsField
    } = this.props;
    const {
      model: {
        everBeenInMilitaryService,
        currentMilitaryInvolvementDescription,
        interestedInReferMilitaryInvolvement,
        additionalComments
      }
    } = this.state;
    this.props.actions
      .saveCustomFields(patientId, [
        {
          customFieldId: everBeenInMilitaryServiceField.customeFieldAthenaId,
          customFieldValue: everBeenInMilitaryService
        },
        {
          customFieldId: currentMilitaryInvolvementDescriptionField.customeFieldAthenaId,
          customFieldValue: currentMilitaryInvolvementDescription.filter((v) => !isEmpty(v.trim())).join(",")
        },
        {
          customFieldId: interestedInReferMilitaryInvolvementField.customeFieldAthenaId,
          customFieldValue: interestedInReferMilitaryInvolvement
        },
        {
          customFieldId: additionalCommentsField.customeFieldAthenaId,
          customFieldValue: additionalComments
        }
      ])
      .then(() => {
        if (this.props.afterSave) this.props.afterSave();
      });
  };

  render() {
    const {
      processing,
      hideCancelButton,
      saveButtonIcon,
      everBeenInMilitaryServiceOptions,
      currentMilitaryInvolvementDescriptionOptions,
      interestedInReferMilitaryInvolvementOptions
    } = this.props;

    const saveButtonText = this.props.saveButtonText || "Save";

    const { model, errors } = this.state;
    const disableEdit = false; // !checkRoles(roleGroups.systemAdmin, authRoles);
    // const formInvalid = requiredFieldsFilled(inputValidation.required, demographics);
    // const disableSave = formInvalid || Object.keys(errors).length !== 0;
    const disableSave = false;

    return (
      <React.Fragment>
        <Container className="no-padding no-margin">
          <Row>
            <Col>
              <MilitaryServiceForm
                model={model}
                errors={errors}
                disableEdit={disableEdit}
                handleInput={this.handleInput}
                handleFocus={this.handleFocus}
                everBeenInMilitaryServiceOptions={everBeenInMilitaryServiceOptions}
                currentMilitaryInvolvementDescriptionOptions={currentMilitaryInvolvementDescriptionOptions}
                interestedInReferMilitaryInvolvementOptions={interestedInReferMilitaryInvolvementOptions}
              />
            </Col>
          </Row>
          <Row className="full-padding" style={{ borderTop: "1px solid rgba(34,36,38,.15)" }}>
            <Col className="no-padding">
              {!hideCancelButton && (
                <Button variant="danger" onClick={this.props.handleCancel} className="float-start">
                  Cancel
                </Button>
              )}
              <Button
                variant="primary"
                onClick={this.handleSave}
                disabled={processing || disableSave}
                loading={processing}
                className="float-end"
                style={{ minWidth: "100px" }}
              >
                {saveButtonText}
                {saveButtonIcon && <i className={saveButtonIcon} />}
              </Button>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const everBeenInMilitaryServiceField = state.lookups.patientCustomFields.find(
    (cf) => cf.id === CUSTOM_FIELD_ID_EVER_BEEN_IN_MILITARY_SERVICE
  );
  const currentMilitaryInvolvementDescriptionField = state.lookups.patientCustomFields.find(
    (cf) => cf.id === CUSTOM_FIELD_ID_CURRENT_MILITARY_INVOLVEMENT_DESCRIPTION
  );
  const interestedInReferMilitaryInvolvementField = state.lookups.patientCustomFields.find(
    (cf) => cf.id === CUSTOM_FIELD_ID_INTERESTED_IN_REFER_MILITARY_INVOLVEMENT
  );
  const additionalCommentsField = state.lookups.patientCustomFields.find(
    (cf) => cf.id === CUSTOM_FIELD_ID_ADDITIONAL_COMMENTS
  );

  const everBeenInMilitaryServiceOptions = (
    everBeenInMilitaryServiceField || {
      customFieldAnswers: []
    }
  ).customFieldAnswers.map((cfa) => ({ text: cfa.aresAnswer, value: cfa.aresAnswer }));

  const currentMilitaryInvolvementDescriptionOptions = (
    currentMilitaryInvolvementDescriptionField || {
      customFieldAnswers: []
    }
  ).customFieldAnswers.map((cfa) => ({ text: cfa.aresAnswer, value: cfa.aresAnswer }));

  const interestedInReferMilitaryInvolvementOptions = (
    interestedInReferMilitaryInvolvementField || {
      customFieldAnswers: []
    }
  ).customFieldAnswers.map((cfa) => ({ text: cfa.aresAnswer, value: cfa.aresAnswer }));

  return {
    patientId: state.demographics?.patientId,
    customFields: state.patient.customFields,
    processing: state.ajaxCallsInProgress > 0,
    // authRoles: state.auth.user.profile.roles,
    everBeenInMilitaryServiceField,
    everBeenInMilitaryServiceOptions,
    currentMilitaryInvolvementDescriptionField,
    currentMilitaryInvolvementDescriptionOptions,
    interestedInReferMilitaryInvolvementField,
    interestedInReferMilitaryInvolvementOptions,
    additionalCommentsField
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...demographicActions,
        ...patientActions,
        ...lookupActions
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MilitaryService));
