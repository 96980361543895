import React, { Component } from "react";
import { Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEqual } from "lodash";
import withRouter from "../../../../hoc/withRouter";
import { Button } from "../../../../ui";
import * as validation from "../../../../constants/validation";
import { removeSpecialCharacters, updateErrors, requiredFieldsFilled } from "../../../../helpers";
import CoreDemographicsForm from "./CoreDemographicsForm";
import referenceValues from "../../../../api/mockReferenceValues";
import * as patientActions from "../../../../actions/patientActions";
import * as lookupActions from "../../../../actions/lookupActions";
import * as demographicActions from "../../../../actions/demographicActions";
import { CUSTOM_FIELD_ID_IDENTIFY_AS_LGBTQI } from "../../../../constants/miscellaneous";

const inputValidation = {
  required: [
    "patientFirstName",
    "patientLastName",
    "patientDateOfBirth"
    // "language",
    // "race",
    // "ethnicity",
    // "maritalStatus",
    // "sexualOrientation",
    // "identifiesAsLgbtqi",
    // "genderIdentity",
    // "sexAssignedAtBirth",
    // "genderPronoun"
  ],
  patientDateOfBirth: validation.dateRegEx,
  socialSecurityNumber: validation.ssn
};

export class CoreDemographics extends Component {
  state = {
    demographics: {
      ...this.props.demographics,
      initSsn: this.props.demographics.socialSecurityNumber || "",
      identifiesAsLgbtqi:
        (this.props.demographics?.patientCustomFieldAnswers || []).find(
          (cfa) => +cfa.id === CUSTOM_FIELD_ID_IDENTIFY_AS_LGBTQI
        )?.customFieldAnswer || "",
      patientCustomFieldAnswers: (this.props.demographics?.patientCustomFieldAnswers || []).map((cfa) => ({
        ...cfa,
        customFieldValue: cfa.customFieldAnswer
      }))
    },
    redirectToPatientPage: false,
    searchResults: [],
    errors: {}
  };

  componentDidMount() {
    this.props.actions.loadLanguages();
    this.props.actions.loadTreatments();
    this.props.actions.loadRaces();
    this.props.actions.loadEthnicities();
    this.props.actions.loadMaritalStatuses();
    this.props.actions.loadCommunicationPreferences();
    this.props.actions.loadAuxFlags();
    this.props.actions.loadGenderPronouns();
    this.props.actions.loadGenderIdentities();
    this.props.actions.loadSexAssignedAtBirths();
    this.props.actions.loadSexualOrientations();
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(this.props.demographics, prevProps.demographics)) {
      this.setState({
        demographics: {
          ...this.props.demographics,
          initSsn: this.props.demographics.socialSecurityNumber || "",
          identifiesAsLgbtqi:
            (this.props.demographics?.patientCustomFieldAnswers || []).find(
              (cfa) => +cfa.id === CUSTOM_FIELD_ID_IDENTIFY_AS_LGBTQI
            )?.customFieldAnswer || "",
          patientCustomFieldAnswers: (this.props.demographics?.patientCustomFieldAnswers || []).map((cfa) => ({
            ...cfa,
            customFieldValue: cfa.customFieldAnswer
          }))
        }
      });
    }
  }

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleSave = () => {
    const { demographics } = this.state;
    // if (demographics.sexAssignedAtBirth === "") demographics.sexAssignedAtBirth = "Unknown";
    // if (demographics.genderIdentity === "") demographics.genderIdentity = "Unknown";
    // if (demographics.genderPronoun === "") demographics.genderPronoun = "Unknown";
    // if (demographics.sexualOrientation === "") demographics.sexualOrientation = "Unknown";
    const { router, patientId, newPatient } = this.props;

    this.props.actions.updateCoreDemographics(patientId, demographics).then(() => {
      if (newPatient) {
        router.navigate({
          pathname: `/patient/${patientId}/demographics`
        });
      }
      if (this.props.afterSave) this.props.afterSave();
    });
  };

  handleInput = (e, data) => {
    const { name: field, value, required } = data;
    const errors = updateErrors(
      field,
      value,
      required || inputValidation.required.includes(field),
      this.state.errors,
      inputValidation[field]
    );
    const demographics = { ...this.state.demographics, [field]: data.value };
    const exists = demographics.patientCustomFieldAnswers.find((pcfa) => +pcfa.id === +data.custome_field_id);
    if (data.custome_field_id !== undefined) {
      if (exists) {
        demographics.patientCustomFieldAnswers = demographics.patientCustomFieldAnswers.map((cfa) => {
          if (+cfa.id === +data.custome_field_id) {
            return { ...cfa, customFieldAnswer: data.value, customFieldValue: data.value };
          }
          return { ...cfa };
        });
      } else {
        const customField = this.props.patientCustomFields.find((cf) => +cf.id === +data.custome_field_id);
        demographics.patientCustomFieldAnswers = [
          ...demographics.patientCustomFieldAnswers,
          {
            id: customField.id,
            customFieldId: customField.customeFieldAthenaId,
            customFieldAnswer: data.value,
            customFieldValue: data.value
          }
        ];
      }
    }
    this.setState({ demographics, errors });
  };

  handleGender = (e, data) => {
    const { name: field, value, required } = data;
    const errors = updateErrors(field, value, required, this.state.errors, inputValidation[field]);
    const demographics = Object.assign({}, this.state.demographics, {
      [field]: data.value,
      ...(!this.state.demographics.gender && { gender: value })
    });
    this.setState({ demographics, errors });
  };

  handleMaskedInput = (e) => {
    const {
      type,
      target: { name: field, required, value }
    } = e;
    let errors = Object.assign({}, this.state.errors);
    const demographics = Object.assign({}, this.state.demographics, {
      [field]: value
    });

    if (type !== "focus" && type !== "blur") {
      errors = updateErrors(
        field,
        required ? removeSpecialCharacters(value) : value,
        required,
        errors,
        inputValidation[field]
      );
    }

    this.setState({ demographics, errors });
  };

  handleSsnOnFocus = () => {
    if (this.state.demographics.socialSecurityNumber[0] === "X") {
      this.setState({
        demographics: { ...this.state.demographics, socialSecurityNumber: "" }
      });
    }
  };

  handleSsnOnBlur = () => {
    if (this.state.demographics.socialSecurityNumber === "") {
      this.setState({
        demographics: {
          ...this.state.demographics,
          socialSecurityNumber: this.state.demographics.initSsn
        }
      });
    }
  };

  render() {
    const {
      _for = "demographics",
      hideCancelButton,
      saveButtonIcon,
      languages,
      treatments,
      races,
      ethnicities,
      maritalStatuses,
      // communicationPreferences,
      saving,
      auxFlagOptions,
      genderPronouns,
      genderIdentities,
      sexAssignedAtBirths,
      sexualOrientations,
      identifiesAsLgbtqiOptions,
      newPatient
      // authRoles
    } = this.props;

    const saveButtonText = this.props.saveButtonText || "Save";

    const { demographics, errors } = this.state;
    const formInvalid = requiredFieldsFilled(inputValidation.required, demographics);
    const disableSave = formInvalid || Object.keys(errors).length !== 0;
    const disableEdit = false; // !checkRoles(roleGroups.systemAdmin, authRoles);
    return (
      <React.Fragment>
        <Grid className="no-margin">
          <Grid.Row>
            <Grid.Column>
              <CoreDemographicsForm
                _for={_for}
                // communicationPreferences={communicationPreferences}
                demographics={demographics}
                ethnicities={ethnicities}
                maritalStatuses={maritalStatuses}
                genderIdentities={genderIdentities}
                genders={referenceValues.genders}
                handleInput={this.handleInput}
                handleMaskedInput={this.handleMaskedInput}
                handleSsnOnFocus={this.handleSsnOnFocus}
                handleSsnOnBlur={this.handleSsnOnBlur}
                handleGender={this.handleGender}
                handleFocus={this.handleFocus}
                languages={languages}
                races={races}
                treatments={treatments}
                auxFlagOptions={auxFlagOptions}
                errors={errors}
                genderPronouns={genderPronouns}
                sexAssignedAtBirths={sexAssignedAtBirths}
                sexualOrientations={sexualOrientations}
                newPatient={newPatient}
                disableEdit={disableEdit}
                identifiesAsLgbtqiOptions={identifiesAsLgbtqiOptions}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row className="full-padding" style={{ borderTop: "1px solid rgba(34,36,38,.15)" }}>
            <Grid.Column className="no-padding" textAlign="right">
              {!hideCancelButton && (
                <Button variant="danger" onClick={this.props.handleCancel} className="float-start">
                  Cancel
                </Button>
              )}
              <Button
                variant="primary"
                onClick={this.handleSave}
                disabled={saving || disableSave}
                loading={saving}
                style={{ minWidth: "100px" }}
              >
                {saveButtonText}
                {saveButtonIcon && <i className={saveButtonIcon} />}
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state, ownProps) {
  let demographics = {
    patientId: "",
    patientFirstName: "",
    patientLastName: "",
    patientDateOfBirth: "",
    auxiliaryFlags: [],
    patientTreatmentType: "",
    driversLicenseNumber: "",
    communicationPreference: "",
    ethnicity: "",
    genderIdentity: "",
    homeCenterId: "",
    language: "",
    gender: "",
    patientPreferredName: "",
    race: "",
    socialSecurityNumber: "",
    genderPronoun: "",
    sexAssignedAtBirth: "",
    sexualOrientation: ""
  };

  let patientId = "";

  const treatments = state.lookups.treatments;

  if (ownProps.newPatient) {
    demographics = { ...demographics, ...state.patient.newPatient };

    // set the default pTT
    if (treatments.length) demographics.patientTreatmentType = treatments[0].value;

    patientId = state.patient.newPatient.patientId;
  } else if (state.demographics) {
    demographics = { ...state.demographics };
    // set the default pTT
    // but only if pTT isn't already set
    if (treatments.length && !demographics.patientTreatmentType)
      demographics.patientTreatmentType = treatments[0].value;

    patientId = state.demographics.patientId;
  }

  const identifiesAsLgbtqiField = state.lookups.patientCustomFields.find(
    (cf) => cf.id === CUSTOM_FIELD_ID_IDENTIFY_AS_LGBTQI
  );

  const identifiesAsLgbtqiOptions = (
    identifiesAsLgbtqiField || {
      customFieldAnswers: []
    }
  ).customFieldAnswers.map((cfa) => ({ text: cfa.aresAnswer, value: cfa.aresAnswer }));

  return {
    patientId,
    languages: state.lookups.languages,
    auxFlagOptions: state.lookups.auxFlags,
    treatments,
    races: state.lookups.races,
    ethnicities: state.lookups.ethnicities,
    maritalStatuses: state.lookups.maritalStatuses,
    communicationPreferences: state.lookups.communicationPreferences,
    saving: state.ajaxCallsInProgress > 0,
    demographics,
    genderPronouns: state.lookups.genderPronouns,
    genderIdentities: state.lookups.genderIdentities,
    sexAssignedAtBirths: state.lookups.sexAssignedAtBirths,
    sexualOrientations: state.lookups.sexualOrientations,
    patientCustomFields: state.lookups.patientCustomFields,
    identifiesAsLgbtqiField,
    identifiesAsLgbtqiOptions,
    authRoles: state.auth.user.profile.roles
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...demographicActions,
        ...patientActions,
        ...lookupActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CoreDemographics));
