import initialState from "./initialState";
import * as types from "../actions/actionTypes";
import { contextualizeSurveys } from "../components/patients/surveys/surveyHelper";

export default function treatmentPlanReducer(state = initialState.treatmentPlan, action) {
  switch (action.type) {
    case types.CLEAR_TREATMENT_PLAN:
      return {
        history: [],
        selectedTreatmentPlan: { header: null, view: null, edit: null },
        selectedTemplate: {
          templateId: null,
          templateName: null,
          version: null,
          surveys: [],
          styles: []
        },
        cachedSurveys: {}
      };
    case types.LOAD_TREATMENT_PLAN_HISTORY_DONE: {
      const history = action.history;
      const selectedTreatmentPlan = !history
        ? { header: null, view: null, edit: null }
        : { header: action.history[0], view: null, edit: null };
      return { ...state, history, selectedTreatmentPlan };
    }
    case types.SET_SELECTED_TREATMENT_PLAN: {
      const treatmentPlan = state.history.find((i) => i.treatmentPlanId === action.treatmentPlanId);
      return { ...state, selectedTreatmentPlan: { header: { ...treatmentPlan }, view: null, edit: null } };
    }
    case types.CLEAR_SELECTED_TREATMENT_PLAN: {
      return { ...state, selectedTreatmentPlan: { header: null, view: null, edit: null } };
    }
    case types.LOAD_TREATMENT_PLAN_DONE: {
      const treatmentPlan = {
        ...action.treatmentPlan,
        surveys: contextualizeSurveys(
          action.treatmentPlan.surveys,
          "treatmentPlan",
          action.treatmentPlan.treatmentPlanId
        )
      };
      return {
        ...state,
        selectedTreatmentPlan: {
          header: { ...state.history.find((i) => i.treatmentPlanId === action.treatmentPlan.treatmentPlanId) },
          view: null,
          edit: treatmentPlan
        },
        selectedTemplate: {
          templateId: action.treatmentPlan.templateId,
          templateName: null,
          version: action.treatmentPlan.version,
          surveys: [],
          styles: []
        }
      };
    }
    case types.LOAD_TREATMENT_PLAN_SUMMARY_DONE: {
      const treatmentPlan = state.history.find(
        (i) => i.treatmentPlanId === action.treatmentPlanSummary.treatmentPlanId
      );
      return {
        ...state,
        selectedTreatmentPlan: {
          header: { ...treatmentPlan },
          view: { ...treatmentPlan, ...action.treatmentPlanSummary },
          edit: null
        }
      };
    }
    case types.HIDE_TREATMENT_PLAN_SURVEY: {
      const { templateId, surveyId, patientId, snapshot } = action;
      const cachedSurveys = { ...state.cachedSurveys };
      cachedSurveys[`${patientId}-${templateId}-${surveyId}`] = { templateId, surveyId, patientId, ...snapshot };
      return { ...state, cachedSurveys };
    }
    case types.CLEAR_HIDDEN_TREATMENT_PLAN_SURVEYS:
      return { ...state, cachedSurveys: {} };
    case types.CLEAR_HIDDEN_TREATMENT_PLAN_SURVEY: {
      const { patientId, templateId, surveyId } = action;
      const cachedSurveys = { ...state.cachedSurveys };
      delete cachedSurveys[`${patientId}-${templateId}-${surveyId}`];
      return { ...state, cachedSurveys };
    }
    // case types.CREATE_TREATMENT_PLAN_DONE:
    //   return { ...state, history: [action.treatmentPlan, ...state.history] };
    default:
      return state;
  }
}
