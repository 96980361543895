import React from "react";
import { runWithAdal } from "react-adal";
import store from "./store";
import getCsSessionStorage from "./common/csSessionStorage";
import LoggingIn from "./components/LoggingIn";
// import { setReloadPageIn /* , updateUserPreference */ } from "./actions/userPreferenceActions";
// import { pollApiEvents, processApiResponse, processApiError } from "./helpers";
import { authContext, adalApiFetch, configureGraphQLClient } from "./adalConfig";
import { setReloadPageIn } from "./actions/userPreferenceActions";
// import { authContext, adalApiFetch } from "./adalConfig";
import { clearCachesAndReload, logClientEvent, getRoot } from "./helpers";

const DO_NOT_LOGIN = false;

window.cleanSlate = {};

const root = getRoot();
root.render(<LoggingIn />);

window.cleanSlate.store = store;
window.cleanSlate.graphQLClient = configureGraphQLClient();
window.cleanSlate.sessionStorage = getCsSessionStorage();
window.cleanSlate.orchestra = [];

let version = null;
let build = null;
const versionAndBuildDiv = document.getElementById("div-version-and-build");
if (versionAndBuildDiv) {
  version = versionAndBuildDiv.hasAttribute("version") ? versionAndBuildDiv.getAttribute("version") : null;
  build = versionAndBuildDiv.hasAttribute("build") ? versionAndBuildDiv.getAttribute("build") : null;
}

/** run every 20 seconds check if any orchestra item older than 2 minutes .. remove it */
setInterval(() => {
  const deleteIndices = [];
  for (let i = 0; i < window.cleanSlate.orchestra.length; i++) {
    if ((+new Date() - window.cleanSlate.orchestra[i].timestamp) / 60000 > 2) deleteIndices.push(i);
  }
  for (let i = deleteIndices.length - 1; i >= 0; i--) window.cleanSlate.orchestra.splice(deleteIndices[i], 1);
}, 20000);

window.addEventListener("load", () => {
  const tabId = Math.floor(Math.random() * (9999 - 1000) + 1000);
  sessionStorage.setItem("ARES_TAB_ID", tabId);
  logClientEvent(`PAGE_LOAD`);
});

window.addEventListener("pagehide", () => {
  logClientEvent(`PAGE_UNLOAD`);
});

runWithAdal(
  authContext,
  () => {
    // adalApiFetch(
    //   `${API_URL}/Users/Login`,
    //   {
    //     method: "post"
    //   },
    //   true,
    //   true /* logout user if he is forbidden from accessing login api */
    // )
    //   .then(response => processApiResponse(response.orchestrationId))
    //   .then(orchestrationId => pollApiEvents(orchestrationId, ["IUserLoggedinSuccessfully"]))
    //   .then(response => {
    //     store.dispatch(updateUserPreference(response.UserPreferenceSetting));
    const aresLastCleanLoad = localStorage.getItem("ARES_LAST_CLEAN_LOAD");
    if (aresLastCleanLoad === null) {
      localStorage.setItem("ARES_LAST_CLEAN_LOAD", +new Date());
    }
    // if last clean load was more than 24 hours ago
    if ((+new Date() - +aresLastCleanLoad) / 1000 / 60 / 60 > 24) {
      clearCachesAndReload();
    }

    const domain = new URL(window.location.href);
    if (!domain.host.startsWith("localhost")) {
      setInterval(() => {
        adalApiFetch(`${window.location.origin}/manifest.json?v=${+new Date()}`, {
          headers: {
            "Content-Type": "application/json",
            "Cache-Control": "no-cache",
            Pragma: "no-cache"
          }
        }).then(({ version: latestVersion, build: latestBuild, forceUpdate }) => {
          if (latestVersion !== version || latestBuild !== build) {
            store.dispatch(setReloadPageIn(30, forceUpdate, { version: latestVersion, build: latestBuild }));
          }
        });
      }, 1000 * 60 * 5);
    }

    // eslint-disable-next-line
    require("./indexApp.js");
    // })
    // .catch(error => {
    //   processApiError(error);
    // });
  },
  DO_NOT_LOGIN
);
