import { toast } from "react-toastify";
import { gql } from "graphql-request";
import { isEmpty } from "lodash";
import "react-toastify/dist/ReactToastify.css";
import * as types from "./actionTypes";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import {
  pollApiEvents,
  processApiResponse,
  processApiError,
  logError,
  subscribe,
  processThenThrowApiError,
  graphQLUtils
} from "../helpers";
import { API_URL } from "../constants/environment";
import { updatePatientLevel } from "./patientActions";
import { reset as resetPatientSurveysData } from "../reducers/surveysSlice";
import { submitAthenaClaimSuccess } from "./reportActions";
import { adalApiFetch, graphQLQuery } from "../adalConfig";
import { VISIT_STATUS_VISIT_CANCELED, emptyGuid } from "../constants/miscellaneous";
import toastErrorOptions from "../constants/toastconfig";
import { fetchNoteSlashTaskDetails } from "./noteSlashTasksActions";
import apiSlice from "../api/apiSlice";

export function updateVisitPatientLevel(visitId, patientId, levelName, bh) {
  return {
    type: types.UPDATE_VISIT_PATIENT_LEVEL,
    context: "patient",
    visitId,
    patientId,
    levelName,
    bh
  };
}

function loadPatientVisitsSuccess(patientId, patientVisits) {
  return {
    type: types.LOAD_PATIENT_VISITS_SUCCESS,
    context: "patient",
    patientId,
    patientVisits
  };
}

function setPatientVisitsLoading(patientId, value) {
  return {
    type: types.SET_PATIENT_VISITS_LOADING,
    context: "patient",
    patientId,
    value
  };
}

function loadPatientActiveVisitsDone(patientId, visits) {
  return {
    type: types.LOAD_PATIENT_ACTIVE_VISITS_SUCCESS,
    context: "patient",
    patientId,
    visits
  };
}

export function clearPatientRelatedVisitsData() {
  return {
    type: types.CLEAR_PATIENT_RELATED_VISITS_DATA
  };
}

function createVisitSuccess(patientId, response, visit, visitId) {
  return {
    type: types.CREATE_VISIT_SUCCESS,
    context: "patient",
    patientId,
    response,
    visit,
    visitId
  };
}

export function navigateToNewVisitWhenItComes(patientId, visitId, tab = ``) {
  return {
    type: types.NAVIGATE_TO_NEW_VISIT_WHEN_IT_COMES,
    context: "patient",
    patientId,
    visitId,
    tab
  };
}

function updateVisitStatusSuccess(response, visitId, visitStatus) {
  return {
    type: types.UPDATE_VISIT_STATUS_SUCCESS,
    response,
    visitId,
    visitStatus
  };
}

function updateVisitTypeSuccess(visitId, visitType) {
  return {
    type: types.UPDATE_VISIT_TYPE_SUCCESS,
    visitId,
    visitType
  };
}

export function updatePatientVisitStatusStarted(visitId, oldVisitStatus) {
  return {
    type: types.UPDATE_PATIENT_VISIT_STATUS_STARTED,
    visitId,
    oldVisitStatus
  };
}

export function updatePatientVisitStatusSuccess(visitId, visitStatus) {
  return {
    type: types.UPDATE_PATIENT_VISIT_STATUS_SUCCESS,
    visitId,
    visitStatus
  };
}

export function updatePatientVisitStatusFailed(visitId) {
  return {
    type: types.UPDATE_PATIENT_VISIT_STATUS_FAILED,
    visitId
  };
}

function loadVisitSummarySuccess(summary) {
  return {
    type: types.LOAD_VISIT_SUMMARY_HEADER_SUCCESS,
    summary
  };
}

export function setAllCenterVisits(allCenterVisits) {
  return {
    type: types.SET_ALL_CENTER_VISITS,
    allCenterVisits
  };
}

function loadStatusWatchVisitingSuccess(status) {
  return {
    type: types.LOAD_STATUS_WATCH_VISITING_SUCCESS,
    status
  };
}

function updateStatusWatchVisitingSuccess(updateValue) {
  return {
    type: types.UPDATE_STATUS_WATCH_VISITING_SUCCESS,
    updateValue
  };
}

function loadStatusWatchMyPatientSuccess(status) {
  return {
    type: types.LOAD_STATUS_WATCH_MY_PATIENT_SUCCESS,
    status
  };
}

function updateStatusWatchMyPatientSuccess(updateValue) {
  return {
    type: types.UPDATE_STATUS_WATCH_MY_PATIENT_SUCCESS,
    updateValue
  };
}

export function updateMyPatientStatusSelectSuccess(statusSelected) {
  return {
    type: types.UPDATE_MY_PATIENT_STATUS_SELECT_SUCCESS,
    statusSelected
  };
}

export function updateVisittingStatusSelectSuccess(statusSelected) {
  return {
    type: types.UPDATE_VISITING_STATUS_SELECT_SUCCESS,
    statusSelected
  };
}

export function setMyCalendar(myCalendar) {
  return {
    type: types.SET_MY_CALENDAR,
    myCalendar
  };
}

function createVisitAmendmentSuccess(response, amendments) {
  return {
    type: types.CREATE_VISIT_AMENDMENT_SUCCESS,
    response,
    amendments
  };
}

function loadAmendmentByVisitIdSuccess(amendments) {
  return {
    type: types.LOAD_AMENDMENT_BY_VISIT_ID_SUCCESS,
    amendments
  };
}

function loadPatientTimelineSuccess(patientId, timeline) {
  return {
    type: types.LOAD_PATIENT_TIMELINE_SUCCESS,
    context: "patient",
    patientId,
    timeline
  };
}

function fetchTimelineItemDetailsDone(patientId, entityType, entityId, entity) {
  return {
    type: types.FETCH_TIMELINE_ITEM_DETAILS_DONE,
    context: "patient",
    patientId,
    entityType,
    entityId,
    entity
  };
}

function loadVisitMedicalRecentDetailsSuccess(visitsMedicalDetail) {
  return {
    type: types.LOAD_VISIT_MEDICAL_RECENT_SUCCESS,
    visitsMedicalDetail
  };
}

function loadVisitPatientHistSuccess(patientId, visitId) {
  return {
    type: types.LOAD_VISIT_PATIENT_HIST_SUCCESS,
    patientId,
    visitId
  };
}

function loadVisitAllergyHistSuccess(patientId, visitId, allergyHistory) {
  return {
    type: types.LOAD_VISIT_ALLERGY_HIST_SUCCESS,
    patientId,
    visitId,
    allergyHistory
  };
}

function loadVisitFamilyHistSuccess(patientId, visitId, familyHistory) {
  return {
    type: types.LOAD_VISIT_FAMILY_HIST_SUCCESS,
    patientId,
    visitId,
    familyHistory
  };
}

function loadVisitMedicalHistSuccess(patientId, visitId, medicalHistory) {
  return {
    type: types.LOAD_VISIT_MEDICAL_HIST_SUCCESS,
    patientId,
    visitId,
    medicalHistory
  };
}

function loadVisitInterventionHistorySuccess(patientId, visitId, interventionHistory) {
  return {
    type: types.LOAD_VISIT_INTERVENTION_HISTORY_SUCCESS,
    patientId,
    visitId,
    interventionHistory
  };
}

function loadVisitMedicationSuccess(patientId, visitId, medicationHistory) {
  return {
    type: types.LOAD_VISIT_MEDICATION_SUCCESS,
    patientId,
    visitId,
    medicationHistory
  };
}

function loadVisitSignPermissionDetailsSuccess(visitDetail) {
  return {
    type: types.LOAD_VISIT_SIGN_PERMISSION_DETAILS_SUCCESS,
    visitDetail
  };
}

export function setForceReloadPatientTimelineHistory(patientId, value) {
  return {
    type: types.SET_FORCE_RELOAD_PATIENT_VISITS,
    context: "patient",
    patientId,
    value
  };
}

function loadSignHistorySuccess(signHistory) {
  return {
    type: types.LOAD_VISIT_SIGN_HISTORY_SUCCESS,
    signHistory
  };
}

export function clearVisitLocations(ctx = "CONTEXT") {
  return {
    type: types.CLEAR_VISIT_LOCATIONS,
    ctx
  };
}

function getVisitLocationsDone(visitLocations, ctx = "CONTEXT") {
  return {
    type: types.GET_VISIT_LOCATIONS_DONE,
    visitLocations,
    ctx
  };
}

export function updateListVisitSummaryToPrint(listVisitSummaryToPrint) {
  return {
    type: types.UPDATE_LIST_VISIT_SUMMARY_TO_PRINT,
    listVisitSummaryToPrint
  };
}

export function setContextVisit(patientId, visitId) {
  return {
    type: types.SET_CONTEXT_VISIT,
    context: "patient",
    patientId,
    visitId
  };
}

export function clearContextVisit(patientId) {
  return {
    type: types.CLEAR_CONTEXT_VISIT,
    context: "patient",
    patientId
  };
}

export function setCenterVisitsMessage(message) {
  return {
    type: types.SET_CENTER_VISITS_MESSAGE,
    message
  };
}

export function setProviderVisitsMessage(message) {
  return {
    type: types.SET_PROVIDER_VISITS_MESSAGE,
    message
  };
}

export function setProviderAppointmentsMessage(message) {
  return {
    type: types.SET_PROVIDER_APPOINTMENTS_MESSAGE,
    message
  };
}

export function createVisit(visit, patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createVisit"));
    return adalApiFetch(`${API_URL}/Visits/${patientId}`, {
      method: "POST",
      body: JSON.stringify(visit)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IVisitCreatedEvent"))
      .then((pollResponse) => {
        if (visit.visitType === "initial" || visit.visitType === "rejoin")
          dispatch(updatePatientLevel(patientId, "orange"));
        dispatch(createVisitSuccess(patientId, pollResponse, visit, pollResponse.VisitId));
      })
      .catch((error) => {
        processApiError(error, dispatch);
        return error;
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function updateVisitStatus(visitId, visitStatus) {
  return (dispatch) => {
    dispatch(beginAjaxCall("updateVisitStatus"));
    return adalApiFetch(`${API_URL}/Visits/${visitId}/${visitStatus}`, {
      method: "PUT"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientVisitSnapshotVisitStatusUpdatedEvent"))
      .then((pollResponse) => dispatch(updateVisitStatusSuccess(pollResponse, visitId, visitStatus)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function updateVisitType(patientId, visitId, visitType) {
  return (dispatch) => {
    dispatch(beginAjaxCall("updateVisitType"));
    return adalApiFetch(`${API_URL}/Visits/${visitId}/VisitType/${visitType}`, {
      method: "PUT"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IVisitTypeChangedEvent"))
      .then(() => dispatch(updateVisitTypeSuccess(visitId, visitType)))
      .then(() => dispatch(apiSlice.util.invalidateTags([`Templates-${patientId}-${visitId}`])))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function updatePatientVisitStatus(patientId, visitId, visitStatus) {
  return (dispatch, getState) => {
    dispatch(updatePatientVisitStatusStarted(visitId, getState().visits.contextVisit.visitStatus));
    dispatch(beginAjaxCall("updatePatientVisitStatus"));
    return adalApiFetch(`${API_URL}/Visits/${visitId}/${visitStatus}`, {
      method: "PUT"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientVisitSnapshotVisitStatusUpdatedEvent"))
      .then(() => {
        dispatch(updatePatientVisitStatusSuccess(visitId, visitStatus));
        if (visitStatus === "complete") dispatch(resetPatientSurveysData());
        if (visitStatus === VISIT_STATUS_VISIT_CANCELED) dispatch(resetPatientSurveysData());
      })
      .then(() => dispatch(apiSlice.util.invalidateTags([`Template-${patientId}-${visitId}`])))
      .catch((error) => {
        dispatch(updatePatientVisitStatusFailed(visitId));
        processApiError(error, dispatch);
      })
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function cancelVisit(patientId, visitId, cancelReasonId, explanation) {
  return (dispatch) => {
    dispatch(beginAjaxCall("cancelVisit"));
    return adalApiFetch(`${API_URL}/Visits/Cancel`, {
      method: "PUT",
      body: JSON.stringify({ visitId, cancelReasonId, explanation })
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientVisitSnapshotVisitStatusUpdatedEvent"))
      .then((pollResponse) => {
        dispatch(updatePatientVisitStatusSuccess(visitId, pollResponse.visitStatus));
        if (pollResponse.visitStatus === VISIT_STATUS_VISIT_CANCELED) dispatch(resetPatientSurveysData());
      })
      .then(() => dispatch(apiSlice.util.invalidateTags([`Template-${patientId}-${visitId}`])))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadPatientActiveVisits(patientId) {
  return (dispatch) =>
    adalApiFetch(`${API_URL}/Visits/${patientId}/ActiveVisits`)
      .then((response) => {
        if (response.groupName) {
          subscribe(response.groupName);
        }
        return dispatch(loadPatientActiveVisitsDone(patientId, response.activeVisits));
      })
      .catch((error) => processApiError(error, dispatch));
}

export function loadPatientVisits(patientId, isLooping = false, visitsToLoad = 10, recursive = false, trial = 1) {
  return (dispatch, getState) => {
    const _patientVisits = getState().visits.patientVisits;
    let _visitsToLoad = visitsToLoad;
    if (_patientVisits && _patientVisits.totalNumberOfVisits && _patientVisits.totalNumberOfVisits > visitsToLoad) {
      _visitsToLoad = _patientVisits.totalNumberOfVisits;
    }

    if (!recursive && _patientVisits && _patientVisits.loading && _patientVisits.loading === patientId) {
      return Promise.resolve({ patientVisits: _patientVisits });
    }

    dispatch(setPatientVisitsLoading(patientId, patientId));

    // don't dispatch a beginAjaxCall if it is looping
    if (!isLooping) {
      dispatch(beginAjaxCall("loadPatientVisits"));
    }

    return adalApiFetch(`${API_URL}/Patients/${patientId}/VisitHistory?pageSize=${_visitsToLoad}&pageNumber=1`)
      .then((patientVisits) => {
        if (patientVisits && patientVisits.visits && patientVisits.visits.length > 0) {
          let retry = false;
          let visit = null;
          patientVisits.visits.forEach((v) => {
            if (v && !v.visitStatus) {
              retry = true;
              visit = { ...v };
            }
          });
          if (retry) {
            if (trial === 3) {
              const message = `Visit with id ${visit.visitId} of type ${visit.visitType} scheduled of ${visit.visitDateTime} is corrupted`;
              toast.error(message, toastErrorOptions);
              logError(message);
            } else {
              setTimeout(() => {
                dispatch(loadPatientVisits(patientId, false, patientVisits.totalNumberOfVisits, true, trial + 1));
              }, 5000);
            }
            return Promise.resolve({ patientVisits: [] });
          }
        }
        if (patientVisits && patientVisits.totalNumberOfVisits > visitsToLoad)
          return dispatch(loadPatientVisits(patientId, false, patientVisits.totalNumberOfVisits, true));

        dispatch(setPatientVisitsLoading(patientId, null));
        return dispatch(loadPatientVisitsSuccess(patientId, patientVisits));
      })
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        if (!isLooping) {
          dispatch(ajaxCallDone());
        }
      });
  };
}

export function loadVisitSummary(visitId) {
  return (dispatch) =>
    adalApiFetch(`${API_URL}/Visits/${visitId}`)
      .then((summary) => dispatch(loadVisitSummarySuccess({ visitId, ...summary })))
      .catch((error) => {
        processApiError(error, dispatch);
      });
}

export function loadAllCenterVisits(clinicId, pageNumber, pageSize = 100) {
  return (dispatch) => {
    if (pageNumber < 1 || pageSize < 1) {
      return Promise.resolve(dispatch(setAllCenterVisits({ totalVisits: 0, dataVisits: [] })));
    }
    return adalApiFetch(
      `${API_URL}/Visits/${clinicId || emptyGuid}/AllVisiting?pageNumber=${pageNumber}&pageSize=${pageSize}`
    )
      .then((response) => {
        if (response && response.totalVisits > pageSize)
          return dispatch(loadAllCenterVisits(clinicId, pageNumber, response.totalVisits, true));

        return dispatch(setAllCenterVisits(response));
      })
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone));
  };
}

export function loadStatusWatchVisiting() {
  return (dispatch) =>
    adalApiFetch(`${API_URL}/Users/GetStatusWatchVisiting`, {
      method: "GET"
    })
      .then((status) => dispatch(loadStatusWatchVisitingSuccess(status)))
      .catch((error) => {
        processApiError(error, dispatch);
      });
}

export function loadVisitAmendment(visitId) {
  return (dispatch) =>
    adalApiFetch(`${API_URL}/Visits/${visitId}/Amendments`)
      .then((amendments) => dispatch(loadAmendmentByVisitIdSuccess(amendments)))
      .catch((error) => {
        processApiError(error, dispatch);
      });
}

export function createVisitAmendment(amendment) {
  const visitId = amendment && amendment.visitId;
  return (dispatch) => {
    dispatch(beginAjaxCall("createAmendment"));
    return adalApiFetch(`${API_URL}/Visits/${visitId}/Amendments`, {
      method: "POST",
      body: JSON.stringify(amendment)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IVisitAmendmentCreatedEvent"))
      .then((pollResponse) => {
        dispatch(createVisitAmendmentSuccess(pollResponse, amendment));
      })

      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function submitAthenaClaim(athenaVisitClaimItems) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createAmendment"));
    return adalApiFetch(`${API_URL}/Visits/SubmitAthenaClaim`, {
      method: "POST",
      body: JSON.stringify({ athenaVisitClaimItems })
    })
      .then(() => {
        dispatch(submitAthenaClaimSuccess(athenaVisitClaimItems));
      })
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadMyCalendar() {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadMyCalendar"));
    return adalApiFetch(`${API_URL}/Visits/MyCalendar`)
      .then((myCalendar) => dispatch(setMyCalendar(myCalendar)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function updateStatusWatchVisiting(statusValue) {
  return (dispatch) => {
    dispatch(beginAjaxCall("updateStatusWatch"));
    return adalApiFetch(`${API_URL}/Users/UpdateStatusWatchVisiting`, {
      method: "PUT",
      body: JSON.stringify({ value: statusValue })
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IUserStatusWatchVisitingUpdatedEvent"))
      .then(() => {
        dispatch(updateStatusWatchVisitingSuccess(statusValue));
      })
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadStatusWatchMyPatient() {
  return (dispatch) =>
    adalApiFetch(`${API_URL}/Users/GetStatusWatchMyPatient`, {
      method: "GET"
    })
      .then((status) => dispatch(loadStatusWatchMyPatientSuccess(status)))
      .catch((error) => {
        processApiError(error, dispatch);
      });
}

export function updateStatusWatchMyPatient(statusValue) {
  return (dispatch) => {
    dispatch(beginAjaxCall("updateStatusWatch"));
    return adalApiFetch(`${API_URL}/Users/UpdateStatusWatchMyPatient`, {
      method: "PUT",
      body: JSON.stringify({ value: statusValue })
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IUserStatusWatchMyPatientUpdatedEvent"))
      .then(() => {
        dispatch(updateStatusWatchMyPatientSuccess(statusValue));
      })
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function fetchTimelineItemDetails(patientId, type, id) {
  // future work .. we may use caching here .. but be careful sometimes we really needs a real reload
  return (dispatch) => {
    switch (type) {
      case "TASK":
        return dispatch(fetchNoteSlashTaskDetails(id)).then(({ entity }) =>
          dispatch(fetchTimelineItemDetailsDone(patientId, type, id, entity))
        );
      default:
        throw new Error("Unknown timeline item type!");
    }
  };
}

export function loadPatientTimeline(filter, trial = 1) {
  const { patientId, from, to, includeDeletedTasks, includeFutureTasks } = filter;
  return (dispatch, getState) =>
    adalApiFetch(
      `${API_URL}/Patients/${patientId}/TimelineHistory?from=${encodeURIComponent(from)}&to=${encodeURIComponent(
        to
      )}&includeDeletedTasks=${includeDeletedTasks}&includeFutureTasks=${includeFutureTasks}`
    )
      .then((timeline) => {
        let retry = false;
        let visit = null;
        timeline.data.forEach((v) => {
          if (v && v.visit && v.visit.visitId && !v.visit.visitStatus) {
            retry = true;
            visit = { ...v.visit };
          }
        });
        if (retry) {
          if (trial === 3) {
            const message = `Visit with id ${visit.visitId} of type ${visit.visitType} scheduled of ${visit.visitDateTime} is corrupted`;
            toast.error(message, toastErrorOptions);
            logError(message);
          } else {
            setTimeout(() => {
              dispatch(loadPatientTimeline(filter, trial + 1));
            }, 5000);
          }
          return Promise.resolve({ timeline: [] });
        }
        const detailedTimeline = getState().visits.detailedTimeline;
        timeline.data.forEach((timelineItem) => {
          if (!isEmpty(detailedTimeline[`${timelineItem.type.toUpperCase()}_${timelineItem.platformId}`]))
            dispatch(fetchTimelineItemDetails(patientId, timelineItem.type.toUpperCase(), timelineItem.platformId));
        });
        return dispatch(loadPatientTimelineSuccess(patientId, timeline));
      })
      .catch((error) => {
        processApiError(error, dispatch);
      });
}

export function loadVisitMedicalRecentDetails(patientId) {
  return (dispatch) =>
    adalApiFetch(`${API_URL}/Visits/${patientId}/VisitMedicalRecentDetails`)
      .then((visitsDetail) => dispatch(loadVisitMedicalRecentDetailsSuccess(visitsDetail)))
      .catch((error) => {
        processApiError(error, dispatch);
      });
}

export function loadVisitMedication(patientId, visitId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadVisitMedication"));
    return adalApiFetch(`${API_URL}/Visits/${patientId}/MedicationHistory/${visitId}`)
      .then((visitMedicationHistory) =>
        dispatch(loadVisitMedicationSuccess(patientId, visitId, visitMedicationHistory))
      )
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadVisitMedicalHist(patientId, visitId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadVisitMedicalHist"));
    return adalApiFetch(`${API_URL}/Visits/${patientId}/MedicalHistory/${visitId}`)
      .then((visitMedicalHistory) => dispatch(loadVisitMedicalHistSuccess(patientId, visitId, visitMedicalHistory)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadVisitInterventionHistory(patientId, visitId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadVisitInterventionHistory"));
    return adalApiFetch(`${API_URL}/Visits/${patientId}/InterventionHistory/${visitId}`)
      .then((interventionHistory) =>
        dispatch(loadVisitInterventionHistorySuccess(patientId, visitId, interventionHistory))
      )
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadVisitFamilyHist(patientId, visitId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadVisitFamilyHist"));
    return adalApiFetch(`${API_URL}/Visits/${patientId}/FamilyHistory/${visitId}`)
      .then((visitFamilyHistory) => dispatch(loadVisitFamilyHistSuccess(patientId, visitId, visitFamilyHistory)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadVisitAllergyHist(patientId, visitId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadVisitAllergyHist"));
    return adalApiFetch(`${API_URL}/Visits/${patientId}/AllergyHistory/${visitId}`)
      .then((visitAllergyHistory) => dispatch(loadVisitAllergyHistSuccess(patientId, visitId, visitAllergyHistory)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadVisitPatientHist(patientId, visitId) {
  return (dispatch) =>
    dispatch(() =>
      Promise.all([
        dispatch(loadVisitFamilyHist(patientId, visitId)),
        dispatch(loadVisitMedication(patientId, visitId)),
        dispatch(loadVisitAllergyHist(patientId, visitId)),
        dispatch(loadVisitMedicalHist(patientId, visitId)),
        dispatch(loadVisitInterventionHistory(patientId, visitId))
      ])
    ).then(() => dispatch(loadVisitPatientHistSuccess(patientId, visitId)));
}

export function loadVisitSignPermissionDetails(visitId) {
  return (dispatch) =>
    adalApiFetch(`${API_URL}/Visits/${visitId}/VisitSignPermissionDetails`)
      .then((visitsDetail) => dispatch(loadVisitSignPermissionDetailsSuccess(visitsDetail)))
      .catch((error) => {
        processApiError(error, dispatch);
      });
}

export function loadSignHistory(visitId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadSignHistory"));
    return adalApiFetch(`${API_URL}/Visits/${visitId}/SignHistory`)
      .then((signHistory) => dispatch(loadSignHistorySuccess(signHistory)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function createProgressNoteVisit(patientId, centerId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createVisit"));
    return adalApiFetch(`${API_URL}/ProgressNote/patients/${patientId}/centers/${centerId}`, {
      method: "POST"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IVisitCreatedEvent"))
      .then((pollResponse) =>
        dispatch(
          createVisitSuccess(
            patientId,
            pollResponse,
            { centerId: pollResponse.CenterId, providerId: pollResponse.ProviderId, visitType: pollResponse.VisitType },
            pollResponse.VisitId
          )
        )
      )
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function getVisitLocations(visitId, ctx = "CONTEXT") {
  return (dispatch) => {
    dispatch(beginAjaxCall("getVisitLocations"));
    return adalApiFetch(`${API_URL}/Visits/${visitId}/Locations`)
      .then((visitLocations) => dispatch(getVisitLocationsDone(visitLocations, ctx)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function setVisitLocations(patientId, visitId, visitLocations, ctx = "CONTEXT") {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall("createVisit"));
    return adalApiFetch(`${API_URL}/Visits/${visitId}/Locations/${patientId}`, {
      method: "POST",
      body: JSON.stringify(visitLocations)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IVisitLocationsUpdatedEvent"))
      .then(() => {
        dispatch(getVisitLocationsDone(visitLocations, ctx));
        if (ctx === "CONTEXT" && getState().visits.visitSummary && getState().visits.visitSummary.visitId === visitId)
          dispatch(getVisitLocationsDone(visitLocations, "SUMMARY"));
      })
      .catch((error) => {
        processApiError(error, dispatch);
        return error;
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function search(filter) {
  return (dispatch) => {
    dispatch(beginAjaxCall("search"));
    return adalApiFetch(`${API_URL}/Visits/Search`, {
      method: "POST",
      body: JSON.stringify(filter)
    })
      .then((response) => response)
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function fetchPatientVisits(patientId, filter) {
  const { from, to, visitTypes, visitStatuses, onlySigned: isVisitSigned } = filter;
  const params = graphQLUtils.constructParams([
    { variable: "$from", type: "DateTime", parameter: from },
    { variable: "$to", type: "DateTime", parameter: to },
    { variable: "$visitTypes", type: "[String]", parameter: visitTypes },
    { variable: "$visitStatuses", type: "[String]", parameter: visitStatuses },
    { variable: "$isVisitSigned", type: "Boolean", parameter: isVisitSigned }
  ]);
  const where = graphQLUtils.constructWhere([
    { field: "visitType", op: "in", variable: "$visitTypes", parameter: visitTypes },
    { field: "visitStatus", op: "in", variable: "$visitStatuses", parameter: visitStatuses },
    { field: "isVisitSigned", op: "eq", variable: "$isVisitSigned", parameter: isVisitSigned },
    {
      field: "visitTime",
      op: [
        { op: "gte", variable: "$from", parameter: from },
        { op: "lte", variable: "$to", parameter: to }
      ]
    }
  ]);
  const query = gql`
    query getPatientVisits(
      $patientId: UUID!
      ${params}
    ) {
      visits(
        patientId: $patientId
        where: { ${where} }
        skip: 0
        take: 1000
      ) {
        items {
          visitId
          visitTime
          visitStatus
          visitType
          visitProviderId
          providerLastName
          providerFirstName
        }
        pageInfo {
          hasNextPage
        }
      }
    }
  `;

  const variables = { patientId, from, to, visitTypes, visitStatuses, isVisitSigned };

  return (dispatch) => {
    dispatch(beginAjaxCall("fetchPatientVisits"));
    return (
      graphQLQuery(query, variables)
        // .then((data) => dispatch(fetchPatientVisitsDone(patientId, data)))
        .catch((error) => processThenThrowApiError(error, dispatch))
        .finally(() => dispatch(ajaxCallDone()))
    );
  };
}
