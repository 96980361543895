/* eslint-disable no-await-in-loop */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Form, Button } from "../../ui";
import { fetchPatientDemographics } from "../../api/patientDemographics";
import { updateCoreDemographics } from "../../actions/demographicActions";
import { checkRoles } from "../../helpers";
import { roleGroups } from "../../constants/securityRoles";

export default function MigratePatientCenter() {
  const dispatch = useDispatch();

  const authRoles = useSelector((state) => state.auth.user.profile.roles);

  const [fromCenterId, setFromCenterId] = useState(``);
  const [toCenterId, setToCenterId] = useState(``);
  const [patientsIds, setPatientsIds] = useState(``);
  const [started, setStarted] = useState(false);
  const [result, setResult] = useState(``);

  if (!checkRoles(roleGroups.systemAdmin, authRoles)) return <div>You are not allowed to do this action</div>;

  const proceed = async () => {
    setStarted(true);
    setResult(
      `Migrating below patients\nFrom: ${fromCenterId}\nTo: ${toCenterId}\n--------------------------------------------------`
    );
    const ids = patientsIds.split("\n").map((i) => i.toLowerCase());
    for (let i = 0; i < ids.length; i++) {
      const patientId = ids[i];
      const demographics = await fetchPatientDemographics(patientId);
      setResult(
        (prevResult) =>
          `${prevResult}\nMigrating ${demographics.patientFirstName} ${
            demographics.patientLastName
          }\nID: ${patientId.toUpperCase()}`
      );

      if (demographics.homeCenterId.toLowerCase() !== fromCenterId.toLowerCase()) {
        if (demographics.homeCenterId.toLowerCase() === toCenterId.toLowerCase())
          setResult((prevResult) => `${prevResult}\nPatient does is already at "To Center"\nDone!`);
        else setResult((prevResult) => `${prevResult}\nPatient does NOT belong to "From Center"\nError!`);
      } else {
        const payload = { ...demographics, homeCenterId: toCenterId.toLowerCase() };
        const response = await dispatch(updateCoreDemographics(patientId, payload));
        if (response.HomeCenter && response.HomeCenter.toLowerCase() === toCenterId.toLowerCase()) {
          setResult((prevResult) => `${prevResult}\nSuccess`);
        } else if (response?.origin?.eventTypes && response?.origin?.eventTypes === "IPlatformErrorEvent") {
          const msg = JSON.parse(response?.origin?.message);
          setResult((prevResult) => `${prevResult}\n${msg?.ErrorUserMessage}\nFailure`);
        }
      }
      setResult((prevResult) => `${prevResult}\n--------------------------------------------------`);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col lg={6}>
          <h3>{`Migrate patients' main center`}</h3>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <label>From center ID:</label>
        </Col>
        <Col lg={6}>
          <label>To center ID:</label>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Form.Control
            type="text"
            style={{ width: "400px" }}
            value={fromCenterId}
            onChange={(e) => {
              setFromCenterId(e.target.value);
            }}
          />
        </Col>
        <Col lg={6}>
          <Form.Control
            type="text"
            style={{ width: "400px" }}
            value={toCenterId}
            onChange={(e) => {
              setToCenterId(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <label>Patient IDs</label>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <Form.Control
            as="textarea"
            rows={30}
            style={{ width: "400px", height: "calc(100vh - 215px)" }}
            value={patientsIds}
            onChange={(e) => {
              setPatientsIds(e.target.value);
            }}
          />
        </Col>
        <Col lg={6}>
          <Button variant="primary" style={{ width: "400px" }} onClick={proceed} disabled={started}>
            Proceed
          </Button>
          <br />
          <br />
          <textarea
            rows={30}
            style={{ width: "400px", height: "calc(100vh - 280px)" }}
            disabled
            value={result}
            onChange={() => {}}
          />
        </Col>
      </Row>
    </Container>
  );
}
