/* eslint-disable no-param-reassign */
// import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
// import { ajaxCallDone, beginAjaxCall } from "../actions/ajaxStatusActions";
// import * as labsApi from "../../../api/labs";
import { apiSlice } from "./apiSlice";
import { pollApiEvents, processApiError } from "../helpers";
import { TEMPLATE_ID_DISCHARGE_TEMPLATE_ID } from "../constants/miscellaneous";
import { contextualizeSurveys } from "../components/patients/surveys/surveyHelper";

export const templatesSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVisitTemplates: builder.query({
      async queryFn({ patientId, visitId, isDischarge = false }, _, extraOptions, baseQuery) {
        if (isDischarge)
          return {
            data: {
              selectedTemplate: TEMPLATE_ID_DISCHARGE_TEMPLATE_ID,
              availableTemplates: [
                { templateId: TEMPLATE_ID_DISCHARGE_TEMPLATE_ID, templateDisplayName: "Discharge Template" }
              ]
            }
          };
        const response = await baseQuery({ url: `Templates/Patients/${patientId}/Visits/${visitId}` });
        return { data: response.data };
      },
      providesTags: (result, error, { patientId, visitId }) => [`Templates-${patientId}-${visitId}`],
      keepUnusedDataFor: 300 // 5 mins, unless invalidated
    }),

    getTemplate: builder.query({
      query: ({ patientId, visitId, templateId, dischargeId }) =>
        `Templates/${templateId}/Patients/${patientId}?visitId=${visitId}&dischargeId=${dischargeId}`,
      transformResponse: (response, meta, { visitId, dischargeId }) => ({
        ...response,
        surveys: contextualizeSurveys(response.surveys, "surveys", dischargeId ? `` : visitId)
        // surveys: contextualizeSurveys(response.surveys, "surveys", dischargeId ? `` : visitId).map((survey) =>
        //   survey.id !== "00000000-0000-0000-0000-000000000046"
        //     ? { ...survey, cptRecommenders: [] }
        //     : {
        //         ...survey,
        //         cptRecommenders: [
        //           {
        //             id: 1,
        //             cpt: "CPT-01",
        //             message: "We recoment you to use this code CPT-01",
        //             triggeringQuestions: ["APS-100-Q74", "APS-100-Q102", "APS-100-Q73"],
        //             condition: [
        //               "AND",
        //               {
        //                 questionKey: "APS-100-Q74",
        //                 triggeringAnswers: [
        //                   "AND",
        //                   "99214 - Outpatient visit, established patient, moderate (Last visit within past 3 years)",
        //                   "99213 - Outpatient visit, established patient, mild to moderate (Last visit within past 3 years)"
        //                 ]
        //               },
        //               [
        //                 "OR",
        //                 {
        //                   questionKey: "APS-100-Q102",
        //                   triggeringAnswers: [
        //                     "OR",
        //                     "F11.20 -- Opioid dependence, uncomplicated (Moderate)",
        //                     "F10.20 -- Alcohol dependence, uncomplicated (Moderate)"
        //                   ]
        //                 },
        //                 {
        //                   questionKey: "APS-100-Q73",
        //                   triggeringAnswers: ["AND", "Zz"]
        //                 }
        //               ]
        //             ],
        //             meta: {},
        //             custom: "js:code"
        //           }
        //         ]
        //       }
        // )
      }),
      providesTags: (result, error, { patientId, visitId, templateId, dischargeId }) => [
        `Template-${patientId}-${templateId}`,
        `Template-${patientId}-${templateId}-${visitId}`,
        `Template-${patientId}-${templateId}-${dischargeId}`,
        `Template-${patientId}-${visitId}`,
        `Template-${patientId}-${dischargeId}`
      ],
      keepUnusedDataFor: 300 // 5 mins, unless invalidated
    }),

    setSelectedTemplate: builder.mutation({
      async queryFn({ templateId, templateVersion, patientId, visitId }, { dispatch }, extraOptions, baseQuery) {
        const response = await baseQuery({
          url: `Templates/${templateId}/version/${templateVersion}/Patients/${patientId}/Visits/${visitId}`,
          method: "POST"
        });
        if (response.error) {
          processApiError(response.error, dispatch);
          return { error: response.error };
        }
        try {
          const data = await pollApiEvents(response.data.orchestrationId, "IPatientTemplateSelectedEvent");
          return { data };
        } catch (error) {
          processApiError(error, dispatch);
          return { error };
        }
      },
      invalidatesTags: (result, error, { patientId, visitId }) => [
        `Templates-${patientId}-${visitId}`
        // do we need to invalidate the template it self here? .. the risk here is only if it got updated from other tab/user .. which might happen anyway even if reloaded
      ]
    })
  })
});

export const { getTemplate } = templatesSlice.endpoints;

export const { useGetVisitTemplatesQuery, useGetTemplateQuery, useSetSelectedTemplateMutation } = templatesSlice;

export default templatesSlice;
