import "react-toastify/dist/ReactToastify.css";
import * as types from "./actionTypes";
import { API_URL } from "../constants/environment";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import {
  pollApiEvents,
  processApiResponse,
  processApiError,
  processThenThrowApiError,
  pushOrchestraItem,
  subscribe
} from "../helpers";
import { adalApiFetch } from "../adalConfig";
import apiSlice from "../api/apiSlice";

export function clearPatientLabs(patientId) {
  return {
    type: types.CLEAR_PATIENT_LABS,
    context: "patient",
    patientId
  };
}

function loadAvailableLabsDone(centerId, availableLabs) {
  return {
    type: types.LOAD_AVAILABLE_LABS_SUCCESS,
    centerId,
    availableLabs
  };
}

function loadRecentOrdersSuccess(patientId, recentOrders) {
  return {
    type: types.LOAD_RECENT_ORDERS_SUCCESS,
    context: "patient",
    patientId,
    recentOrders
  };
}

function loadLabResultGraphsSuccess(labResultGraphs) {
  return {
    type: types.LOAD_LAB_RESULT_GRAPHS_SUCCESS,
    labResultGraphs
  };
}

export function setSelectedLabOrder(patientId, selectedLabOrder) {
  return {
    type: types.SET_SELECTED_LAB_ORDER,
    context: "patient",
    patientId,
    selectedLabOrder
  };
}

function setLabsRapidTestReference(data) {
  return {
    type: types.SET_LABS_RAPID_TEST_REFERENCE,
    data
  };
}

function initLabsRapidTestResults(labCode) {
  return {
    type: types.INIT_LABS_RAPID_TEST_RESULTS,
    labCode
  };
}

export function prefillLabsRapidTestResults(orderId, submissionNumber, labCode) {
  return {
    type: types.PREFILL_LABS_RAPID_TEST_RESULTS,
    orderId,
    submissionNumber,
    labCode
  };
}

function loadPendingOrdersSuccess(patientId, pendingOrders) {
  return {
    type: types.LOAD_PEND_ORDERS_SUCCESS,
    context: "patient",
    patientId,
    pendingOrders
  };
}

function loadLabResultsHeadersSuccess(results) {
  return {
    type: types.LOAD_LAB_RESULT_HEADERS_SUCCESS,
    results
  };
}

function loadLabResultsSuccess(results, orderId, labCode) {
  return {
    type: types.LOAD_LAB_RESULTS_SUCCESS,
    results,
    orderId,
    labCode
  };
}

function setFetchingRequestedLabsFlag(value) {
  return {
    type: types.SET_FETCHING_REQUESTED_LABS_FLAG,
    value
  };
}

function setRequestedLabs(requestedLabs) {
  return (dispatch, getState) => {
    const centerContext = getState().userPreference.centerContext;
    dispatch({
      type: types.SET_REQUESTED_LABS,
      requestedLabs,
      centerContext
    });
  };
}

export function clearRecentAndSelectedLabOrders() {
  return {
    type: types.CLEAR_RECENT_AND_SELECTED_LAB_ORDERS
  };
}

export function setRequestedLabSpecimenCollectionCenter(orderId, centerId) {
  return {
    type: types.SET_REQUESTED_LAB_SPECIMEN_COLLECTION_CENTER,
    orderId,
    centerId
  };
}
export function setRequestedLabSpecimenCollectionDate(orderId, date) {
  return {
    type: types.SET_REQUESTED_LAB_SPECIMEN_COLLECTION_DATE,
    orderId,
    date
  };
}

export function setLabSubmissionQueuePatientContext(patient) {
  return {
    type: types.SET_LAB_SUBMISSION_QUEUE_PATIENT_CONTEXT,
    patient
  };
}

export function confirmingSpecimenDateAndCenter(orderId) {
  return {
    type: types.CONFIRMING_SPECIMEN_DATE_AND_CENTER,
    orderId
  };
}

export function confirmingSpecimenDateAndCenterDone(orderId, succeeded = true) {
  return {
    type: types.CONFIRMING_SPECIMEN_DATE_AND_CENTER_DONE,
    orderId,
    succeeded
  };
}

export function setStandingLabOrderRendered(value) {
  return {
    type: types.SET_STANDING_LAB_ORDER_RENDERED,
    value
  };
}

export function setStandingLabOrderSaving(value) {
  return {
    type: types.SET_STANDING_LAB_ORDER_SAVING,
    value
  };
}

export function setTestCancelButtonPressed(orderId, labProvider, testType, submissionId, labSource, labCode, value) {
  return {
    type: types.SET_TEST_CANCEL_BUTTON_PRESSED,
    orderId,
    labProvider,
    testType,
    submissionId,
    labSource,
    labCode,
    value
  };
}

function confirmSpecimenDateAndCenterErrorHandler(payload) {
  window.cleanSlate.store.dispatch(confirmingSpecimenDateAndCenterDone(payload.orderId, false));
}

export function confirmSpecimenDateAndCenter(patientId, orderId, centerId, specimenCollectionDate) {
  return (dispatch) => {
    dispatch(beginAjaxCall("orderConfirmatory"));
    dispatch(confirmingSpecimenDateAndCenter(orderId));
    const url = `${API_URL}/PatientLabs/${patientId}/SpecimenDateAndCenter`;
    return adalApiFetch(url, {
      method: "POST",
      body: JSON.stringify({ orderId, centerId, specimenCollectionDate })
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => {
        pushOrchestraItem(orchestrationId, +new Date(), confirmSpecimenDateAndCenterErrorHandler, { url, orderId });
      })
      .catch((error) => {
        processThenThrowApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadAlreadyAddedConfirmatoryTestsSuccess(confirmatoryTests) {
  return {
    type: types.ALREADY_ADDED_CONFIRMATORY_TESTS,
    confirmatoryTests
  };
}

export function loadLabsRapidTestReference(labCode) {
  // eslint-disable-next-line consistent-return
  return (dispatch, getState) => {
    if (getState().labs.labsRapidTestReference) {
      dispatch(initLabsRapidTestResults(labCode));
      return Promise.resolve(true);
    }

    dispatch(beginAjaxCall("loadLabsRapidTestReference"));
    return adalApiFetch(`${API_URL}/LookupData/LabsRapidTestReference`, {
      method: "GET"
    })
      .then((response) =>
        dispatch(
          setLabsRapidTestReference(
            response.map((item) => ({
              ...item,
              validationRules: item.validationRules ? JSON.parse(item.validationRules.replace("\\", "\\\\")) : []
            }))
          )
        )
      )
      .then(() => dispatch(initLabsRapidTestResults(labCode)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function updateLabRapidTestResults(key, value) {
  return {
    type: types.UPDATE_LAB_RAPID_TEST_RESULTS,
    key,
    value
  };
}

export function loadAvailableLabs(centerId = null) {
  if (!centerId) throw new Error("Cener must be set to be able to fetch available labs");
  return (dispatch, getState) => {
    const value = getState().labs.availableLabs[centerId];
    if (value !== undefined) return Promise.resolve(value);

    dispatch(beginAjaxCall("loadAvailableLabs"));
    return adalApiFetch(`${API_URL}/LookupData/Labs${centerId ? `?centerId=${centerId}` : ``}`)
      .then((availableLabs) => dispatch(loadAvailableLabsDone(centerId, availableLabs)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadPendingOrders(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadPendingOrders"));
    return adalApiFetch(`${API_URL}/PatientLabs/${patientId}/PendingOrders`)
      .then((pendingOrders) => dispatch(loadPendingOrdersSuccess(patientId, pendingOrders)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadLabResults(patientId, orderId, labCode, forceReload = false) {
  return (dispatch, getState) => {
    if (patientId === undefined) return Promise.resolve(null);

    const result = getState().labs.results.find((r) => r.orderId === orderId && r.labCode === labCode);
    if (!forceReload && result && result.labResults) return Promise.resolve(null);

    dispatch(beginAjaxCall("loadLabResults"));
    return adalApiFetch(
      `${API_URL}/PatientLabs/${patientId}/LabResults?${labCode ? `&labCode=${labCode}` : ``}${
        orderId ? `&orderId=${orderId}` : ``
      }`
    )
      .then((results) => {
        dispatch(loadLabResultsSuccess(results, orderId, labCode));
      })
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadLabResultsHeaders(patientId, orderId, labCode, ignoreOrderIdAndLabCode = false) {
  return (dispatch) => {
    if (patientId === undefined) return Promise.resolve(null);
    dispatch(beginAjaxCall("loadabResultsHeaders"));
    return adalApiFetch(
      `${API_URL}/PatientLabs/${patientId}/LabResultsHeaders?${
        !ignoreOrderIdAndLabCode && labCode ? `&labCode=${labCode}` : ``
      }${!ignoreOrderIdAndLabCode && orderId ? `&orderId=${orderId}` : ``}`
    )
      .then((results) => dispatch(loadLabResultsHeadersSuccess(results)))
      .then((results) => {
        if (orderId && labCode) return dispatch(loadLabResults(patientId, orderId, labCode, true));
        return results;
      })
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function printPrimaryLabel(patientId, date) {
  return (dispatch) => {
    dispatch(beginAjaxCall("printPrimaryLabel"));
    return adalApiFetch(`${API_URL}/PatientLabs/PrimaryLabel`, {
      method: "POST",
      body: JSON.stringify({ patientId, date })
    })
      .then((response) => {
        if (response.isSucceeded === false) throw new Error(response.errorMessage);
        return response.labelPdf;
      })
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function orderConfirmatory(patientId, orderId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("orderConfirmatory"));
    return adalApiFetch(`${API_URL}/PatientLabs/${patientId}/OrderConfirmatory`, {
      method: "POST",
      body: JSON.stringify(orderId)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, ["ILabOrderSubmittedEvent"]))
      .then(() => {
        dispatch(loadLabResultsHeaders(patientId));
        dispatch(loadPendingOrders(patientId));
      })
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

/* eslint-disable no-unused-vars */
export function addTestsToPendingOrder(
  orderId,
  patientId,
  visitId,
  labs,
  submissionComment = null,
  sampleCollectionDate = null, // ignored for now as we stopped PSC feature
  isPSCHold = false // ignored for now as we stopped PSC feature
) {
  return (dispatch) => {
    dispatch(beginAjaxCall("addTestsToPendingOrder"));
    const url = `${API_URL}/PatientLabs/${patientId}/PendingOrder`;
    return adalApiFetch(url, {
      method: "POST",
      body: JSON.stringify({
        orderId,
        visitId,
        labs,
        submissionComment,
        sampleCollectionDate: null,
        isPSCHold: false
      })
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => {
        pushOrchestraItem(orchestrationId, +new Date(), null, { url });
      })
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}
/* eslint-enable no-unused-vars */

export function clearLabSubmissionQueue() {
  return {
    type: types.CLEAR_LAB_SUBMISSION_QUEUE
  };
}

function syncLabSources(requestedLabs) {
  return requestedLabs.map((requestedLab) => ({
    ...requestedLab,
    labSources: requestedLab.labSources.map((ls) => ({
      ...ls,
      checkedCount: requestedLab.tests.filter(
        (test) =>
          !test.isConfirmatory && test.labSource === ls.source && test.actionable && !test.isPSCHold && test.checked
      ).length,
      uncheckedCount: requestedLab.tests.filter(
        (test) =>
          !test.isConfirmatory && test.labSource === ls.source && test.actionable && !test.isPSCHold && !test.checked
      ).length,
      loading: requestedLab.tests.filter((test) => test.labSource === ls.source).some((i) => i.loading),
      checked: requestedLab.tests
        .filter((test) => test.labSource === ls.source)
        .every((i) => !i.actionable || i.checked),
      canceled: requestedLab.tests.filter((test) => test.labSource === ls.source).every((i) => i.canceled),
      submitted: requestedLab.tests.filter((test) => test.labSource === ls.source).some((lab) => lab.submitted)
    }))
  }));
}

function setRequestedLabsLoading(dispatch, getState, patientId, orderId, labSource = null, labCode = null, loading) {
  const requestedLabs = getState().labs.labSubmissionQueue.requestedLabs.map((group) => {
    if (group.orderId !== orderId) return group;
    return {
      ...group,
      labSources: group.labSources.map((ls) => {
        if (labSource && ls.source !== labSource.source) return { ...ls };
        return {
          ...ls,
          loading
        };
      }),
      tests: group.tests.map((test) => {
        if (!test.actionable) return { ...test };
        if (labCode && test.labCode !== labCode) return { ...test };
        if (!labCode && labSource && test.labSource !== labSource.source) return { ...test };
        return {
          ...test,
          loading
        };
      })
    };
  });
  dispatch(setRequestedLabs(requestedLabs));
}

export function fetchRequestedLabs(setFetchingFlag = false) {
  return async (dispatch, getState) => {
    const currentCenterId = getState().userPreference.centerContext;
    if (!currentCenterId) return Promise.resolve(null);
    dispatch(beginAjaxCall("fetchRequestedLabs"));
    if (setFetchingFlag) dispatch(setFetchingRequestedLabsFlag(true));
    const contextPatientId = getState().labs.labSubmissionQueue.contextPatient.patientId;
    const url = `${API_URL}/PatientLabs/${contextPatientId ? `PatientRequestedLabs` : `RequestedLabs`}?${
      contextPatientId ? `patientId=${contextPatientId}` : `centerId=${currentCenterId}`
    }&includeOrderedToday=${getState().labs.labSubmissionQueue.includeOrderedToday}`;
    return adalApiFetch(url)
      .then((response) => {
        if (response.groupName) {
          subscribe(response.groupName);
        }
        let _requestedLabs = response.pendingLabs.reduce((rv, x) => {
          const el = rv.find((r) => r && r.orderId === x.orderId);
          const test = {
            ...x,
            isRapid: x.labProvider === "Clean Slate Rapid",
            isConfirmatory: x.testType === "Confirmatory",
            actionable:
              x.labProvider !== "Clean Slate Rapid" &&
              !x.isPSCHold &&
              x.orderStatus !== "Canceled" &&
              x.orderStatus !== "Sent",
            checked: x.isSpecimenCollected,
            canceled: x.orderStatus === "Canceled",
            submitted: x.orderStatus === "Sent"
          };

          if (el) {
            if (!el.labSources.find((item) => item.source === x.labSource))
              el.labSources.push({ source: x.labSource, checked: false });
            el.tests.push(test);
          } else {
            rv.push({
              orderId: x.orderId,
              patientId: x.patientId,
              labSources: [{ source: x.labSource, checked: false }],
              isSpecimenCollected: test.isSpecimenCollected,
              specimenCollectionDate: test.specimenCollectionDate,
              specimenCenterId: test.specimenCenterId,
              isFirstSubmission: test.isFirstSubmission,
              tests: [test]
            });
          }
          return rv;
        }, []);
        _requestedLabs = syncLabSources(_requestedLabs);
        dispatch(setRequestedLabs(_requestedLabs));
        if (setFetchingFlag) dispatch(setFetchingRequestedLabsFlag(false));
      })
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function toggleIncludeOrderedToday() {
  return async (dispatch) => {
    Promise.resolve(
      dispatch({
        type: types.TOGGLE_INCLUDE_ORDERED_TODAY
      })
    ).then(() => dispatch(fetchRequestedLabs()));
  };
}

export function setRequestedLabCheckedValue(checked, patientId, orderId, labCode, labName, labSource) {
  return (dispatch, getState) => {
    new Promise((resolve) => {
      setRequestedLabsLoading(dispatch, getState, patientId, orderId, { source: labSource }, labCode, true);
      resolve();
    }).then(() => {
      dispatch(beginAjaxCall("setRequestedLabCheckedValue"));
      return adalApiFetch(`${API_URL}/PatientLabs/${patientId}/SelectOrClearRequestedLab?isSelected=${checked}`, {
        method: "POST",
        body: JSON.stringify({
          orderId,
          labCodes: [labCode]
        })
      })
        .then((response) =>
          processApiResponse(response.orchestrationId, [
            "IRequestedLabSelectedEvent",
            "ISelectedRequestedLabClearedEvent"
          ])
        )
        .then((response) => {
          let requestedLabs = getState().labs.labSubmissionQueue.requestedLabs.map((group) => {
            if (group.orderId !== orderId) return group;
            const rChecked = response.SampleCollectedById !== undefined && response.SampleCollectedById !== null;
            const rCanceled = response.CanceledById !== undefined && response.CanceledById !== null;
            return {
              ...group,
              tests: group.tests.map((test) => {
                if (test.labName !== labName) return { ...test };
                return {
                  ...test,
                  loading: false,
                  checked: rChecked,
                  canceled: rCanceled,
                  actionable: test.actionable && !rCanceled,
                  specimenCollectedByFirstName: response.SampleCollectedByFirstName,
                  specimenCollectedByLastName: response.SampleCollectedByLastName,
                  specimenCollectionDate: response.SampleCollectionDate
                };
              })
            };
          });
          requestedLabs = syncLabSources(requestedLabs);
          dispatch(setRequestedLabs(requestedLabs));
        })

        .catch((error) => {
          processApiError(error, dispatch);
        })
        .finally(() => {
          dispatch(ajaxCallDone());
        });
    });
  };
}

export function setAllLabSourceCheckedValue(checked, patientId, orderId, labSource) {
  return (dispatch, getState) => {
    new Promise((resolve) => {
      setRequestedLabsLoading(dispatch, getState, patientId, orderId, labSource, null, true);
      resolve();
    }).then(() => {
      const labCodes = getState().labs.labSubmissionQueue.requestedLabs.reduce((acc, group) => {
        if (group.orderId === orderId) {
          group.tests.forEach((test) => {
            if (test.labSource === labSource.source && test.actionable) acc.push(test.labCode);
          });
        }
        return acc;
      }, []);
      dispatch(beginAjaxCall("setAllLabSourceCheckedValue"));
      return adalApiFetch(`${API_URL}/PatientLabs/${patientId}/SelectOrClearRequestedLab?isSelected=${checked}`, {
        method: "POST",
        body: JSON.stringify({
          orderId,
          labCodes
        })
      })
        .then((response) =>
          processApiResponse(response.orchestrationId, [
            "IRequestedLabSelectedEvent",
            "ISelectedRequestedLabClearedEvent"
          ])
        )
        .then((response) => {
          let requestedLabs = getState().labs.labSubmissionQueue.requestedLabs.map((item) => {
            if (item.orderId !== orderId) return item;
            const rChecked = response.SampleCollectedById !== undefined && response.SampleCollectedById !== null;
            const rCanceled = response.CanceledById !== undefined && response.CanceledById !== null;
            return {
              ...item,
              tests: item.tests.map((test) => {
                if (test.labSource !== labSource.source || !test.actionable) return { ...test };
                return {
                  ...test,
                  loading: false,
                  checked: rChecked,
                  canceled: rCanceled,
                  actionable: test.actionable && !rCanceled,
                  specimenCollectedByFirstName: response.SampleCollectedByFirstName,
                  specimenCollectedByLastName: response.SampleCollectedByLastName,
                  specimenCollectionDate: response.SampleCollectionDate
                };
              })
            };
          });
          requestedLabs = syncLabSources(requestedLabs);
          dispatch(setRequestedLabs(requestedLabs));
        })
        .catch((error) => {
          processApiError(error, dispatch);
        })
        .finally(() => {
          dispatch(ajaxCallDone());
        });
    });
  };
}

export function printLabResults(patientId, orderId, orderSubmissionId, labCode) {
  return (dispatch) => {
    dispatch(beginAjaxCall("getDocument"));
    return adalApiFetch(`${API_URL}/PatientLabs/${patientId}/PrintLabResults`, {
      method: "POST",
      body: JSON.stringify({
        orderId,
        orderSubmissionId,
        labCode
      })
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, ["ILabResultPrintedEvent"]))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function cancelRequestedLab(
  patientId,
  orderId,
  labSource,
  labCode,
  cancellationReason,
  isConfirmatory = false,
  isCancelOrder = false,
  isAoe = false
) {
  return (dispatch, getState) => {
    new Promise((resolve) => {
      setRequestedLabsLoading(dispatch, getState, patientId, orderId, labSource, labCode, true);
      resolve();
    }).then(() => {
      dispatch(beginAjaxCall("cancelRequestedLab"));
      const promise = isAoe
        ? adalApiFetch(`${API_URL}/PatientLabs/${patientId}/CancelAOE`, {
            method: "POST",
            body: JSON.stringify({
              orderId,
              cancellationReason
            })
          })
        : adalApiFetch(`${API_URL}/PatientLabs/${patientId}/CancelRequestedLab`, {
            method: "POST",
            body: JSON.stringify({
              orderId,
              labCode,
              cancellationReason,
              isConfirmatory,
              isCancelOrder
            })
          });

      promise
        .then((response) => processApiResponse(response.orchestrationId))
        .then((orchestrationId) =>
          pollApiEvents(orchestrationId, ["IRequestedLabCanceledEvent", "ILabOrderCanceledEvent", "IAOECanceledEvent"])
        )
        .then((response) => {
          const rCanceled = response.CanceledById !== undefined && response.CanceledById !== null;
          let requestedLabs = getState().labs.labSubmissionQueue.requestedLabs.map((requestedLab) => {
            if (requestedLab.orderId !== orderId) return requestedLab;
            return {
              ...requestedLab,
              tests: requestedLab.tests.map((test) => {
                if (labCode && test.labCode !== labCode) return { ...test };
                if (isCancelOrder && !response.CanceledLabCodes.includes(test.labCode)) return { ...test };
                return {
                  ...test,
                  loading: false,
                  checked: false,
                  canceled: rCanceled,
                  actionable: !rCanceled,
                  canceledById: response.CanceledById,
                  canceledByFirstName: response.CanceledByFirstName,
                  canceledByLastName: response.CanceledByLastName,
                  canceledDate: response.CanceledDate,
                  cancellationReason: response.CancellationReason
                };
              })
            };
          });
          requestedLabs = syncLabSources(requestedLabs);
          dispatch(setRequestedLabs(requestedLabs));
        })
        .catch((error) => {
          processApiError(error, dispatch);
        })
        .finally(() => {
          dispatch(ajaxCallDone());
        });
    });
  };
}

export function cancelSubmittedLab(patientId, testType, orderId, labCode, reason) {
  return async (dispatch) => {
    dispatch(beginAjaxCall("cancelSubmittedLab"));
    return adalApiFetch(
      `${API_URL}/PatientLabs/${patientId}/${
        testType === "Confirmatory" ? "CancelSubmittedConfirmatoryLab" : "CancelSubmittedLab"
      }`,
      {
        method: "POST",
        body: JSON.stringify({
          orderId,
          labCodes: [labCode],
          cancellationReason: reason
        })
      }
    )
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, ["ISubmittedLabsCanceledEvent"]))
      .then(() => dispatch(loadPendingOrders(patientId)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function submitLabOrder(patientId, orderId) {
  return async (dispatch, getState) =>
    new Promise((resolve) => {
      setRequestedLabsLoading(dispatch, getState, patientId, orderId, null, null, true);
      resolve();
    })
      .then(() => {
        dispatch(beginAjaxCall("validateSubmitOrder"));
        return adalApiFetch(`${API_URL}/PatientLabs/${patientId}/ValidateSubmitOrder?orderId=${orderId}`, {
          method: "GET"
        })
          .then((response) => response.errors)
          .catch((error) => {
            processApiError(error, dispatch);
          })
          .finally(() => {
            dispatch(ajaxCallDone());
          });
      })
      .then((errors) => {
        if (errors && Array.isArray(errors) && errors.length) {
          errors.forEach((error) => processApiError(error, dispatch));
          setRequestedLabsLoading(dispatch, getState, patientId, orderId, null, null, false);

          return null;
        }
        dispatch(beginAjaxCall("submitLabOrder"));
        return adalApiFetch(`${API_URL}/PatientLabs/${patientId}/SubmitOrder`, {
          method: "POST",
          body: JSON.stringify(orderId)
        })
          .then((response) => processApiResponse(response.orchestrationId))
          .then((orchestrationId) => pollApiEvents(orchestrationId, "ILabOrderSubmittedEvent", true))
          .then((response) => {
            dispatch(fetchRequestedLabs());
            return response;
          })

          .catch((error) => {
            setRequestedLabsLoading(dispatch, getState, patientId, orderId, null, null, false);
            processApiError(error, dispatch);
          })
          .finally(() => {
            dispatch(ajaxCallDone());
          });
      });
}

export function submitAOE(patientId, orderId) {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall("submitAOE"));
    return adalApiFetch(`${API_URL}/PatientLabs/${patientId}/SubmitAOE `, {
      method: "POST",
      body: JSON.stringify(orderId)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "ILabOrderSubmittedEvent", true))
      .then((response) => {
        setRequestedLabsLoading(dispatch, getState, patientId, orderId, null, null, false);
        return response;
      })
      .then((response) => {
        dispatch(fetchRequestedLabs());
        return response;
      })
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadRecentOrders(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadRecentOrders"));
    return adalApiFetch(`${API_URL}/PatientLabs/${patientId}/RecentOrders`)
      .then((recentOrders) => dispatch(loadRecentOrdersSuccess(patientId, recentOrders)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function saveRapidResult(patientId, visitId, orderId, labCode) {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall("saveRapidResult"));
    return adalApiFetch(`${API_URL}/PatientLabs/${patientId}/RapidResult`, {
      method: "POST",
      body: JSON.stringify({
        orderId,
        labCode,
        rapidTestInputModel: Object.keys(getState().labs.labsRapidTestResults).map((key) => ({
          labCodeParameter: key,
          result: getState().labs.labsRapidTestResults[key]
        }))
      })
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, ["IRapidLabResultAddedEvent"]))
      .then(() => {
        dispatch(
          apiSlice.util.invalidateTags([
            `LabResultsVisits-${patientId}`,
            `NotReviewedLabResults-${patientId}`,
            `LabResultsHistory-${patientId}`,
            `ReviewedLabResultsHistory-${patientId}-${visitId}`,
            `LabResultsReviewDetails-${patientId}-${orderId}`
          ])
        );
        const promises = [
          dispatch(loadPendingOrders(patientId)),
          dispatch(loadLabResultsHeaders(patientId, orderId, labCode, true))
        ];
        if (getState().labs.standingLabOrder.rendered) {
          promises.push(dispatch(loadRecentOrders(patientId)));
        }
        return Promise.all(promises);
      })
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadLabResultGraphs(patientId, labCode) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadLabResultGraphs"));
    return adalApiFetch(`${API_URL}/PatientLabs/${patientId}/LabResultGraphs?labCode=${labCode}`)
      .then((labResultGraphs) => dispatch(loadLabResultGraphsSuccess(labResultGraphs.map((i) => ({ ...i, labCode })))))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadLabOrdersAndResultsPerVisit(visitId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadLabOrdersPerVisit"));
    return adalApiFetch(`${API_URL}/PatientLabs/Visits/${visitId}/OrdersAndResults`)
      .then((response) =>
        dispatch({ type: types.LOAD_LABS_ORDES_AND_RESULTS_PER_VISIT_SUCCESS, labOrdersAndResults: response })
      )
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadAlreadyAddedConfirmatoryTests(patientId, orderId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadAlreadyAddedConfirmatoryTests"));
    return adalApiFetch(`${API_URL}/PatientLabs/${patientId}/ConfirmatorySelections?orderId=${orderId}`)
      .then((confirmatoryTests) => dispatch(loadAlreadyAddedConfirmatoryTestsSuccess(confirmatoryTests)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}
