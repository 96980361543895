import React from "react";
import { Row as BsRow } from "react-bootstrap";

const Row = React.forwardRef(({ children, ...rest }, ref) => (
  <BsRow {...rest} ref={ref}>
    {children}
  </BsRow>
));

export default Row;
