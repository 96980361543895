/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loadTemplateDefinition as _loadTemplateDefinition } from "../api/templates";

export const loadTemplateDefinition = createAsyncThunk(
  "templates/loadTemplateDefinition",
  async (templateId, { getState } /* , {dispatch, getState, extra, requestId, signal} */) => {
    const templates = getState().templates;
    if (templates[templateId]) return { templateId, templateDefinition: templates[templateId] };
    const templateDefinition = await _loadTemplateDefinition(templateId);
    return { templateId, templateDefinition };
  }
);

export const surveysSlice = createSlice({
  name: "surveys",
  initialState: {
    context: { expanded: false, patientId: null, visitId: null },
    expandedSurveys: [],
    savedSurveys: {},
    activeSurvey: { surveyId: null, sectionKey: null, questionKey: null },
    cachedSurveys: {},
    templates: {} // TODO move this away as it is considered to be a lookup more than related to any patient
  },
  reducers: {
    setSurveysContext: (state, { payload: context }) => {
      if (
        context.patientId &&
        (context.isDischarge || context.visitId) &&
        (state.context.patientId !== context.patientId || state.context.visitId !== context.visitId)
      ) {
        state.context.expanded = false;
        state.context.patientId = context.patientId;
        state.context.visitId = context.visitId;
        state.expandedSurveys = [];
        state.activeSurvey = { surveyId: null, sectionKey: null, questionKey: null };
        state.cachedSurveys = {};
      }
    },
    updateSurveysContext: (state, { payload: context }) => {
      Object.keys(context).forEach((key) => {
        state.context[key] = context[key];
      });
    },
    expandSurvey: (state, { payload: surveyId }) => {
      if (!state.expandedSurveys.includes(surveyId)) state.expandedSurveys.push(surveyId);
    },
    expandSurveys: (state, { payload: surveyIds }) => {
      surveyIds.forEach((surveyId) => {
        if (!state.expandedSurveys.includes(surveyId)) state.expandedSurveys.push(surveyId);
      });
    },
    collapseSurvey: (state, { payload: surveyId }) => {
      if (state.expandedSurveys.includes(surveyId))
        state.expandedSurveys = state.expandedSurveys.filter((_surveyId) => _surveyId !== surveyId);
    },
    collapseAllSurveys: (state) => {
      state.expandedSurveys = [];
    },
    surveySaved: (state, { payload }) => {
      const { patientId, templateId, surveyId } = payload;
      state.savedSurveys[`${patientId}-${templateId}-${surveyId}`] = Date.now();
    },
    cacheSurvey: (state, { payload }) => {
      const { patientId, templateId, surveyId, snapshot } = payload;
      state.cachedSurveys[`${patientId}-${templateId}-${surveyId}`] = {
        patientId,
        templateId,
        surveyId,
        ...snapshot
      };
    },
    clearCachedSurvey: (state, { payload }) => {
      const { patientId, templateId, surveyId } = payload;
      delete state.cachedSurveys[`${patientId}-${templateId}-${surveyId}`];
      // business below is a side effect .. I feel like it is not 100% OK to do a side effect here
      if (state.activeSurvey.surveyId === surveyId) {
        state.activeSurvey = {
          surveyId: Object.values(state.cachedSurveys)[0]?.surveyId || null,
          sectionKey: null,
          questionKey: null
        };
      }
    },
    setActiveSurvey: (state, { payload }) => {
      state.activeSurvey = payload;
    },
    clearCachedSurveys: (state) => {
      state.cachedSurveys = {};
    },
    reset: (state) => {
      state.context = { expanded: false, patientId: null, visitId: null };
      state.expandedSurveys = [];
      state.activeSurvey = { surveyId: null, sectionKey: null, questionKey: null };
      state.cachedSurveys = {};
      state.savedSurveys = {};
    },
    syncActiveAndExpandedSurveys: (state, { payload: template }) => {
      if (!template) return;
      const surveyIds = template.surveys.map((survey) => survey.id);
      if (!surveyIds.includes(state.activeSurvey.surveyId)) {
        state.activeSurvey = { surveyId: null, sectionKey: null, questionKey: null };
      }
      state.expandedSurveys = state.expandedSurveys.filter((surveyId) => surveyIds.includes(surveyId));
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loadTemplateDefinition.fulfilled, (state, action) => {
      state.surveys[action.payload.templateId] = action.payload.templateDefinition;
    });
  }
});

export const {
  surveySaved,
  setSurveysContext,
  updateSurveysContext,
  expandSurvey,
  expandSurveys,
  collapseSurvey,
  collapseAllSurveys,
  cacheSurvey,
  clearCachedSurvey,
  clearCachedSurveys,
  setActiveSurvey,
  reset,
  syncActiveAndExpandedSurveys
} = surveysSlice.actions;

export const reducer = surveysSlice.reducer;

export default surveysSlice;
