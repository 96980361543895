import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withRouter from "../../../hoc/withRouter";
import { Button, Modal, Container, Row, Col } from "../../../ui";
import * as modalActions from "../../../actions/modalActions";
import { generatePrescriptionDocuments } from "../../../actions/prescriptionActions";
import { getDocument } from "../../../actions/documentActions";
import { loadLookup } from "../../../actions/lookupActions";

import "../../reusable/Modal.css";

export class PrintPrescriptionModal extends Component {
  handleClose = () => {
    const {
      actions: { hideModal }
    } = this.props;

    hideModal();
  };

  handlePrint = () => {
    const {
      actions: { generatePrescriptionDocuments: createPrescriptionDocuments, getDocument: fetchDocument },
      patientId,
      prescriptionIds,
      prescriberId,
      pharmacyKey
    } = this.props;

    const query = `prescriptionIds=${prescriptionIds.join("&prescriptionIds=")}`;
    createPrescriptionDocuments(patientId, query, { prescriberId, pharmacyKey })
      .then(({ prescriptionsToPrint = [] } = {}) => {
        const documentsToGet = prescriptionsToPrint.map((rx) =>
          fetchDocument(rx.DocumentId, "prescriptionToPrint", rx.DocumentContainer)
        );

        return prescriptionsToPrint.length ? Promise.all(documentsToGet) : Promise.reject();
      })
      .then((responses) => {
        responses.forEach((response) => {
          const blob = new Blob([response.document], {
            type: "application/pdf"
          });
          const fileURL = URL.createObjectURL(blob);
          const w = window.open(fileURL);
          w.print();
        });
      })
      .then(() => this.handleClose())
      .catch(() => this.handleClose());
  };

  render() {
    const { open, processing } = this.props;

    return (
      <Modal
        className="intake-modal"
        size="lg"
        backdrop="static"
        keyboard={false}
        onHide={this.handleClose}
        show={open}
      >
        <Modal.Body>
          <Container>
            <Row>
              <Col width={16}>
                <h4>Are you sure you want to print this prescription?</h4>
              </Col>
            </Row>
            <Row>
              <Col width={16}>
                <h5>This will move the selected prescription(s) to prescriptions sent.</h5>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={this.handleClose} loading={processing} style={{ marginRight: "auto" }}>
            {`Don't Print`}
          </Button>
          <Button variant="primary" loading={processing} onClick={this.handlePrint}>
            Print
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  rxAuthenticationTypes: state.lookups.rxAuthenticationTypes,
  processing: state.ajaxCallsInProgress > 0,
  currentRx: state.prescriptions.currentPrescriptions
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...modalActions,
      getDocument,
      loadLookup,
      generatePrescriptionDocuments
    },
    dispatch
  )
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrintPrescriptionModal));
