import moment from "moment";
import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function visitsReducer(state = initialState.visits, action) {
  switch (action.type) {
    case types.LOAD_PATIENT_VISITS_SUCCESS: {
      const latestVisit = action.patientVisits.visits && action.patientVisits.visits[0];
      let contextVisit = state.contextVisit;
      if (
        contextVisit &&
        ((contextVisit.visitStatus && !contextVisit.visitStatus.updatedOn) ||
          (contextVisit.prescribingProvider && !contextVisit.prescribingProvider.firstName))
      ) {
        /** this code here fills the visitStatus and prescribingProvider objexts missing data not sent in active visits api */
        const _v = action.patientVisits.visits.find((v) => v.visitId === contextVisit.visitId);
        contextVisit = { ...contextVisit, visitStatus: _v.visitStatus, prescribingProvider: _v.prescribingProvider };
      }
      return { ...state, patientVisits: action.patientVisits, latestVisit, contextVisit };
    }
    case types.SET_PATIENT_VISITS_LOADING:
      return { ...state, patientVisits: { ...state.patientVisits, loading: action.value } };
    case types.LOAD_PATIENT_ACTIVE_VISITS_SUCCESS: {
      const sortedActiveVisits = action.visits.sort((a, b) =>
        moment(a.visitTime) === moment(b.visitTime) ? 0 : moment(a.visitTime) > moment(b.visitTime) ? -1 : 1
      );

      // update context visit status and provider if changed
      const contextVisitInActive = state.contextVisit
        ? sortedActiveVisits.find((v) => v.visitId === state.contextVisit.visitId)
        : null;
      const contextVisitInAll = state.contextVisit
        ? state.patientVisits.visits.find((v) => v.visitId === state.contextVisit.visitId)
        : null;
      const contextVisit = contextVisitInActive || contextVisitInAll;
      const updatedContextVisit = contextVisit ? { ...contextVisit } : null;

      if (
        updatedContextVisit &&
        updatedContextVisit.status &&
        updatedContextVisit.providerId &&
        (!updatedContextVisit.visitStatus || !updatedContextVisit.prescribingProvider)
      ) {
        updatedContextVisit.visitStatus = { visitStatus: updatedContextVisit.status };
        updatedContextVisit.prescribingProvider = { providerId: updatedContextVisit.providerId };
      }

      return {
        ...state,
        activeVisits: sortedActiveVisits,
        contextVisit: updatedContextVisit
      };
    }
    case types.CLEAR_CONTEXT_VISIT:
      return { ...state, contextVisit: null };
    case types.CLEAR_PATIENT_RELATED_VISITS_DATA:
      return {
        ...state,
        activeVisits: [],
        contextVisit: null,
        latestVisit: null,
        patientVisits: {
          visits: []
        },
        timeline: [],
        detailedTimeline: {},
        // counselingVisitDetail: { loading: true },
        visitMedicalRecentDetail: {},
        medicationHistory: [],
        allergyHistory: [],
        familyHistory: [],
        medicalHistory: [],
        interventionHistory: [],
        listVisitSummaryToPrint: []
      };

    case types.SET_CONTEXT_VISIT: {
      const visit = state.patientVisits.visits.find((v) => v.visitId === action.visitId);
      return {
        ...state,
        contextVisit: visit
          ? {
              ...visit,
              status: visit.visitStatus ? visit.visitStatus.visitStatus : null,
              providerId: visit.prescribingProvider ? visit.prescribingProvider.providerId : null
            }
          : null
      };
    }
    case types.CREATE_VISIT_SUCCESS: {
      const newLatestVisit = {
        visitId: action.visitId,
        centerId: action.visit.centerId,
        visitType: action.visit.visitType,
        visitStatus: {
          visitStatus: action.response.VisitStatus
        },
        status: action.response.VisitStatus,
        prescribingProvider: {
          providerId: action.visit.providerId
        }
      };
      return Object.assign({}, state, {
        latestVisit: newLatestVisit,
        activeVisits: [newLatestVisit, ...state.activeVisits]
        // contextVisit: newLatestVisit
      });
    }
    case types.UPDATE_VISIT_STATUS_SUCCESS:
      return {
        ...state,
        allCenterVisits: {
          ...state.allCenterVisits,
          dataVisits: state.allCenterVisits.dataVisits.map((visit) =>
            visit.visitId === action.visitId ? { ...visit, status: action.visitStatus } : { ...visit }
          )
        }
      };
    case types.UPDATE_VISIT_PATIENT_LEVEL:
      return {
        ...state,
        patientVisits: {
          ...state.patientVisits,
          visits: state.patientVisits.visits.map((pv) => {
            if (pv.visitId === action.visitId)
              return { ...pv, [action.bh ? "bhLevel" : "level"]: { ...pv.level, levelName: action.levelName } };
            return { ...pv };
          })
        },
        activeVisits: state.activeVisits.map((v) => {
          if (v.visitId !== action.visitId) return { ...v };
          return { ...v, [action.bh ? "bhLevel" : "level"]: { ...v.level, levelName: action.levelName } };
        })
      };
    case types.UPDATE_VISIT_TYPE_SUCCESS:
      return {
        ...state,
        latestVisit: { ...state.latestVisit, visitType: action.visitType },
        patientVisits: {
          ...state.patientVisits,
          visits: state.patientVisits.visits.map((pv) => {
            if (pv.visitId === action.visitId) return { ...pv, visitType: action.visitType };
            return { ...pv };
          })
        },
        contextVisit: { ...state.contextVisit, visitType: action.visitType },
        activeVisits: state.activeVisits.map((v) => {
          if (v.visitId !== action.visitId) return { ...v };
          return { ...v, visitType: action.visitType };
        })
      };
    case types.UPDATE_PATIENT_VISIT_STATUS_STARTED: {
      return {
        ...state,
        oldVisitStatus: action.oldVisitStatus,
        latestVisit: { ...state.latestVisit, visitStatus: { visitStatus: "__LOADING__" } },
        patientVisits: {
          ...state.patientVisits,
          visits: state.patientVisits.visits.map((v) => {
            if (v.visitId !== action.visitId) return { ...v };
            return { ...v, visitStatus: { visitStatus: "__LOADING__" } };
          })
        },
        activeVisits: state.activeVisits.map((v) => {
          if (v.visitId !== action.visitId) return { ...v };
          return { ...v, status: "__LOADING__" };
        }),
        contextVisit: {
          ...state.contextVisit,
          status: "__LOADING__",
          visitStatus: { visitStatus: "__LOADING__" }
        }
      };
    }
    case types.UPDATE_PATIENT_VISIT_STATUS_SUCCESS: {
      return {
        ...state,
        oldVisitStatus: null,
        latestVisit:
          state.latestVisit.visitId !== action.visitId
            ? { ...state.latestVisit }
            : { ...state.latestVisit, visitStatus: { visitStatus: action.visitStatus } },
        patientVisits: {
          ...state.patientVisits,
          visits: state.patientVisits.visits.map((v) => {
            if (v.visitId !== action.visitId) return { ...v };
            return { ...v, visitStatus: { visitStatus: action.visitStatus } };
          })
        },
        activeVisits: state.activeVisits.map((v) => {
          if (v.visitId !== action.visitId) return { ...v };
          return { ...v, status: action.visitStatus };
        }),
        contextVisit: !state.contextVisit
          ? state.contextVisit
          : state.contextVisit.visitId !== action.visitId
          ? { ...state.contextVisit }
          : {
              ...state.contextVisit,
              status: action.visitStatus,
              visitStatus: { visitStatus: action.visitStatus }
            }
      };
    }
    case types.UPDATE_PATIENT_VISIT_STATUS_FAILED: {
      const oldVisitStatus = state.oldVisitStatus;
      return {
        ...state,
        oldVisitStatus: null,
        latestVisit: { ...state.latestVisit, visitStatus: oldVisitStatus },
        patientVisits: {
          ...state.patientVisits,
          visits: state.patientVisits.visits.map((v) => {
            if (v.visitId !== action.visitId) return { ...v };
            return { ...v, visitStatus: oldVisitStatus };
          })
        },
        activeVisits: state.activeVisits.map((v) => {
          if (v.visitId !== action.visitId) return { ...v };
          return { ...v, status: oldVisitStatus.visitStatus };
        }),
        contextVisit: {
          ...state.contextVisit,
          status: oldVisitStatus.visitStatus,
          visitStatus: oldVisitStatus
        }
      };
    }
    case types.LOAD_VISIT_SUMMARY_HEADER_SUCCESS:
      return Object.assign({}, state, { visitSummary: action.summary });
    case types.SET_ALL_CENTER_VISITS:
      return { ...state, allCenterVisits: action.allCenterVisits };
    case types.SET_CENTER_VISITS_MESSAGE:
      return { ...state, centerVisitsMessage: action.message };
    case types.UPDATE_VISITING_STATUS_SELECT_SUCCESS:
      return Object.assign({}, state, {
        visitingStatusSelected: action.statusSelected
      });
    case types.LOAD_STATUS_WATCH_VISITING_SUCCESS: {
      const visitingStatusSelected = action.status.value;
      return Object.assign({}, state, { visitingStatusSelected, isVisitStatusWatchLoaded: true });
    }
    case types.UPDATE_STATUS_WATCH_VISITING_SUCCESS:
      return Object.assign({}, state);
    case types.UPDATE_MY_PATIENT_STATUS_SELECT_SUCCESS:
      return Object.assign({}, state, {
        visitStatusFilter: action.statusSelected
      });
    case types.LOAD_STATUS_WATCH_MY_PATIENT_SUCCESS: {
      const visitStatusFilter = action.status.value;
      return Object.assign({}, state, { visitStatusFilter, isMyPatientStatusWatchLoaded: true });
    }
    case types.UPDATE_STATUS_WATCH_MY_PATIENT_SUCCESS:
      return Object.assign({}, state, {
        statusWatch: action.updateValue
      });
    case types.LOAD_AMENDMENT_BY_VISIT_ID_SUCCESS:
      return Object.assign({}, state, { visitAmendment: { amendments: action.amendments } });
    case types.CREATE_VISIT_AMENDMENT_SUCCESS:
      return Object.assign({}, state);
    case types.SET_MY_CALENDAR:
      return Object.assign({}, state, { myCalendar: action.myCalendar });
    case types.SET_PROVIDER_VISITS_MESSAGE:
      return { ...state, providerVisitsMessage: action.message };
    case types.SET_PROVIDER_APPOINTMENTS_MESSAGE:
      return { ...state, providerAppointmentsMessage: action.message };
    case types.LOAD_PATIENT_TIMELINE_SUCCESS:
      return Object.assign({}, state, { timeline: action.timeline });
    case types.FETCH_TIMELINE_ITEM_DETAILS_DONE: {
      return {
        ...state,
        detailedTimeline: { ...state.detailedTimeline, [`${action.entityType}_${action.entityId}`]: action.entity }
      };
    }
    case types.LOAD_VISIT_MEDICAL_RECENT_SUCCESS:
      return Object.assign({}, state, { visitMedicalRecentDetail: action.visitsMedicalDetail });
    case types.LOAD_VISIT_MEDICATION_SUCCESS:
      return Object.assign({}, state, {
        medicationHistory: action.medicationHistory
      });
    case types.LOAD_VISIT_FAMILY_HIST_SUCCESS:
      return Object.assign({}, state, {
        familyHistory: action.familyHistory
      });
    case types.LOAD_VISIT_ALLERGY_HIST_SUCCESS:
      return Object.assign({}, state, {
        allergyHistory: action.allergyHistory
      });
    case types.LOAD_VISIT_MEDICAL_HIST_SUCCESS:
      return Object.assign({}, state, {
        medicalHistory: action.medicalHistory
      });
    case types.LOAD_VISIT_INTERVENTION_HISTORY_SUCCESS:
      return { ...state, interventionHistory: action.interventionHistory };
    case types.LOAD_VISIT_PATIENT_HIST_SUCCESS:
      return state;
    case types.LOAD_VISIT_SIGN_PERMISSION_DETAILS_SUCCESS:
      return Object.assign({}, state, { visitSignPermissionDetail: action.visitDetail });
    case types.UPDATE_LIST_VISIT_SUMMARY_TO_PRINT:
      return Object.assign({}, state, { listVisitSummaryToPrint: action.listVisitSummaryToPrint });
    case types.LOAD_VISIT_SIGN_HISTORY_SUCCESS:
      return {
        ...state,
        visitSummary: { ...state.visitSummary, signHistory: action.signHistory }
      };
    case types.SET_FORCE_RELOAD_PATIENT_VISITS:
      return { ...state, forceReloadPatientTimelineHistory: action.value };
    case types.GET_VISIT_LOCATIONS_DONE: {
      return action.ctx === "CONTEXT"
        ? { ...state, contextVisitLocations: action.visitLocations }
        : { ...state, visitSummaryLocations: action.visitLocations };
    }
    case types.CLEAR_VISIT_LOCATIONS:
      return action.ctx === "CONTEXT"
        ? {
            ...state,
            contextVisitLocations: {
              visitSpecimenCenterId: null,
              sampleCollectionTime: null,
              isPatientHome: false,
              isProviderHome: false,
              patientCenterId: null,
              providerCenterId: null
            }
          }
        : {
            ...state,
            visitSummaryLocations: {
              visitSpecimenCenterId: null,
              sampleCollectionTime: null,
              isPatientHome: false,
              isProviderHome: false,
              patientCenterId: null,
              providerCenterId: null
            }
          };
    case types.NAVIGATE_TO_NEW_VISIT_WHEN_IT_COMES:
      return { ...state, navigateToNewVisitWhenItComes: { visitId: action.visitId, tab: action.tab } };
    default:
      return state;
  }
}

export const selectIsContextVisitInActiveVisits = (state) =>
  state.contextVisit && !!state.activeVisits.find((v) => v.visitId === state.contextVisit.visitId);
