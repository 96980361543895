import React, { Component } from "react";
import { Modal, Button } from "semantic-ui-react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import find from "lodash/find";
import withRouter from "../../../hoc/withRouter";
import * as modalActions from "../../../actions/modalActions";
import * as lookupActions from "../../../actions/lookupActions";
import * as visitActions from "../../../actions/visitActions";
import * as providerActions from "../../../actions/providerActions";
import * as groupActions from "../../../actions/groupActions";
import * as demographicActions from "../../../actions/demographicActions";
import { isoFormat } from "../../../constants/miscellaneous";
import { updateErrors, requiredFieldsFilled } from "../../../helpers";

import NewVisitForm from "./NewVisitForm";
import "./NewVisitModal.css";

const inputValidation = {
  required: ["providerId", "visitType"]
};

const inputValidationGroup = {
  required: ["providerId", "visitGroupId"]
};

export class NewVisitModal extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.centerContext !== nextProps.centerContext) {
      nextProps.actions.loadProvidersByState(nextProps.centerContext);
      return { centerContext: nextProps.centerContext };
    }
    return { centerContext: prevState.centerContext };
  }

  state = {
    centerContext: this.props.centerContext,
    visit: {
      visitType: "",
      providerId: "",
      visitGroupId: "",
      centerId: this.props.centerId
    },
    mainPrescriber: this.props.currentProvider,
    errors: {},
    groupParams: {
      dateTime: moment().format("MM/DD/YYYY")
    }
  };

  componentDidMount() {
    const {
      homeCenterId,
      actions: { loadVisitTypes, loadProvidersByState, loadGroups },
      centerContext
    } = this.props;
    const { groupParams } = this.state;
    loadVisitTypes();
    loadProvidersByState(centerContext || this.props.centerId);
    loadGroups({ ...groupParams, centerId: homeCenterId });
    this.props.actions.loadPrescriberSlots();
  }

  componentWillUnmount() {
    const { centerContext, patient } = this.props;
    this.props.actions.loadProvidersByState(centerContext || this.props.centerId);
    // Reload visit
    if (patient.patientId) {
      this.props.actions.loadPatientVisits(patient.patientId);
    }
  }

  handleGroupModalOpen = () => {
    const { visit } = this.state;
    return this.props.actions.showModal({
      type: "NEW_GROUP",
      props: { open: true, visit, withVisit: true }
    });
  };

  groupByProvider = (groups) => {
    const { providerId } = this.state.visit;
    if (providerId) {
      const providerGroups = groups.filter((group) => group.providerId === providerId);
      return providerGroups;
    }
    return [];
  };

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleSave = () => {
    const {
      patient,
      patientStatus,
      actions: { setDefaultPrescribingProvider }
    } = this.props;
    const { visit, mainPrescriber } = this.state;
    // if is visit for new patient
    if (this.props.newPatient) {
      this.props.actions.createVisit(visit, this.props.patient.patientId).then((response) => {
        if (typeof response === "undefined") {
          // TODO check this logic
          if ((visit.visitType === "initial" || visit.visitType === "rejoin") && patientStatus === "Discharged") {
            this.props.actions.updateDischargeSuccess(patient.patientId, false);
          }

          if (mainPrescriber.providerId) {
            setDefaultPrescribingProvider(patient.patientId, mainPrescriber.providerId);
          }
        }
        this.props.actions.showModal({
          type: "CORE_DEMOGRAPHICS",
          props: { open: true, newPatient: true }
        });
      });
      // else visit for exisiting patient in the system
    } else {
      this.props.actions.createVisit(visit, this.props.patient.patientId).then((response) => {
        if (typeof response === "undefined") {
          // TODO check this logic
          if ((visit.visitType === "initial" || visit.visitType === "rejoin") && patientStatus === "Discharged") {
            this.props.actions.updateDischargeSuccess(patient.patientId, false);
          }

          if (mainPrescriber.providerId) {
            setDefaultPrescribingProvider(patient.patientId, mainPrescriber.providerId);
          }
        }
        this.handleCancel();
      });
    }
  };

  handleCancel = () => {
    const {
      router,
      patient: { patientId },
      actions: { hideModal }
    } = this.props;
    hideModal();
    if (router?.location?.pathname !== `/patient/${patientId}/demographics`)
      router.navigate({
        pathname: `/patient/${patientId}/demographics`
      });
  };

  handleInput = (e, data) => {
    const { name: field, value, required } = data || e.target;
    const errors = updateErrors(field, value, required, this.state.errors, inputValidation[field]);
    const visit = Object.assign({}, this.state.visit, { [field]: value });
    this.setState({
      visit,
      errors
    });
  };

  handlePrescriber = (e, data) => {
    const { value, options } = data;
    const selectedPrescriber = find(options, { value });
    const mainPrescriber = Object.assign({}, this.state.mainPrescriber, {
      providerId: value,
      firstName: selectedPrescriber.fname,
      lastName: selectedPrescriber.lname
    });

    this.setState({ mainPrescriber });
  };

  render() {
    const { visit } = this.state;
    const {
      open,
      patient: { patientFirstName, patientLastName },
      providers,
      visitTypeOptions,
      prescriberSlots,
      loading,
      groups,
      patientStatus
    } = this.props;
    const { mainPrescriber } = this.state;
    const requiredFieldsCheck = requiredFieldsFilled(inputValidation.required, visit);
    const requiredFieldsCheckGroup = requiredFieldsFilled(inputValidationGroup.required, visit);
    const disabled = visit.visitType === "group" ? requiredFieldsCheckGroup : requiredFieldsCheck;
    return (
      <React.Fragment>
        <Modal open={open} size={"small"} className="new-visit-modal" closeIcon onClose={this.handleCancel}>
          <Modal.Header>{`New Visit: ${patientFirstName} ${patientLastName}`}</Modal.Header>
          <Modal.Content>
            <NewVisitForm
              visit={visit}
              onChange={this.handleInput}
              onChangePrescriber={this.handlePrescriber}
              patientStatus={patientStatus}
              onToggle={this.handleToggle}
              providers={providers}
              visitTypeOptions={visitTypeOptions}
              prescriberSlots={prescriberSlots}
              mainPrescriber={mainPrescriber}
              groups={groups}
              groupByProvider={this.groupByProvider}
              handleFocus={this.handleFocus}
              handleGroupModalOpen={this.handleGroupModalOpen}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleCancel} content="Cancel" id="button-cancel" />
            <Button
              color="blue"
              onClick={this.handleSave}
              content="Save"
              disabled={disabled || loading}
              loading={loading}
              id="button-save"
            />
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
}

function providersFormattedDropdown(providers) {
  return providers.map((provider) => ({
    value: provider.userId,
    text: `${provider.firstName} ${provider.lastName}`
  }));
}

function groupsFormattedDropdown(groups) {
  return groups.map((group) => ({
    value: group.groupId,
    text: moment(group.visitGroupTime, isoFormat).format("hh:mm a"),
    providerId: group.providerId
  }));
}
function mainPrecribersFormattedDropdown(prescribersWithSlots) {
  return prescribersWithSlots.map(({ platformId, firstName, lastName, numberOfAvailableSlots }) => ({
    value: platformId,
    text: `${firstName} ${lastName} (${numberOfAvailableSlots})`,
    fname: firstName,
    lname: lastName,
    disabled: numberOfAvailableSlots === 0
  }));
}

function mapStateToProps(state, ownProps) {
  let patient = {
    patientFirstName: "",
    patientLastName: "",
    patientId: ""
  };

  patient = ownProps.newPatient ? state.patient.newPatient : state.patient.currentPatient;

  return {
    visitTypeOptions: state.lookups.visitTypes.map((i) => ({ text: i.text, value: i.value })),
    prescriberSlots: mainPrecribersFormattedDropdown(state.lookups.prescribersWithSlots),
    providers: providersFormattedDropdown(state.providers.allProviders),
    currentProvider: {},
    patientStatus: state.demographics.status,
    patient,
    groups: groupsFormattedDropdown(state.groups),
    loading: state.ajaxCallsInProgress > 0,
    centerContext: state.userPreference.centerContext,
    homeCenterId: (state.demographics || {}).homeCenterId
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...lookupActions,
        ...visitActions,
        ...providerActions,
        ...groupActions,
        ...demographicActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NewVisitModal));
