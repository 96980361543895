import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { API_URL } from "../constants/environment";
import { processApiResponse, processApiError, processThenThrowApiError } from "../helpers";
import { adalApiFetch } from "../adalConfig";
// import apiSlice from "../api/apiSlice";

// TODO p2 move these two functions to templatesSlice and get rid of this file

export function saveSurvey(visitId, templateId, surveyId, patientId, surveyAnswers) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createSurvey"));
    return (
      adalApiFetch(`${API_URL}/Templates/${templateId}/Surveys/${surveyId}/patients/${patientId}`, {
        method: "POST",
        body: JSON.stringify(surveyAnswers)
      })
        .then((response) => processApiResponse(response.orchestrationId))
        // .then((orchestrationId) => pollApiEvents(orchestrationId, "ISurveyResultAddedEvent"))
        // .then(() =>
        //   dispatch(
        //     apiSlice.util.invalidateTags([
        //       `Template-${patientId}-${visitId}`,
        //       `Template-${patientId}-${templateId}`,
        //       `Template-${patientId}-${templateId}-${visitId}`
        //     ])
        //   )
        // )
        .catch((error) => processApiError(error, dispatch))
        .finally(() => dispatch(ajaxCallDone()))
    );
  };
}

export function search(searchUrl, keyword) {
  return (dispatch) => {
    dispatch(beginAjaxCall("search"));
    return adalApiFetch(`${API_URL}${searchUrl.replace(`{keyword}`, keyword)}`)
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}
