import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { cloneDeep } from "lodash";
import ErrorLogSlider from "./ErrorLogSlider";

// Actions
import * as sidebarErrorLogActions from "../../actions/sidebarErrorLogActions";

export class SidebarErrorLog extends Component {
  componentDidMount() {}

  closeSlider = () => {
    this.props.actions.closeSliderPanel();
  };

  dismissItem = (item) => {
    this.props.actions.dismissItemErrorLog(item);
  };

  dismissAll = () => {
    toast.dismiss();
    this.props.actions.dismissAllErrorLog();
  };

  render() {
    const { sliderVisible, errors } = this.props;
    return (
      <React.Fragment>
        <ErrorLogSlider
          sliderVisible={sliderVisible}
          sliderClose={this.closeSlider}
          errors={cloneDeep(errors)}
          dismissAll={this.dismissAll}
          dismissItem={this.dismissItem}
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    sliderVisible: state.sidebarErrorLog.sliderVisible,
    errors: state.sidebarErrorLog.errors
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...sidebarErrorLogActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarErrorLog);
