import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Message, Button } from "../ui";
import * as userPreferenceActions from "../actions/userPreferenceActions";
import { clearCachesAndReload } from "../helpers";

class NewVersionAlert extends Component {
  componentDidMount() {
    this.init();
  }
  componentDidUpdate() {
    this.init();
  }

  init = () => {
    const { reloadPageIn, forceUpdate } = this.props;
    if (reloadPageIn === 0) {
      clearCachesAndReload();
    }
    if (forceUpdate && reloadPageIn > -1) {
      setTimeout(() => {
        this.props.actions.setReloadPageIn(reloadPageIn - 1, forceUpdate);
      }, 1000);
    }
  };

  render() {
    const {
      reloadPageIn,
      forceUpdate,
      newVersion: { version, build }
    } = this.props;
    return reloadPageIn > -1 && forceUpdate ? (
      <div className="page-wrapper">
        <Message variant="danger" className="m-0 p-2 ps-4">
          {`A mandatory update v${version}, b${build} found, page is going to reload in `}
          {reloadPageIn <= 5 ? <strong>{reloadPageIn}</strong> : reloadPageIn} seconds, please save any unsaved work
        </Message>
      </div>
    ) : reloadPageIn > -1 ? (
      <div className="page-wrapper">
        <Message variant="warning" className="m-0 p-2 ps-4">
          {`New release v${version}, b${build} detected `}
          <Button
            variant="outline-danger"
            size="sm"
            onClick={() => {
              clearCachesAndReload();
            }}
            className="mx-1"
          >
            Update
          </Button>
          <Button
            variant="outline-info"
            size="sm"
            onClick={() => this.props.actions.setReloadPageIn(-1, false)}
            className="mx-1"
          >
            Later
          </Button>
          <strong>please save any unsaved work before hitting update</strong>
        </Message>
      </div>
    ) : null;
  }
}

function mapStateToProps(state) {
  return {
    reloadPageIn: state.userPreference.reloadPageIn,
    forceUpdate: state.userPreference.forceUpdate,
    newVersion: state.userPreference.newVersion
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...userPreferenceActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewVersionAlert);
