import React, { lazy, Component } from "react";
import { Routes, Route, BrowserRouter, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import IdleTimer from "react-idle-timer";
import Redirect from "./Redirect";
import BlankLayout from "./BlankLayout";
import DefaultLayout from "./DefaultLayout";
import PatientSubLayout from "./patients/PatientSubLayout";
import { logout } from "../adalConfig";
import webSocket from "../ws/index";
import "./App.css";
import { checkRoles } from "../helpers";
import { roleGroups } from "../constants/securityRoles";
import MigratePatientCenter from "./migrate-patient-center";

const Admin = lazy(() => import("./admin"));
const Stratus = lazy(() => import("./stratus"));
const LoginPage = lazy(() => import("./Login"));
const MyPatientsPage = lazy(() => import("./patients/myPatients/MyPatientsPage"));
const VisitingPatientsPage = lazy(() => import("./patients/visitingPatients/VisitingPatientsPage"));
const SearchResultsPage = lazy(() => import("./search/SearchResultsPage"));
const TasksDashboard = lazy(() => import("./tasks"));
const RequestedLabs = lazy(() => import("./requested-labs/RequestedLabs"));
const GroupVisitsPage = lazy(() => import("./groups/GroupVisitsPage"));
const ChartsPage = lazy(() => import("./charts/ChartsPage"));
const ReportDashboard = lazy(() => import("./report/ReportDashboard"));
const ReportPageBI = lazy(() => import("./report/ReportPage"));
const ClaimReconciliationsReport = lazy(() => import("./report/ClaimReconciliationsReport"));
const LabOrderStatusReport = lazy(() => import("./report/LabOrderStatusReport"));
const PatientsPendingDischargeReport = lazy(() => import("./report/PatientsPendingDischargeReport"));
const AppointmentReminderConfirmationReport = lazy(() => import("./report/AppointmentReminderConfirmationReport"));
const ManageClaimErrorsPage = lazy(() => import("./error/ManageClaimErrorsPage"));
const ReviewOfPatients = lazy(() => import("./report/ReviewOfPatients"));
const PrescriptionStatusReport = lazy(() => import("./report/PrescriptionStatusReport"));
const PrintedPrescriptionReport = lazy(() => import("./report/PrintedPrescriptionReport"));
const ExostarAuditLog = lazy(() => import("./report/ExostarAuditLog"));
const PrescriptionsToSign = lazy(() => import("./prescriptions/PrescriptionsToSign"));
const ChartsToCoSignPage = lazy(() => import("./cosign/ChartsToCoSignPage"));
const EpcsCancel = lazy(() => import("./admin/EpcsCancel"));
const ActiveControlledSubstancePatients = lazy(() => import("./report/ActiveControlledSubstancePatients"));
const ReportsLandingPage = lazy(() => import("./report/ReportsLandingPage"));

class App extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
  }

  state = {
    isMobile: false,
    sessionTimeout: -1
  };

  componentDidMount() {
    localStorage.setItem("ARES_LAST_ACTIVE_TIME", +new Date());
    this.updateWindowWidth();
    window.addEventListener("resize", this.updateWindowWidth);
    window.addEventListener("beforeunload", this.handleUnload);
    webSocket.connectSocket();
  }

  updateWindowWidth = () => {
    if (window.innerWidth < 767) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  onAction = () => {
    this.setState({ sessionTimeout: -1 });
    localStorage.setItem("ARES_LAST_ACTIVE_TIME", this.idleTimer.getLastActiveTime());
  };

  onIdle = () => {
    if ((+new Date() - +localStorage.getItem("ARES_LAST_ACTIVE_TIME")) / (1000 * 60) >= this.props.sessionTimeout) {
      logout();
    } else {
      this.setState(
        {
          sessionTimeout:
            1000 * 60 * this.props.sessionTimeout - (+new Date() - +localStorage.getItem("ARES_LAST_ACTIVE_TIME"))
        },
        () => {
          this.idleTimer.reset();
        }
      );
    }
  };

  handleUnload = () => {
    webSocket.closeSocket();
    return "cleared";
  };

  render() {
    const { isProvider, sessionTimeout } = this.props;
    const { isMobile, sessionTimeout: stateSessionTimeOut } = this.state;
    const childRoutes = (
      <>
        <Route index element={isProvider ? <MyPatientsPage /> : <VisitingPatientsPage />} />
        <Route path={`login`} element={<LoginPage />} />
        <Route path={`visiting-patients`} element={<VisitingPatientsPage />} />
        <Route path={`my-patients`} element={<MyPatientsPage />} />
        <Route path={`patient`} element={<Outlet />}>
          {PatientSubLayout}
        </Route>
        <Route path={`current-patients/*`} element={<Redirect from="current-patients" to="patient" />} />
        <Route path={`group-patients`} element={<GroupVisitsPage />} />
        <Route path={`admin/*`} element={<Admin />} />
        <Route path={`stratus/*`} element={<Stratus />} />
        <Route path={`search`} element={<SearchResultsPage />} />
        <Route path={`tasks`} element={<TasksDashboard />} />
        <Route path={`requested-labs`} element={<RequestedLabs />} />
        <Route path={`charts`} element={<ChartsPage />} />
        <Route path={`charts-cosign`} element={<ChartsToCoSignPage isMobile={isMobile} />} />
        <Route path={`prescriptions-to-sign`} element={<PrescriptionsToSign isMobile={isMobile} />} />
        <Route path={`report`} element={<ReportDashboard />}>
          <Route index element={<ReportsLandingPage />} />
          <Route path={`athena-claim-error/*`} element={<ManageClaimErrorsPage athenaServiceErrorsOnly />} />
          <Route path={`center-claim-error/*`} element={<ManageClaimErrorsPage athenaServiceErrorsOnly={false} />} />
          <Route path={`lab-order-status/*`} element={<LabOrderStatusReport />} />
          <Route path={`patients-pending-discharge-report/*`} element={<PatientsPendingDischargeReport />} />
          <Route
            path={`appointment-reminder-confirmation-report/*`}
            element={<AppointmentReminderConfirmationReport />}
          />
          <Route path={`claim-reconciliations-report/*`} element={<ClaimReconciliationsReport />} />
          <Route path={`review-of-patients/*`} element={<ReviewOfPatients />} />
          <Route path={`prescription-status/*`} element={<PrescriptionStatusReport />} />
          <Route path={`exostar-audit-log/*`} element={<ExostarAuditLog />} />
          <Route path={`printed-prescription-report/*`} element={<PrintedPrescriptionReport />} />
        </Route>
        <Route path={`report-details/:reportId`} element={<ReportPageBI />} />
        <Route path={`epcs/cancel/*`} element={<EpcsCancel />} />
        <Route
          path={`active-controlled-substance-patients-report/:reportId`}
          element={<ActiveControlledSubstancePatients />}
        />
        <Route path={`migrate-patient-center`} element={<MigratePatientCenter />} />
      </>
    );
    return (
      <React.Fragment>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          element={document}
          // onActive={this.onActive}
          onAction={this.onAction}
          onIdle={this.onIdle}
          debounce={250}
          timeout={stateSessionTimeOut > -1 ? stateSessionTimeOut : 1000 * 60 * sessionTimeout}
        />
        <BrowserRouter>
          <Routes>
            <Route path={`/b`} element={<BlankLayout />}>
              {childRoutes}
            </Route>
            <Route path={`/s`} element={<DefaultLayout showSearchBar={false} />}>
              {childRoutes}
            </Route>
            <Route path="/" element={<DefaultLayout />}>
              {childRoutes}
            </Route>
          </Routes>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { auth: { user: { profile: { roles = [] } = {} } = {} } = {} } = state;
  const isProvider = checkRoles(roleGroups.medicalProvider, roles);
  return {
    isProvider,
    sessionTimeout: state.userPreference.SessionTimeout ? state.userPreference.SessionTimeout : 15
  };
}

export default connect(mapStateToProps, null)(App);
