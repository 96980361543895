import React, { Component } from "react";
import { Modal, Button, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty, isEqual } from "lodash";
import * as modalActions from "../../../actions/modalActions";
import * as templateActions from "../../../actions/templateActions";
import * as visitActions from "../../../actions/visitActions";
import * as contextActions from "../../../actions/contextActions";
import { Message } from "../../../ui";
import { isTelephonVisit, isZoomVisit } from "../../../helpers";

export class TelemedDataModal extends Component {
  state = {
    visitSpecimenCenterId: this.props.visitLocations.visitSpecimenCenterId,
    sampleCollectionTime: this.props.visitLocations.sampleCollectionTime,
    isOrderSubmitted: this.props.visitLocations.isOrderSubmitted,
    isPatientHome: this.props.visitLocations.isPatientHome,
    isProviderHome: this.props.visitLocations.isProviderHome,
    patientCenterId: this.props.visitLocations.patientCenterId,
    providerCenterId: this.props.visitLocations.providerCenterId,
    facilitatedBy: this.props.visitLocations.facilitatedBy || ""
  };

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/no-did-update-set-state
    if (!isEqual(prevProps.visitLocations, this.props.visitLocations)) this.setState({ ...this.props.visitLocations });
  }

  handleSave = () => {
    const { patientId, visitId } = this.props;
    this.props.actions.setVisitLocations(patientId, visitId, this.state).then(() => {
      this.handleCancel();
    });
  };

  handleCancel = () => this.props.actions.hideModal();

  handleDropdownChange = (e, data) => {
    const { value } = data;
    this.setState({ [data.name]: value });
  };

  handleRadioChange = (e, data) => {
    this.setState({ [data.name]: data.value === "true" }, () => {
      if (this.state.isPatientHome === true) this.setState({ patientCenterId: null });
      if (this.state.isProviderHome === true) this.setState({ providerCenterId: null });
    });
  };

  render() {
    const {
      visitSpecimenCenterId,
      isOrderSubmitted,
      isPatientHome,
      patientCenterId,
      isProviderHome,
      providerCenterId,
      facilitatedBy
    } = this.state;
    const { open, processing, centerOptions, visitTypeObject, facilitatedByOptions } = this.props;
    const isMedicalVisit = visitTypeObject.medical;
    const showFacilitatedBy = [
      "telemedsudcounseling",
      "telemedmentalhealthcounseling",
      "telemedcarecoordination",
      "telemedcertrecoveryspecialist",
      "telemedintake"
    ].includes(visitTypeObject.value);
    const telemedDateMissing =
      (isMedicalVisit && !visitSpecimenCenterId) ||
      (!isPatientHome && !patientCenterId) ||
      (!isProviderHome && !providerCenterId) ||
      (showFacilitatedBy && isEmpty(facilitatedBy));

    return (
      <Modal open={open} size="small" closeIcon closeOnDimmerClick={false} onClose={this.handleCancel}>
        <Modal.Header>{`Telemed Data`}</Modal.Header>
        <Modal.Content>
          {isZoomVisit(visitTypeObject.value) && (
            <Message variant="primary" className="p-2 ps-4">
              {`This is a `}
              <strong>{`telemedicine`}</strong>
              {` visit conducted via `}
              <strong>{`ZOOM`}</strong>
              {`. Patient's identity verified via full name and DOB.`}
            </Message>
          )}
          {isTelephonVisit(visitTypeObject.value) && (
            <Message variant="primary" className="p-2 ps-4">
              {`This is a `}
              <strong>{`telemedicine`}</strong>
              {` visit conducted via `}
              <strong>{`telephone`}</strong>
              {`. Patient's identity verified via full name and DOB.`}
            </Message>
          )}
          <Form>
            {isMedicalVisit && (
              <Form.Group widths="equal">
                <Form.Field width={7}>
                  <label style={{ marginTop: "1em" }}>Specimen Collection Center</label>
                </Form.Field>
                <Form.Field width={9}>
                  <Form.Dropdown
                    fluid
                    search
                    label=""
                    selection
                    options={centerOptions}
                    name="visitSpecimenCenterId"
                    value={visitSpecimenCenterId}
                    onChange={this.handleDropdownChange}
                    disabled={isOrderSubmitted}
                  />
                </Form.Field>
              </Form.Group>
            )}
            <Form.Group widths="equal">
              <Form.Field width={7}>
                <label>{`Patient Site`}</label>
                <Form.Radio
                  label="At Home"
                  name="isPatientHome"
                  value="true"
                  checked={isPatientHome}
                  onChange={this.handleRadioChange}
                  inline
                />
                <Form.Radio
                  label="At Center"
                  name="isPatientHome"
                  value="false"
                  style={{ marginLeft: "1em" }}
                  checked={!isPatientHome}
                  onChange={this.handleRadioChange}
                  inline
                />
              </Form.Field>
              <Form.Field width={9}>
                <Form.Dropdown
                  fluid
                  search
                  style={{ marginTop: "1em" }}
                  selection
                  options={centerOptions}
                  name="patientCenterId"
                  value={patientCenterId}
                  onChange={this.handleDropdownChange}
                  disabled={isPatientHome}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field width={7}>
                <label>{`Provider Site`}</label>
                <Form.Radio
                  label="At Home"
                  name="isProviderHome"
                  value="true"
                  checked={isProviderHome}
                  onChange={this.handleRadioChange}
                  inline
                />
                <Form.Radio
                  label="At Center"
                  name="isProviderHome"
                  value="false"
                  style={{ marginLeft: "1em" }}
                  checked={!isProviderHome}
                  onChange={this.handleRadioChange}
                  inline
                />
              </Form.Field>
              <Form.Field width={9}>
                <Form.Dropdown
                  fluid
                  search
                  style={{ marginTop: "1em" }}
                  selection
                  options={centerOptions}
                  name="providerCenterId"
                  value={providerCenterId}
                  onChange={this.handleDropdownChange}
                  disabled={isProviderHome}
                />
              </Form.Field>
            </Form.Group>

            {showFacilitatedBy && (
              <Form.Group widths="equal" style={{ borderTop: "1px solid rgba(34,36,38,.15)" }}>
                <Form.Field width={7}>
                  <label style={{ marginTop: "1em" }}>Facilitated by</label>
                </Form.Field>
                <Form.Field width={9}>
                  <Form.Dropdown
                    fluid
                    search
                    label=""
                    selection
                    options={facilitatedByOptions}
                    name="facilitatedBy"
                    value={facilitatedBy}
                    onChange={this.handleDropdownChange}
                  />
                </Form.Field>
              </Form.Group>
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleCancel} content="Cancel" />
          <Button
            color="blue"
            onClick={this.handleSave}
            content="Save"
            loading={processing}
            disabled={processing || telemedDateMissing}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    processing: state.ajaxCallsInProgress > 0,
    centerOptions: state.lookups.centers.map((i) => ({ text: i.text, value: i.value })),
    visitLocations: state.visits.contextVisitLocations,
    facilitatedByOptions: [
      { text: "Audio", value: "audio" },
      { text: "Video", value: "video" }
    ]
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...templateActions,
        ...visitActions,
        ...contextActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TelemedDataModal);
