/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: { useNewSurveysUi: true },
  reducers: {
    setUseNewSurveysUi: (state, action) => {
      state.useNewSurveysUi = action.payload;
    }
  }
});

export const { setUseNewSurveysUi } = settingsSlice.actions;

export default settingsSlice.reducer;
