/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useRef } from "react";
import styles from "./Select.module.css";

const Select = React.forwardRef((props, ref) => {
  const {
    name = "",
    multiple,
    placeholder = "Select...",
    disabled,
    searchable,
    className,
    isValid,
    isInvalid,
    minCharacters = 1,
    clearable = false,
    closeOnBlur = true,
    selectOnBlur = true,
    selectOnNavigation = true
  } = props;

  let isMouseDown = false;

  const [isOpen, setIsOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [internalOptions, setInternalOptions] = useState(props.options);
  const [internalValue, setInternalValue] = useState(props.value);
  const [searchQuery, setSearchQuery] = useState("");
  const [upward, setUpward] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const containerRef = useRef(null);
  const searchRef = useRef(null);

  useEffect(() => {
    setInternalValue(props.value);
  }, [props.value]);

  useEffect(() => {
    setInternalOptions(props.options);
  }, [props.options.length]); // TODO is it better to use _.isEqual

  useEffect(() => {
    if (!isOpen) handleClose();
    if (isOpen) {
      setOpenDirection();
      scrollSelectedItemIntoView();
    }
  }, [isOpen]);

  useEffect(() => {
    scrollSelectedItemIntoView();
  }, [selectedIndex]);

  // text // TODO fix multi later
  let text = null;
  if (multiple) {
    text = internalValue.map((v) => {
      const op = internalOptions.find((o) => o.value === v);
      return op ? (
        <React.Fragment key={op.value}>
          {op.text}
          <span className={styles["remove-btn"]}>&times;</span>
        </React.Fragment>
      ) : null;
    });
  } else {
    text = internalOptions.find((o) => o.value === internalValue)?.text;
  }

  const setOpenDirection = () => {
    if (!containerRef.current) return;

    const menu = containerRef.current.querySelector(`.${styles.menu}.${styles.visible}`);

    if (!menu) return;

    const dropdownRect = containerRef.current.getBoundingClientRect();
    const menuHeight = menu.clientHeight;
    const spaceAtTheBottom =
      document.documentElement.clientHeight - dropdownRect.top - dropdownRect.height - menuHeight;
    const spaceAtTheTop = dropdownRect.top - menuHeight;

    const _upward = spaceAtTheBottom < 0 && spaceAtTheTop > spaceAtTheBottom;

    if (!_upward !== !upward) {
      setUpward(_upward);
    }
  };

  const scrollSelectedItemIntoView = () => {
    if (!containerRef.current) return;
    const menu = containerRef.current.querySelector(`.${styles.menu}.${styles.visible}`);
    if (!menu) return;
    const item = menu.querySelector(`.${styles.item}.${styles.selected}`);
    if (!item) return;
    const isOutOfUpperView = item.offsetTop < menu.scrollTop;
    const isOutOfLowerView = item.offsetTop + item.clientHeight > menu.scrollTop + menu.clientHeight;

    if (isOutOfUpperView) {
      menu.scrollTop = item.offsetTop;
    } else if (isOutOfLowerView) {
      menu.scrollTop = item.offsetTop + item.clientHeight - menu.clientHeight;
    }
  };

  const clearSearchQuery = () => {
    setSearchQuery("");
  };

  const open = (e = null, triggerSetState = true) => {
    if (disabled) return;
    if (searchable) searchRef.current.focus();

    if (props.onOpen && typeof props.onOpen === "function") props.onOpen(e, props);

    if (triggerSetState) {
      setIsOpen(true);
    }
    scrollSelectedItemIntoView();
  };

  const close = (e) => {
    if (isOpen) {
      if (props.onClose && typeof props.onClose === "function") props.onClose(e, props);
      setIsOpen(false);
      setInternalOptions(props.options);
    }
  };

  const toggle = (e) => (isOpen ? close(e) : open(e));

  const handleClose = () => {
    const hasSearchFocus = document.activeElement === searchRef.current;
    if (!hasSearchFocus && containerRef.current) {
      containerRef.current.blur();
    }

    const hasDropdownFocus = document.activeElement === containerRef.current;
    const hasFocus = hasSearchFocus || hasDropdownFocus;
    setIsFocus(hasFocus);
  };

  const handleChange = (e, newValue) => {
    if (props.onChange && typeof props.onChange === "function") {
      props.onChange({ ...e, target: { ...e.target, ...props, value: newValue } });
    }
  };

  const makeSelectedItemActive = (e) => {
    const item = internalOptions[selectedIndex];
    const selectedValue = item?.value;

    if (!selectedValue || !isOpen || item.disabled) {
      return internalValue;
    }

    const newValue = multiple ? [...new Set([...internalValue, selectedValue])] : selectedValue;
    const valueHasChanged = multiple ? !internalValue.includes(selectedValue) : newValue !== internalValue;

    if (valueHasChanged) {
      setInternalValue(newValue);
      handleChange(e, newValue);

      // TODO
      // if (item["data-additional"] && props.onAddItem && typeof props.onAddItem === "function") {
      //   props.onAddItem(e, { ...props, value: selectedValue });
      // }
    }

    return internalValue;
  };

  const handleClearIconClick = (e) => {
    e.stopPropagation();
    const hasValue = (multiple && Array.isArray(internalValue) && internalValue.length) || internalValue;
    if (clearable && hasValue) {
      const newValue = multiple ? [] : "";
      setInternalValue(newValue);
      handleChange(e, newValue);
    }
  };

  const handleDocumentMouseUp = () => {
    isMouseDown = false;
    document.removeEventListener("mouseup", handleDocumentMouseUp);
  };

  const handleMouseDown = () => {
    isMouseDown = true;
    if (props.onMouseDown && typeof props.onMouseDown === "function") props.onMouseDown();
    document.addEventListener("mouseup", handleDocumentMouseUp);
  };

  const handleBlur = (e) => {
    const currentTarget = e.currentTarget;
    if (currentTarget && currentTarget.contains(document.activeElement)) return;

    if (isMouseDown) return;

    if (props.onBlur && typeof props.onBlur === "function") props.onBlur();

    if (selectOnBlur && !multiple) {
      makeSelectedItemActive(e);
      if (closeOnBlur) close();
    }

    setIsFocus(false);
    clearSearchQuery();
  };

  const handleSearchChange = (e) => {
    e.stopPropagation();

    const newQuery = e.target.value;

    if (props.onSearchChange && typeof props.onSearchChange === "function") {
      props.onSearchChange(e, { ...props, searchQuery: newQuery });
    }

    setSearchQuery(newQuery);
    setInternalOptions(props.options.filter((option) => option.text.toLowerCase().includes(newQuery.toLowerCase())));
    setSelectedIndex(0);

    if (!isOpen && newQuery.length >= minCharacters) {
      open();
      return;
    }
    if (open && minCharacters !== 1 && newQuery.length < minCharacters) close();
  };

  const getSelectedIndexAfterMove = (offset, startIndex = selectedIndex) => {
    if (internalOptions === undefined || internalOptions === [] || internalOptions.every((option) => option.disabled))
      return;

    const lastIndex = internalOptions.length - 1;
    const { wrapSelection } = props;
    let nextIndex = startIndex + offset;

    if (!wrapSelection && (nextIndex > lastIndex || nextIndex < 0)) {
      nextIndex = startIndex;
    } else if (nextIndex > lastIndex) {
      nextIndex = 0;
    } else if (nextIndex < 0) {
      nextIndex = lastIndex;
    }

    if (internalOptions[nextIndex].disabled) {
      return getSelectedIndexAfterMove(offset, nextIndex);
    }

    return nextIndex;
  };

  const moveSelectionOnKeyDown = (e) => {
    if (!isOpen) {
      return;
    }
    const moves = {
      ArrowDown: 1,
      ArrowUp: -1
    };
    const move = moves[e.code];

    if (move === undefined) {
      return;
    }

    e.preventDefault();
    const nextIndex = getSelectedIndexAfterMove(move);

    setSelectedIndex(nextIndex);

    if (!multiple && selectOnNavigation) {
      makeSelectedItemActive(e);
    }
  };

  const openOnArrow = (e) => {
    if (isFocus && !isOpen) {
      if (e.code === "ArrowDown" || e.code === "ArrowUp") {
        e.preventDefault();
        open(e);
      }
    }
  };

  const openOnSpace = (e) => {
    const shouldHandleEvent = isFocus && !isOpen && e.code === "Space";
    const shouldPreventDefault =
      e.target?.tagName !== "INPUT" && e.target?.tagName !== "TEXTAREA" && e.target?.isContentEditable !== true;

    if (shouldHandleEvent) {
      if (shouldPreventDefault) {
        e.preventDefault();
      }

      open(e);
    }
  };

  const closeOnChange = (e) => {
    const shouldClose = props.closeOnChange === undefined ? !multiple : props.closeOnChange;
    if (shouldClose) {
      close(e);
    }
  };

  const selectItemOnEnter = (e) => {
    if (!isOpen) {
      return;
    }

    const shouldSelect = e.code === "Enter" || (!searchable && e.code === "Space");

    if (!shouldSelect) {
      return;
    }

    e.preventDefault();

    if (searchable && internalOptions.length === 0) {
      return;
    }

    makeSelectedItemActive(e);

    closeOnChange(e);
    clearSearchQuery();

    if (searchable) {
      searchRef.current.focus();
    }
  };

  const handleKeyDown = (e) => {
    moveSelectionOnKeyDown(e);
    openOnArrow(e);
    openOnSpace(e);
    selectItemOnEnter(e);

    if (props.onKeyDown && typeof props.onKeyDown === "function") props.onKeyDown(e);
  };

  const handleClick = (e) => {
    if (props.onClick && typeof props.onClick === "function") props.onClick(e, props);

    e.stopPropagation();

    if (!searchable) return toggle(e);
    if (isOpen) {
      searchRef.current.focus();
      return;
    }
    if (searchQuery.length >= minCharacters || minCharacters === 1) {
      open(e);
      return;
    }
    searchRef.current.focus();
  };

  const handleFocus = (e) => {
    if (disabled) {
      setIsFocus(false);
      return;
    }
    if (isFocus) return;
    if (props.onFocus && typeof props.onFocus === "function") props.onFocus(e, props);
    setIsFocus(true);
  };

  const handleItemClick = (e, option) => {
    e.stopPropagation();

    if (multiple || option.disabled) {
      e.nativeEvent.stopImmediatePropagation();
    }
    if (option.disabled) {
      return;
    }

    // const isAdditionItem = item['data-additional']  // TODO
    const newValue = multiple ? [...new Set([...internalValue, option.value])] : option.value;
    const valueHasChanged = multiple ? newValue.length !== internalValue.length : newValue !== internalValue;

    if (valueHasChanged) {
      setInternalValue(newValue);
      handleChange(e, newValue);
    }

    clearSearchQuery();

    if (searchable) {
      searchRef.current.focus();
    } else {
      containerRef.current.focus();
    }

    closeOnChange(e);

    // TODO Heads up! This event handler should be called after `onChange`
    // Notify the onAddItem prop if this is a new value
    // if (isAdditionItem) {
    //   _.invoke(props, "onAddItem", e, { ...props, value });
    // }
  };

  return (
    <div
      ref={ref}
      className={`${styles.wrapper} ${isInvalid ? `is-invalid ${styles.invalid}` : ``} ${
        isValid ? `is-valid ${styles.valid}` : ``
      }  ${className || ``}`}
    >
      <div
        ref={containerRef}
        role={multiple ? `combobox` : `listbox`}
        aria-disabled={disabled ? "true" : "false"}
        aria-expanded={isOpen ? "true" : "false"}
        className={`${styles.container} ${isFocus ? styles.active : ``}`}
        tabIndex={searchable ? undefined : "0"}
        onBlur={handleBlur}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        onMouseDown={handleMouseDown}
        onFocus={handleFocus}
      >
        {false && (
          <a className="ui label" value="hold">
            Hold
            <i aria-hidden="true" className="delete icon" />
          </a>
        )}
        <input
          ref={searchRef}
          aria-autocomplete="list"
          autoComplete="off"
          className="search"
          tabIndex={searchable ? "0" : undefined}
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          disabled={disabled}
        />
        {false && <span className="sizer" />}
        {!isOpen && searchQuery.length === 0 && (
          <div
            aria-atomic="true"
            aria-live="polite"
            role="alert"
            className={`${styles.text} ${!text ? styles.default : ``}`}
          >
            {!text && placeholder}
            {text}
          </div>
        )}
        {clearable && <i className={`bi bi-x ${styles.icon} ${styles["clear-icon"]}`} onClick={handleClearIconClick} />}
        <i
          className={`bi bi-caret-${isOpen ? `up` : `down`} ${styles.icon} ${styles["caret-icon"]}`}
          onClick={(e) => {
            e.stopPropagation();
            toggle();
          }}
        />
        <div
          aria-multiselectable={multiple ? "true" : "false"}
          role="listbox"
          className={`${styles.menu} ${styles.transition} ${isOpen ? styles.visible : ``}`}
        >
          {internalOptions.map((option) => (
            <div
              key={`${name}-option-${option.value}`}
              id={`${name}-option-${option.value}`}
              role="option"
              aria-checked="true"
              aria-selected={internalValue === option.value ? "true" : "false"}
              className={`${styles.item} ${internalValue === option.value ? styles.selected : ``}`}
              style={{ pointerEvents: "all" }}
              onClick={(e) => {
                handleItemClick(e, option);
              }}
            >
              <span className="text">{option.text}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
});

export default Select;
