import { isEmpty } from "lodash";
import { setIdentityProofingStatus, setLaunchSelfAdminSession } from "../../actions/providerActions";

const store = window.cleanSlate.store;

const handleStatusUpdated = (message) => {
  store.dispatch(setIdentityProofingStatus({ ...message.Content, error: false, errorMessage: "" }));
  if (["wtg_redirect"].includes(message.Content.status) && !isEmpty(message.Content.uiLink)) {
    window.location = message.Content.uiLink;
  }
};

const handleLaunchSelfAdminSession = (message) => {
  store.dispatch(setLaunchSelfAdminSession(message.Content));
};

export default {
  handleStatusUpdated,
  handleLaunchSelfAdminSession
};
