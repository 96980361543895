import React, { Component } from "react";
import { Button, Header, Modal, Input, List } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as modalActions from "../../../actions/modalActions";
import * as searchActions from "../../../actions/searchActions";
import * as prescriptionActions from "../../../actions/prescriptionActions";
import * as demographicActions from "../../../actions/demographicActions";
import { largeResultMessage } from "../../../constants/validation";
import { fetchDrugAllergies } from "../../../api/screening";

import "../../reusable/Modal.css";
import "../history/PatientHistorySearchModal.css";

const getInitialSearch = (props) => props.searchValue || "";

const getSearchError = (props) =>
  (props.searchValue || []).length >= 3 ? "" : "Please type more than 3 characters to search.";

export class SearchMedPharmacyModal extends Component {
  state = {
    searchMedication: getInitialSearch(this.props),
    searchedMedication: getInitialSearch(this.props),
    selectedMedication: {},
    searchError: getSearchError(this.props),
    isLoading: false
  };

  componentDidMount() {
    const { searchValue } = this.props;
    if ((searchValue || []).length >= 3) {
      this.props.actions.searchMedication({ drugName: searchValue });
    }
  }

  componentWillUnmount() {
    this.props.actions.clearSearch("medications");
  }

  handleSearch = () => {
    const { searchMedication } = this.state;
    this.props.actions.clearSearch("medications");
    if (searchMedication.length >= 3) {
      this.props.actions.searchMedication({ drugName: searchMedication });
      this.setState({
        searchError: ""
      });
    } else {
      this.setState({
        searchError: "Please type more than 3 characters to search."
      });
    }
  };

  handleAllergiesModalOpen = (selectedMedication, screeningResults) => {
    const {
      activeRxTab,
      actions: { showModal },
      multiple
    } = this.props;
    const { searchedMedication } = this.state;

    showModal({
      type: "PATIENT_ALLERGY_MATCH",
      props: {
        open: true,
        searchedMedication,
        selectedMedication,
        screeningResults,
        activeRxTab,
        multiple
      }
    });
  };

  handleResultClick = (_, data) => {
    const { index } = data;
    const { medications, patientId } = this.props;
    const selectedMedication = medications[index];
    const { drugKey } = selectedMedication;

    this.setState({ selectedMedication, isLoading: true });

    fetchDrugAllergies(patientId, drugKey).then((result) => {
      this.setState({ isLoading: false });

      if (result.length) {
        this.handleAllergiesModalOpen(selectedMedication, result);
      }
    });
  };

  handleClose = () => {
    const {
      actions: { hideModal, clearSearch },
      multiple
    } = this.props;
    hideModal(multiple);
    clearSearch("medications");
  };

  handleSearchInput = (e, data) => {
    this.setState({ searchMedication: data.value });
  };

  handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      const { searchMedication } = this.state;

      this.setState({ searchedMedication: searchMedication, selectedMedication: "" }, () => this.handleSearch());
    }
  };

  handleSearchClick = () => {
    const { searchMedication } = this.state;
    this.setState({ searchedMedication: searchMedication, selectedMedication: "" }, () => this.handleSearch());
  };

  handleAddMed = () => {
    const { selectedMedication } = this.state;
    const {
      patientId,
      activeRxTab,
      actions: { createPrescription, createRecurringPrescription }
    } = this.props;

    if (activeRxTab === "Current Rx") {
      createPrescription(patientId, selectedMedication).then(this.handleClose);
    } else {
      createRecurringPrescription(patientId, selectedMedication).then(this.handleClose);
    }
  };

  render() {
    const { open, medications, processing } = this.props;
    const { searchMedication, selectedMedication, searchedMedication, searchError, isLoading } = this.state;

    const items = medications.slice(0, 50).map((medication, i) => (
      <List.Item
        key={medication.drugKey}
        index={i}
        value={medication.drugName}
        active={selectedMedication.drugKey === medication.drugKey}
        onClick={this.handleResultClick}
      >
        {medication.drugDescription}
      </List.Item>
    ));

    return (
      <Modal className="search-medication-modal" open={open} onClose={this.handleClose} closeIcon size="small">
        <Header content="Search Meds" />
        <Modal.Content>
          <div className="search-container-modal">
            <Input
              icon={{
                name: "search",
                link: true,
                onClick: this.handleSearchClick
              }}
              placeholder="Meds Search"
              onChange={this.handleSearchInput}
              onKeyPress={this.handleSearchEnter}
              value={searchMedication}
              loading={processing || isLoading}
              id="input-search-med-pharm"
              autoFocus
            />
          </div>
          <div className="medication-search-results">
            {selectedMedication.drugName ? (
              <h3 id="lbl-selected">You selected: {selectedMedication.drugName}</h3>
            ) : (
              searchedMedication && <h3 id="lbl-searched">Search results for: {searchedMedication}</h3>
            )}
            {medications.length > 50 ? <div className="large-result-message">{largeResultMessage}</div> : null}
            {searchedMedication ? (
              <List selection className="search-results-list">
                {searchError.length ? <div id="searchError">{searchError}</div> : items}
              </List>
            ) : null}
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
          <Button
            disabled={Object.keys(selectedMedication).length === 0 || processing || isLoading}
            onClick={this.handleAddMed}
            loading={processing || isLoading}
            color="blue"
            content="Add"
            id="btn-add"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    medications: state.search.medications || [],
    patientId: state.patient.currentPatient.patientId,
    processing: state.ajaxCallsInProgress > 0,
    prescribingProvider: state.demographics.prescribingProvider || {}
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...searchActions,
        ...prescriptionActions,
        ...demographicActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchMedPharmacyModal);
