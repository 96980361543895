import React, { lazy } from "react";
import { Route } from "react-router-dom";

const ViewPatientDemographics = lazy(() => import("./demographics/ViewPatientDemographics"));
const PatientHistoryPage = lazy(() => import("./history/PatientHistoryPage"));
const PrescriptionsPage = lazy(() => import("./prescriptions/PrescriptionsPage"));
const VisitsPage = lazy(() => import("./visits/VisitsPage"));
const LabsPage = lazy(() => import("./labs/LabsPage"));
const HistoryPhysicalPage = lazy(() => import("./hp/HistoryPhysicalPage"));
const DischargePage = lazy(() => import("./discharge/DischargePage"));
const SurveysDashboard = lazy(() => import("./surveys/SurveysDashboard"));
const TreatmentPlan = lazy(() => import("./treatmentPlan/TreatmentPlan"));
const PatientTasks = lazy(() => import("./tasks"));
const ScansPage = lazy(() => import("./scans/ScansPage"));
const VisitingPatientsPage = lazy(() => import("./visitingPatients/VisitingPatientsPage"));
const ManagePatientSubLayout = lazy(() => import("./ManagePatientSubLayout"));
const IExportPatient = lazy(() => import("./iexport/index"));

const PatientSubLayout = (
  <>
    <Route index element={<VisitingPatientsPage />} />
    <Route path={`:patientId/iexport`} element={<IExportPatient />} />
    <Route path={`:patientId`} element={<ManagePatientSubLayout />}>
      <Route path={`visits`} element={<VisitsPage />} />
      <Route path={`visits/vid/:visitId`} element={<VisitsPage />} />

      <Route path={`demographics`} element={<ViewPatientDemographics />} />
      <Route path={`demographics/vid/:visitId`} element={<ViewPatientDemographics />} />

      <Route path={`history`} element={<PatientHistoryPage />} />
      <Route path={`history/vid/:visitId`} element={<PatientHistoryPage />} />

      <Route path={`prescriptions`} element={<PrescriptionsPage />} />
      <Route path={`prescriptions/vid/:visitId`} element={<PrescriptionsPage />} />

      <Route path={`scans`} element={<ScansPage />} />
      <Route path={`scans/vid/:visitId`} element={<ScansPage />} />

      <Route path={`labs`} element={<LabsPage />} />
      <Route path={`labs/vid/:visitId`} element={<LabsPage />} />

      <Route path={`hp`} element={<HistoryPhysicalPage />} />
      <Route path={`hp/vid/:visitId`} element={<HistoryPhysicalPage />} />

      <Route path={`discharge`} element={<DischargePage />} />

      <Route path={`counseling`} element={<SurveysDashboard tab="counseling" />} />
      <Route path={`counseling/vid/:visitId`} element={<SurveysDashboard tab="counseling" />} />
      <Route path={`treatment-plan`} element={<TreatmentPlan />} />
      <Route path={`treatment-plan/vid/:visitId`} element={<TreatmentPlan />} />
      <Route path={`tasks`} element={<PatientTasks />} />
      <Route path={`tasks/vid/:visitId`} element={<PatientTasks />} />
    </Route>
  </>
);

export default PatientSubLayout;
