import "react-toastify/dist/ReactToastify.css";
import * as types from "./actionTypes";
import { processApiError } from "../helpers";
import api from "../api";
import { fetchProvider } from "../api/provider";

const asyncLocalStorage = {
  setItem: async (key, value) => {
    await null;
    return localStorage.setItem(key, value);
  },
  getItem: async (key) => {
    await null;
    return localStorage.getItem(key);
  }
};

function loadCenterContextSuccess(center) {
  return { type: types.LOAD_CENTER_CONTEXT_SUCCESS, center };
}

function updateCenterContextSuccess(center) {
  return { type: types.UPDATE_CENTER_CONTEXT_SUCCESS, center };
}

function loadCurrentUserIdSuccess(user) {
  return { type: types.LOAD_CURRENT_USER_ID_SUCCESS, user };
}

function setProviderDetails(providerDetails) {
  return { type: types.SET_PROVIDER_DETAILS, providerDetails };
}

export function updateUserPreference(userPreference) {
  return { type: types.UPDATE_USER_PREFERENCE, userPreference };
}

export function setReloadPageIn(value, forceUpdate, newVersion = null) {
  return { type: types.SET_RELOAD_PAGE_IN, value, forceUpdate, newVersion };
}

export function setRetryConnectingIn(value) {
  return { type: types.SET_RETRY_CONNECTING_IN, value };
}

export function setRetryReachingServerIn(value) {
  return { type: types.SET_RETRY_REACHING_SERVER_IN, value };
}

export function updateCenterContext(center) {
  return async (dispatch) => {
    asyncLocalStorage
      .setItem("centerContext", center)
      .then(() => {
        asyncLocalStorage.getItem("centerContext");
      })
      .then(() => {
        dispatch(updateCenterContextSuccess(center));
      });
  };
}

export function loadCenterContext() {
  return async (dispatch) => {
    const center = await asyncLocalStorage.getItem("centerContext");
    return dispatch(loadCenterContextSuccess(center));
  };
}

export function loadCurrentUserId() {
  return (dispatch) =>
    api
      .fetchCurrentUser()
      .then((data) => {
        // this second arg true to ignore 404 .. because not all users are providers
        fetchProvider(data.currentUser, true).then((providerDetails) => {
          if (providerDetails) dispatch(setProviderDetails(providerDetails));
        });
        return dispatch(loadCurrentUserIdSuccess(data));
      })
      .catch((error) => {
        processApiError(error, dispatch);
      });
}

export function setTheme(theme) {
  return async (dispatch) => {
    asyncLocalStorage.setItem("theme", theme).then(() => {
      dispatch({
        type: types.SET_THEME,
        theme
      });
    });
  };
}

export function loadTheme() {
  return async (dispatch) => {
    let theme = await asyncLocalStorage.getItem("theme");
    if (!theme) theme = "/themes/default.css";
    asyncLocalStorage.setItem("theme", theme).then(() => {
      dispatch({
        type: types.SET_THEME,
        theme
      });
    });
  };
}
