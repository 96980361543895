import React, { Component } from "react";
import { Button, Header, Modal, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DateInput, TimeInput } from "semantic-ui-calendar-react";
import moment from "moment";
import withRouter from "../../hoc/withRouter";
import * as modalActions from "../../actions/modalActions";
import * as lookupActions from "../../actions/lookupActions";
import * as providerActions from "../../actions/providerActions";
import * as groupActions from "../../actions/groupActions";
import * as visitActions from "../../actions/visitActions";
import { dateRegEx, timeRegEx } from "../../constants/validation";
import { removeSpecialCharacters, updateErrors, requiredFieldsFilled, hasProperty } from "../../helpers";

import "./NewGroupModal.css";
import { dateFormat, isoFormat, dateWithTimeFormat } from "../../constants/miscellaneous";

const inputValidation = {
  required: ["groupTypeCode", "providerId", "meetingDate", "meetingTime"],
  meetingDate: dateRegEx,
  meetingTime: timeRegEx
};

export class NewGroupModal extends Component {
  state = {
    group: {
      groupTypeCode: "",
      providerId: "",
      meetingDate: "",
      meetingTime: ""
    },
    visit: {},
    errors: {}
  };

  componentDidMount() {
    this.setDefaults();
    this.props.actions.loadGroupTypes();
    this.props.actions.loadProviders();
  }

  setDefaults = () => {
    const currentDate = moment().format("MM/DD/YYYY");
    const currentTime = moment().add(1, "hour").startOf("hour").format("hh:mm a");
    const providerId = this.props.withVisit ? this.props.visit.providerId : "";
    const visit = this.props.withVisit ? Object.assign({}, this.state.visit, this.props.visit) : {};
    const group = Object.assign({}, this.state.group, {
      meetingDate: currentDate,
      meetingTime: currentTime,
      providerId
    });
    this.setState({ group, visit });
  };

  createAnswersObject = () => {
    const {
      group: { groupTypeCode, providerId, meetingDate, meetingTime }
    } = this.state;
    const dateTime = `${meetingDate} ${meetingTime}`;
    const answersObject = {
      groupTypeCode,
      providerId,
      visitGroupTime: moment(dateTime, dateWithTimeFormat).format(isoFormat),
      patients: []
    };
    return answersObject;
  };

  addPatientToGroup = (group) => {
    const visit = Object.assign({}, this.state.visit, {
      visitGroupId: group.response.VisitGroupPlatformId,
      providerId: group.response.ProviderId
    });
    this.setState({ visit }, () => {
      this.props.actions
        .createVisit(this.state.visit, this.props.patientId)
        .then(() => this.props.router.navigate(`/group-patients`))
        .then(this.handleClose);
    });
  };

  handleSave = () => {
    const answersObject = this.createAnswersObject();
    if (this.props.withVisit) {
      this.props.actions.createGroup(answersObject).then((response) => {
        if (response) {
          this.addPatientToGroup(response);
        } else {
          this.handleClose();
        }
      });
    } else {
      this.props.actions.createGroup(answersObject).then(this.handleClose);
    }
  };

  handleClose = () => this.props.actions.hideModal();

  handleFocus = (e) => {
    e.target.setAttribute("autocomplete", "nope");
  };

  handleInput = (e, data) => {
    const { name: field, value, required } = data;
    const errors = updateErrors(field, value, required, this.state.errors, inputValidation[field]);
    const group = Object.assign({}, this.state.group, {
      [field]: value
    });
    this.setState({ group, errors });
  };

  handleMaskedInput = (e) => {
    const {
      type,
      target: { name: field, required, value }
    } = e;
    let errors = Object.assign({}, this.state.errors);
    const group = Object.assign({}, this.state.group, {
      [field]: value
    });

    if (type !== "focus" && type !== "blur") {
      errors = updateErrors(
        field,
        required ? removeSpecialCharacters(value) : value,
        required,
        errors,
        inputValidation[field]
      );
    }

    this.setState({ group, errors });

    if (field === "meetingDate") {
      this.checkIfFuture(field, value);
    }
  };

  checkIfFuture = (field, value) => {
    const { errors } = this.state;
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const meetingDate = moment(value).toDate();
    if (currentDate <= meetingDate) {
      delete errors[field];
    } else {
      errors[field] = "";
    }
    this.setState({ errors });
  };

  render() {
    const { errors, group } = this.state;
    const { open, providers, groupTypes, saving } = this.props;
    const requiredFieldsCheck = requiredFieldsFilled(inputValidation.required, group);
    const disableSave = requiredFieldsCheck || Object.keys(errors).length !== 0;

    return (
      <Modal className="new-group-modal" open={open} onClose={this.handleClose} closeIcon size="small">
        <Header content="Create a Group" />
        <Modal.Content>
          <Form className="new-group-form">
            <Form.Group widths="equal">
              <Form.Field width={16}>
                <Form.Dropdown
                  required
                  fluid
                  selection
                  search
                  options={groupTypes}
                  label="Choose a Group Type"
                  name="groupTypeCode"
                  placeholder="Select..."
                  value={group.groupTypeCode}
                  onChange={this.handleInput}
                  error={hasProperty(errors, "groupTypeCode")}
                  id="input-groupTypeCode"
                  onFocus={this.handleFocus}
                />
              </Form.Field>
            </Form.Group>
            <Form.Field width={16}>
              <Form.Dropdown
                required
                fluid
                selection
                search
                options={providers}
                label="Choose a Provider"
                name="providerId"
                placeholder="Select..."
                value={group.providerId}
                onChange={this.handleInput}
                error={hasProperty(errors, "providerId")}
                id="input-providerId"
                onFocus={this.handleFocus}
              />
            </Form.Field>
            <Form.Group className="date-time-container">
              <Form.Field
                width={7}
                className={hasProperty(errors, "meetingDate") ? "required error field" : " required field"}
              >
                <label htmlFor="input-meetingDate">Meeting Date</label>
                <DateInput
                  id="input-meetingDate"
                  name="meetingDate"
                  placeholder="Meeting Date"
                  value={group.meetingDate}
                  dateFormat={dateFormat}
                  iconPosition="right"
                  onChange={this.handleInput}
                  hideMobileKeyboard
                  closable
                />
              </Form.Field>
              <Form.Field
                width={7}
                className={hasProperty(errors, "meetingTime") ? "error field required" : "field required"}
              >
                <label>Meeting Time</label>
                <TimeInput
                  name="meetingTime"
                  placeholder="Meeting Time"
                  value={group.meetingTime}
                  timeFormat="ampm"
                  iconPosition="right"
                  onChange={this.handleInput}
                  hideMobileKeyboard
                  closable
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
          <Button onClick={this.handleSave} color="blue" id="btn-next" disabled={disableSave} loading={saving}>
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function formatProvidersForDropdown(providers) {
  return providers.map((provider) => ({
    value: provider.userId,
    text: `${provider.firstName} ${provider.lastName}`,
    key: provider.userId.toString()
  }));
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    providers: formatProvidersForDropdown(state.providers.allProviders),
    groupTypes: state.lookups.groupTypes,
    saving: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        ...modalActions,
        ...lookupActions,
        ...providerActions,
        ...groupActions,
        ...visitActions
      },
      dispatch
    )
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewGroupModal));
