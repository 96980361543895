import React, { Component } from "react";
import { Button, Header, Modal, Form, Table, Grid } from "semantic-ui-react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DateInput } from "semantic-ui-calendar-react";
import * as modalActions from "../../../../actions/modalActions";
import * as patientHistoryActions from "../../../../actions/patientHistoryActions";
import { dateFormat, longDateFormat } from "../../../../constants/miscellaneous";
import "../../../reusable/Modal.css";
import "./AddMedicationModal.css";
import { dateRegEx } from "../../../../constants/validation";
import { updateErrorsWithStartEndDates, formatDateWithoutTimezone } from "../../../../helpers";

const inputValidation = {
  required: ["strength"],
  startDate: dateRegEx,
  endDate: dateRegEx
};

const getMedication = (props) => {
  const { medication } = props;
  if (medication) {
    medication.startDate = medication.startDate && formatDateWithoutTimezone(medication.startDate);
    medication.endDate = medication.endDate && formatDateWithoutTimezone(medication.endDate);
  }
  return (
    medication || {
      startDate: "",
      endDate: "",
      strength: "",
      notes: []
    }
  );
};

export class AddMedicationModal extends Component {
  state = {
    medication: getMedication(this.props),
    isNoteInputAvailiable: false,
    isNoteInputDisable: false,
    errors: {},
    medicationNotesUpdate: [],
    isSaveDisable: false,
    note: ""
  };
  componentWillUnmount() {
    const { patientId } = this.props;
    this.props.actions.loadMedication(patientId);
  }

  handleInput = (e, data) => {
    const { name: field, value, required } = data;
    const { startDate, endDate } = this.state.medication;

    const errors = updateErrorsWithStartEndDates(
      field,
      value,
      required,
      this.state.errors,
      inputValidation,
      startDate,
      endDate
    );

    const medication = Object.assign({}, this.state.medication, {
      [field]: value.trim()
    });
    this.setState({ medication, errors });
  };

  handlerInputStrength = (e, data) => {
    const { name: field, value } = data;
    const trimmedValue = value.replace(/  +/g, " ");

    const medication = Object.assign({}, this.state.medication, {
      [field]: trimmedValue
    });
    this.setState({ medication });
  };

  handleMaskedInput = (e) => {
    const {
      type,
      target: { name: field, required, value }
    } = e;
    const { startDate, endDate } = this.state.medication;

    let errors = Object.assign({}, this.state.errors);
    const medication = Object.assign({}, this.state.medication, {
      [field]: value
    });

    // ensure we dont check validation on focus and blur events
    if (type !== "focus" && type !== "blur") {
      errors = updateErrorsWithStartEndDates(
        field,
        value,
        required,
        this.state.errors,
        inputValidation,
        startDate,
        endDate
      );
    }

    this.setState({ medication, errors });
  };

  handleSave = () => {
    this.setState({ isSaveDisable: true });
    const { patientId, drugName, drugKey } = this.props;
    const { strength } = this.state.medication;

    const newMedication = Object.assign({}, this.state.medication, {
      drugName,
      drugKey: drugKey || "freeTextKeyId",
      strength: this.props.strength || strength
    });

    if (newMedication.itemId) {
      // Only get new note added
      newMedication.notes = this.state.medicationNotesUpdate;
      this.props.actions.updateMedication(patientId, newMedication).then(this.handleClose);
    } else {
      this.props.actions.createMedication(patientId, newMedication).then(this.handleClose);
    }
  };

  handleClose = () => this.props.actions.hideModal();

  handleChangeNote = (e, data) => {
    const { value } = data;
    if (value.trim() !== "") {
      this.setState({ isNoteInputAvailiable: true });
    } else {
      this.setState({ isNoteInputAvailiable: false });
    }
    this.setState({ note: value });
  };

  handleAddNote = (notes) => () => {
    const noteInput = this.state.note;
    let updateNotes = [];
    // Current list note != null -> restore old note
    if (notes && notes.length > 0) {
      updateNotes = notes;
    }
    const noteAdd = {
      text: noteInput,
      noteAddedOn: new Date(),
      creatorFullName: this.props.userName
    };

    // Is update medication => save new note to list separate old note list
    if (this.state.medication.itemId) {
      const { medicationNotesUpdate } = this.state;
      medicationNotesUpdate.unshift(noteAdd);
    } else {
      // Add new medication -> only add one note
      this.setState({ isNoteInputDisable: true });
    }

    // Update to current notes
    updateNotes.unshift(noteAdd);
    const currentMedication = this.state.medication;

    currentMedication.notes = updateNotes;
    const updateMedication = Object.assign({}, currentMedication, { note: noteInput });

    this.setState({
      medication: updateMedication,
      note: "",
      isNoteInputAvailiable: false
    });
  };

  render() {
    const { open, processing } = this.props;
    const { medication, errors, note, isNoteInputAvailiable, isNoteInputDisable, isSaveDisable } = this.state;
    const disableSave = Object.keys(errors).length !== 0;
    const { notes } = medication;
    return (
      <Modal className="add-medication-modal" open={open} onClose={this.handleClose} closeIcon size="small">
        <Header content="Add Medication" />
        <h2>
          Drug: <span data-testid="drug-header">{this.props.drugName || medication.drugName} </span>
        </h2>
        <Modal.Content>
          <Form>
            <Form.Group widths="three">
              <Form.Field className={Object.prototype.hasOwnProperty.call(errors, "startDate") ? "error field" : ""}>
                <label htmlFor="input-startDate">Start Date</label>
                <DateInput
                  id="input-startDate"
                  name="startDate"
                  placeholder="Start Date"
                  value={medication.startDate}
                  dateFormat={dateFormat}
                  iconPosition="right"
                  onChange={this.handleInput}
                  hideMobileKeyboard
                  closable
                />
              </Form.Field>
              <Form.Field className={Object.prototype.hasOwnProperty.call(errors, "endDate") ? "error field" : ""}>
                <label>End Date</label>
                <DateInput
                  id="input-endDate"
                  name="endDate"
                  placeholder="End Date"
                  value={medication.endDate}
                  dateFormat={dateFormat}
                  iconPosition="right"
                  onChange={this.handleInput}
                  hideMobileKeyboard
                  closable
                />
              </Form.Field>
              <Form.Field>
                <Form.Input
                  label="Strength"
                  name="strength"
                  value={this.props.strength || medication.strength}
                  onChange={this.handlerInputStrength}
                  id="input-strength"
                />
              </Form.Field>
            </Form.Group>
            <Grid>
              <Grid.Column computer={13} tablet={13} moblie={13}>
                <Form.Input
                  className="medication-note-input"
                  onChange={this.handleChangeNote}
                  id="input-note"
                  name="note"
                  value={note}
                  disabled={isNoteInputDisable}
                  label="Notes"
                />
              </Grid.Column>
              <Grid.Column className="btn-add" computer={3} tablet={3} mobile={3}>
                <Button
                  content="Add Note"
                  loading={processing}
                  color="blue"
                  id="btn-add-note"
                  onClick={this.handleAddNote(notes)}
                  disabled={!isNoteInputAvailiable}
                />
              </Grid.Column>
            </Grid>
            <div className="note-section">
              {notes
                ? notes.map((mNote, index) => (
                    <React.Fragment key={mNote.noteId || index}>
                      <Table unstackable>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell colSpan="2">
                              <p>
                                <strong>{mNote.text}</strong>
                              </p>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row className="grey">
                            {mNote.noteAddedOn && (
                              <Table.Cell>{moment(mNote.noteAddedOn).format(longDateFormat)}</Table.Cell>
                            )}
                            <Table.Cell>{mNote.creatorFullName}</Table.Cell>
                          </Table.Row>
                          <Table.Row />
                        </Table.Body>
                      </Table>
                    </React.Fragment>
                  ))
                : null}
            </div>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
          <Button
            disabled={disableSave || isSaveDisable}
            loading={processing}
            onClick={this.handleSave}
            color="blue"
            id="btn-save"
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  let medicationHistory = [];
  const tempNoteList = [];

  if (state.patientHistory.medicationHistory.length) {
    medicationHistory = state.patientHistory.medicationHistory;
  }
  return {
    patientId: state.patient.currentPatient.patientId,
    processing: state.ajaxCallsInProgress > 0,
    userName: state.auth.user.profile.fullName,
    medicationHistory,
    tempNoteList
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...patientHistoryActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMedicationModal);
