import { isEmpty } from "lodash";
import * as types from "../actions/actionTypes";
import initialState from "./initialState";
import prescriptionStatuses from "../constants/prescriptionStatus";

export default function prescriptionsReducer(state = initialState.prescriptions, action) {
  switch (action.type) {
    // case types.ADD_PATIENT_FAVORITE_PHARMACY_SUCCESS:
    //   return {}
    case types.LOAD_PATIENT_FAVORITE_PHARMACIES_SUCCESS: {
      let selectedPharmacy = state.selectedPharmacy;
      if (!selectedPharmacy || !action.pharmacies.find((ph) => ph.pharmacyKey === selectedPharmacy.pharmacyKey)) {
        selectedPharmacy = action.pharmacies[0];
      }
      return {
        ...state,
        favoritePharmacies: action.pharmacies,
        selectedPharmacy
      };
    }
    case types.CLEAR_PATIENT_FAVORITE_PHARMACIES: {
      return { ...state, favoritePharmacies: [], selectedPharmacy: null };
    }
    case types.LOAD_PRESCRIPTIONS_SUCCESS:
      return Object.assign({}, state, {
        currentPrescriptions: action.prescriptions
      });
    case types.LOAD_RECURRING_PRESCRIPTION_SUCCESS:
      return Object.assign({}, state, {
        recurringPrescriptions: action.prescriptions
      });
    case types.CREATE_PRESCRIPTION_SUCCESS: {
      const rxAdded = action.prescriptions[0];

      return {
        ...state,
        currentPrescriptions: [
          { ...rxAdded },
          ...action.prescriptions.filter((rx) => rx.prescriptionId !== rxAdded.prescriptionId)
        ]
      };
    }
    case types.CREATE_RECURRING_PRESCRIPTION_SUCCESS:
      return Object.assign({}, state, {
        recurringPrescriptions: [
          Object.assign({}, action.prescription, {
            prescriptionId: action.prescriptionId
          }),
          ...state.recurringPrescriptions
        ]
      });
    case types.ADD_RECURRING_TO_CURRENT_SUCCESS:
      return Object.assign({}, state, {
        currentPrescriptions: [
          Object.assign({}, action.prescription, {
            prescriptionId: action.prescriptionId,
            recurringPrescriptionKey: action.recurringPrescriptionKey
          }),
          ...state.currentPrescriptions
        ]
      });
    case types.UPDATE_PRESCRIPTION_SUCCESS:
    case types.DELETE_PRESCRIPTION_SUCCESS: {
      return {
        ...state,
        currentPrescriptions: action.prescriptions
      };
    }
    case types.UPDATE_PRESCRIPTION_PRESCRIBER_SUCCESS: {
      const { prescription: { prescriptionId, prescriberId, prescriberFirstName, prescriberLastName } = {} } = action;
      const currentPrescriptions = state.currentPrescriptions.map((rx) => {
        if (rx.prescriptionId === prescriptionId) {
          return {
            ...rx,
            prescriberId,
            prescriberFirstName,
            prescriberLastName
          };
        }
        return rx;
      });

      return { ...state, currentPrescriptions };
    }
    case types.SIGN_PRESCRIPTION_SUCCESS: {
      const { prescriptionId, prescriptionStatus, noteToPharmacist } = action;
      const currentPrescriptions = state.currentPrescriptions.map((rx) => {
        if (rx.prescriptionId === prescriptionId) {
          return {
            ...rx,
            prescriptionStatus,
            noteToPharmacist
          };
        }
        return rx;
      });

      return { ...state, currentPrescriptions };
    }
    case types.UPDATE_RECURRING_PRESCRIPTION_SUCCESS:
      return Object.assign({}, state, {
        recurringPrescriptions: [
          action.recurringPrescription,
          ...state.recurringPrescriptions.filter(
            (prescription) => prescription.prescriptionId !== action.recurringPrescription.prescriptionId
          )
        ]
      });
    case types.DELETE_RECURRING_PRESCRIPTION_SUCCESS:
      return Object.assign({}, state, {
        recurringPrescriptions: [
          ...state.recurringPrescriptions.filter((rx) => rx.prescriptionId !== action.recurringPrescriptionId)
        ]
      });
    case types.LOAD_DRUG_FORMULARY_SUCCESS:
      return Object.assign({}, state, {
        currentDrugFormulary: action.formularyResult
      });
    case types.SET_PRESCRIPTION_DRUG_CHANGE:
      return Object.assign({}, state, {
        currentPrescriptions: state.currentPrescriptions.map((element) =>
          element.isSelected ? { ...element, drugKey: action.drugKey } : element
        )
      });
    case types.SET_SELECTED_PRESCRIPTION: {
      switch (action.rxType) {
        case "currentRx":
          return {
            ...state,
            currentPrescriptions: state.currentPrescriptions.map((i) =>
              i.prescriptionId === action.prescriptionId ? { ...i, isSelected: true } : { ...i, isSelected: false }
            ),
            recurringPrescriptions: state.recurringPrescriptions.map((i) => ({ ...i, isSelected: false }))
          };
        case "recurringRx":
          return {
            ...state,
            currentPrescriptions: state.currentPrescriptions.map((i) => ({ ...i, isSelected: false })),
            recurringPrescriptions: state.recurringPrescriptions.map((i) =>
              i.prescriptionId === action.prescriptionId ? { ...i, isSelected: true } : { ...i, isSelected: false }
            )
          };
        default:
          return {
            ...state,
            currentPrescriptions: state.currentPrescriptions.map((i) => ({ ...i, isSelected: false })),
            recurringPrescriptions: state.recurringPrescriptions.map((i) => ({ ...i, isSelected: false }))
          };
      }
    }
    case types.PRINT_PRESCRIPTION_SUCCESS:
    case types.SEND_PRESCRIPTION_SUCCESS: {
      return { ...state, currentPrescriptions: action.prescriptions };
    }
    case types.SEND_PRESCRIPTION_FROM_REPORT_SUCCESS:
      return state;
    case types.LOAD_PRESCRIPTIONS_SENT_SUCCESS:
      return Object.assign({}, state, {
        prescriptionsSent: action.prescriptionsSent
      });

    case types.LOAD_PRESCRIPTIONS_TO_SIGN_SUCCESS:
      return { ...state, prescriptionsToSign: action.prescriptionsToSign };
    case types.SET_FORCE_RELOAD_PRESCRIPTIONS_TO_SIGN:
      return { ...state, forceReloadPrescriptionsToSign: action.value };

    case types.CLEAR_PRESCRIPTIONS_TO_SIGN:
      return { ...state, prescriptionsToSign: [] };
    case types.LOAD_PRESCRIPTION_TO_SIGN_SUCCESS:
      return { ...state, currentPrescriptionToSign: action.prescription };
    case types.CLEAR_PRESCRIPTION_TO_SIGN:
      return { ...state, currentPrescriptionToSign: {} };
    case types.SAVE_ATTESTATION_SUCCESS:
      return {
        ...state,
        currentPrescriptionToSign: {
          ...state.currentPrescriptionToSign,
          attestationSaved: true
        }
      };
    case types.DELETE_PRESCRIPTION_TO_SIGN_SUCCESS:
      return {
        ...state,
        prescriptionsToSign: state.prescriptionsToSign.filter((rx) => rx.prescriptionId !== action.prescriptionId)
      };
    case types.LOAD_PRESCRIPTIONS_SENT_BY_VISIT_SUCCESS:
      return { ...state, prescriptionsSentByVisit: action.prescriptionsSentByVisit };
    case types.SET_RX_ITEM_FORM: {
      if (isEmpty(action.prescription)) {
        return {
          ...state,
          rxItemForm: {}
        };
      }
      const originalPrescription = state.rxItemForm ? state.rxItemForm.originalPrescription : null;
      const updateOriginal = isEmpty(originalPrescription) || action.updateOriginal;

      let prescriptionStatus = updateOriginal
        ? action.prescription.prescriptionStatus
        : originalPrescription
        ? originalPrescription.prescriptionStatus
        : prescriptionStatuses.blank;

      if (
        originalPrescription &&
        !updateOriginal &&
        Object.keys(originalPrescription)
          .filter((key) => key !== "prescriptionStatus")
          .some((key) => `${originalPrescription[key]}` !== `${action.prescription[key]}`)
      ) {
        prescriptionStatus = prescriptionStatuses.changesUnsaved;
      }

      return {
        ...state,
        rxItemForm: {
          prescription: { ...action.prescription, prescriptionStatus },
          originalPrescription: updateOriginal ? { ...action.prescription, prescriptionStatus } : originalPrescription
        }
      };
    }
    case types.APPLY_FAVORITE_PRESCRIPTION: {
      let _selectedPrescription = state.currentPrescriptions.find((i) => i.isSelected === true);
      if (!_selectedPrescription)
        _selectedPrescription = state.recurringPrescriptions.find((i) => i.isSelected === true);
      if (_selectedPrescription) {
        const updatedPrescription = { ...state.rxItemForm.prescription, ...action.drugFavorite };
        const originalPrescription = state.rxItemForm.originalPrescription;
        if (
          Object.keys(originalPrescription)
            .filter((key) => key !== "prescriptionStatus")
            .some((key) => `${originalPrescription[key]}` !== `${updatedPrescription[key]}`)
        ) {
          updatedPrescription.prescriptionStatus = prescriptionStatuses.changesUnsaved;
        } else {
          updatedPrescription.prescriptionStatus = originalPrescription.prescriptionStatus;
        }
        return {
          ...state,
          rxItemForm: {
            ...state.rxItemForm,
            prescription: updatedPrescription
          }
        };
      }
      return state;
    }
    case types.FETCH_RX_PIN_INFO_DONE: {
      return { ...state, rxPinInfo: action.rxPinInfo };
    }
    default:
      return state;
  }
}

export const selectedPrescription = (state) => {
  let _selectedPrescription = state.currentPrescriptions.find((i) => i.isSelected === true);
  if (!_selectedPrescription) _selectedPrescription = state.recurringPrescriptions.find((i) => i.isSelected === true);
  return _selectedPrescription;
};
