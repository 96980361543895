import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import ajaxCallsInProgress from "./ajaxStatusReducer";
import context from "./contextReducer";
import centers from "./centersReducer";
import modal from "./modalReducer";
import confirmationModal from "./confirmationModalReducer";
import patient from "./patientReducer";
import patientHistory from "./patientHistoryReducer";
import patientExport from "./patientExportReducer";
import lookups from "./lookupReducer";
import search from "./searchReducer";
import demographics from "./demographicsReducer";
import prescriptions, * as fromPrescriptions from "./prescriptionReducer";
import providers from "./providersReducer";
import visits, * as fromVisits from "./visitsReducer";
import noteSlashTasks from "./noteSlashTasksReducer";
import { reducer as authReducer } from "./authSlice";
import settingsReducer from "./settingsReducer";
import { reducer as surveysReducer } from "./surveysSlice";
import insurance from "./insuranceReducer";
import treatmentPlan from "./treatmentPlanReducer";
// import templates from "./templateReducer"; // migrated to rtk
import groups from "./groupReducer";
import labs from "./labReducer";
import documents from "./documentReducer";
import userPreference from "./userPreferenceReducer";
import charts from "./chartsReducer";
import badges from "./badgeReducer";
import errorLogs from "./errorLogsReducer";
import claimErrors from "./claimErrorsReducer";
import sidebarErrorLog from "./sidebarErrorLogReducer";
import reports from "./reportReducer";
import multipleModals from "./multipleModalReducer";
import user from "./userReducer";
import admin from "./adminReducer";
import notifications from "./notificationReducer";
import notes from "./noteReducer";
import streaming from "./streamingReducer";
import misc from "./miscReducer";
import { apiSlice } from "../api/apiSlice";
// import labsSlice from "../components/patients/labs/labsSlice";

const settingsPersistConfig = {
  key: "settings",
  storage
  // blacklist: ["settings"]
};

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  surveys: surveysReducer,
  ajaxCallsInProgress,
  context,
  centers,
  search,
  patient,
  patientHistory,
  patientExport,
  modal,
  confirmationModal,
  lookups,
  demographics,
  prescriptions,
  providers,
  visits,
  noteSlashTasks,
  // auth, // migrated to rtk
  insurance,
  treatmentPlan,
  // templates, // migrated to rtk
  groups,
  labs,
  documents,
  userPreference,
  charts,
  badges,
  errorLogs,
  claimErrors,
  sidebarErrorLog,
  reports,
  multipleModals,
  user,
  admin,
  notifications,
  notes,
  streaming,
  misc,
  // _labs: labsSlice
  settings: persistReducer(settingsPersistConfig, settingsReducer)
});

export const selectIsContextVisitInActiveVisits = (state) =>
  fromVisits.selectIsContextVisitInActiveVisits(state.visits);
export const selectedPrescription = (state) => fromPrescriptions.selectedPrescription(state.prescriptions);

export default rootReducer;
