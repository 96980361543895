import React, { Component } from "react";
import MaskedInput from "react-text-mask";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import shortid from "shortid";
import { head, isEmpty } from "lodash";
import { Button, Modal, Form, Container, Row, Col, Card, Table } from "../../../ui";
import referenceValues from "../../../api/mockReferenceValues";
import { zipMask } from "../../../constants/validation";
import api from "../../../api";
import * as prescriptionActions from "../../../actions/prescriptionActions";
import PaginationRow from "../../reusable/PaginationRow";

import "./PatientPharmacyModal.css";
import "../../reusable/Modal.css";
import { hideModal } from "../../../helpers";

const getProximityZip = (proximityOption, searchZip, patientZip, clinicZip) => {
  if (proximityOption === "Patient") {
    return patientZip;
  }

  if (proximityOption === "Clinic") {
    return clinicZip;
  }

  return searchZip;
};

export class PatientPharmacyModal extends Component {
  state = {
    searchCriteria: {
      pharmacyType: "Retail",
      zip: "",
      pharmacyName: "",
      proximityMiles: -1,
      controlledSubstanceOnly: true,
      proximityOption: "None",
      requestedPageSize: 5,
      requestedPage: 1
    },
    pharmacy: this.props.pharmacy || {},
    errors: {},
    currentPatient: {},
    centerDetails: {},
    patientFavoritePharmacies: {},
    isLoading: false,
    pharmacies: {
      results: [],
      currentPage: 0,
      actualPageSize: 0,
      totalRecords: 0
    }
  };

  componentDidMount() {
    const {
      patientId,
      centerId,
      // pharmacy,
      // update,
      actions: { loadPatientFavoritePharmacies }
    } = this.props;
    if (!isEmpty(patientId) && !isEmpty(centerId)) {
      this.setState({ isLoading: true });
      Promise.all([
        api.fetchPatientDemographics(patientId),
        api.fetchCenter(centerId),
        loadPatientFavoritePharmacies(patientId)
      ])
        .then(([currentPatient, centerDetails, patientFavoritePharmacies]) => {
          this.setState({
            currentPatient,
            centerDetails,
            patientFavoritePharmacies
            // isLoading: !prevState.isLoading
          });
        })
        .catch(() => {})
        .finally(() => {
          this.setState({ isLoading: false });
        });
    }
  }

  onPageChange = (_, { activePage }) => {
    this.handleSearch(null, null, activePage);
  };

  handleSearch = (_, __, activePage = 1) => {
    this.setState((prevState) => ({ isLoading: !prevState.isLoading }));
    api
      .fetchPharmacies(this.buildAndLaunchPharmaciesSearch(activePage))
      .then((pharmacies) => this.setState((prevState) => ({ isLoading: !prevState.isLoading, pharmacies })));
  };

  buildAndLaunchPharmaciesSearch = (activePage) => {
    const {
      searchCriteria,
      searchCriteria: { proximityMiles },
      currentPatient: { associatedAddresses = [] },
      centerDetails
    } = this.state;
    const { proximityOption, ...rest } = searchCriteria;
    const { zip: patientZip } = associatedAddresses.find((a) => a.isBilling) || head(associatedAddresses) || {};

    return {
      ...rest,
      proximityMiles: proximityMiles === -1 ? null : proximityMiles,
      requestedPage: activePage,
      zip: getProximityZip(proximityOption, rest.zip, patientZip, centerDetails.zip)
    };
  };

  handleSave = () => {
    const { favoriteId } = this.props;
    let { pharmacy } = this.state;
    if (this.props.order !== undefined) pharmacy = { ...pharmacy, order: this.props.order };
    if (pharmacy.zip.length > 5) {
      pharmacy = {
        ...pharmacy,
        zip: pharmacy.zip.substring(0, 5),
        zip4: pharmacy.zip.substring(pharmacy.zip.length - 4)
      };
    }
    const {
      patientId,
      actions: { savePatientFavoritePharmacy }
    } = this.props;
    const { services, ...rest } = pharmacy;
    this.setState({ isLoading: true });
    savePatientFavoritePharmacy(patientId, {
      ...rest,
      favoriteId,
      ePrescribeServices: services
    })
      .then(() => this.setState({ pharmacy, isLoading: false }))
      .then(this.handleClose);
  };

  handlePharmacySelect = (e) => {
    const { pharmacies: { results = [] } = {} } = this.state;
    const pharmacykey = e.currentTarget.getAttribute("pharmacykey");
    const pharmacy = results.find((_pharmacy) => _pharmacy.pharmacyKey === pharmacykey);
    this.setState({ pharmacy });
  };

  handleInput = (event) => {
    const field = event.target.name;
    const value = event.target.value;
    const {
      searchCriteria,
      searchCriteria: { proximityOption }
    } = this.state;
    const updatedSearchCriteria = { ...searchCriteria, [field]: value };
    this.setState({
      searchCriteria: {
        ...updatedSearchCriteria,
        zip: proximityOption !== updatedSearchCriteria.proximityOption ? "" : updatedSearchCriteria.zip
      }
    });
  };

  handleCheckbox = (event) => {
    this.setState({ searchCriteria: { ...this.state.searchCriteria, [event.target.name]: event.target.checked } });
  };

  handleMaskedInput = (e) => {
    const {
      target: { name: field, value }
    } = e;
    const searchCriteria = Object.assign({}, this.state.searchCriteria, {
      [field]: value
    });

    this.setState({ searchCriteria });
  };

  handleClose = () => {
    const { multiple } = this.props;
    hideModal(multiple);
  };

  render() {
    const { open } = this.props;
    const {
      pharmacy,
      searchCriteria: {
        pharmacyName,
        controlledSubstanceOnly,
        pharmacyType,
        proximityMiles,
        proximityOption,
        zip: zipCode,
        requestedPageSize
      },
      pharmacies: { results: pharmacyData = [], totalRecords, currentPage },
      isLoading
    } = this.state;

    return (
      <Modal
        className="patient-pharmacy-modal"
        size="xl"
        backdrop="static"
        keyboard={false}
        onHide={this.handleClose}
        show={open}
      >
        <Modal.Header closeButton>
          <Modal.Title>Patient Pharmacy</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Container fluid>
            <Row>
              {pharmacy.pharmacyKey ? (
                <Col sm={3} className="smallerFont">
                  <h3 className="currentSelectedHeader">Current Selected Pharmacy:</h3>
                  <h3>{pharmacy.pharmacyName || "Selected Pharmacy"}</h3>
                  <h4>{pharmacy.address1}</h4>
                  <h4>{pharmacy.address2}</h4>
                  <h4>
                    {pharmacy.city}, {pharmacy.state} {pharmacy.zip} {pharmacy.zip4}
                  </h4>
                  <h4>{pharmacy.phone}</h4>
                  <Card>
                    {pharmacy.services ? (
                      <Card>
                        <Card.Title>Services</Card.Title>
                        <Card.Text>
                          {pharmacy.services.map((v) => (
                            <span key={`rand-${shortid.generate()}`}>{v}</span>
                          ))}
                        </Card.Text>
                      </Card>
                    ) : (
                      ""
                    )}
                    {pharmacy.specialties ? (
                      <Card>
                        <Card.Title>Specialties</Card.Title>
                        <Card.Text>
                          {pharmacy.specialties.map((s) => (
                            <span key={`rand-${shortid.generate()}`}>{s}</span>
                          ))}
                        </Card.Text>
                      </Card>
                    ) : (
                      ""
                    )}
                  </Card>
                </Col>
              ) : (
                <Col sm={3} className="smallerFont">
                  <h3 className="currentSelectedHeader">Current Selected Pharmacy</h3>
                  <h4>No Pharmacy Selected</h4>
                </Col>
              )}
              <Col sm={9}>
                <Form className="lightGreyBackground">
                  <Row>
                    <Col>
                      <h4>Pharmacy Search</h4>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Pharmacy Type</Form.Label>
                      <Form.Select
                        id="input-pharmacyType"
                        name="pharmacyType"
                        placeholder="Select..."
                        options={referenceValues.pharmacyTypes}
                        value={pharmacyType}
                        onFocus={this.handleFocus}
                        onChange={this.handleInput}
                        searchable
                      />
                    </Col>
                    <Col>
                      <Form.Label htmlFor="input-pharmacyName">Pharmacy Name / Phone Number</Form.Label>
                      <Form.Control
                        type="input"
                        width={6}
                        name="pharmacyName"
                        id="input-pharmacyName"
                        placeholder=""
                        value={pharmacyName}
                        onChange={this.handleInput}
                      />
                    </Col>
                    <Col>
                      <Form.Check
                        type="checkbox"
                        className="cbxLongLabel"
                        id="input-controlledSubstanceOnly"
                        label="Accepts e-Prescriptions for Controlled Substances"
                        name="controlledSubstanceOnly"
                        checked={controlledSubstanceOnly}
                        onChange={this.handleCheckbox}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Label>Proximity</Form.Label>
                      <Form.Select
                        fluid
                        selection
                        label="Proximity"
                        placeholder="Select..."
                        name="proximityOption"
                        value={proximityOption}
                        options={referenceValues.proximities}
                        onChange={this.handleInput}
                      />
                    </Col>
                    <Col>
                      <Form.Label
                        className={proximityOption === "Zipcode" ? undefined : "disabled-label"}
                        htmlFor="input-zip"
                      >
                        Zip Code
                      </Form.Label>
                      <MaskedInput
                        name="zip"
                        id="input-zip"
                        mask={zipMask}
                        guide={false}
                        value={zipCode}
                        onChange={this.handleMaskedInput}
                        disabled={proximityOption !== "Zipcode"}
                        className="form-control"
                      />
                    </Col>
                    <Col>
                      <Form.Label>Max distance</Form.Label>
                      <Form.Select
                        fluid
                        selection
                        placeholder="Select..."
                        name="proximityMiles"
                        value={proximityMiles}
                        options={referenceValues.distances}
                        onChange={this.handleInput}
                      />
                    </Col>
                    <Col>
                      <Form.Label>&nbsp;</Form.Label>
                      <Button
                        onClick={this.handleSearch}
                        variant="primary"
                        loading={isLoading}
                        disabled={isLoading}
                        className="searchButton"
                        style={{ display: "block", width: "100%" }}
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <h5>
                  {totalRecords >= 0
                    ? `There are ${pharmacyData.length} pharmacies in this search range out of ${totalRecords} possibilities.`
                    : "Enter search criteria to view pharmacies."}
                </h5>
                <div className="pharmacy-table-container">
                  <Table className="pharmacy-search-results" size="sm" striped hover>
                    <thead>
                      <tr>
                        <th width={6} className="reducedHeader">
                          <h5>Name, Address, Phone</h5>
                        </th>
                        <th width={5} className="reducedHeader">
                          <h5>e-Prescribing Services</h5>
                        </th>
                        <th width={3} className="reducedHeader">
                          <h5>Specialties</h5>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {pharmacyData.map((p) => (
                        <tr
                          className={pharmacy.pharmacyKey === p.pharmacyKey ? "lightGreyBackground" : ""}
                          key={`rand-${shortid.generate()}`}
                          // eslint-disable-next-line react/no-unknown-property
                          pharmacykey={p.pharmacyKey}
                          onClick={this.handlePharmacySelect}
                        >
                          <td key={p.pharmacyKey} width={6}>
                            <h3>{p.pharmacyName}</h3>
                            <h4>{p.name}</h4>
                            <h5>{p.address1}</h5>
                            <h5>{p.address2}</h5>
                            <h5>
                              {p.city}, {p.state} {p.zip}
                            </h5>
                            <h5>{p.phone}</h5>
                          </td>
                          <td width={5}>
                            <ul>
                              {p.services.map((v) => (
                                <li key={`rand-${shortid.generate()}`}>{v}</li>
                              ))}
                            </ul>
                          </td>
                          <td width={3}>
                            <ul>
                              {p.specialties.map((s) => (
                                <li key={`rand-${shortid.generate()}`}>{s}</li>
                              ))}
                            </ul>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td style={{ textAlign: "center" }} colSpan={3}>
                          <PaginationRow
                            requestedPage={currentPage}
                            totalRecords={totalRecords}
                            resultsPerPage={requestedPageSize}
                            onPageChange={this.onPageChange}
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
              </Col>
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="danger" onClick={this.handleClose} style={{ marginRight: "auto" }}>
            Cancel
          </Button>
          <Button
            color="blue"
            onClick={this.handleSave}
            loading={isLoading}
            disabled={isLoading || !pharmacy.pharmacyKey}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...prescriptionActions }, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(PatientPharmacyModal);
