import "react-toastify/dist/ReactToastify.css";
import * as types from "./actionTypes";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { API_URL } from "../constants/environment";
import prescriptionStatus from "../constants/prescriptionStatus";
import { adalApiFetch } from "../adalConfig";
import api from "../api";
import {
  pollApiEvents,
  processApiResponse,
  processApiError,
  processThenThrowApiError,
  pendingPrescriptionsUrlQuery
} from "../helpers";

function loadPrescriptionsSuccess(prescriptions) {
  return {
    type: types.LOAD_PRESCRIPTIONS_SUCCESS,
    prescriptions
  };
}

function loadRecurringPrescriptionsSuccess(prescriptions) {
  return {
    type: types.LOAD_RECURRING_PRESCRIPTION_SUCCESS,
    prescriptions
  };
}

function loadPatientFavoritePharmaciesSuccess(pharmacies) {
  return {
    type: types.LOAD_PATIENT_FAVORITE_PHARMACIES_SUCCESS,
    pharmacies
  };
}

export function clearPatientFavoritePharmacies() {
  return {
    type: types.CLEAR_PATIENT_FAVORITE_PHARMACIES
  };
}

function createPrescriptionSuccess(prescriptions) {
  return {
    type: types.CREATE_PRESCRIPTION_SUCCESS,
    prescriptions
  };
}

function createRecurringPrescriptionSuccess(prescriptionId, prescription) {
  return {
    type: types.CREATE_RECURRING_PRESCRIPTION_SUCCESS,
    prescriptionId,
    prescription
  };
}

function addRecurringToCurrentSuccess(prescriptionId, prescription, recurringPrescriptionKey) {
  return {
    type: types.ADD_RECURRING_TO_CURRENT_SUCCESS,
    prescriptionId,
    prescription,
    recurringPrescriptionKey
  };
}

function updatePrescriptionSuccess(prescriptions) {
  return {
    type: types.UPDATE_PRESCRIPTION_SUCCESS,
    prescriptions
  };
}

function setPrescriptionPrescriber(prescription) {
  return {
    type: types.UPDATE_PRESCRIPTION_PRESCRIBER_SUCCESS,
    prescription
  };
}

function updateRecurringPrescriptionSuccess(recurringPrescription) {
  return {
    type: types.UPDATE_RECURRING_PRESCRIPTION_SUCCESS,
    recurringPrescription
  };
}

function deleteRecurringPrescriptionSuccess(recurringPrescriptionId) {
  return {
    type: types.DELETE_RECURRING_PRESCRIPTION_SUCCESS,
    recurringPrescriptionId
  };
}

function loadDrugFormularySuccess(formularyResult) {
  return { type: types.LOAD_DRUG_FORMULARY_SUCCESS, formularyResult };
}

function loadPrescriptionsSentSuccess(prescriptionsSent) {
  return { type: types.LOAD_PRESCRIPTIONS_SENT_SUCCESS, prescriptionsSent };
}

function loadPrescriptionsToSignSuccess(prescriptionsToSign) {
  return {
    type: types.LOAD_PRESCRIPTIONS_TO_SIGN_SUCCESS,
    prescriptionsToSign
  };
}

export function setForceReloadPrescriptionsToSign(value) {
  return {
    type: types.SET_FORCE_RELOAD_PRESCRIPTIONS_TO_SIGN,
    value
  };
}

function loadPrescriptionToSignSuccess(prescription) {
  return {
    type: types.LOAD_PRESCRIPTION_TO_SIGN_SUCCESS,
    prescription
  };
}

function fetchRxPinInfoDone(response) {
  return {
    type: types.FETCH_RX_PIN_INFO_DONE,
    rxPinInfo: response
  };
}

export function setRxItemForm(prescription, updateOriginal = false) {
  return {
    type: types.SET_RX_ITEM_FORM,
    prescription,
    updateOriginal
  };
}

export function applyFavoriteToSelectedPrescription(drugFavorite) {
  return {
    type: types.APPLY_FAVORITE_PRESCRIPTION,
    drugFavorite
  };
}

export function loadPatientFavoritePharmacies(patientId, force = false) {
  return (dispatch, getState) => {
    const cached = getState().prescriptions.favoritePharmacies;
    if (!force && cached.length) return Promise.resolve(cached);
    dispatch(beginAjaxCall("loadPatientFavoritePharmacies"));
    return api
      .fetchPatientFavoritePharmacies(patientId)
      .then((pharmacies) => dispatch(loadPatientFavoritePharmaciesSuccess(pharmacies)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function savePatientFavoritePharmacy(patientId, pharmacy) {
  const func = pharmacy.favoriteId ? api.updatePatientFavoritePharmacies : api.addPatientFavoritePharmacy;
  return (dispatch) => {
    dispatch(beginAjaxCall("savePatientFavoritePharmacy"));
    return func(patientId, pharmacy)
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) =>
        pollApiEvents(
          orchestrationId,
          pharmacy.favoriteId ? "IPatientFavoritePharmacyReplacedEvent" : "IPatientFavoritePharmacyAddedEvent"
        )
      )
      .then(() => dispatch(loadPatientFavoritePharmacies(patientId, true)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function deletePatientFavoritePharmacies(patientId, pharmacy) {
  return (dispatch) => {
    dispatch(beginAjaxCall("deletePatientFavoritePharmacies"));
    return api
      .deletePatientFavoritePharmacies(patientId, pharmacy)
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientFavoritePharmacyRemovedEvent"))
      .then(() => dispatch(loadPatientFavoritePharmacies(patientId, true)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadPrescriptions(patientId, status) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadPrescriptions"));
    return adalApiFetch(`${API_URL}/Prescriptions/${patientId}/Prescriptions?${status}`)
      .then((response) => dispatch(loadPrescriptionsSuccess(response)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadRecurringPrescriptions(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadRecurringPrescriptions"));
    return adalApiFetch(`${API_URL}/Prescriptions/${patientId}/RecurringPrescriptions`)
      .then((response) => dispatch(loadRecurringPrescriptionsSuccess(response)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function createPrescription(patientId, prescription) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createPrescription"));
    return adalApiFetch(`${API_URL}/Prescriptions/${patientId}/Medication`, {
      method: "POST",
      body: JSON.stringify(prescription)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) =>
        Promise.all([
          pollApiEvents(orchestrationId, "IMedicationAddedToPrescriptionEvent")
          // pollApiEvents(orchestrationId, "IDrugInteractionsScreenedEvent"),
          // pollApiEvents(orchestrationId, "IDrugAllergyScreenedEvent")
        ])
      )
      .then(() => adalApiFetch(`${API_URL}/Prescriptions/${patientId}/Prescriptions?${pendingPrescriptionsUrlQuery}`))
      .then((rxs) => dispatch(createPrescriptionSuccess(rxs)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function createRecurringPrescription(patientId, prescription) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createRecurringPrescription"));
    return adalApiFetch(`${API_URL}/Prescriptions/${patientId}/RecurringPrescription`, {
      method: "POST",
      body: JSON.stringify(prescription)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IRecurringMedicationToPrescriptionSavedEvent"))
      .then((pollResponse) =>
        dispatch(createRecurringPrescriptionSuccess(pollResponse.RecurringPrescriptionKey, prescription))
      )
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function addRecurringToCurrent(patientId, prescriptionId, prescription) {
  return (dispatch) => {
    dispatch(beginAjaxCall("addRecurringToCurrent"));
    return adalApiFetch(`${API_URL}/Prescriptions/${patientId}/CreateFromRecurringPrescriptions`, {
      method: "POST",
      body: JSON.stringify(prescriptionId)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IMedicationAddedToPrescriptionEvent"))
      .then((pollResponse) =>
        dispatch(addRecurringToCurrentSuccess(pollResponse.PrescriptionId, prescription, prescription.prescriptionId))
      )
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function updatePrescription(patientId, prescription, prevDrugKey, isRedux = true) {
  return (dispatch) => {
    if (isRedux) {
      dispatch(beginAjaxCall("updatePrescription"));
    }

    const promise = api.updatePrescription(prescription).then(({ orchestrationId } = {}) =>
      Promise.all(
        prevDrugKey === prescription.drugKey
          ? [pollApiEvents(orchestrationId, "IPrescriptionMedicationDetailsUpdatedEvent")]
          : [
              pollApiEvents(orchestrationId, "IPrescriptionMedicationDetailsUpdatedEvent")
              // pollApiEvents(orchestrationId, "IDrugInteractionsScreenedEvent"),
              // pollApiEvents(orchestrationId, "IDrugAllergyScreenedEvent")
            ]
      )
    );

    if (isRedux) {
      return promise
        .then(() => adalApiFetch(`${API_URL}/Prescriptions/${patientId}/Prescriptions?${pendingPrescriptionsUrlQuery}`))
        .then((prescriptions) => dispatch(updatePrescriptionSuccess(prescriptions)))
        .catch((error) => processApiError(error, dispatch))
        .finally(() => dispatch(ajaxCallDone()));
    }

    return promise.catch((error) => processApiError(error, dispatch));
  };
}

export function updatePrescriptionPrescriber(
  prescriptionId,
  { prescriberId, prescriberFirstName, prescriberLastName }
) {
  return (dispatch) => {
    dispatch(beginAjaxCall("updatePrescriptionPrescriber"));
    return api
      .savePrescriptionPrescriber(prescriptionId, { prescriberId })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPrescriptionProviderUpdatedEvent"))
      .then(() =>
        dispatch(
          setPrescriptionPrescriber({
            prescriberId,
            prescriptionId,
            prescriberFirstName,
            prescriberLastName
          })
        )
      )
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function signPrescription(patientId, prescriptionId, payload, isRedux = true) {
  return (dispatch) => {
    if (isRedux) {
      dispatch(beginAjaxCall("signPrescription"));
    }

    const promise = api
      .signPrescription(patientId, prescriptionId, payload)
      .then(({ orchestrationId } = {}) => pollApiEvents(orchestrationId, "IPrescriptionSignedEvent"));

    if (isRedux) {
      return promise
        .then((event) =>
          dispatch({
            type: types.SIGN_PRESCRIPTION_SUCCESS,
            prescriptionStatus: prescriptionStatus.signed,
            prescriptionId,
            noteToPharmacist: event.NoteToPharmacist
          })
        )
        .catch((error) => processApiError(error, dispatch))
        .finally(() => dispatch(ajaxCallDone()));
    }

    return promise.catch((error) => processApiError(error, dispatch));
  };
}

export function updateRecurringPrescription(patientId, recurringPrescription) {
  return (dispatch) => {
    dispatch(beginAjaxCall("updateRecurringPrescription"));
    return adalApiFetch(
      `${API_URL}/Prescriptions/${patientId}/RecurringPrescription/${recurringPrescription.prescriptionId}`,
      {
        method: "PUT",
        body: JSON.stringify(recurringPrescription)
      }
    )
      .then(() => dispatch(updateRecurringPrescriptionSuccess(recurringPrescription)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function deletePrescription(prescriptionId, patientId, isPatientContext = false) {
  return (dispatch) => {
    dispatch(beginAjaxCall("deletePrescription"));
    return adalApiFetch(`${API_URL}/Prescriptions/${prescriptionId}/Medication/${patientId}`, {
      method: "DELETE"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) =>
        Promise.all([
          pollApiEvents(orchestrationId, "IMedicationDeletedFromPrescriptionEvent")
          // pollApiEvents(orchestrationId, "IDrugInteractionsScreenedEvent"),
          // pollApiEvents(orchestrationId, "IDrugAllergyScreenedEvent")
        ])
      )
      .then(() =>
        isPatientContext
          ? adalApiFetch(`${API_URL}/Prescriptions/${patientId}/Prescriptions?${pendingPrescriptionsUrlQuery}`)
          : Promise.resolve([])
      )
      .then((response) => (isPatientContext ? response : Promise.resolve([])))
      .then((prescriptions) =>
        dispatch(
          isPatientContext
            ? { type: types.DELETE_PRESCRIPTION_SUCCESS, prescriptions }
            : {
                type: types.DELETE_PRESCRIPTION_TO_SIGN_SUCCESS,
                prescriptionId
              }
        )
      )
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function deleteRecurringPrescription(recurringPrescriptionId, patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("deleteRecurringPrescription"));
    return adalApiFetch(
      `${API_URL}/Prescriptions/${patientId}/RecurringPrescription/${recurringPrescriptionId}`,
      {
        method: "DELETE"
      },
      false
    )
      .then(() => dispatch(deleteRecurringPrescriptionSuccess(recurringPrescriptionId, patientId)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadDrugFormulary(prescriptionId, patientId, drugKey) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadDrugFormulary"));
    return adalApiFetch(`${API_URL}/Prescriptions/${prescriptionId}/Patients/${patientId}/Alternatives`, {
      method: "POST",
      body: JSON.stringify({ drugKeys: [drugKey] })
    })
      .then((response) => dispatch(loadDrugFormularySuccess(response)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function setPrescriptionDrugChange(drugKey) {
  return (dispatch) =>
    dispatch({
      type: types.SET_PRESCRIPTION_DRUG_CHANGE,
      drugKey
    });
}

export function setSelectedPrescription(prescriptionId, rxType) {
  return (dispatch) =>
    dispatch({
      type: types.SET_SELECTED_PRESCRIPTION,
      prescriptionId,
      rxType
    });
}

export function sendPrescription(patientId, prescriptionIds, payload, fromReport = false) {
  return (dispatch) => {
    dispatch(beginAjaxCall("sendPrescription"));
    const rxIds = prescriptionIds.join("&prescriptionIds=");
    return adalApiFetch(`${API_URL}/Prescriptions/Patients/${patientId}/SendPrescription?prescriptionIds=${rxIds}`, {
      method: "POST",
      body: JSON.stringify(payload)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) =>
        Promise.all([
          pollApiEvents(orchestrationId, "IDrugInteractionsScreenedEvent"),
          pollApiEvents(orchestrationId, "IDrugAllergyScreenedEvent"),
          pollApiEvents(orchestrationId, [
            "IPrescriptionMarkedSentEvent",
            "IPrescriptionsStatusVerifiedEvent",
            "IPrescriptionMarkedAsFailedToCreateBatchEvent",
            "IPrescriptionMarkedAsFailedToSendEvent",
            "IPrescriptionNotSentEvent"
          ])
        ])
      )
      .then(() =>
        fromReport
          ? Promise.resolve([])
          : adalApiFetch(`${API_URL}/Prescriptions/${patientId}/Prescriptions?${pendingPrescriptionsUrlQuery}`)
      )
      .then((response) => (fromReport ? Promise.resolve([]) : response))
      .then((prescriptions) =>
        dispatch({
          type: fromReport ? types.SEND_PRESCRIPTION_FROM_REPORT_SUCCESS : types.SEND_PRESCRIPTION_SUCCESS,
          prescriptions
        })
      )
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadPrescriptionsSent(patientId, _dispatch = true) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadPrescriptonsSent"));
    return adalApiFetch(`${API_URL}/Prescriptions/${patientId}/Prescriptions/Transactions`)
      .then((response) => {
        if (_dispatch) return dispatch(loadPrescriptionsSentSuccess(response));
        return response;
      })
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadPrescriptionsToSign() {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadPrescriptionsToSign"));
    return adalApiFetch(`${API_URL}/Prescriptions/Statuses/ToSign`)
      .then((prescriptionsToSign) => dispatch(loadPrescriptionsToSignSuccess(prescriptionsToSign)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function clearPrescriptionsToSign() {
  return (dispatch) => dispatch({ type: types.CLEAR_PRESCRIPTIONS_TO_SIGN });
}

export function loadPrescriptionToSign(prescriptionId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadPrescription"));
    return adalApiFetch(`${API_URL}/Prescriptions/${prescriptionId}`)
      .then((response) => dispatch(loadPrescriptionToSignSuccess(response)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function clearPrescriptionToSign() {
  return (dispatch) => dispatch({ type: types.CLEAR_PRESCRIPTION_TO_SIGN });
}

export function saveAttestation(patientId, prescriptionId, payload) {
  return (dispatch) => {
    dispatch(beginAjaxCall("saveAttestation"));
    return api
      .saveAttestation(patientId, prescriptionId, payload)
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IAttestationSavedEvent"))
      .then(() => dispatch({ type: types.SAVE_ATTESTATION_SUCCESS }))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function generatePrescriptionDocuments(patientId, query, payload) {
  return (dispatch) => {
    dispatch(beginAjaxCall("generatePrescriptionDocuments"));
    return api
      .generatePrescriptionDocuments(patientId, query, payload)
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPrescriptionsToPrintDocumentCreatedEvent"))
      .then((event) =>
        Promise.all([
          Promise.resolve(event),
          adalApiFetch(`${API_URL}/Prescriptions/${patientId}/Prescriptions?${pendingPrescriptionsUrlQuery}`)
        ])
      )
      .then(([event, prescriptions]) =>
        dispatch({
          type: types.PRINT_PRESCRIPTION_SUCCESS,
          prescriptions,
          prescriptionsToPrint: event.Prescriptions
        })
      )
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadPrescriptionsSentByVisit(visitId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("getPrescriptionsSentByVisit"));
    return api
      .getPrescriptionsSentByVisit(visitId)
      .then((response) =>
        dispatch({ type: types.LOAD_PRESCRIPTIONS_SENT_BY_VISIT_SUCCESS, prescriptionsSentByVisit: response })
      )
      .catch((error) => processApiError(error, dispatch))
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function checkBuprenorphinePrescriptionQueued(patientId, visitId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("checkBuprenorphinePrescriptionQueued"));
    return api
      .checkBuprenorphinePrescriptionQueued(patientId, visitId)
      .then((response) => {
        dispatch(ajaxCallDone());
        return response;
      })
      .catch((error) => processApiError(error, dispatch));
  };
}

export function fetchRxPinInfo() {
  return (dispatch) => {
    dispatch(beginAjaxCall("fetchRxPinInfo"));
    return adalApiFetch(`${API_URL}/Prescriptions/rxpin`)
      .then((response) => dispatch(fetchRxPinInfoDone(response)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function setRxPin(form) {
  return (dispatch) => {
    dispatch(beginAjaxCall("setRxPin"));
    return adalApiFetch(`${API_URL}/Prescriptions/rxpin`, {
      method: "POST",
      body: JSON.stringify(form)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IRxPinSetEvent"))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}
