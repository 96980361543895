/* eslint-disable no-param-reassign */
import { createReducer } from "@reduxjs/toolkit";
import * as types from "../actions/actionTypes";
import initialState from "./initialState";

const reducer = createReducer(initialState.userPreference, (builder) => {
  builder
    .addCase(types.UPDATE_USER_PREFERENCE, (state, action) => {
      state = action.userPreference;
    })
    .addCase(types.LOAD_CURRENT_USER_ID_SUCCESS, (state, action) => {
      Object.keys(action.user.userPreferenceSetting).forEach((key) => {
        state[key] = action.user.userPreferenceSetting[key];
      });
      state.currentUserId = action.user.currentUser;
      state.currentUserFullName = action.user.userFullName;
    })
    .addCase(types.SET_PROVIDER_DETAILS, (state, action) => {
      state.providerDetails = action.providerDetails;
    })
    .addCase(types.SET_RELOAD_PAGE_IN, (state, action) => {
      state.reloadPageIn = action.value;
      state.forceUpdate = action.forceUpdate;
      state.newVersion = action.newVersion || state.newVersion;
    })
    .addCase(types.SET_RETRY_CONNECTING_IN, (state, action) => {
      if (state.retryConnectingIn <= -1 || action.value !== 30) state.retryConnectingIn = action.value;
    })
    .addCase(types.SET_RETRY_REACHING_SERVER_IN, (state, action) => {
      if (state.retryConnectingIn > -1) state.retryReachingServerIn = -1;
      else if (state.retryReachingServerIn <= -1 || action.value !== 30) state.retryReachingServerIn = action.value;
    })
    .addCase(types.SET_THEME, (state, action) => {
      state.theme = action.theme;
    })
    .addMatcher(
      ({ type }) => [types.LOAD_CENTER_CONTEXT_SUCCESS, types.UPDATE_CENTER_CONTEXT_SUCCESS].includes(type),
      (state, action) => {
        state.centerContext = action.center;
      }
    );
});

export default reducer;
