import "react-toastify/dist/ReactToastify.css";
import * as types from "./actionTypes";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { pollApiEvents, processApiResponse, processApiError, processThenThrowApiError } from "../helpers";
import { adalApiFetch } from "../adalConfig";
import { API_URL } from "../constants/environment";
import { updateVisitPatientLevel } from "./visitActions";

function loadPatientSummarySuccess(patientId, patientSummary) {
  return {
    type: types.LOAD_PATIENT_SUMMARY_SUCCESS,
    context: "patient",
    patientId,
    patientSummary
  };
}

function loadPatientExternalRefsSuccess(patientId, patientExternalRefs) {
  return {
    type: types.LOAD_PATIENT_EXTERNAL_REFS_SUCCESS,
    context: "patient",
    patientId,
    patientExternalRefs
  };
}

function loadAdvanceDirectivesDone(patientId, advanceDirectives) {
  return {
    type: types.LOAD_ADVANCE_DIRECTIVES_DONE,
    context: "patient",
    patientId,
    advanceDirectives
  };
}

function createPatientSuccess(response, patient) {
  return { type: types.CREATE_PATIENT_SUCCESS, response, patient };
}

export function updateCurrentPatient(patientId, patient) {
  return { type: types.UPDATE_CURRENT_PATIENT, context: "patient", patientId, patient };
}

export function updateCurrentPatientAddress(patientId, address) {
  return { type: types.UPDATE_CURRENT_PATIENT_ADDRESS, context: "patient", patientId, address };
}

export function updateCurrentPatientInsurance(patientId, insurance) {
  return { type: types.UPDATE_CURRENT_PATIENT_INSURANCE, context: "patient", patientId, insurance };
}

export function updateCurrentPatientTreatmentPlanStatus(patientId, treatmentPlanStatus) {
  return {
    type: types.UPDATE_CURRENT_PATIENT_TREATMENT_PLAN_STATUS,
    context: "patient",
    patientId,
    treatmentPlanStatus
  };
}

function createPhotoSuccess(patientId, response, image) {
  return {
    type: types.CREATE_PHOTO_SUCCESS,
    context: "patient",
    patientId,
    image
  };
}

/** deprecated not used
function saveDefaultPrescribingProviderSuccess(patientId, response, providerId) {
  return {
    type: types.SET_DEFAULT_PRESCRIBING_PROVIDER_DONE,
    context: "patient",
    patientId,
    providerId
  };
}
*/

function setPatientLevelSuccess(patientId, response, level, bh) {
  return {
    type: types.SET_PATIENT_LEVEL,
    context: "patient",
    patientId,
    response,
    level,
    bh
  };
}

export function updatePatientLevel(patientId, level) {
  return {
    type: types.UPDATE_PATIENT_LEVEL,
    context: "patient",
    patientId,
    level
  };
}

export function getPatientNoteSlashTaskCountDone(patientId, response) {
  return {
    type: types.GET_PATIENT_NOTE_SLASH_TASK_COUNT_DONE,
    context: "patient",
    patientId,
    response
  };
}

function fetchCustomFieldsDone(patientId, response) {
  return {
    type: types.FETCH_CUSTOM_FIELDS_DONE,
    context: "patient",
    patientId,
    data: response
  };
}

export function clearPatient() {
  return {
    type: types.CLEAR_PATIENT
  };
}

export function createPatient(patient) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createPatient"));
    return adalApiFetch(`${API_URL}/Patients`, {
      method: "POST",
      body: JSON.stringify(patient)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientCreatedEvent"))
      .then((pollResponse) => dispatch(createPatientSuccess(pollResponse, patient)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadPatientSummary(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadPatientSummary"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/Summary`)
      .then((patientSummary) => dispatch(loadPatientSummarySuccess(patientId, patientSummary)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadPatientExternalRefs(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadPatientExternalRefs"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/ExternalRefs`)
      .then((patientExternalRefs) => dispatch(loadPatientExternalRefsSuccess(patientId, patientExternalRefs)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadAdvanceDirectives(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadAdvanceDirectives"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/AdvanceDirectives`)
      .then((advanceDirectives) => dispatch(loadAdvanceDirectivesDone(patientId, advanceDirectives)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function createPhoto(patientId, image) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createPhoto"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/Photo`, {
      method: "POST",
      body: JSON.stringify(image)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientPhotoUploadedEvent"))
      .then((pollResponse) => dispatch(createPhotoSuccess(patientId, pollResponse, image)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

/** deprecated not used
export function retrievePatientId(orchestrationId) {
  return dispatch => {
    dispatch(beginAjaxCall("retrievePatientId"));
    return adalApiFetch(`${API_URL}/Patients/Orchestrations/${orchestrationId}`, {
      method: "GET"
    })
      .then(response => {
        dispatch(retrievePatientIdSuccess(response.orchestrationId));
      })
      .catch(error => {
        processApiError(error, dispatch);
      }).finally(() => {
            dispatch(ajaxCallDone());
          });
  };
}
*/

/** deprecated not used
export function saveDefaultPrescribingProvider(patientId, providerId) {
  return dispatch => {
    dispatch(beginAjaxCall("saveDefaultPrescribingProvider"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/DefaultPrescribingProvider/${providerId}`, {
      method: "POST"
    })
      .then(response => {
        dispatch(saveDefaultPrescribingProviderSuccess(patientId, response, providerId));
      })
      .catch(error => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}
*/

export function setPatientLevel(patientId, visitId, levelName, bh = false) {
  return (dispatch) => {
    dispatch(beginAjaxCall("setPatientLevel"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/${bh ? `BHLevel` : `Level`}`, {
      method: "POST",
      body: JSON.stringify({ visitId, levelName })
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) =>
        pollApiEvents(orchestrationId, bh ? "IPatientBHLevelUpdatedEvent" : "IPatientLevelUpdatedEvent")
      )
      .then((pollResponse) => {
        dispatch(updateVisitPatientLevel(visitId, patientId, levelName, bh));
        dispatch(setPatientLevelSuccess(patientId, pollResponse, levelName, bh));
      })
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function saveAdvanceDirectives(patientId, advanceDirectives) {
  return (dispatch) => {
    dispatch(beginAjaxCall("saveAdvanceDirectives"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/AdvanceDirectives`, {
      method: "POST",
      body: JSON.stringify(advanceDirectives)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientAdvanceDirectivesUpdatedEvent"))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function getPatientNoteSlashTaskCount(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("getPatientNoteSlashTaskCount"));
    return adalApiFetch(`${API_URL}/Patient/${patientId}/Tasks/Open/Count`)
      .then((response) => dispatch(getPatientNoteSlashTaskCountDone(patientId, response)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function fetchCustomFields(patientId, ids) {
  const customFieldIds = ids.join(",");
  return (dispatch) => {
    dispatch(beginAjaxCall("fetchCustomFields"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/CustomFieldAnswer/${customFieldIds}`)
      .then((response) => dispatch(fetchCustomFieldsDone(patientId, response)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function saveCustomFields(patientId, customFields) {
  return (dispatch, getState) => {
    const patientCustomFields = getState().lookups.patientCustomFields;
    dispatch(beginAjaxCall("saveCustomFields"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/CustomFieldAnswer`, {
      method: "PUT",
      body: JSON.stringify(customFields)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IPatientCustomFieldsAnswersUpdatedEvent"))
      .then((response) => {
        dispatch(
          fetchCustomFieldsDone(
            patientId,
            response.CustomFieldsAnswers.map((item) => ({
              ...item,
              id: patientCustomFields.find((cf) => +cf.customeFieldAthenaId === +item.CustomFieldId).id
            }))
          )
        );
      })
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}
