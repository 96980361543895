import React from "react";
import { Form } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import { dateFormat } from "../../../constants/miscellaneous";
import * as validation from "../../../constants/validation";

const NewPatientForm = ({
  patient,
  handleInput,
  handleInputWithSearch,
  handleMaskedInputWithSearch,
  // onToggle,
  // handleDatePicker,
  centers,
  // providers,
  homeCenterId,
  handleFocus,
  errors
}) => {
  const withoutVisit = (
    <Form.Field
      className={
        Object.prototype.hasOwnProperty.call(errors, "homeCenterId") ? "error required field" : "required field"
      }
    >
      <Form.Dropdown
        fluid
        selection
        search
        required
        label="Center"
        name="homeCenterId"
        id="input-centerDropdown"
        placeholder="Select..."
        options={centers}
        value={patient.homeCenterId}
        onChange={handleInput}
        onFocus={handleFocus}
      />
    </Form.Field>
  );
  return (
    <Form>
      <Form.Field>
        <Form.Radio
          label="With Visit"
          name="visit_radio"
          id="input-withVisitRadio"
          value="with"
          checked={patient.visit_radio === "with"}
          onChange={handleInput}
        />
      </Form.Field>
      <Form.Field>
        <Form.Radio
          label="Without Visit"
          name="visit_radio"
          id="input-withoutVisitRadio"
          value="without"
          checked={patient.visit_radio === "without"}
          onChange={handleInput}
        />
      </Form.Field>
      <Form.Field>
        <Form.Input
          label="First Name"
          name="patientFirstName"
          id="input-firstName"
          required
          maxLength={validation.inputMaxLength}
          error={Object.prototype.hasOwnProperty.call(errors, "patientFirstName")}
          value={patient.patientFirstName}
          onChange={handleInputWithSearch}
        />
      </Form.Field>
      <Form.Field>
        <Form.Input
          label="Last Name"
          name="patientLastName"
          id="input-lastName"
          required
          maxLength={validation.inputMaxLength}
          error={Object.prototype.hasOwnProperty.call(errors, "patientLastName")}
          value={patient.patientLastName}
          onChange={handleInputWithSearch}
        />
      </Form.Field>
      <Form.Field
        className={
          Object.prototype.hasOwnProperty.call(errors, "patientDateOfBirth") ? "error required field" : "required field"
        }
      >
        <label htmlFor="input-dob" className="mock-semantic-required">
          Date of Birth
        </label>
        <DateInput
          id="input-dob"
          name="patientDateOfBirth"
          placeholder="Date Of Birth"
          value={patient.patientDateOfBirth}
          dateFormat={dateFormat}
          iconPosition="right"
          onChange={handleMaskedInputWithSearch}
          required
          hideMobileKeyboard
          closable
        />
      </Form.Field>
      {patient.visit_radio === "with" && homeCenterId ? null : withoutVisit}
    </Form>
  );
};

export default NewPatientForm;
