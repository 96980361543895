import "react-toastify/dist/ReactToastify.css";
import * as types from "./actionTypes";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { API_URL } from "../constants/environment";
import {
  pollApiEvents,
  processApiResponse,
  processApiError,
  processThenThrowApiError,
  pushOrchestraItem
} from "../helpers";
import { adalApiFetch } from "../adalConfig";
import { registerUserCenterByGateway } from "./userActions";

function loadProvidersSuccess(providers) {
  return {
    type: types.LOAD_PROVIDERS_SUCCESS,
    providers
  };
}

function updateProviderSuccess(providerDetails) {
  return {
    type: types.UPDATE_PROVIDER_SUCCESS,
    providerDetails
  };
}

function loadPrescriberHistorySuccess(prescribers) {
  return {
    type: types.LOAD_PRESCRIBER_HISTORY_SUCCESS,
    prescribers
  };
}

function loadProviderCentersSuccess(centers) {
  return {
    type: types.LOAD_PROVIDER_CENTERS_SUCCESS,
    centers
  };
}

function updateProviderCentersSuccess(centers) {
  return {
    type: types.UPDATE_PROVIDER_CENTERS_SUCCESS,
    centers
  };
}

function setSelectedProviderIntegrationValues(selectedProviderIntegrationValues) {
  return {
    type: types.SET_SELECTED_PROVIDER_INTEGRATION_KEYS,
    selectedProviderIntegrationValues
  };
}

function setSelectedProviderGateways(gateways) {
  return {
    type: types.SET_SELECTED_PROVIDER_GATEWAYS,
    gateways
  };
}

export function setIdentityProofingStatus(status) {
  return {
    type: types.SET_IDENTITY_PROOFING_STATUS,
    status
  };
}

export function setLaunchSelfAdminSession(selfAdminSession) {
  return {
    type: types.SET_LAUNCH_SELF_ADMIN_SESSION,
    selfAdminSession
  };
}

export function loadProviders() {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadProviders"));
    return adalApiFetch(`${API_URL}/Users/Roles?roleName=EMR-Provider`)
      .then((json) => dispatch(loadProvidersSuccess(json)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadProviderIntegrationValues(providerId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadProviderIntegrationValues"));
    return adalApiFetch(`${API_URL}/Users/Providers/${providerId}/StateKeys`, {
      method: "GET"
    })
      .then((response) => dispatch(setSelectedProviderIntegrationValues(response)))

      .catch((error) => {
        dispatch(setSelectedProviderIntegrationValues([]));
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function updateProvider(providerId, providerDetails) {
  return (dispatch) => {
    dispatch(beginAjaxCall("updateProvider"));
    return adalApiFetch(`${API_URL}/Users/Providers/${providerId}`, {
      method: "PUT",
      body: JSON.stringify(providerDetails)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IUserProviderDetailsUpdatedEvent"))
      .then((response) => dispatch(updateProviderSuccess(response, providerDetails)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function updateProviderBonusSlots(providerId, bonusCoverageSlots) {
  return (dispatch) => {
    dispatch(beginAjaxCall("updateProvider"));
    return adalApiFetch(`${API_URL}/Users/Providers/${providerId}`, {
      method: "PATCH",
      body: JSON.stringify({ bonusCoverageSlots })
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IProviderSlotsDataUpdatedEvent"))
      .then((response) => dispatch(updateProviderSuccess(response, bonusCoverageSlots)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadPrescriberHistory(patientId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadPatientDemo"));
    return adalApiFetch(`${API_URL}/Patients/${patientId}/PrescribingProviderHistory`)
      .then((prescribers) => dispatch(loadPrescriberHistorySuccess(prescribers)))
      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function loadProviderCenters(providerId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadProviderCenters"));
    return adalApiFetch(`${API_URL}/Users/${providerId}/Centers`)
      .then((json) => dispatch(loadProviderCentersSuccess(json)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function updateProviderCenters(providerId, providerCenters, gateway) {
  return (dispatch) => {
    dispatch(beginAjaxCall("updateProviderCenters"));
    return adalApiFetch(`${API_URL}/Users/${providerId}/Centers`, {
      method: "PUT",
      body: JSON.stringify(providerCenters)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IUserCentersUpdatedEvent"))
      .then(() => dispatch(updateProviderCentersSuccess(providerCenters)))
      .then(() => {
        const specificCenterIds = [...new Set([providerCenters.homeCenterId, ...providerCenters.otherCenterIds])];
        return dispatch(registerUserCenterByGateway(providerId, { allCenters: false, specificCenterIds, gateway }));
      })
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadProvidersByState(currenCenter) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadProviders"));
    return adalApiFetch(`${API_URL}/Users/ProvidersInState?currentCenter=${currenCenter || ""}`)
      .then((json) => dispatch(loadProvidersSuccess(json)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

/**
export function loadProvidersAndSuperProvider() {
  const roles = ["EMR-Provider", "EMR-SupervisingProvider"];
  return (dispatch) => {
    dispatch(beginAjaxCall("loadProvidersAndSuperProvider"));
    return adalApiFetch(`${API_URL}/Users/Roles`, {
      method: "POST",
      body: JSON.stringify(roles)
    })
      .then((json) => dispatch(loadProvidersSuccess(json)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}
*/

export function addSelectedProviderIntegrationKeyValue(integrationKey) {
  return (dispatch) =>
    Promise.resolve(
      dispatch({
        type: types.ADD_SELECTED_PROVIDER_INTEGRATION_KEY_VALUE,
        integrationKey
      })
    );
}

export function deleteSelectedProviderIntegrationKeyValues(index) {
  return (dispatch) =>
    Promise.resolve(
      dispatch({
        type: types.DELETE_SELECTED_PROVIDER_INTEGRATION_KEY_VALUE,
        index
      })
    );
}

export function clearIdentityProofingStatus() {
  return {
    type: types.SET_IDENTITY_PROOFING_STATUS,
    status: {}
  };
}

export function saveSelectedProviderIntegrationKeyValues(providerId) {
  return (dispatch, getState) => {
    dispatch(beginAjaxCall("saveSelectedProviderIntegrationKeyValues"));
    return adalApiFetch(`${API_URL}/Users/Providers/${providerId}/StateKeys`, {
      method: "PUT",
      body: JSON.stringify(getState().providers.selectedProviderIntegrationValues)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IProviderStateKeysUpdatedEvent"))
      .then(() => dispatch(setSelectedProviderIntegrationValues([])))

      .catch((error) => {
        processApiError(error, dispatch);
      })
      .finally(() => {
        dispatch(ajaxCallDone());
      });
  };
}

export function clearSelectedProviderIntegrationKeyValues() {
  return (dispatch) => {
    dispatch(setSelectedProviderIntegrationValues([]));
  };
}

export function fetchSelectedProviderGateways(providerId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("fetchSelectedProviderGateways"));
    return adalApiFetch(`${API_URL}/Admin/gateways/${providerId}`)
      .then((response) => dispatch(setSelectedProviderGateways(response)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function queryIdentityProofingStatus() {
  return (dispatch) => {
    dispatch(beginAjaxCall("queryIdentityProofingStatus"));
    return adalApiFetch(`${API_URL}/Providers/IdentityProofing/QueryStatus`, { method: "POST" })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => {
        pushOrchestraItem(orchestrationId, +new Date());
      })
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function setProviderRxGateway(providerId, gateway) {
  return (dispatch) => {
    dispatch(beginAjaxCall("setProviderRxGateway"));
    return adalApiFetch(`${API_URL}/Admin/rxgateway`, {
      method: "POST",
      body: JSON.stringify({
        allUsers: false,
        userIds: [providerId],
        gateway
      })
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IUserRxGatewayUpdatedEvent"))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function orderLicenseAndStartProofing(form) {
  return (dispatch) => {
    dispatch(beginAjaxCall("orderLicenseAndStartProofing"));
    return adalApiFetch(`${API_URL}/Providers/IdentityProofing/OrderLicenseAndStartProofing`, {
      method: "POST",
      body: JSON.stringify(form)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => {
        pushOrchestraItem(orchestrationId, +new Date(), () => {
          window.cleanSlate.store.dispatch(setIdentityProofingStatus({ error: true }));
        });
      })
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function setExostarSigningPermission(providerId, granted) {
  return (dispatch) => {
    dispatch(beginAjaxCall("setExostarSigningPermission"));
    return adalApiFetch(`${API_URL}/Users/${providerId}/ExostarSigningPermission/${granted}`, { method: "POST" })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "IExostarSigningPermissionChangedEvent"))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function resumeIdentityProofing() {
  return (dispatch) => {
    dispatch(beginAjaxCall("resumeIdentityProofing"));
    return adalApiFetch(`${API_URL}/Providers/IdentityProofing/Resume`, { method: "POST" })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => {
        pushOrchestraItem(orchestrationId, +new Date());
      })
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function cancelIdentityProofing() {
  return (dispatch) => {
    dispatch(beginAjaxCall("cancelIdentityProofing"));
    return adalApiFetch(`${API_URL}/Providers/IdentityProofing/Cancel`, { method: "POST" })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => {
        pushOrchestraItem(orchestrationId, +new Date());
      })
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function queryLaunchSessionUrl() {
  return (dispatch) => {
    dispatch(beginAjaxCall("queryLaunchSessionUrl"));
    return adalApiFetch(`${API_URL}/Providers/LaunchSession`, { method: "POST" })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => {
        pushOrchestraItem(orchestrationId, +new Date());
      })

      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

function setIdentityProofingFlags(flags) {
  return {
    type: types.SET_IDENTITY_PROOFING_FLAGS,
    flags
  };
}

export function fetchIdentityProofingFlags() {
  return (dispatch) => {
    dispatch(beginAjaxCall("fetchIdentityProofingFlags"));
    return adalApiFetch(`${API_URL}/Providers/IdentityProofing/Flags`)
      .then((response) => dispatch(setIdentityProofingFlags(response)))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function finalizeIdentityProofing(form) {
  return (dispatch) => {
    dispatch(beginAjaxCall("finalizeIdentityProofing"));
    return adalApiFetch(`${API_URL}/Providers/IdentityProofing/Finalize`, {
      method: "POST",
      body: JSON.stringify(form)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) =>
        pollApiEvents(orchestrationId, ["IEpcsRegistrationFinalizedEvent", "IFinalizeEpcsRegistrationFailedEvent"])
      )
      .then((response) => {
        if (response.eventTypes === "IFinalizeEpcsRegistrationFailedEvent") {
          throw new Error(response.Error);
        }
        return response;
      })
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function renewIdentityProofing(userId, model) {
  return (dispatch) => {
    dispatch(beginAjaxCall("renewIdentityProofing"));
    return adalApiFetch(`${API_URL}/Providers/IdentityProofing/renew/${userId}`, {
      method: "POST",
      body: JSON.stringify(model)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, ["IIdentityLicenseRenewedEvent"]))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function searchSupervisionCycle(model) {
  return (dispatch) => {
    dispatch(beginAjaxCall("searchSupervisionCycle"));
    return adalApiFetch(`${API_URL}/Supervision/Cycle/Search`, {
      method: "POST",
      body: JSON.stringify(model)
    })
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function saveNewSupervisionCycle(providerId, model) {
  return (dispatch) => {
    dispatch(beginAjaxCall("searchSupervisionCycle"));
    return adalApiFetch(`${API_URL}/Supervision/Cycle/${providerId}`, {
      method: "POST",
      body: JSON.stringify(model)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, ["ISupervisionCycleAddedEvent"]))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function updateSupervisionCycle(cycleId, model) {
  return (dispatch) => {
    dispatch(beginAjaxCall("updateSupervisionCycle"));
    return adalApiFetch(`${API_URL}/Supervision/Providers/${cycleId}`, {
      method: "PUT",
      body: JSON.stringify(model)
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, ["ISupervisionCycleUpdatedEvent"]))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function syncUserRoles(userId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("syncUserRoles"));
    return adalApiFetch(`${API_URL}/Users/${userId}/syncRoles`, {
      method: "POST"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, ["IUserRolesRetrievedSuccessfullyEvent"]))
      .catch((error) => processThenThrowApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}
