import React from "react";
import { Menu, Popup, Grid, Button, Icon, Transition } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isObjectLike } from "lodash";
import moment from "moment";
import * as badgeActions from "../../actions/badgeActions";
import * as notificationActions from "../../actions/notificationActions";
import { MyNotifications } from "../../assets/common/SideNavImages";
import { dateWithTimeFormat } from "../../constants/miscellaneous";
import { resetNotifications, dismissNotification } from "../../api/notification";
import { processApiError, isEmpty } from "../../helpers";

export class Notifications extends React.Component {
  state = {
    fetched: false,
    isLoading: false,
    loadingNotifications: []
  };

  componentDidMount() {
    const { actions, loadAction } = this.props;
    if (loadAction) {
      actions[loadAction]().then();
    }
  }

  getNotifications = () => {
    if (this.state.fetched) return;
    this.setState({ isLoading: true });
    this.props.actions.getNotifications().then(() => {
      this.setState({ isLoading: false, fetched: true });
    });
  };

  dismissNotification = (notificationId) => {
    this.setState({ isLoading: true, loadingNotifications: [...this.state.loadingNotifications, notificationId] });
    dismissNotification(notificationId)
      .then(() => {
        this.setState({ isLoading: false });
      })
      .catch((error) => processApiError(error));
  };

  dismissAllNotifications = () => {
    this.setState({ isLoading: true, loadingNotifications: this.props.notifications.notifications.map((n) => n.id) });
    resetNotifications().catch((error) => processApiError(error));
  };

  renderNotifications = () => {
    const {
      notifications: { notifications }
    } = this.props;
    const { isLoading, loadingNotifications } = this.state;

    if (isLoading && notifications.length < 1) {
      return (
        <Grid.Row>
          <Grid.Column width={16} className="text-center">
            <h5>Loading...</h5>
          </Grid.Column>
        </Grid.Row>
      );
    }

    if (notifications.length) {
      return notifications.map(({ id, notificationTime, content, options }) => {
        const linkIdentifier = isObjectLike(options) && Object.keys(options).find((o) => o === "patientId");
        return (
          <Grid.Row key={id} verticalAlign="top" className="notification-row">
            <Grid.Column width={5}>{moment(notificationTime).format(dateWithTimeFormat)}</Grid.Column>
            <Grid.Column width={8}>
              {content}
              {linkIdentifier && (
                <div>
                  Click{" "}
                  <Link
                    to={`/patient/${options[linkIdentifier]}/demographics`}
                    style={{ display: "inline-block" }}
                    target="_blank"
                  >
                    {"here"}
                  </Link>{" "}
                  to see patient.
                </div>
              )}
            </Grid.Column>
            <Grid.Column width={3} textAlign="center" className="half-padding">
              <Button
                size="mini"
                animated
                loading={loadingNotifications.indexOf(id) !== -1}
                disabled={loadingNotifications.indexOf(id) !== -1}
                className="transparent-button half-padding"
                onClick={() => this.dismissNotification(id)}
              >
                <Button.Content visible>Dismiss</Button.Content>
                <Button.Content hidden>
                  <Icon name="check" />
                </Button.Content>
              </Button>
            </Grid.Column>
          </Grid.Row>
        );
      });
    }

    return (
      <Grid.Row>
        <Grid.Column width={16}>
          <h5>No notifications at the moment!</h5>
        </Grid.Column>
      </Grid.Row>
    );
  };

  render() {
    const {
      badge,
      name,
      isMobile,
      notifications: { notifications }
    } = this.props;
    const { isLoading } = this.state;
    return (
      <Popup
        on="click"
        trigger={
          <Menu.Item id="sidebar-notifications" style={{ cursor: "pointer" }}>
            <MyNotifications />
            {badge && badge !== 0 ? <span className="connected-badge urgent">{badge}</span> : null}{" "}
            {isMobile && <span>{name}</span>}
          </Menu.Item>
        }
        onOpen={this.getNotifications}
        onClose={() => this.setState({ isLoading: false, loadingNotifications: [] })}
        wide="very"
        hoverable
        className="sysnoti no-padding"
      >
        <Popup.Content>
          <div style={{ maxHeight: "80vh", overflow: "auto" }}>
            <Grid className="no-padding no-margin">
              <Grid.Row>
                <Grid.Column width={16} textAlign="right">
                  {!isEmpty(notifications) && (
                    <Button
                      size="mini"
                      animated
                      loading={isLoading}
                      disabled={isLoading}
                      className="transparent-button half-padding"
                      onClick={this.dismissAllNotifications}
                    >
                      <Button.Content visible>Dismiss all</Button.Content>
                      <Button.Content hidden>
                        <Icon name="check" />
                      </Button.Content>
                    </Button>
                  )}
                </Grid.Column>
              </Grid.Row>
              <Transition.Group animation="slide right" duration={500}>
                {this.renderNotifications()}
              </Transition.Group>
            </Grid>
          </div>
        </Popup.Content>
      </Popup>
    );
  }
}

function mapStateToProps(state) {
  return {
    badge: state.badges.notifications,
    notifications: state.notifications
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...badgeActions, ...notificationActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
