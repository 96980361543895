import { loadTasksBadge } from "../../actions/badgeActions";

const store = window.cleanSlate.store;

const handleNumberOfPendingTasks = (message) => {
  /* {"Type":"@Notification.Center.NumberOfPendingTasks","Title":"Number of pending tasks is updated","Content":{"reload":true},"Options":null,"Time":"2020-10-28T19:54:35.9093169+02:00"} */
  if (message.Content.reload) store.dispatch(loadTasksBadge());
};

export default {
  handleNumberOfPendingTasks
};
