import * as types from "./actionTypes";

export function showModal(modal) {
  const { props: { multiple } = {} } = modal;
  return { type: multiple ? types.SHOW_MULTIPLE_MODALS : types.SHOW_MODAL, modal };
}

export function hideModal(multiple, closeAll = false, prevModalProps = {}) {
  return { type: multiple ? types.HIDE_MULTIPLE_MODALS : types.HIDE_MODAL, closeAll, prevModalProps };
}

export function showConfirmationModal(modal) {
  return { type: types.SHOW_CONFIRMATION_MODAL, modal };
}

export function hideConfirmationModal() {
  return { type: types.HIDE_CONFIRMATION_MODAL };
}
