import React from "react";
import { Message, Button } from "semantic-ui-react";
import withRouter from "../../hoc/withRouter";
import { logError } from "../../helpers";

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(/* error */) {
    return { hasError: true, error: { message: "" } };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error, info) {
    this.setState({ error });
    logError(error.message, "", "", info.componentStack);
  }

  handleTryAgain = () => this.setState({ hasError: false });

  handleBackToHome = () => {
    const { router } = this.props;
    router.navigate("/");
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <React.Fragment>
          <Message color="red" style={{ margin: "0", textAlign: "center" }}>
            <h3>Something went wrong.</h3>
            <h4>{this.state.error.message}</h4>
            <hr />
            <Button color="green" size="tiny" onClick={this.handleTryAgain} content="Try Again" />
            <Button color="blue" size="tiny" onClick={this.handleBackToHome} content="Back to Home" />
          </Message>
        </React.Fragment>
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
