export const roleNames = {
  callCenter: "EMR-CallCenter",
  receptionist: "EMR-Receptionist",
  labTech: "EMR-LabTechnician",
  medicalAssist: "EMR-MedicalAssistant",
  provider: "EMR-Provider",
  supProvider: "EMR-SupervisingProvider",
  ldac1: "EMR-LDAC1",
  ldac2: "EMR-LDAC2",
  careCoordinator: "EMR-CareCoordinator",
  certRecovSpecialist: "EMR-CertifiedRecoverySpecialist",
  socialWorker: "EMR-SocialWorker",
  centerManager: "EMR-CenterManager",
  billingSpecialist: "EMR-BillingSpecialist",
  billingManager: "EMR-BillingManager",
  billingDirector: "EMR-BillingDirector",
  systemAdmin: "EMR-SystemAdministrator",
  helpDesk: "EMR-HelpDesk"
};

function filterRoles(roles) {
  return Object.keys(roleNames)
    .filter((role) => !roles.includes(role))
    .map((role) => roleNames[role]);
}

export const roleGroups = {
  excludeHelpDesk: filterRoles(["helpDesk"]),
  excludeHelpDeskCallCenter: filterRoles(["helpDesk", "callCenter"]),
  dischargeView: [
    roleNames.receptionist,
    roleNames.provider,
    roleNames.supProvider,
    roleNames.centerManager,
    roleNames.systemAdmin
  ],
  labHistory: [
    roleNames.medicalAssist,
    roleNames.receptionist,
    roleNames.labTech,
    roleNames.provider,
    roleNames.supProvider,
    roleNames.systemAdmin,
    roleNames.callCenter,
    roleNames.ldac1,
    roleNames.ldac2,
    roleNames.careCoordinator,
    roleNames.certRecovSpecialist,
    roleNames.centerManager,
    roleNames.billingManager,
    roleNames.socialWorker,
    roleNames.billingSpecialist,
    roleNames.billingDirector
  ],
  providersLdac: [roleNames.provider, roleNames.supProvider, roleNames.ldac1, roleNames.ldac2, roleNames.centerManager],
  supProvidersLdac1: [roleNames.supProvider, roleNames.ldac1, roleNames.centerManager],
  systemAdmin: [roleNames.systemAdmin],
  centerManager: [roleNames.centerManager],
  createNewPatient: [roleNames.systemAdmin],
  createNewVisit: [roleNames.systemAdmin],
  createProgressNoteVisit: [
    roleNames.systemAdmin,
    roleNames.centerManager,
    roleNames.careCoordinator,
    roleNames.certRecovSpecialist,
    roleNames.ldac1,
    roleNames.ldac2,
    roleNames.socialWorker
  ],
  centersFull: [roleNames.centerManager, roleNames.billingSpecialist, roleNames.billingManager, roleNames.systemAdmin],
  canEditVisitType: [
    roleNames.provider,
    roleNames.centerManager,
    roleNames.systemAdmin,
    roleNames.careCoordinator,
    roleNames.certRecovSpecialist,
    roleNames.ldac1,
    roleNames.ldac2,
    roleNames.socialWorker
  ],
  providerAuditReportFullView: [roleNames.systemAdmin, roleNames.centerManager],
  centerDetailsView: [
    roleNames.centerManager,
    roleNames.billingSpecialist,
    roleNames.billingManager,
    roleNames.systemAdmin,
    roleNames.helpDesk
  ],
  viewLabOrders: [
    roleNames.labTech,
    roleNames.medicalAssist,
    roleNames.provider,
    roleNames.supProvider,
    roleNames.centerManager,
    roleNames.systemAdmin,
    roleNames.billingSpecialist,
    roleNames.billingManager,
    roleNames.billingDirector
  ],
  selectFirstMedicalVisitIfNoVisitSelected: [roleNames.medicalAssist],
  printPrimaryLabel: [roleNames.centerManager, roleNames.medicalAssist],
  addLabOrders: [roleNames.provider, roleNames.systemAdmin],
  canPrintLabDaqLabels: [roleNames.systemAdmin, roleNames.centerManager, roleNames.labTech],
  createStandingLabOrders: [roleNames.medicalAssist, roleNames.systemAdmin],
  cancelSubmittedLabOrders: [roleNames.centerManager, roleNames.systemAdmin],
  viewLabResults: [
    roleNames.medicalAssist,
    roleNames.receptionist,
    roleNames.labTech,
    roleNames.provider,
    roleNames.supProvider,
    roleNames.systemAdmin,
    roleNames.callCenter,
    roleNames.ldac1,
    roleNames.ldac2,
    roleNames.careCoordinator,
    roleNames.certRecovSpecialist,
    roleNames.centerManager,
    roleNames.billingManager,
    roleNames.socialWorker,
    roleNames.billingSpecialist,
    roleNames.billingDirector
  ],
  myPatientView: filterRoles(["receptionist", "systemAdmin"]),
  viewRequestedLabs: [
    roleNames.labTech,
    roleNames.medicalAssist,
    roleNames.provider,
    roleNames.supProvider,
    roleNames.centerManager,
    roleNames.systemAdmin
  ],
  labsCreate: [
    roleNames.labTech,
    roleNames.medicalAssist,
    roleNames.provider,
    roleNames.supProvider,
    roleNames.centerManager,
    roleNames.systemAdmin
  ],
  templatesView: [
    roleNames.provider,
    roleNames.supProvider,
    roleNames.systemAdmin,
    roleNames.ldac1,
    roleNames.ldac2,
    roleNames.centerManager
  ],
  counselingView: [
    roleNames.provider,
    roleNames.supProvider,
    roleNames.systemAdmin,
    roleNames.ldac1,
    roleNames.ldac2,
    roleNames.socialWorker,
    roleNames.certRecovSpecialist,
    roleNames.careCoordinator
  ],
  surveyEdit: [
    roleNames.provider,
    roleNames.supProvider,
    roleNames.ldac1,
    roleNames.ldac2,
    roleNames.socialWorker,
    roleNames.certRecovSpecialist,
    roleNames.careCoordinator,
    roleNames.centerManager,
    roleNames.systemAdmin
  ],
  providers: [roleNames.provider, roleNames.supProvider],
  medicalProvider: [roleNames.provider, roleNames.supProvider],
  bhProviders: [roleNames.careCoordinator, roleNames.certRecovSpecialist, roleNames.ldac1, roleNames.socialWorker],
  viewPrescriptions: filterRoles(["callCenter"]),
  editPrescriptions: [
    roleNames.medicalAssist,
    roleNames.provider,
    roleNames.supProvider,
    roleNames.systemAdmin,
    roleNames.centerManager,
    roleNames.callCenter,
    roleNames.receptionist,
    roleNames.labTech,
    roleNames.ldac1,
    roleNames.ldac2,
    roleNames.careCoordinator,
    roleNames.certRecovSpecialist,
    roleNames.socialWorker
  ],
  editPrescriptionPrescriber: [
    roleNames.medicalAssist,
    roleNames.provider,
    roleNames.systemAdmin,
    roleNames.receptionist,
    roleNames.centerManager,
    roleNames.supProvider
  ],
  prescriberEpcsSetup: [roleNames.provider, roleNames.supProvider],
  viewPrescriptionsToSign: [roleNames.provider, roleNames.centerManager],
  chartsToSign: [
    roleNames.provider,
    roleNames.supProvider,
    roleNames.ldac1,
    roleNames.ldac2,
    roleNames.centerManager,
    roleNames.careCoordinator,
    roleNames.certRecovSpecialist,
    roleNames.systemAdmin,
    roleNames.socialWorker
  ],
  addAmendment: [
    roleNames.provider,
    roleNames.supProvider,
    roleNames.ldac1,
    roleNames.ldac2,
    roleNames.careCoordinator,
    roleNames.socialWorker,
    roleNames.certRecovSpecialist
  ],
  canEditTreatmentPlan: [
    roleNames.provider,
    roleNames.supProvider,
    roleNames.ldac1,
    roleNames.ldac2,
    roleNames.socialWorker,
    roleNames.certRecovSpecialist,
    roleNames.careCoordinator
  ],
  canCancelTreatmentPlan: [roleNames.centerManager, roleNames.systemAdmin],
  canExportPatient: [roleNames.systemAdmin, roleNames.centerManager, roleNames.receptionist],
  viewAdminPage: [roleNames.systemAdmin, roleNames.centerManager],

  undoCancelAndCompleteVisit: [roleNames.systemAdmin, roleNames.centerManager],

  canEditNoteSlashTask: filterRoles([]),
  // canCompleteTask: filterRoles([]),
  canDeleteNoteSlashTask: [roleNames.systemAdmin, roleNames.centerManager],
  canRestoreNoteSlashTask: [roleNames.systemAdmin, roleNames.centerManager],

  canDeleteDocument: [roleNames.systemAdmin, roleNames.centerManager]
};
