import React from "react";
import { Navigate } from "react-router";
import withRouter from "../hoc/withRouter";

const Redirect = ({ from, to, router: { location } }) => {
  const _to = location.pathname.replace(from, to);
  return <Navigate to={_to} replace />;
};

export default withRouter(Redirect);
