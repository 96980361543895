import React, { Component } from "react";
import moment from "moment-timezone";
import { Search, Header, Segment } from "semantic-ui-react";
import shortid from "shortid";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce } from "lodash";
import * as searchActions from "../../actions/searchActions";
import withRouter from "../../hoc/withRouter";

const PatientSearchResultWrapper = (params) => (
  <div className={params.className} role="button" tabIndex={0} onClick={(e) => params.onClick(e)}>
    {params.children}
  </div>
);

function PatientSearchResult({ patientId, firstName, lastName, city, state, dateOfBirth }) {
    // render the -1 as a block that just says click for more
    if (patientId === -1) {
        return <Segment basic key={patientId}>
            <Header as="h3">MORE RESULTS...</Header>
            Click to see more results
        </Segment>;
    } 
        return <Segment basic key={patientId}>
            <Header as="h4" content={`${firstName} ${lastName}`} />
            {
                city && state ? (
                    <div>
                        {city}, {state} ({moment(dateOfBirth).tz('UTC').format("MM/DD/YYYY")})
                    </div>
                ) : (
                    "No address information."
                )
            }
        </Segment>;
    
};

class PatientSearch extends Component {
  state = {
    value: "",
    result: { patients: [], totalPatientsCount: 0 }
  };

  componentDidMount() {
    this.resetComponent();
  }

  resetComponent = () => this.setState({ result: { patients: [], totalPatientsCount: 0 }, value: "" });

  handleResultSelect = (e, data) => {
    const { patientId } = data.result;

    if (this.props.handlePatientSelected) {
      this.props.handlePatientSelected(this, data.result);
      return;
      }

    // if it's -1 they clicked the more results button.  Send them to advanced search.
    if (patientId === -1) {
      this.props.router.navigate(`/search/?searchValue=${data.value}`);
      this.resetComponent();
    } else {
      this.props.router.navigate(`/patient/${patientId}/demographics`);
      this.resetComponent();
    }
  };

  debouncedSearch = debounce(() => this.handleSearchChange(), 500);
  handleInput = (e, { value }) => {
    if (value.length < 3) this.setState({ result: { patients: [], totalPatientsCount: 0 }, value });
    else this.setState({ value }, this.debouncedSearch);
  };

  handleSearchChange = () => {
    const { value } = this.state;
    if (value.length < 1) {
      this.resetComponent();
    } else {
      const {
        actions: { searchPatientAdvanced }
      } = this.props;
      searchPatientAdvanced({ value, pageSize: 10, page: 1 }).then((result) => {
        this.setState({
          result: result.patients
        });
      });
    }
  };

    render() {
        const { value, result } = this.state;
        const { processing, className } = this.props;
        const mappedResults = result.patients.map((p) => ({
            childKey: `rand-${shortid.generate()}` /* using rand key here as it really happened on prod .. we have duplicate patient id */,
            as: PatientSearchResultWrapper,
            id: p.platformId,
            title: `${p.firstName} ${p.lastName}`,
            patientId: p.platformId,
            firstName: p.firstName,
            lastName: p.lastName,
            city: p.city,
            state: p.state,
            dateOfBirth: p.dateOfBirth
        }));

        // if there are more results then displayed add the -1 placeholder at the end
        // we will render that as the 'more results' widget
        if (result.totalPatientsCount > result.patients.length) {
            mappedResults.push(
            {
                childKey: `rand-${shortid.generate()}` /* using rand key here as it really happened on prod .. we have duplicate patient id */,
                as: PatientSearchResultWrapper,
                id: -1,
                patientId: -1,
                title: "More results..."
            })
        }
      
    return (
      <Search
        loading={processing}
        className={className || ""}
        onResultSelect={this.handleResultSelect}
        onSearchChange={this.handleInput}
        results={mappedResults}
        value={value}
        resultRenderer={PatientSearchResult}
        placeholder="Patient Search..."
        fluid
        id={`input-patient-search-rand-${shortid.generate()}`}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    patients: state.search.patients,
    processing: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(searchActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PatientSearch));
