/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: { user: "" },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    }
  }
});

export const { setUser } = authSlice.actions;

export const reducer = authSlice.reducer;

export default authSlice;
