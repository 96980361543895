import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Detector } from "react-detect-offline";
import { Button } from "../ui";
import * as userPreferenceActions from "../actions/userPreferenceActions";
import { isLocalhost } from "../helpers";

const __CS_OFFLINE_THRESHOLD_IN_SECONDS = 15;
class ConnectionLostAlert extends Component {
  state = {
    backOnline: false,
    offlineForNSeconds: 0
  };
  componentDidMount() {
    this.check();
  }
  componentDidUpdate() {
    this.check();
  }

  handleConnectionStateChange = (connected) => {
    if (!connected) {
      this.interval = setInterval(() => {
        this.setState({ backOnline: false, offlineForNSeconds: this.state.offlineForNSeconds + 1 }, () => {
          if (this.state.offlineForNSeconds >= __CS_OFFLINE_THRESHOLD_IN_SECONDS) {
            this.props.actions.setRetryConnectingIn(30);
            this.props.actions.setRetryReachingServerIn(-1);
          }
        });
      }, 1000);
    } else {
      clearInterval(this.interval);
      this.props.actions.setRetryConnectingIn(-1);
      if (this.state.offlineForNSeconds >= __CS_OFFLINE_THRESHOLD_IN_SECONDS) {
        this.setState({ backOnline: true });
        this.props.actions.setRetryReachingServerIn(3);
      }
      this.setState({ offlineForNSeconds: 0 });
    }
  };

  check = () => {
    const { retryReachingServerIn } = this.props;
    if (retryReachingServerIn === 0) {
      window.location.reload();
    }
    if (retryReachingServerIn > -1) {
      setTimeout(() => {
        if (this.props.retryReachingServerIn > -1)
          this.props.actions.setRetryReachingServerIn(retryReachingServerIn - 1);
      }, 1000);
    }
  };

  render() {
    const { retryReachingServerIn } = this.props;
    const { backOnline, offlineForNSeconds } = this.state;
    const servers = ["https://ipv4.icanhazip.com", window.location.origin];
    return isLocalhost() ? null : (
      <Detector
        polling={{ url: servers[offlineForNSeconds % 2], interval: 5000, timeout: 5000 }}
        onChange={this.handleConnectionStateChange}
        render={({ online }) =>
          online || offlineForNSeconds < __CS_OFFLINE_THRESHOLD_IN_SECONDS ? (
            retryReachingServerIn > -1 ? (
              <div
                style={{
                  position: "fixed",
                  top: "0",
                  bottom: "0",
                  left: "0",
                  right: "0",
                  backgroundColor: "rgba(0, 0, 0, 0.9)",
                  zIndex: "10001"
                }}
              >
                <div
                  className="text-center"
                  style={{ margin: "20% 15%", color: "white", opacity: "1", zIndex: "10002" }}
                >
                  <h3>
                    {backOnline ? `You are back online` : `Server is not reachable`}
                    {`, retrying to connect in ${retryReachingServerIn} seconds`}
                    {
                      <Button
                        variant="outline-warning"
                        onClick={() => this.props.actions.setRetryReachingServerIn(0)}
                        size="sm"
                        className="ms-3"
                      >
                        retry now
                      </Button>
                    }
                  </h3>
                </div>
              </div>
            ) : null
          ) : (
            <div className="most-important">
              <span>You lost internet connection, please try again later</span>
            </div>
          )
        }
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    retryReachingServerIn: state.userPreference.retryReachingServerIn
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...userPreferenceActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConnectionLostAlert);
