import * as types from "./actionTypes";
import { beginAjaxCall, ajaxCallDone } from "./ajaxStatusActions";
import { API_URL } from "../constants/environment";
import { pollApiEvents, processApiResponse, processApiError } from "../helpers";
import { adalApiFetch } from "../adalConfig";

function loadTreatmentPlanHistoryDone(history) {
  return {
    type: types.LOAD_TREATMENT_PLAN_HISTORY_DONE,
    history
  };
}

export function loadTreatmentPlanDone(treatmentPlan) {
  return {
    type: types.LOAD_TREATMENT_PLAN_DONE,
    treatmentPlan
  };
}

export function loadTreatmentPlanSummaryDone(treatmentPlanSummary) {
  return {
    type: types.LOAD_TREATMENT_PLAN_SUMMARY_DONE,
    treatmentPlanSummary
  };
}

export function loadTreatmentPlanHistory(patientId) {
  return (dispatch) => {
    dispatch(clearSelectedTreatmentPlan());
    dispatch(beginAjaxCall("loadTreatmentPlanHistory"));
    return adalApiFetch(`${API_URL}/TreatmentPlan/Patients/${patientId}/History`)
      .then((history) => dispatch(loadTreatmentPlanHistoryDone(history)))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function setSelectedTreatmentPlanDone(treatmentPlanId) {
  return {
    type: types.SET_SELECTED_TREATMENT_PLAN,
    treatmentPlanId
  };
}

export function setSelectedTreatmentPlan(treatmentPlanId) {
  return (dispatch) => {
    dispatch(setSelectedTreatmentPlanDone(treatmentPlanId));
    return Promise.resolve(true);
  };
}

export function clearSelectedTreatmentPlan() {
  return {
    type: types.CLEAR_SELECTED_TREATMENT_PLAN
  };
}

export function loadTreatmentPlan(treatmentPlanId, templateId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadTreatmentPlan"));
    return adalApiFetch(`${API_URL}/TreatmentPlan/${treatmentPlanId}/Template/${templateId}`)
      .then((treatmentPlan) => {
        const _treatmentPlan = { ...treatmentPlan, treatmentPlanId };
        dispatch(loadTreatmentPlanDone(_treatmentPlan));
        return _treatmentPlan;
      })
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function loadTreatmentPlanSummary(treatmentPlanId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("loadTreatmentPlan"));
    return adalApiFetch(`${API_URL}/TreatmentPlan/${treatmentPlanId}/Summary`)
      .then((summary) => {
        const _treatmentPlan = {
          treatmentPlanId,
          ...summary
        };
        dispatch(loadTreatmentPlanSummaryDone(_treatmentPlan));
        return _treatmentPlan;
      })
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function createTreatmentPlan(patientId, visitId = null) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createTreatmentPlan"));
    return (
      adalApiFetch(`${API_URL}/TreatMentPlan/Patient/${patientId}`, {
        method: "POST",
        body: JSON.stringify({ visitId })
      })
        .then((response) => processApiResponse(response.orchestrationId))
        .then((orchestrationId) => pollApiEvents(orchestrationId, "ITreatmentPlanCreatedEvent"))
        // .then(treatmentPlan => dispatch(createTreatmentPlanDone(treatmentPlan)))
        .catch((error) => processApiError(error, dispatch))
        .finally(() => dispatch(ajaxCallDone()))
    );
  };
}

export function updateTreatmentPlan(patientId, treatmentPlanId, answers) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createSurvey"));
    return (
      adalApiFetch(
        // `${API_URL}/Templates/UpdateTreatmentPlan`,
        `${API_URL}/TreatMentPlan/${treatmentPlanId}/Patient/${patientId}`,
        {
          method: "PUT",
          body: JSON.stringify(answers)
        }
      )
        .then((response) => processApiResponse(response.orchestrationId))
        .then((orchestrationId) => pollApiEvents(orchestrationId, "ITreatmentPlanUpdatedEvent"))
        // .then(() => dispatch(updateTreatmentPlanDone()))
        .catch((error) => processApiError(error, dispatch))
        .finally(() => dispatch(ajaxCallDone()))
    );
  };
}

export function signTreatmentPlan(patientId, treatmentPlanId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("createSurvey"));
    return (
      adalApiFetch(`${API_URL}/TreatmentPlan/${treatmentPlanId}/Patient/${patientId}/Sign`, {
        method: "PUT"
      })
        .then((response) => processApiResponse(response.orchestrationId))
        .then((orchestrationId) => pollApiEvents(orchestrationId, "ITreatmentPlanSignedEvent"))
        // .then(() => dispatch(signTreatmentPlanDone()))
        .catch((error) => processApiError(error, dispatch))
        .finally(() => dispatch(ajaxCallDone()))
    );
  };
}

export function deleteTreatmentPlan(patientId, treatmentPlanId) {
  return (dispatch) => {
    dispatch(beginAjaxCall("deleteTreatmentPlan"));
    return adalApiFetch(`${API_URL}/TreatmentPlan/${treatmentPlanId}/Patient/${patientId}`, {
      method: "DELETE"
    })
      .then((response) => processApiResponse(response.orchestrationId))
      .then((orchestrationId) => pollApiEvents(orchestrationId, "ITreatmentPlanDeletedEvent"))
      .catch((error) => processApiError(error, dispatch))
      .finally(() => dispatch(ajaxCallDone()));
  };
}

export function hideTreatmentPlanSurvey({ templateId, surveyId, patientId, snapshot }) {
  return {
    type: types.HIDE_TREATMENT_PLAN_SURVEY,
    context: "patient",
    templateId,
    surveyId,
    patientId,
    snapshot
  };
}

export function clearHiddenTreatmentPlanSurveys() {
  return {
    type: types.CLEAR_HIDDEN_TREATMENT_PLAN_SURVEYS
  };
}

export function clearHiddenTreatmentPlanSurvey({ patientId, templateId, surveyId }) {
  return {
    type: types.CLEAR_HIDDEN_TREATMENT_PLAN_SURVEY,
    patientId,
    templateId,
    surveyId
  };
}

export function clearTreatmentPlan() {
  return {
    type: types.CLEAR_TREATMENT_PLAN
  };
}

// center details
// /Centers/{centerId}/Details
