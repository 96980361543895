import React, { Component } from "react";
import { Button, Modal, Icon, Grid, Label } from "semantic-ui-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import shortid from "shortid";
import withRouter from "../../../hoc/withRouter";
import * as modalActions from "../../../actions/modalActions";
import * as prescriptionActions from "../../../actions/prescriptionActions";
import "../../reusable/Modal.css";
import "../history/PatientHistorySearchModal.css";
import "./ScreeningMatchModal.css";
import "./InteractionMatchModal.css";

export class InteractionMatchModal extends Component {
  state = {
    isLoading: false
  };
  handleClose = () => {
    this.props.actions.hideModal();
  };

  handleDelete = () => {
    const {
      prescription,
      patientId,
      actions: { deletePrescription },
      isQueuedRxForm,
      isPatientContext,
      router
    } = this.props;

    this.setState({ isLoading: true });

    deletePrescription(prescription.prescriptionId, patientId, isPatientContext).then(() => {
      this.setState({ isLoading: false });
      this.handleClose();
    });

    if (isQueuedRxForm) {
      router.navigate("/prescriptions-to-sign/");
    }
  };

  render() {
    const { open, prescription, interactionsResult: { interactions = [], warnings } = {} } = this.props;
    const { isLoading } = this.state;

    return (
      <Modal open={open} onClose={this.handleClose} closeIcon size="small">
        <Modal.Header>
          <Label className="interaction-match-alert-header" size="massive" circular>
            <Icon name="exclamation triangle" color="orange" />
            Drug Alerts: {prescription.drugName}
          </Label>
        </Modal.Header>
        <Modal.Content>
          <Grid columns={1} className="screening-match-alert-grid" relaxed stackable>
            <Grid.Column className="interaction-match-alert-row" width={16}>
              Drug Interactions
            </Grid.Column>
            {interactions.map((interaction) => (
              <React.Fragment key={shortid.generate()}>
                <Grid.Column floated="right" className="interaction-match-alert-cell" width={15}>
                  {interaction.drugName}
                </Grid.Column>
                <Grid.Column floated="right" width={15}>
                  {interaction.message}
                </Grid.Column>
              </React.Fragment>
            ))}
            <Grid.Column className="interaction-match-alert-row" width={16}>
              Drug Warnings
            </Grid.Column>
            <Grid.Column floated="right" width={15}>
              {warnings}
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleDelete} color="red" loading={isLoading}>
            Remove
          </Button>
          <Button onClick={this.handleClose} content="Continue anyway" primary loading={isLoading} />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      ...modalActions,
      ...prescriptionActions
    },
    dispatch
  )
});

export default connect(null, mapDispatchToProps)(withRouter(InteractionMatchModal));
