import initialState from "./initialState";
import * as types from "../actions/actionTypes";

export default function confirmationModalReducer(state = initialState.confirmationModal, action) {
  switch (action.type) {
    case types.SHOW_CONFIRMATION_MODAL: {
      const {
        modal: { props }
      } = action;
      return {
        show: true,
        props
      };
    }
    case types.HIDE_CONFIRMATION_MODAL:
      return { show: false, props: null };

    default:
      return state;
  }
}
