import React, { lazy } from "react";
import { Routes, Route } from "react-router-dom";

const IExportPatient = lazy(() => import("./patients/iexport"));

const BlankLayout = () => (
  <Routes>
    <Route exact path={`/patient/:patientId/iexport`} element={<IExportPatient />} />
  </Routes>
);

export default BlankLayout;
