import { isEmpty } from "lodash";
import {
  loadPendingOrders,
  confirmingSpecimenDateAndCenterDone,
  setStandingLabOrderSaving,
  loadRecentOrders,
  fetchRequestedLabs
} from "../../actions/labActions";
import { setRequestedLabsCount } from "../../actions/badgeActions";
import { printLabBarcode } from "../../components/patients/labs/labsSlice";
import { getDocument } from "../../actions/documentActions";

const store = window.cleanSlate.store;

const handleOrderCreated = (message) => {
  /* {"Type":"@Notification.Lab.OrderCreated","Title":"Pending Lab Order Updated","Content":{"patientId":"4925133c-c222-4312-9384-76b22e82138b","labOrderId":"3a91040b-c00d-43e1-9826-3eb35fbf2ced"},"Options":null,"Time":"2020-06-16T18:40:46.2882617+02:00"} */
  if (message.Content.patientId === store.getState().patient.currentPatient.patientId)
    store.dispatch(loadPendingOrders(message.Content.patientId));
  if (store.getState().labs.standingLabOrder.saving) {
    store.dispatch(loadRecentOrders(message.Content.patientId));
    store.dispatch(setStandingLabOrderSaving(false));
  }
};

const handleOrderUpdated = (message) => {
  if (message.Content.patientId === store.getState().patient.currentPatient.patientId)
    store.dispatch(loadPendingOrders(message.Content.patientId));
  if (store.getState().labs.standingLabOrder.saving) {
    store.dispatch(loadRecentOrders(message.Content.patientId));
    store.dispatch(setStandingLabOrderSaving(false));
  }
};

const handleSpecimenDateCenterSaved = (message) => {
  /* {"Type":"@Notification.Lab.SpecimenDateCenterSaved","Title":"Specimen Collection Date and Center saved","Content":{"labOrderId":"8fe939bb-a826-4c16-8987-9b3d7d6776b6","centerId":"8127c40c-06e1-48a9-a55e-ec4051863f6d","specimenCollectionDate":"2020-06-11T16:49:17.389+02:00"},"Options":null,"Time":"2020-06-11T16:51:38.8569738+02:00"} */
  store.dispatch(confirmingSpecimenDateAndCenterDone(message.Content.labOrderId, true));
};

const handleLabNotifications = (message) => {
  /*
  {"Type":"@Notification.Labs","Title":null,"Content":{"reload":true,"centerId":"8127c40c-06e1-48a9-a55e-ec4051863f6d","patientCount":5,"subType":"RequestedLabs"},"Options":null,"Time":"2020-10-26T18:29:25.5143304+02:00"}
  {"Type":"@Notification.Labs","Title":null,"Content":{"reload":true,"patientId":"059c4f22-adb3-42c6-9cbb-e7c01fe83aee","subType":"PatientRequestedLabs"},"Options":null,"Time":"2020-10-26T18:31:00.1157677+02:00"}
  */
  const state = store.getState();
  switch (message.Content.subType) {
    case "RequestedLabs":
      store.dispatch(setRequestedLabsCount(message.Content.patientCount));
      if (!isEmpty(state.labs.labSubmissionQueue.requestedLabs)) {
        store.dispatch(fetchRequestedLabs());
      }
      break;
    case "PatientRequestedLabs":
      store.dispatch(fetchRequestedLabs());
      break;
    default:
      break;
  }
};

const handleLabDaqOrderSubmitted = (message) => {
  /*
  {"Type":"@Notification.Lab.LabDaqOrderSubmitted","Title":null,"Content":{"orderSubmissionIdsWithLabCodes":{"684":["601"]}},"Options":null,"Time":"2023-10-27T21:32:44.4351791+02:00"}
  */
  if (message && message.Content && message.Content.orderSubmissionIdsWithLabCodes) {
    store.dispatch(
      printLabBarcode.initiate({
        body: {
          orderSubmissionIdsWithLabCodes: message.Content.orderSubmissionIdsWithLabCodes
        }
      })
    );
  }
};

const handleLabQuestOrderSubmitted = (message) => {
  ["labLabelDocumentKey", "requisitionDocumentKey"].forEach((attribute) => {
    if (message.Content[attribute]) {
      store
        .dispatch(
          getDocument(
            message.Content[attribute],
            message.LabProvider + message.Content[attribute],
            message.Content.container
          )
        )
        .then((response) => {
          const blob = new Blob([response.document], {
            type: "application/pdf"
          });
          const fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        });
    }
  });
};

export default {
  handleOrderCreated,
  handleOrderUpdated,
  handleSpecimenDateCenterSaved,
  handleLabNotifications,
  handleLabDaqOrderSubmitted,
  handleLabQuestOrderSubmitted
};
