// import { AuthenticationContext, adalFetch, withAdalLogin } from "react-adal";
import React from "react";
import { AuthenticationContext, adalFetch } from "react-adal";
import { GraphQLClient } from "graphql-request";
import { toast } from "react-toastify";
import moment from "moment";
import { API_URL, CLIENT_CODE, GRAPH_QL_END_POINT } from "./constants/environment";
import LoggingOut from "./components/LoggingOut";
import { getRoot, handleErrors, logError } from "./helpers";
import { toastErrorOptions } from "./constants/toastconfig";
import { dateWithFullTimeFormat } from "./constants/miscellaneous";
import { login } from "./actions/userActions";
import { setRetryReachingServerIn } from "./actions/userPreferenceActions";

export const adalConfig = {
  tenant: "CleanSlateCenter.onmicrosoft.com",
  clientId: CLIENT_CODE,
  endpoints: {
    api: CLIENT_CODE
  },
  cacheLocation: "sessionStorage",
  postLogoutRedirectUri: window.location.origin,
  redirectUri: window.location.origin,
  navigateToLoginRequestUrl: true,
  callback: login
};

export const authContext = new AuthenticationContext(adalConfig);

export const getToken = () => authContext.getCachedToken(authContext.config.clientId);

export const defaultOptions = {
  headers: {
    "Content-Type": "application/json"
  }
};

export const logout = () => authContext.logOut();

let __CS_REACHING_SERVER_TRIAL_COUNT = 0;

export const configureGraphQLClient = (endpoint = GRAPH_QL_END_POINT) => {
  window.cleanSlate.graphQLAbortController = new AbortController();
  return new GraphQLClient(endpoint, {
    signal: window.cleanSlate.graphQLAbortController.signal,
    headers: {
      authorization: `Bearer ${getToken()}`
    }
  });
};

export const adalApiFetch = (
  url,
  options = defaultOptions,
  parseJson = true,
  logoutIfForbidden = false,
  ignoreClientErrors = false
) => {
  const _options = { ...options };
  if (!_options.headers) _options.headers = defaultOptions.headers;
  _options.headers.Authorization = `Bearer ${getToken()}`;
  const connect = window?.cleanSlate?.store
    ? window.cleanSlate.store.getState().userPreference.retryReachingServerIn === -1
    : true;
  if (!connect) return Promise.reject();
  const promise = adalFetch(authContext, adalConfig.endpoints.api, fetch, url, _options)
    .catch((error) => {
      if (error instanceof DOMException && error.name === "AbortError") {
        throw Error("USER_ABORTED");
      } else if (error.msg === "login_required" || error.message === "User login is required") {
        const root = getRoot();
        root.render(<LoggingOut />);
        logout();
      } else if (error.message === "Token renewal operation failed due to timeout") {
        logError(error.message, url); // just log to let us know
      } else if (error.message === "Failed to fetch") {
        __CS_REACHING_SERVER_TRIAL_COUNT += 1;
        if (__CS_REACHING_SERVER_TRIAL_COUNT === 3) {
          window.cleanSlate.store.dispatch(setRetryReachingServerIn(30));
        }
        throw Error("IGNORE_THIS_ERROR");
      }
      // else if (...) { ... }
      else if (!ignoreClientErrors) {
        const message = `Error [${error.message}] while trying to reach endpoint ${url}`;
        if (url !== `${API_URL}/Admin/NonRecoverableError`) logError(message, url);
        toast.error(
          <span>
            <div>
              {`${error.message}`}
              <small style={{ float: "right", marginRight: "10px" }}>
                {moment().utc().format(dateWithFullTimeFormat)}
              </small>
            </div>
            {message}
            <br />
            <strong>{`Error reported to IT department`}</strong>
          </span>,
          toastErrorOptions
        );
        throw Error("IGNORE_THIS_ERROR");
      }
    })
    .then((response) => {
      if (!response) {
        logError(`undefined response for url: ${url}`, url);
        logout();
      }
      if (
        !response.ok &&
        (!ignoreClientErrors || response.status < 400 || response.status > 500) &&
        url !== `${API_URL}/Admin/NonRecoverableError`
      )
        return handleErrors(response, logoutIfForbidden);

      __CS_REACHING_SERVER_TRIAL_COUNT = 0;

      return response;
    });
  if (parseJson)
    return promise.then((response) =>
      ignoreClientErrors && response.status >= 400 && response.status < 500 ? Promise.resolve() : response.json()
    );
  return promise;
};

export const graphQLQuery = (query, variables) => {
  if (!window.cleanSlate.graphQLClient) throw Error("GrapgQL Client not found!");
  return window.cleanSlate.graphQLClient.request(query, variables);
};

export const getUser = () => {
  const user = authContext.getCachedUser();
  const trimmedUser = {
    userName: user.userName,
    profile: {
      roles: user.profile.roles,
      fullName: user.profile.name
    }
  };
  return trimmedUser;
};
