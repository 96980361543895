import React, { Component } from "react";
import { Button, Header, Modal, Form, Table, Grid, Message } from "semantic-ui-react";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DateInput } from "semantic-ui-calendar-react";
import { isEmpty } from "lodash";
import shortid from "shortid";
import * as modalActions from "../../../../actions/modalActions";
import * as patientHistoryActions from "../../../../actions/patientHistoryActions";
import * as lookupActions from "../../../../actions/lookupActions";
import { dateFormat, isoFormat, longDateFormat } from "../../../../constants/miscellaneous";
import "../../../reusable/Modal.css";
import "./AddInterventionHistoryModal.css";
import { dateRegEx } from "../../../../constants/validation";
import { removeSpecialCharacters, updateErrors } from "../../../../helpers";

const inputValidation = {
  startDate: dateRegEx,
  endDate: dateRegEx
};

const getInterventionHistory = (props) => {
  const { interventionHistory } = props;
  return (
    interventionHistory || {
      condition: props.conditionToAdd,
      startDate: moment().format(isoFormat),
      endDate: "",
      isComplete: false,
      notes: []
    }
  );
};

export class AddInterventionHistoryModal extends Component {
  state = {
    interventionHistory: getInterventionHistory(this.props),
    errors: {},
    isNoteInputAvailiable: false,
    isNoteInputDisable: false,
    interventionNotesUpdate: [],
    isSaveDisable: false,
    note: ""
  };

  componentDidMount() {
    const { patientId } = this.props;
    this.props.actions.loadInterventionHistory(patientId);
    const currentState = this.state;
    this.setState(currentState);
    this.props.actions.loadInterventionConditions();
  }

  componentWillUnmount() {
    const { patientId } = this.props;
    this.props.actions.loadInterventionHistory(patientId);
  }

  handleCheckbox = (e, data) => {
    const { name: field, checked } = data;
    const interventionHistory = {
      ...this.state.interventionHistory,
      [field]: checked,
      endDate:
        isEmpty(this.state.interventionHistory.endDate) && checked
          ? moment().endOf("day").format(isoFormat)
          : this.state.interventionHistory.endDate
    };
    this.setState({ interventionHistory });
  };

  handleInput = (e, data) => {
    const { name: field, value, required } = data;
    const errors = updateErrors(field, value, required, this.state.errors, inputValidation[field]);
    const interventionHistory = {
      ...this.state.interventionHistory,
      [field]: value.trim()
    };

    if (field === "startDate") {
      interventionHistory.startDate = isEmpty(value) ? `` : moment(value, dateFormat).format(isoFormat);
    }
    if (field === "endDate") {
      interventionHistory.endDate = isEmpty(value) ? `` : moment(value, dateFormat).format(isoFormat);
    }

    this.setState({ interventionHistory, errors });
  };

  handleMaskedInput = (e) => {
    const {
      type,
      target: { name: field, required, value }
    } = e;
    let errors = Object.assign({}, this.state.errors);
    const interventionHistory = Object.assign({}, this.state.interventionHistory, {
      [field]: value
    });

    if (type !== "focus" && type !== "blur") {
      errors = updateErrors(
        field,
        required ? removeSpecialCharacters(value) : value,
        required,
        errors,
        inputValidation[field]
      );
    }

    this.setState({ interventionHistory, errors });
  };

  handleSave = () => {
    this.setState({ isSaveDisable: true });
    const { patientId, conditionToAdd } = this.props;
    const interventionHistory = {
      ...this.state.interventionHistory,
      startDate: isEmpty(this.state.interventionHistory.startDate)
        ? ``
        : moment(this.state.interventionHistory.startDate).startOf("day").format(isoFormat),
      endDate: isEmpty(this.state.interventionHistory.endDate)
        ? ``
        : moment(this.state.interventionHistory.endDate).endOf("day").format(isoFormat)
    };

    if (!isEmpty(interventionHistory.endDate) && interventionHistory.endDate < interventionHistory.startDate) {
      this.setState({
        isSaveDisable: false,
        errors: { ...this.state.errors, endDate: `End date can't be before start date` }
      });
    } else {
      this.setState({ errors: {} });

      // Add intervention
      if (conditionToAdd) {
        this.props.actions.createInterventionHistory(patientId, interventionHistory).then(this.handleClose);
      }
      // Update Intervention
      else {
        interventionHistory.notes = this.state.interventionNotesUpdate;

        this.props.actions.updateInterventionHistory(patientId, interventionHistory).then(this.handleClose);
      }
    }
  };

  handleChangeNote = (e, data) => {
    const { value } = data;
    if (value.trim() !== "") {
      this.setState({ isNoteInputAvailiable: true });
    } else {
      this.setState({ isNoteInputAvailiable: false });
    }
    this.setState({ note: value });
  };

  handleAddNote = (notes) => () => {
    const noteInput = this.state.note;
    let updateNotes = [];
    // Current list note != null -> restore old note
    if (notes && notes.length > 0) {
      updateNotes = notes;
    }
    const noteAdd = {
      text: noteInput,
      noteAddedOn: new Date(),
      creatorFullName: this.props.userName
    };

    // Is update intervention history item => save new note to list separate old note list
    if (this.state.interventionHistory.itemId) {
      const { interventionNotesUpdate } = this.state;
      interventionNotesUpdate.unshift(noteAdd);
    } else {
      // Add new intervention history item -> only add one note
      this.setState({ isNoteInputDisable: true });
    }

    // Update to current notes
    updateNotes.unshift(noteAdd);
    const currentInterventionHistory = this.state.interventionHistory;

    currentInterventionHistory.notes = updateNotes;
    const updateInterventionHistory = Object.assign({}, currentInterventionHistory, { note: noteInput });

    this.setState({
      interventionHistory: updateInterventionHistory,
      note: "",
      isNoteInputAvailiable: false
    });
  };

  handleClose = () => this.props.actions.hideModal();

  render() {
    const { conditions, open, processing } = this.props;
    const { interventionHistory, errors, isNoteInputAvailiable, isNoteInputDisable, isSaveDisable } = this.state;
    const disableSave = Object.keys(errors).length !== 0 || isEmpty(interventionHistory.startDate);
    const { notes } = interventionHistory;
    const displayConditionText = isEmpty(conditions)
      ? ``
      : conditions.find((i) => i.code === interventionHistory.condition).text;
    return (
      <Modal className="add-intervention-history-modal" open={open} onClose={this.handleClose} closeIcon size="small">
        <Header content="Add Intervention History" />
        <h2>
          Condition:{" "}
          <span data-testid="condition-header" className="condition-header">
            {displayConditionText}
          </span>
        </h2>
        <Modal.Content>
          <Form>
            <Form.Group widths="three">
              <Form.Field className={Object.prototype.hasOwnProperty.call(errors, "startDate") ? "error field" : ""}>
                <label htmlFor="input-startDate">Start Date</label>
                <DateInput
                  id="input-startDate"
                  name="startDate"
                  placeholder="Start Date"
                  value={
                    isEmpty(interventionHistory.startDate)
                      ? ""
                      : moment(interventionHistory.startDate).format(dateFormat)
                  }
                  dateFormat={dateFormat}
                  iconPosition="right"
                  onChange={this.handleInput}
                  maxDate={this.props.interventionHistory ? null : moment()}
                  hideMobileKeyboard
                  required
                  closable
                />
              </Form.Field>
              <Form.Field className={Object.prototype.hasOwnProperty.call(errors, "endDate") ? "error field" : ""}>
                <label>End Date</label>
                <DateInput
                  id="input-endDate"
                  name="endDate"
                  placeholder="End Date"
                  value={
                    isEmpty(interventionHistory.endDate) ? "" : moment(interventionHistory.endDate).format(dateFormat)
                  }
                  dateFormat={dateFormat}
                  iconPosition="right"
                  onChange={this.handleInput}
                  hideMobileKeyboard
                  closable
                />
              </Form.Field>
              <Form.Field>
                <Form.Checkbox
                  label="Is Complete"
                  name="isComplete"
                  // required
                  checked={interventionHistory.isComplete}
                  onChange={this.handleCheckbox}
                  id="input-is-complete"
                />
              </Form.Field>
            </Form.Group>
            <Grid>
              <Grid.Column computer={13} tablet={13} moblie={13}>
                <Form.Input
                  className="intervention-history-note-input"
                  onChange={this.handleChangeNote}
                  id="input-note"
                  name="note"
                  disabled={isNoteInputDisable}
                  value={this.state.note}
                  label="Notes"
                />
              </Grid.Column>
              <Grid.Column className="btn-add" computer={3} tablet={3} mobile={3}>
                <Button
                  content="Add Note"
                  loading={processing}
                  color="blue"
                  id="btn-add-note"
                  onClick={this.handleAddNote(notes)}
                  disabled={!isNoteInputAvailiable}
                />
              </Grid.Column>
            </Grid>
            <div className="note-section">
              {notes
                ? notes.map((note) => (
                    <React.Fragment key={note.noteId || shortid.generate()}>
                      <Table unstackable>
                        <Table.Body>
                          <Table.Row>
                            <Table.Cell colSpan="2">
                              <p>
                                <strong>{note.text}</strong>
                              </p>
                            </Table.Cell>
                          </Table.Row>
                          <Table.Row className="grey">
                            {note.noteAddedOn && (
                              <Table.Cell>{moment(note.noteAddedOn).format(longDateFormat)}</Table.Cell>
                            )}
                            <Table.Cell>{note.creatorFullName}</Table.Cell>
                          </Table.Row>
                          <Table.Row />
                        </Table.Body>
                      </Table>
                    </React.Fragment>
                  ))
                : null}
            </div>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <div style={{ marginBottom: "1em" }}>
            {Object.keys(errors).length > 0 &&
              Object.keys(errors).map((key) =>
                isEmpty(errors[key]) ? null : <Message key={key} color="red" content={errors[key]} />
              )}
          </div>
          <Button onClick={this.handleClose} id="btn-cancel">
            Cancel
          </Button>
          <Button
            disabled={disableSave || isSaveDisable}
            loading={processing}
            onClick={this.handleSave}
            color="blue"
            id="btn-save"
          >
            Save
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    patientId: state.patient.currentPatient.patientId,
    processing: state.ajaxCallsInProgress > 0,
    userName: state.auth.user.profile.fullName,
    conditions: state.lookups.interventionConditions.sort((a, b) =>
      a.sortOrder > b.sortOrder ? 1 : b.sortOrder > a.sortOrder ? -1 : 0
    )
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...modalActions, ...patientHistoryActions, ...lookupActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddInterventionHistoryModal);
