import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEmpty, isEqual } from "lodash";
import { Container, Row, Col, Form, Button } from "../../../../ui";
import * as patientActions from "../../../../actions/patientActions";
import * as demographicActions from "../../../../actions/demographicActions";
import * as patientHistoryActions from "../../../../actions/patientHistoryActions";
import { CUSTOM_FIELD_ID_OTHER_PROVIDERS } from "../../../../constants/miscellaneous";
import { checkLengthError, removeExtraSpace } from "../../../../helpers";

class PatientOtherProviders extends Component {
  state = {
    model: { otherProviders: "" },
    originalModal: { otherProviders: "" },
    error: false,
    errorMessage: ""
  };

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    const { patientId, otherProvidersField } = this.props;
    if (!patientId || !otherProvidersField) return;
    this.fetchOtherProviders();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.patientId &&
      this.props.otherProvidersField &&
      (this.props.patientId !== prevProps.patientId ||
        !isEqual(this.props.otherProvidersField, prevProps.otherProvidersField))
    ) {
      this.fetchOtherProviders();
    }
    if (this.props.otherProviders !== prevProps.otherProviders) {
      const otherProviders = this.props.otherProviders;
      if (this._isMounted) this.setState({ model: { otherProviders }, originalModal: { otherProviders } });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetchOtherProviders = () => {
    const { patientId, otherProvidersField } = this.props;
    this.props.actions.fetchCustomFields(patientId, [otherProvidersField.customeFieldAthenaId]).then(() => {
      const model = {
        otherProviders: this.props.customFields[otherProvidersField.id]
          ? this.props.customFields[otherProvidersField.id].customFieldAnswer
          : ""
      };
      if (this._isMounted)
        this.setState({
          model,
          originalModal: { ...model }
        });
    });
  };

  handleInput = (e) => {
    const { name: field, value } = e.target;
    const model = Object.assign({}, this.state.model, {
      [field]: value
    });

    if (this._isMounted)
      this.setState({
        model,
        error: checkLengthError(model.otherProviders, 100)
      });
  };

  handleBlur = () => {
    const { otherProviders } = this.state.model;
    if (checkLengthError(otherProviders, 100)) {
      if (this._isMounted)
        this.setState({
          errorMessage: "Other Provider's name should not exceed 100 text characters."
        });
    }
    if (this._isMounted) this.setState({ errorMessage: "" });
  };

  handleSave = () => {
    const { patientId, otherProvidersField } = this.props;
    const {
      model: { otherProviders },
      error
    } = this.state;

    if (!error) {
      this.props.actions
        .saveCustomFields(patientId, [
          {
            customFieldId: otherProvidersField.customeFieldAthenaId,
            customFieldValue: removeExtraSpace(otherProviders.trim())
          }
        ])
        .then(() => {
          if (this._isMounted) this.setState({ originalModal: { otherProviders } });
          this.props.actions.updateOtherProviderSuccess(patientId, otherProviders);
          // this.props.actions.loadOtherProvidersHistory(this.props.patientId);
        });
    }
  };

  render() {
    const { model, originalModal, error, errorMessage } = this.state;
    const { saving } = this.props;
    const noChange = isEmpty(model.otherProviders) || model.otherProviders === originalModal.otherProviders;
    return (
      <div className="patient-other-provider-container">
        <Container>
          <Row>
            <Col md={8} className="p-0">
              <Form.Control
                type="text"
                id="input-other-provider"
                name="otherProviders"
                className="m-0"
                value={model.otherProviders || ""}
                onChange={this.handleInput}
                onBlur={this.handleBlur}
                // error={error}
              />
            </Col>
            <Col md={2}>
              <Button
                color="blue"
                onClick={this.handleSave}
                content=""
                loading={saving}
                disabled={saving || error || noChange}
              >
                Save
              </Button>
            </Col>
            <Col md={2} />
          </Row>
          {errorMessage.length > 0 && (
            <Row>
              <div className="search-error">{errorMessage}</div>
            </Row>
          )}
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const otherProvidersField = state.lookups.patientCustomFields.find((cf) => cf.id === CUSTOM_FIELD_ID_OTHER_PROVIDERS);

  return {
    otherProvidersField,
    patientId: state.patient.currentPatient.patientId,
    customFields: state.patient.customFields,
    saving: state.ajaxCallsInProgress > 0
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ ...patientActions, ...demographicActions, ...patientHistoryActions }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientOtherProviders);
