import React from "react";
import { PersistGate } from "redux-persist/integration/react";
import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { loadCentersLookup } from "./actions/lookupActions";
import { getUser } from "./adalConfig";
import App from "./components/App";
import { getRoot } from "./helpers";
import { setUser } from "./reducers/authSlice";
import { persistor } from "./store";

const store = window.cleanSlate.store;

const authUser = getUser();

store.dispatch(setUser(authUser));

store.dispatch(loadCentersLookup());

const root = getRoot();
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);
serviceWorkerRegistration.register();
