import { setDefaultPrescribingProviderDone } from "../../actions/demographicActions";
import { loadPatientSummary } from "../../actions/patientActions";

const store = window.cleanSlate.store;

/* {"Type":"@Notification.User.PatientPrescriberUnassigned","Title":"Patient 41b8d85d-3482-485c-8b25-87a37adba259 assigned to prescriber e373e2e6-b38a-4500-9345-ad7411d2418c","Content":{"providerId":"e373e2e6-b38a-4500-9345-ad7411d2418c","patientId":"41b8d85d-3482-485c-8b25-87a37adba259","orchestrationId":"f76c15d2-a1b5-4d4a-b313-c3a7274ce644","platformEventId":"a7299d00-d6ff-4007-ae06-3f82c901ed73","revision":730},"Options":null,"Time":"2020-06-05T18:48:19.6629635+02:00"} */
const handlePatientPrescriberUnassigned = (message) => {
  const state = store.getState();
  if (
    state.demographics.prescribingProvider &&
    state.demographics.prescribingProvider.providerId === message.Content.providerId
  ) {
    store.dispatch(store.dispatch(setDefaultPrescribingProviderDone(message.Content.patientId, null)));
  }
};

/* {"Type":"@Notification.User.PatientPrescriberAssigned","Title":"Patient 41b8d85d-3482-485c-8b25-87a37adba259 assigned to prescriber 177624d5-8cab-e911-bcd0-0003ffca95c7","Content":{"providerId":"177624d5-8cab-e911-bcd0-0003ffca95c7","patientId":"41b8d85d-3482-485c-8b25-87a37adba259","orchestrationId":"f76c15d2-a1b5-4d4a-b313-c3a7274ce644","platformEventId":"9c24e302-ff42-4297-8882-29ffd36a080f","revision":7109},"Options":null,"Time":"2020-06-05T18:48:19.8939945+02:00"} */
const handlePatientPrescriberAssigned = (message) => {
  const prescribingProvider = store
    .getState()
    .lookups.prescribersWithSlots.find((p) => p.platformId === message.Content.providerId);
  store.dispatch(
    store.dispatch(
      setDefaultPrescribingProviderDone(message.Content.patientId, {
        ...prescribingProvider,
        providerId: prescribingProvider.platformId
      })
    )
  );
};

/* {"Type":"@Notification.User.PatientInterventionFlagsUpdated","Title":null,"Content":{"patientId":"4925133c-c222-4312-9384-76b22e82138b","reload":true},"Options":null,"Time":"2021-10-19T21:21:58.9635627+02:00"} */
const handlePatientInterventionFlagsUpdated = (message) => {
  if (message.Content.reload) store.dispatch(loadPatientSummary(message.Content.patientId));
};

export default {
  handlePatientPrescriberUnassigned,
  handlePatientPrescriberAssigned,
  handlePatientInterventionFlagsUpdated
};
